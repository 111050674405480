/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Field Phone Component plugin
 */
export class FieldPhoneComponentPlugin {
    // eslint-disable-next-line consistent-return
    aroundMaskBuilder = (args, callback, instance) => {
        const { prefix } = instance.props;

        if (prefix) {
            return callback(...args);
        }
    };
}

const {
    aroundMaskBuilder
} = new FieldPhoneComponentPlugin();

export const config = {
    'Component/FieldPhone/Component': {
        'member-function': {
            maskBuilder: aroundMaskBuilder
        }
    }
};

export default config;
