/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable prefer-rest-params */
/* eslint-disable fp/no-arguments */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const DOT_MAILER_PROFILE_ID = 'DM-9918492070-02';
export const DOT_MAILER_TRACKING_URL = 'mx.puma.com';

/**
 * Dot Mailer Tracking
 */
export class DotMailerTracking {
    initDotMailerTrackingScript() {
        const script = document.createElement('script');

        script.dangerouslySetInnerHTML = (
            (function (w, d, u, t, o, c) {
                w.dmtrackingobjectname = o; c = d.createElement(t); c.async = 1; c.src = u; t = d.getElementsByTagName(t)[0]; t.parentNode.insertBefore(c, t); w[o] = w[o] || function () {
                    (w[o].q = w[o].q || []).push(arguments);
                };
            })(window, document, '//static.trackedweb.net/js/_dmptv4.js', 'script', 'dmPt')
        );

        window.dmPt('create', DOT_MAILER_PROFILE_ID, DOT_MAILER_TRACKING_URL);

        document.body.appendChild(script);
    }
}

export default new DotMailerTracking();
