/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import {
    RenderWhenVisible as SourceRenderWhenVisible
} from 'SourceComponent/RenderWhenVisible/RenderWhenVisible.component';

import './RenderWhenVisible.style';

/** @namespace Scandipwa/Component/RenderWhenVisible/Component */
export class RenderWhenVisibleComponent extends SourceRenderWhenVisible {
    static propTypes = {
        ...SourceRenderWhenVisible.propTypes,
        threshold: PropTypes.number,
        rootMargin: PropTypes.string,
        isWrappingChildren: PropTypes.bool,
        rootTag: PropTypes.string,
        isMirroringChildren: PropTypes.bool,
        rootBlock: PropTypes.string
    };

    static defaultProps = {
        ...SourceRenderWhenVisible.defaultProps,
        threshold: 0,
        isWrappingChildren: true, // source component wraps the children with div
        rootTag: 'div',
        rootBlock: 'RenderWhenVisible',
        rootMargin: '0px'
    };

    renderVisibilitySensor() {
        const { threshold, rootMargin } = this.props;
        return (
            <InView onChange={ this.handleVisibilityToggle } threshold={ threshold } rootMargin={ rootMargin }>
                { this.renderFallback() }
            </InView>
        );
    }

    /**
     * Overriden to allow possibility to change the root tag of the component
     * @returns {JSX.Element}
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    defaultRender() {
        const { rootTag: RootTag, rootBlock } = this.props;
        return (
            <RootTag
              block={ rootBlock }
              ref={ (node) => {
                  this.node = node;
              } }
            >
                { this.renderContent() }
            </RootTag>
        );
    }

    /**
     * Overriden to allow possibility to render children without wrapping them after the component is visible
     * @returns {JSX.Element}
     */
    render() {
        const { isWrappingChildren } = this.props;
        const { wasVisible } = this.state;

        if (isWrappingChildren || !wasVisible) {
            return this.defaultRender();
        }

        return this.renderChildren();
    }
}

export default RenderWhenVisibleComponent;
