export * from 'SourceUtil/Address';

/** @namespace Scandipwa/Util/Address/Index/trimAddressFields */
export const trimAddressFields = (fields) => {
    const {
        region_string: region,
        ...fieldsData
    } = fields;

    return { ...fieldsData, region };
};
