/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getInitialState as sourceGetInitialState } from 'SourceStore/Notification/Notification.reducer';

import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './Notification.action';

/** @namespace Scandipwa/Store/Notification/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isNotificationListVisible: false
});

/** @namespace Scandipwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (
    state = getInitialState(),
    action
) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
    case SHOW_NOTIFICATION:
        const {
            msgType, msgText, msgDebug, showDebugInProduction
        } = action;

        // Dump the stacktrace in the console so jam.dev can record it
        const stackTrace = new Error();
        // eslint-disable-next-line no-console
        console.warn(msgText);
        console.warn(stackTrace);

        notifications[Date.now()] = {
            msgType, msgText, msgDebug, showDebugInProduction
        };

        return {
            ...state,
            notifications,
            isNotificationListVisible: true
        };

    case HIDE_NOTIFICATION:
        // eslint-disable-next-line no-unused-vars
        const { [action.id]: id, ...shownNotifications } = notifications;

        return {
            ...state,
            notifications: shownNotifications
        };

    default:
        return state;
    }
};

export default NotificationReducer;
