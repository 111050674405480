/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';

/** @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    static propTypes = {
        ...super.propTypes,
        // eslint-disable-next-line react/boolean-prop-naming
        disablePercentageLabel: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        disablePercentageLabel: false
    };

    getCurrentPriceSchema() {
        return { itemProp: 'price' };
    }

    /**
     * Renders old price
     *
     * @returns {JSX.Element}
     */
    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            currency
        } = this.props;

        return (
            <span
              block="ProductPrice"
              elem="HighPrice"
              mods={ { isVisible: discountPercentage > 0 } }
              aria-label={ __('Old product price') }
            >
                <span>{ currency }</span>
                { roundedRegularPrice }
            </span>
        );
    }

    /**
     * Render current price
     *
     * @returns {JSX.Element}
     */
    renderCurrentPrice() {
        const {
            roundedFinalPrice,
            currency
        } = this.props;

        const schema = this.getCurrentPriceSchema();

        const PriceSemanticElementName = 'ins';

        return (
            <PriceSemanticElementName
              block="ProductPrice"
              elem="CurrentPrice"
            >
                <data
                  value={ roundedFinalPrice }
                >
                    <span>{ currency }</span>
                    <span { ...schema }>{ roundedFinalPrice }</span>
                </data>
            </PriceSemanticElementName>
        );
    }

    renderPercentageDiscountLabel() {
        const {
            discountPercentage,
            disablePercentageLabel
        } = this.props;

        if (!discountPercentage || disablePercentageLabel) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="PercentageLabel">
            { Math.round(discountPercentage) }
            % OFF
            </span>
        );
    }

    renderSchema() {
        const { isSchemaRequired, priceCurrency } = this.props;

        if (isSchemaRequired) {
            return (
                <meta itemProp="priceCurrency" content={ priceCurrency } />
            );
        }

        return null;
    }

    render() {
        const { price } = this.props;

        if (!price) {
            return null;
        }

        const {
            roundedFinalPrice,
            currency,
            mix
        } = this.props;

        if (!roundedFinalPrice) {
            return this.renderPlaceholder();
        }

        return (
            <p
              block="ProductPrice"
              mix={ mix }
              aria-label={ `Product price: ${ roundedFinalPrice }${ currency }` }
              data-clarity-unmask="true"
            >
                { this.renderCurrentPrice() }
                { this.renderOldPrice() }
                <br />
                { this.renderPercentageDiscountLabel() }
                { this.renderSchema() }
            </p>
        );
    }
}

export default ProductPriceComponent;
