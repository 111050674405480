/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router';

import CheckoutTermsAndConditions from 'Component/CheckoutTermsAndConditions';
import Loader from 'Component/Loader';
import { TotalsType } from 'Type/MiniCart.type';
import { appendWithStoreCode } from 'Util/Url';

import { PAYPAL_SCRIPT } from './PayPal.config';

import './PayPal.style';

/**
 * *Note*
 * This component currently can be rendered only once
 * Please try to have no more than 1 component per page and use isDisabled to hide it.
 * @namespace Paypal/Component/PayPal/Component */
export class PayPalComponent extends PureComponent {
    static propTypes = {
        isDisabled: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        paypal: PropTypes.any.isRequired,
        clientId: PropTypes.string.isRequired,
        cartTotals: TotalsType.isRequired,
        onError: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onApprove: PropTypes.func.isRequired,
        createOrder: PropTypes.func.isRequired,
        environment: PropTypes.oneOf([
            'production',
            'sandbox'
        ]).isRequired,
        code: PropTypes.string.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        isTACAccepted: PropTypes.bool.isRequired,
        setTACStatus: PropTypes.func.isRequired,
        location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        shouldRedirect: PropTypes.bool.isRequired,
        orderId: PropTypes.string.isRequired,
        activeOverlay: PropTypes.string,
        isCartOverlay: PropTypes.bool
    };

    static defaultProps = {
        isDisabled: false,
        activeOverlay: '',
        isCartOverlay: false
    };

    getClientId = () => {
        const {
            clientId
        } = this.props;

        return clientId;
    };

    renderButtons() {
        const {
            paypal,
            onError,
            onCancel,
            onApprove,
            createOrder,
            environment,
            isTACAccepted,
            setTACStatus,
            activeOverlay,
            isCartOverlay
        } = this.props;

        if (!paypal) {
            return <Loader isLoading />;
        }

        const PayPalButton = paypal && paypal.Buttons.driver('react', { React, ReactDOM });

        return (
            <>
                <CheckoutTermsAndConditions
                  setTACStatus={ setTACStatus }
                  isTACAccepted={ isTACAccepted }
                  activeOverlay={ activeOverlay }
                  isCartOverlay={ isCartOverlay }
                  isPaypal
                />
                <div block="PayPalButtons" mods={ { isDisabled: !isTACAccepted } }>
                    <div block="PayPalButtons">
                        <PayPalButton
                          env={ environment }
                          onError={ onError }
                          onCancel={ onCancel }
                          onApprove={ onApprove }
                          createOrder={ createOrder }
                          style={ {
                              layout: 'horizontal',
                              label: 'pay'
                          } }
                        />
                    </div>
                </div>
            </>
        );
    }

    getPayPalScript = () => {
        const {
            cartTotals: { prices: { quote_currency_code } },
            code
        } = this.props;

        const params = {
            currency: quote_currency_code,
            intent: 'capture',
            'client-id': this.getClientId(),
            locale: code === 'en' ? 'en_US' : 'es_MX'
        };

        const paypalScript = document.getElementById(PAYPAL_SCRIPT);

        if (!paypalScript) {
            const paramsString = (Object.entries(params).map(([key, value]) => `${key}=${value}`)).join('&');

            const script = document.createElement('script');
            script.id = PAYPAL_SCRIPT;
            script.src = `https://www.paypal.com/sdk/js?${ paramsString }`;
            script.async = false;

            document.body.appendChild(script);
        }

        return null;
    };

    render() {
        const { isDisabled } = this.props;

        const { shouldRedirect } = this.props;

        if (shouldRedirect) {
            const { orderId } = this.props;

            const redirectProps = {
                orderId
            };

            return (
                <Redirect
                  to={ {
                      pathname: appendWithStoreCode('/checkout/success'),
                      state: redirectProps
                  } }
                />
            );
        }

        return (
            <div block="PayPal" mods={ { isDisabled } }>
                { this.getPayPalScript() }
                { this.renderButtons() }
            </div>
        );
    }
}

export default PayPalComponent;
