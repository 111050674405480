/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import WishlistHeartIcon from 'Component/WishlistHeartIcon';
import {
    ProductWishlistButton as SourceProductWishlistButton
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.style';
import './ProductWishlistButton.style.override';

/**
 * Button for adding product to Cart
 * @class ProductWishlistButton
 * @namespace Scandipwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButtonComponent extends SourceProductWishlistButton {
    renderInWishlistText() {
        return (
            <span
              block="ProductWishlistButton"
              elem="ButtonText"
            >
                { __('Remove from Wishlist') }
            </span>
        );
    }

    renderAddToWishlistText() {
        return (
            <span
              block="ProductWishlistButton"
              elem="ButtonText"
            >
                { __('Add to Wishlist') }
            </span>
        );
    }

    renderButton() {
        const { isInWishlist, isDisabled, mix } = this.props;

        return (
            <button
              block="ProductWishlistButton"
              elem="Button"
              mods={ { isInWishlist, isDisabled } }
              mix={ { block: 'Button', mods: { isHollow: !isInWishlist }, mix } }
              title={ this.getTitle() }
              onClick={ this.onClick }
            >
                <WishlistHeartIcon
                  mix={ { block: 'ProductWishlistButton', elem: 'ProductWishlistIcon' } }
                />
                    { isInWishlist ? this.renderInWishlistText() : this.renderAddToWishlistText() }
            </button>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderButton() }
                { this.renderLoader() }
            </>
        );
    }
}

export default ProductWishlistButtonComponent;
