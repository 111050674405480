import { ProductConfigurableAttributeDropdownContainer as SourceComponent }
from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.container';

/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Container */
export class ProductConfigurableAttributeDropdownContainer extends SourceComponent {
    containerProps() {
        const {
            isLabelInner,
            innerLabel
        } = this.props;

        return {
            ...super.containerProps(),
            isLabelInner,
            innerLabel
        };
    }
}

export default ProductConfigurableAttributeDropdownContainer;
