/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Link from 'Component/Link';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import { BIG_SCREEN_HOMEPAGE } from '../../../route/QrPage/QrPage.config';

/**
 * NoMatch Component plugin
 */
export class BreadCrumbsComponentPlugin {
    renderHomeBreadcrumb = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);
        const { search, breadcrumbs } = instance.props;

        if (!isBigScreen) {
            return callback(...args);
        }

        if (!breadcrumbs.length && !search) {
            return null;
        }

        return (
            <Link
              block="Breadcrumbs"
              elem="Home"
              mods={ { isNotHomeBreadcrumb: (!search && breadcrumbs.length > 1) } }
              to={ BIG_SCREEN_HOMEPAGE }
            >
                <span>{ __('Home') }</span>
            </Link>
        );
    };
}

const {
    renderHomeBreadcrumb
} = new BreadCrumbsComponentPlugin();

export const config = {
    'Component/Breadcrumbs/Component': {
        'member-function': {
            renderHomeBreadcrumb
        }
    }
};

export default config;
