/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    setEnterPressed,
    setInputName,
    setInputs,
    setIsKeyboardClicked,
    setIsKeyboardOpened
} from '../../store/Keyboard';
import DraggableKeyboard from './DraggableKeyboard.component';

/** @namespace Bigscreen/Component/DraggableKeyboard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    locale: state.ConfigReducer.locale || '',
    inputName: state.KeyboardReducer.inputName,
    isKeyboardOpened: state.KeyboardReducer.isKeyboardOpened,
    isKeyboardClicked: state.KeyboardReducer.isKeyboardClicked,
    inputs: state.KeyboardReducer.inputs
});

/** @namespace Bigscreen/Component/DraggableKeyboard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setInputs: (inputs) => dispatch(setInputs(inputs)),
    setInputName: (input) => dispatch(setInputName(input)),
    setEnterPressed: (isEnterPressed) => dispatch(setEnterPressed(isEnterPressed)),
    setIsKeyboardClicked: (isKeyboardClicked) => dispatch(setIsKeyboardClicked(isKeyboardClicked)),
    setIsKeyboardOpened: (isKeyboardOpened) => dispatch(setIsKeyboardOpened(isKeyboardOpened))
});

/** @namespace Bigscreen/Component/DraggableKeyboard/Container */
export class DraggableKeyboardContainer extends PureComponent {
    static propTypes = {
        locale: PropTypes.string.isRequired,
        setInputs: PropTypes.func.isRequired,
        setEnterPressed: PropTypes.func.isRequired,
        setIsKeyboardClicked: PropTypes.func.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        inputName: PropTypes.string.isRequired,
        isKeyboardOpened: PropTypes.bool.isRequired,
        isKeyboardClicked: PropTypes.bool.isRequired
    };

    render() {
        return (
            <DraggableKeyboard
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DraggableKeyboardContainer);
