import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    SearchOverlayContainer as SourceSearchOverlayContainer
} from 'SourceComponent/SearchOverlay/SearchOverlay.container';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import browserHistory from 'Util/History';

export const SearchBarDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SearchBar/SearchBar.dispatcher'
);

/** @namespace Scandipwa/Component/SearchOverlay/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    suggestions: state.SearchBarReducer.suggestions,
    display_suggestions: state.SearchBarReducer.display_suggestions,
    display_results: state.SearchBarReducer.display_results
});

/** @namespace Scandipwa/Component/SearchOverlay/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    makeSearchRequest: (options) => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
    ),
    clearSearchResults: () => SearchBarDispatcher.then(
        ({ default: dispatcher }) => dispatcher.clearSearchResults(dispatch)
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/SearchOverlay/Container */
export class SearchOverlayContainer extends SourceSearchOverlayContainer {
    static propTypes = {
        ...this.propTypes,
        suggestions: PropTypes.arrayOf(PropTypes.string),
        display_suggestions: PropTypes.number,
        display_results: PropTypes.number
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleSuggestionClick: this.handleSuggestionClick.bind(this)
    };

    handleSuggestionClick(event, url) {
        const { clearSearch, closeSearch } = this.props;

        event.preventDefault();

        browserHistory.push(url);

        clearSearch();
        closeSearch();
    }

    makeSearchRequest() {
        const {
            makeSearchRequest,
            clearSearchResults,
            searchCriteria
        } = this.props;

        if (searchCriteria) {
            makeSearchRequest({
                args: {
                    search: searchCriteria,
                    pageSize: 24,
                    currentPage: 1
                }
            });
        } else {
            clearSearchResults();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchOverlayContainer);
