/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from 'SourceComponent/NavigationTabs/NavigationTabs.config';
export const SEARCH_TAB = 'SEARCH_TAB';
export const NAVIGATION_SEARCH = 'navigation_search';
export const MOBILE_MENU = 'MOBILE_MENU';
export const EXTRA_LARGE_SCREEN_WIDTH = 1181;
export const SUBSCRIBE_TAB = 'SUBSCRIBE_TAB';
