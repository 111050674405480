/* eslint-disable array-callback-return */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const DEFAULT_SKU = '{SKU}';
export const DEFAULT_CATEGORY_ID = '{IDCATEGORY}';
export const DEFAULT_USER_ID = '{USERID}';
export const DEFAULT_CHECKOUT_STEP = 1;

export const GROOVIN_PIXELS_CART = 'cart';
export const GROOVIN_PIXELS_CONVERSION = 'conversion';
export const GROOVIN_PIXELS_RETARGETING = 'retargeting';

/**
 * Groovin pixels
 * @namespace GroovinPixels/Util/GroovinPixels/Index
 */
export class GroovinPixels {
    groovinPixelsTags = {
        [GROOVIN_PIXELS_RETARGETING]: {
            id: 'grooving-pixels-retargeting',
            // Should be added to all website pages -> app/design/frontend/Scandiweb/pwa/src/public/root.phtml
            src: 'https://ads01.groovinads.com/grv/track/bhpx.os?idc=2827&sku={SKU}&fgjs=1&idcategory={IDCATEGORY}&uid={USERID}'
        },
        [GROOVIN_PIXELS_CART]: {
            id: 'grooving-pixels-cart',
            src: 'https://ads01.groovinads.com/grv/track/bhpx.os?idc=2827&sku={SKU}&idcategory={IDCATEGORY}&fgjs=1&uid={USERID}&cart=1'
        },
        [GROOVIN_PIXELS_CONVERSION]: {
            id: 'grooving-pixels-conversion',
            src: 'https://ssl01.groovinads.com/grv/track/px.os?fgimg=0&idpixel=2701&goalvalue={transaction_value}&idtransaction={your_id_transaction}'
        }
    };

    updateGroovinPixels(params, tag) {
        const groovinPixelsTag = this.groovinPixelsTags[tag];
        const { id, src: groovingPixelsCartScriptSrc } = groovinPixelsTag;

        const groovingPixelsCartScript = this._getGroovinPixelsScript(id);
        const updateParams = params && Object.keys(params).length;

        if (groovingPixelsCartScript) {
            this.removeGroovinPixels(tag);
        }

        const script = document.createElement('script');
        script.src = updateParams
            ? this._getNewScript(groovingPixelsCartScriptSrc, params) : groovingPixelsCartScriptSrc;
        script.id = id;
        script.async = true;

        document.body.appendChild(script);
    }

    removeGroovinPixels(tag) {
        const groovinPixelsTag = this.groovinPixelsTags[tag];
        const { id } = groovinPixelsTag;

        const groovingPixelsTag = document.getElementById(id);

        if (groovingPixelsTag) {
            groovingPixelsTag.remove();
        }
    }

    _getNewScript = (script, params) => {
        // eslint-disable-next-line fp/no-let
        let newScript = script;

        if (!Object.keys(params).length) {
            return script;
        }

        Object.entries(params).map(([key, value]) => {
            const replace = new RegExp(`(${key}=).*?(&|$)`, 'g');

            newScript = newScript.replace(replace, `$1${ value }$2`);
        });

        return newScript;
    };

    _getGroovinPixelsScript(id) {
        return document.getElementById(id);
    }

    _getDefaultParameters() {
        return {
            sku: DEFAULT_SKU,
            idcategory: DEFAULT_CATEGORY_ID,
            uid: DEFAULT_USER_ID,
            cart: DEFAULT_CHECKOUT_STEP
        };
    }
}

export default new GroovinPixels();
