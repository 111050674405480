import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubscriptionQuery from 'Query/Subscription.query';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { fetchMutation } from 'Util/Request';

import SubscriptionPopup from './SubscriptionPopup.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "accountDispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/SubscriptionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key)),
    showPopup: (payload) => dispatch(showPopup('title', payload)),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    )
});

/** @namespace Scandipwa/Component/SubscriptionPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    isSubscriptionPopupActive: state.ConfigReducer.isSubscriptionPopupActive
});

/** @namespace Scandipwa/Component/SubscriptionPopup/Container */
export class SubscriptionPopupContainer extends PureComponent {
    static propTypes = {
        requestCustomerData: PropTypes.func.isRequired,
        isSignedIn: PropTypes.func.isRequired,
        isSubscriptionPopupActive: PropTypes.bool.isRequired
    };

    containerFunctions = {
        subscribe: this.subscribe.bind(this)
    };

    componentDidMount() {
        const { requestCustomerData, isSignedIn } = this.props;

        if (isSignedIn) {
            requestCustomerData();
        }
    }

    subscribe(email, response) {
        const requestField = SubscriptionQuery.getSubscriptionEmailQuery(email);

        fetchMutation(requestField).then(
            /** @namespace Scandipwa/Component/SubscriptionPopup/Container/SubscriptionPopupContainer/subscribe/fetchMutation/then */
            ({ addEmailToSubscription }) => {
                response(addEmailToSubscription);
            },
            /** @namespace Scandipwa/Component/SubscriptionPopup/Container/SubscriptionPopupContainer/subscribe/fetchMutation/then/catch */
            ([{ message }]) => {
                response(message);
            }
        );
    }

    render() {
        return (
            <SubscriptionPopup
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPopupContainer);
