/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const ADYEN_PAYMENTS_ID = 'ADYEN_PAYMENTS_ID';

// vvv Adyen Payment method codes
export const ADYEN_GC_METHOD_CODE = 'giftcard';
export const ADYEN_CC_METHOD_CODE = 'adyen_cc';
export const ADYEN_HPP_METHOD_CODE = 'adyen_hpp';
export const ADYEN_BOLETO_METHOD_CODE = 'adyen_boleto';
export const ADYEN_CC_VAULT_METHOD_CODE = 'adyen_cc_vault';
export const ADYEN_ONECLICK_METHOD_CODE = 'adyen_oneclick';
export const ADYEN_POS_CLOUD_METHOD_CODE = 'adyen_pos_cloud';
export const ADYEN_OXXO_METHOD_CODE = 'adyen_oxxo';

// vvv Adyen HPP payment types
export const ADYEN_CARD_PAYMENT_TYPE = 'card';
export const ADYEN_SCHEME_PAYMENT_TYPE = 'scheme';
export const ADYEN_PAYPAL_PAYMENT_TYPE = 'paypal';
export const ADYEN_APPLE_PAY_PAYMENT_TYPE = 'applepay';
export const ADYEN_GOOGLE_PAY_PAYMENT_TYPE = 'googlepay';
export const ADYEN_AMAZON_PAY_PAYMENT_TYPE = 'amazonpay';

// vvv ThreeDS2
export const THREE_DS2_POPUP_ID = 'three-ds2-popup';
export const THREE_DS2_DIV_ID = 'adyen-checkout-threeds2-popup';

/** @namespace Scandiweb/AdyenPayments/Util/Adyen/getCheckoutEnvironment */
export const getCheckoutEnvironment = (isDemoMode) => (isDemoMode ? 'test' : 'live');
