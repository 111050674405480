/* eslint-disable react/no-unknown-property */
/**
* ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/base-theme
* @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import SourceCartCoupon from 'SourceComponent/CartCoupon/CartCoupon.component';

import './CartCoupon.style.override.scss';

/** @namespace Scandipwa/Component/CartCoupon/Component */
export class CartCouponComponent extends SourceCartCoupon {
    renderApplyCoupon() {
        const { enteredCouponCode } = this.state;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.text }
                  attr={ {
                      id: 'couponCode',
                      name: 'couponCode',
                      defaultValue: enteredCouponCode,
                      placeholder: __('Enter code (maximum one)'),
                      'aria-label': __('Enter code (maximum one)')
                  } }
                  events={ {
                      onChange: this.handleCouponCodeChange
                  } }
                  validateOn={ ['onChange'] }
                  mix={ { block: 'CartCoupon', elem: 'Input' } }
                />
                <button
                  block="CartCoupon"
                  elem="Button"
                  type={ FIELD_TYPE.button }
                  mix={ { block: 'Button' } }
                  disabled={ !enteredCouponCode }
                  onClick={ this.handleApplyCoupon }
                >
                    { __('Apply') }
                </button>
            </>
        );
    }
}

export default CartCouponComponent;
