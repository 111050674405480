/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { setInputName, setIsKeyboardOpened } from '../../../store/Keyboard';

/**
 * FieldEmail Container plugin
 */
export class FieldEmailContainerPlugin {
    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            setInputName: (inputName) => dispatch(setInputName(inputName)),
            setIsKeyboardOpened: (isKeyboardOpened) => dispatch(setIsKeyboardOpened(isKeyboardOpened))
        };
    };

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            inputs: state.KeyboardReducer.inputs,
            inputName: state.KeyboardReducer.inputName,
            isEnterPressed: state.KeyboardReducer.isEnterPressed
        };
    };

    propTypes = (originalMember) => ({
        ...originalMember,
        setInputName: PropTypes.func.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        inputs: PropTypes.object.isRequired,
        inputName: PropTypes.string.isRequired,
        isEnterPressed: PropTypes.bool.isRequired
    });
}

const {
    mapDispatchToProps,
    mapStateToProps,
    propTypes
} = new FieldEmailContainerPlugin();

export const config = {
    'Scandipwa/Component/FieldEmail/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/FieldEmail/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/FieldEmail/Container': {
        'static-member': {
            propTypes
        }
    }
};

export default config;
