/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import browserHistory from 'Util/History';

import { BIG_SCREEN_HOMEPAGE } from '../../route/QrPage/QrPage.config';
import IdleTimer from '../../util/IdleTimer';
import Session from '../../util/Session';
import { SALES_ASSOCIATE_ID_COOKIE } from '../SalesAssociate/SalesAssociate.config';
import IdleTimePopup from './IdleTimePopup.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Bigscreen/Component/IdleTimePopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Bigscreen/Component/IdleTimePopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, getAuthorizationToken())
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        }
    )
});

/** @namespace Bigscreen/Component/IdleTimePopup/Container */
export class IdleTimePopupContainer extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        resetCart: PropTypes.func.isRequired
    };

    containerFunctions = {
        onHide: this.onHide.bind(this),
        handleTimeEnd: this.handleTimeEnd.bind(this)
    };

    onHide() {
        IdleTimer.startTimer();
    }

    async handleTimeEnd() {
        const { resetCart, resetGuestCart, hideActiveOverlay } = this.props;

        await BrowserDatabase.deleteItem(SALES_ASSOCIATE_ID_COOKIE);
        await Session.clearBrowserStorage();

        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }

        // Wait till closed, so that the page is not 'freeze' state when redirecting to home
        await hideActiveOverlay();

        document.body.click(); // To hide the keyboard
        browserHistory.push(BIG_SCREEN_HOMEPAGE);
    }

    render() {
        return (
            <IdleTimePopup
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IdleTimePopupContainer);
