/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { TRACKING_SCRIPT_BODY, TRACKING_SCRIPT_ID } from 'Route/Checkout/Checkout.config';
import { updateCheckoutState } from 'Store/Checkout/Checkout.action';
import BrowserDatabase from 'Util/BrowserDatabase';

import CheckoutSuccess from './CheckoutSuccess.component';

/** @namespace Scandipwa/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isProcessed: state.CheckoutReducer.isProcessed
});

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "cartDispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandipwa/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCheckoutState: (state) => dispatch(updateCheckoutState(state)),
    createGuestEmptyCart: () => {
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.createGuestEmptyCart(dispatch)
        );
    }
});

/** @namespace Scandipwa/Component/CheckoutSuccess/Container */
export class CheckoutSuccessContainer extends PureComponent {
    static propTypes = {
        email: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        orderID: PropTypes.string.isRequired,
        grandTotal: PropTypes.number.isRequired,
        shippingAddress: PropTypes.objectOf(
            PropTypes.string
        ).isRequired
    };

    componentDidMount() {
        window.clarity('event', 'checkoutSuccess');

        this.addTrackingScript();
    }

    componentWillUnmount() {
        this.removeTrackingScript();
    }

    removeTrackingScript() {
        const script = document.getElementById(TRACKING_SCRIPT_ID);
        script.parentNode.removeChild(script);
        BrowserDatabase.deleteItem(TRACKING_SCRIPT_BODY);
    }

    // Add DotDigital tracking script to DOM
    addTrackingScript() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = TRACKING_SCRIPT_ID;
        script.innerHTML = BrowserDatabase.getItem(TRACKING_SCRIPT_BODY);
        document.body.appendChild(script);
    }

    render() {
        return (
            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
            <CheckoutSuccess { ...this.props } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessContainer);
