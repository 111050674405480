/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    GUEST_EMAIL_FIELD_ID
} from 'Component/CheckoutGuestForm/CheckoutGuestForm.config';
import FIELD_TYPE from 'Component/Field/Field.config';
import { validatePassword } from 'Util/Validator';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { GUEST_EMAIL_CONFIRM_FIELD_ID } from './CheckoutGuestForm.config';

/**
 * Form for guest checkout
 * @param props
 * @param events
 * @namespace Scandipwa/Component/CheckoutGuestForm/Form/checkoutGuestForm */
export const checkoutGuestForm = (props, events) => {
    const {
        emailValue, isCreateUser, range, minimunPasswordCharacter, confirmedEmail
    } = props;
    const { handleEmailInput, handlePasswordInput, handleSecondEmailInput } = events;

    return [
        {
            type: FIELD_TYPE.email,
            attr: {
                id: GUEST_EMAIL_FIELD_ID,
                name: GUEST_EMAIL_FIELD_ID,
                value: emailValue,
                autocomplete: 'email'
            },
            label: __('Email'),
            validationRule: {
                inputType: VALIDATION_INPUT_TYPE.email,
                isRequired: true
            },
            events: {
                onChange: handleEmailInput
            },
            validateOn: ['onChange', 'onSubmit', 'focusout'],
            addRequiredTag: true,
            skipValue: true,
            isQuestionMarkNeeded: true
        },
        {
            type: FIELD_TYPE.email,
            label: __('Confirm Email'),
            validateOn: ['onChange', 'onSubmit', 'focusout'],
            attr: {
                id: GUEST_EMAIL_CONFIRM_FIELD_ID,
                name: GUEST_EMAIL_CONFIRM_FIELD_ID,
                value: confirmedEmail,
                autocomplete: 'email'
            },
            validationRule: {
                isRequired: true,
                inputType: VALIDATION_INPUT_TYPE.email,
                match: (value) => {
                    const email = document.getElementById(GUEST_EMAIL_FIELD_ID);

                    return value && email.value === value;
                },
                customErrorMessages: {
                    onMatchFail: __('Emails do not match!')
                }
            },
            events: {
                onChange: handleSecondEmailInput
            },
            addRequiredTag: true,
            skipValue: true,
            isConfirmation: true
        },
        ...(isCreateUser ? [{
            type: FIELD_TYPE.password,
            label: __('Create Password'),
            events: {
                onChange: handlePasswordInput
            },
            validationRule: {
                isRequired: true,
                inputType: VALIDATION_INPUT_TYPE.password,
                match: (value) => validatePassword(value, range, minimunPasswordCharacter)
            },
            validateOn: ['onChange', 'onSubmit'],
            addRequiredTag: true,
            validationType: 'register',
            skipValue: true
        }] : [])
    ];
};

export default checkoutGuestForm;
