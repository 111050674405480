/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import GroovinPixels, { DEFAULT_USER_ID, GROOVIN_PIXELS_RETARGETING } from '../util/GroovinPixels';
import Product from '../util/Product';

/**
 * Product page component plugin
 */
class ProductPageComponentPlugin {
    aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
        const {
            productSKU,
            areDetailsLoaded,
            product: { categories, id }
        } = instance.props;
        const [prevProps] = args;
        const { product: { id: prevId } } = prevProps;

        if (areDetailsLoaded && id !== prevId) {
            const params = {
                sku: productSKU,
                idcategory: Product.getProductCategoryIds(categories),
                uid: DEFAULT_USER_ID
            };

            GroovinPixels.updateGroovinPixels(params, GROOVIN_PIXELS_RETARGETING);
        }

        return callback(...args);
    };

    aroundComponentWillUnmount= (args, callback = () => {}) => {
        GroovinPixels.updateGroovinPixels({}, GROOVIN_PIXELS_RETARGETING);

        return callback(...args);
    };
}

const {
    aroundComponentDidUpdate,
    aroundComponentWillUnmount
} = new ProductPageComponentPlugin();

const config = {
    'Route/ProductPage/Component': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate,
            componentWillUnmount: aroundComponentWillUnmount
        }
    }
};

export default config;
