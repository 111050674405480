/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';

import Timer from '../Timer';
import { IDLE_TIME_POPUP_ID, START_NEW_SESSION_AFTER_SECONDS } from './IdleTimePopup.config';

import './IdleTimePopup.style.scss';

/** @namespace Bigscreen/Component/IdleTimePopup/Component */
export class IdleTimePopupComponent extends PureComponent {
    static propTypes = {
        hideActiveOverlay: PropTypes.func.isRequired,
        onHide: PropTypes.func.isRequired,
        handleTimeEnd: PropTypes.func.isRequired
    };

    renderContent() {
        const { handleTimeEnd, hideActiveOverlay } = this.props;

        return (
            <div
              block="IdleTimePopup"
              elem="Content"
            >
                <span>
                    { __('New session starts in') }
                </span>
                <Timer
                  time={ START_NEW_SESSION_AFTER_SECONDS }
                  onTimeEnd={ handleTimeEnd }
                />
                <button
                  block="IdleTimePopup"
                  elem="Button"
                  mix={ { block: 'Button' } }
                  onClick={ hideActiveOverlay }
                >
                    { __('Extend my session') }
                </button>
                <button
                  block="IdleTimePopup"
                  elem="Button"
                  mix={ { block: 'Button' } }
                  onClick={ handleTimeEnd }
                >
                    { __('Start new session') }
                </button>
            </div>
        );
    }

    render() {
        const { onHide } = this.props;

        return (
            <Popup
              id={ IDLE_TIME_POPUP_ID }
              mix={ { block: 'IdleTimePopup' } }
              onHide={ onHide }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default IdleTimePopupComponent;
