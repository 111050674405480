/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { updateNoMatch } from 'Store/NoMatch/NoMatch.action';
import { showNotification } from 'Store/Notification/Notification.action';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getIndexedProducts } from 'Util/Product';
import DataContainer from 'Util/Request/DataContainer';

import ProductCarousel from './ProductCarousel.component';

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/create-config-files
export const CONTENTS_PRODUCTS = 'contents_products';

/** @namespace Scandipwa/Component/ProductCarousel/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({
    updateNoMatch,
    showNotification
});

/**
 * Product carousel container
 * @class ProductCarouselContainer
 * @namespace Scandipwa/Component/ProductCarousel/Container */
export class ProductCarouselContainer extends DataContainer {
    static propTypes = {
        productSkuArray: PropTypes.string.isRequired
    };

    state = {
        isLoading: true,
        carouselProducts: [],
        activeImage: 0
    };

    containerFunctions = {
        requestCarouselProductList: this.requestCarouselProductList.bind(this),
        onActiveImageChange: this.onActiveImageChange.bind(this)
    };

    updateProductCarouselItems = this.updateProductCarouselItems.bind(this);

    onError = this.onError.bind(this);

    /**
     * On component did mount
     */
    componentDidMount() {
        this.requestCarouselProductList();
    }

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    /**
     * Requests product list for carousel
     */
    requestCarouselProductList() {
        const { productSkuArray } = this.props;

        if (!productSkuArray) {
            return;
        }

        const options = {
            args: {
                filter: {
                    productsSkuArray: productSkuArray
                }
            }
        };

        this.fetchData(
            [ProductListQuery.getQuery(options)],
            this.updateProductCarouselItems,
            this.onError
        );
    }

    /**
     * Updates carouselProducts state on product list request success
     *
     * @param data
     */
    updateProductCarouselItems(data) {
        const {
            products: {
                items
            } = {}
        } = data;
        const { carouselId } = this.props;

        if (!items) {
            return;
        }

        const sortedItems = this.getItemsInCorrectOrder(items);

        if (!sortedItems) {
            return;
        }

        this.setState({
            carouselProducts: getIndexedProducts(sortedItems)
        });

        const contentsProducts = BrowserDatabase.getItem(CONTENTS_PRODUCTS) || {};

        if (!contentsProducts[carouselId]) {
            contentsProducts[carouselId] = sortedItems;
            BrowserDatabase.setItem(contentsProducts, CONTENTS_PRODUCTS);
        }
    }

    /**
     * Returns carousel products in correct order.
     * We do this because products requested by graphql have incorrect order.
     * We order them by correctly ordered productSkuArray.
     *
     * @param items
     * @returns {*}
     */
    getItemsInCorrectOrder(items) {
        const { productSkuArray } = this.props;

        return productSkuArray.reduce((acc, sku) => {
            const item = items.find(({ sku: productSku }) => productSku === sku);

            if (item) {
                acc.push(item);
            }

            return acc;
        }, []);
    }

    /**
     * Shows error on product list request fail
     *
     * @param error
     */
    onError(error) {
        const { showNotification, updateNoMatch } = this.props;
        showNotification('error', __('Error fetching Carousel Product List!'), error);
        updateNoMatch(true);
    }

    /**
     * Changes active image
     *
     * @param e
     * @param activeImage
     */
    onActiveImageChange(e, activeImage) {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            activeImage
        });
    }

    render() {
        return (
            <ProductCarousel
              { ...this.props }
              { ...this.state }
              { ...this.containerFunctions }
            />
        );
    }
}

/** @namespace Scandipwa/Component/ProductCarousel/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCarouselContainer);
