/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { FieldGroup as SourceFieldGroup } from 'SourceComponent/FieldGroup/FieldGroup.component';

/** @namespace Scandipwa/Component/FieldGroup/Component */
export class FieldGroupComponent extends SourceFieldGroup {
    // Renders group label above field
    // Rewrite fixes issue where label could not render since it was incorrect syntax
    // Also added support for the required tag
    renderLabel() {
        const { label } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="FieldGroup" elem="Label">
                { label }
                { this.renderRequiredTag() }
            </div>
        );
    }

    // Renders group label under field
    // Rewrite fixes issue where label could not render since it was incorrect syntax
    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="FieldGroup" elem="SubLabel">
                { subLabel }
            </div>
        );
    }

    // Renders * for required fields
    renderRequiredTag() {
        const { addRequiredTag } = this.props;

        if (!addRequiredTag) {
            return null;
        }

        return (
            <span block="FieldGroup" elem="Label" mods={ { isRequired: true } }>
                { ' *' }
            </span>
        );
    }
}

export default FieldGroupComponent;
