/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-useless-return */
/* eslint-disable no-fallthrough */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Keyboard from 'react-simple-keyboard';
import layout from 'simple-keyboard-layouts/build/layouts/spanish';

import isMobile from 'Util/Mobile';

import ClickOutsideWithExtraNodes from '../ClickOutSideWithExtraNodes';
import DraggableElement from '../DraggableElement';

import 'react-simple-keyboard/build/css/index.css';
import './DraggableKeyboard.style.scss';

/** @namespace Bigscreen/Component/DraggableKeyboard/Component */
export class DraggableKeyboardComponent extends PureComponent {
    static propTypes = {
        locale: PropTypes.string.isRequired,
        setInputs: PropTypes.func.isRequired,
        setEnterPressed: PropTypes.func.isRequired,
        setInputName: PropTypes.func.isRequired,
        setIsKeyboardClicked: PropTypes.func.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        inputName: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        inputs: PropTypes.object.isRequired,
        isKeyboardOpened: PropTypes.bool.isRequired
    };

    state = {
        layoutName: 'default',
        // diffX: 0,
        // diffY: 0,
        // dragging: false,
        // styles: {},
        input: ''
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    async componentDidMount() {
        const {
            inputs,
            inputName,
            setInputs,
            setInputName
        } = this.props;

        if (inputs[inputName]) {
            this.setState({
                input: inputs[inputName]
            });
        }

        if (inputName === 'search-field') {
            /**
             * this logic refers to Segmentify search pop-up on mobile, which appears over regular search
             * in this case we need to duplicate values from regular search input and pass it to external Segmentify search wrapper.
             */
            const segmentifySearchWrapper = await this.waitForElement('#sgm-mobile-input');

            const segmentifyInput = segmentifySearchWrapper?.firstChild?.firstChild;

            if (segmentifyInput) {
                segmentifyInput.setAttribute('id', 'sgm-search-field');
                segmentifyInput.setAttribute('value', inputs['sgm-search-field'] ? inputs['sgm-search-field'] : '');

                this.setState({
                    segmentifySearchMounted: true
                });

                setInputs({ 'sgm-search-field': '' });
                setInputName('sgm-search-field');
            }
        }
    }

    componentDidUpdate(_prevProps, _prevState) {
        const { segmentifySearchMounted } = this.state;

        if (segmentifySearchMounted && this.keyboard) {
            this.keyboard.setInput({ 'sgm-search-field': '' });
        }
    }

    componentWillUnmount() {
        this.handleEnter(false);
    }

    // eslint-disable-next-line consistent-return
    waitForElement = (selector) => new Promise((resolve) => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        this.observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                this.observer.disconnect();
            }
        });

        this.observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });

    onKeyPress = async (button, _event) => {
        const { setIsKeyboardClicked } = this.props;

        setIsKeyboardClicked(true);

        switch (button) {
        case '{shift}':
        case '{lock}':
            this.handleShift();
            return;
        case '{enter}':
            this.handleEnter();
            return;
        default:
            return;
        }
    };

    handleShift = () => {
        const { layoutName } = this.state;

        this.setState({
            layoutName: layoutName === 'default' ? 'shift' : 'default'
        });
    };

    handleEnter = (isEnterPressed = true) => {
        const { setEnterPressed } = this.props;

        setEnterPressed(isEnterPressed);
        this.onClickOutside();
        this.keyboard.destroy();
    };

    onChangeAll = (inputs) => {
        const { setInputs, inputs: storeInputs } = this.props;

        if (!storeInputs.length) {
            setInputs({ ...storeInputs, ...inputs });
        } else {
            setInputs({ ...inputs });
        }
    };

    onClickOutside = () => {
        const {
            setIsKeyboardOpened,
            setEnterPressed,
            setIsKeyboardClicked,
            inputName
        } = this.props;

        setIsKeyboardOpened(false);
        setEnterPressed(false);
        setIsKeyboardClicked(false);

        /**
         * In case of regular search overlay, we do not close it on 'outside click' because it will
         * trigger the closing of the keyboard and Segmentify will stop collecting input value.
         * If customer accidentally clicks outside the keyboard it will disappear, but the input value will stay,
         * thus we need to reload the Segmentify
         *
         * The mobile logic will be different as the Segmentify search pop-up overlays the regular one.
         */
        if (!isMobile.any() && inputName === 'search-field') {
            const segmentifyWrapper = document.getElementsByClassName('seg-search-wrapper')[0];

            if (segmentifyWrapper) {
                segmentifyWrapper.remove();
            }
        }

        this.keyboard.destroy();
    };

    onChange = (newInput) => {
        const { inputs, inputName, setInputs } = this.props;

        if (inputs) {
            setInputs({ ...inputs, [inputName]: newInput });
        } else {
            setInputs({ [inputName]: newInput });
        }

        this.setState({
            input: newInput
        });
    };

    render() {
        const {
            locale,
            inputName,
            inputs
        } = this.props;
        const { layoutName } = this.state;
        const languageLayout = locale === 'es_AR' ? layout : {};

        if (this.keyboard && inputs[inputName]) {
            this.keyboard.setInput(inputs[inputName], inputName);
        }

        return (
              <DraggableElement>
                  <ClickOutsideWithExtraNodes
                    onClick={ this.onClickOutside }
                    nodeIds={ [inputName] }
                  >
                      <div
                        block="DraggableKeyboard"
                        role="button"
                        tabIndex="0"
                      >
                          <Keyboard
                            // eslint-disable-next-line arrow-parens, no-return-assign
                            keyboardRef={ r => (this.keyboard = r) }
                            inputName={ inputName }
                            onKeyPress={ (button, event) => this.onKeyPress(button, event) }
                            onChangeAll={ this.onChangeAll }
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            { ...languageLayout }
                            layoutName={ layoutName }
                          />
                      </div>
                  </ClickOutsideWithExtraNodes>
              </DraggableElement>
        );
    }
}

export default DraggableKeyboardComponent;
