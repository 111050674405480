/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { lazy, Suspense } from 'react';

import CheckoutGuestForm from 'Component/CheckoutGuestForm';
import CheckoutOrderSummary from 'Component/CheckoutOrderSummary';
import CheckoutSuccess from 'Component/CheckoutSuccess';
import ContentWrapper from 'Component/ContentWrapper';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import {
    Checkout as SourceCheckout
} from 'SourceRoute/Checkout/Checkout.component';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { deleteCartId } from 'Util/Cart';
import { formatCurrency, roundPrice } from 'Util/Price';

import {
    BILLING_STEP,
    DETAILS_STEP,
    ORDER_ID_KEY,
    SHIPPING_STEP
} from './Checkout.config';

import './Checkout.override.style';

export const CmsBlock = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-info" */
    'Component/CmsBlock'
));

export const CheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/CheckoutBilling'
));

/** @namespace Scandipwa/Route/Checkout/Component */
export class CheckoutComponent extends SourceCheckout {
    navigationMap = [
        {
            checkoutStep: SHIPPING_STEP,
            label: __('Shipping'),
            link: 'checkout/shipping'
        },
        {
            checkoutStep: BILLING_STEP,
            label: __('Review & Payments'),
            link: 'checkout/billing'
        }
    ];

    __construct(props) {
        super.__construct(props);

        this.resetCart = this.resetCart.bind(this);
    }

    renderPrice(price) {
        const { totals: { quote_currency_code } } = this.props;
        const priceString = formatCurrency(quote_currency_code);

        return `${priceString}${roundPrice(Number(price))}`;
    }

    renderNavigationStep(value) {
        const { checkoutStep, label } = value;
        const { checkoutStep: currentCheckoutStep } = this.props;
        const isCurrent = checkoutStep === currentCheckoutStep;

        if (checkoutStep === SHIPPING_STEP) {
            return (
                <Link
                  block="Checkout"
                  elem="NavigationStep"
                  mods={ { isCurrent } }
                  to={ this.navigationMap.find((item) => item.checkoutStep === checkoutStep).link }
                >
                    <span block="Checkout" elem="NavigationStepIndex" mods={ { isCurrent } } />
                    <span block="Checkout" elem="NavigationStepLabel" mods={ { isCurrent } }>{ label }</span>
                </Link>
            );
        }

        return (
            <li
              block="Checkout"
              elem="NavigationStep"
              mods={ { isCurrent } }
            >
                <span block="Checkout" elem="NavigationStepIndex" mods={ { isCurrent } } />
                <span block="Checkout" elem="NavigationStepLabel" mods={ { isCurrent } }>{ label }</span>
            </li>
        );
    }

    renderNavigation() {
        const { checkoutStep: currentCheckoutStep } = this.props;

        return (
            <ol
              block="Checkout"
              elem="Navigation"
              mods={ { isDetailsStep: currentCheckoutStep === DETAILS_STEP } }
            >
                { this.navigationMap.map((value) => this.renderNavigationStep(value)) }
            </ol>
        );
    }

    renderMobileSummary() {
        const {
            checkoutStep,
            checkoutTotals,
            paymentTotals,
            selectedShippingMethod: { method_code = '' } = {}
        } = this.props;
        const { areTotalsVisible = false } = this.stepMap[checkoutStep];

        if (!areTotalsVisible) {
            return null;
        }

        return (
            <CheckoutOrderSummary
              checkoutStep={ checkoutStep }
              totals={ checkoutTotals }
              paymentTotals={ paymentTotals }
              selectedShippingMethodCode={ method_code }
              isMobile
            />
        );
    }

    renderSummary() {
        const {
            checkoutTotals,
            checkoutStep,
            paymentTotals,
            selectedShippingMethod: { method_code = '' } = {}
        } = this.props;
        const { areTotalsVisible } = this.stepMap[checkoutStep];

        if (!areTotalsVisible) {
            return null;
        }

        return (
            <CheckoutOrderSummary
              checkoutStep={ checkoutStep }
              totals={ checkoutTotals }
              paymentTotals={ paymentTotals }
              selectedShippingMethodCode={ method_code }
            />
        );
    }

    renderGuestForm() {
        const {
            checkoutStep,
            isCreateUser,
            isEmailRecognitionLoading,
            isGuestEmailSaved,
            isSignedIn,
            isVisibleEmailRequired,
            onCreateUserChange,
            onEmailChange,
            onPasswordChange,
            isSignedInUtil,
            onConfirmationEmailChange
        } = this.props;
        const isBilling = checkoutStep === BILLING_STEP;

        // Changed: Only render during shipping step
        if ((checkoutStep !== SHIPPING_STEP) || (isSignedInUtil || isSignedIn)) {
            return null;
        }

        return (
            <CheckoutGuestForm
              isBilling={ isBilling }
              isCreateUser={ isCreateUser }
              isEmailRecognitionLoading={ isEmailRecognitionLoading }
              isGuestEmailSaved={ isGuestEmailSaved }
              isVisibleEmailRequired={ isVisibleEmailRequired }
              onCreateUserChange={ onCreateUserChange }
              onEmailChange={ onEmailChange }
              onPasswordChange={ onPasswordChange }
              onConfirmationEmailChange={ onConfirmationEmailChange }
            />
        );
    }

    resetCart() {
        const { checkoutStep, createGuestEmptyCart } = this.props;
        const isSuccess = checkoutStep === DETAILS_STEP;

        if (isSuccess) {
            deleteCartId();
            BrowserDatabase.deleteItem(PAYMENT_TOTALS);
            BrowserDatabase.deleteItem(CART_TOTALS);
            BrowserDatabase.deleteItem(ORDER_ID_KEY);
            createGuestEmptyCart();
        }
    }

    renderLogo() {
        return (
            <Link
              block="Checkout"
              elem="MainLogoLink"
              aria-label="Go to homepage by clicking on Puma logo"
              key="logo"
              to="/"
              onClick={ this.resetCart }
            >
                <div block="Checkout" elem="MainLogo">
                    <svg fill="black" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 37" enableBackground="new 0 0 48 37" xmlSpace="preserve">
                        <path d="M47.689,0.517c-0.834-1.066-2.291-0.213-2.933,0.16c-4.569,2.692-5.243,7.432-6.834,10.154 c-1.253,2.178-3.304,3.779-5.159,3.903c-1.373,0.098-2.861-0.167-4.338-0.81c-3.613-1.562-5.56-3.583-6.034-3.94 c-0.973-0.739-8.459-8.03-14.559-8.327c0,0-0.744-1.5-0.93-1.526C6.457,0.08,6,1.033,5.669,1.133c-0.3,0.105-0.825-1.024-1.13-0.975 C4.233,0.2,3.936,1.33,3.34,1.913C2.901,2.338,2.367,2.311,2.065,2.839c-0.104,0.192-0.068,0.53-0.186,0.84 C1.626,4.32,0.777,4.387,0.769,5.073c0,0.762,0.714,0.907,1.338,1.438c0.496,0.425,0.53,0.725,1.109,0.924 C3.731,7.611,4.48,7.061,5.144,7.258c0.553,0.163,1.085,0.279,1.204,0.846c0.108,0.513,0,1.316-0.682,1.226 C5.444,9.3,4.472,8.982,3.271,9.11c-1.45,0.154-3.105,0.618-3.267,2.22c-0.083,0.895,1.028,1.942,2.11,1.733 c0.742-0.143,0.392-1.013,0.797-1.433c0.535-0.541,3.545,1.888,6.344,1.888c1.186,0,2.063-0.3,2.935-1.21 c0.078-0.057,0.185-0.203,0.31-0.218c0.113,0.015,0.324,0.128,0.39,0.175c2.262,1.793,3.967,5.399,12.26,5.441 c1.164,0.014,2.498,0.558,3.591,1.553c0.96,0.866,1.528,2.251,2.075,3.65c0.836,2.106,2.322,4.139,4.584,6.407 c0.119,0.135,1.98,1.561,2.119,1.666c0.025,0.021,0.168,0.334,0.106,0.51c-0.039,1.38-0.245,5.34,2.731,5.506 c0.731,0.04,0.549-0.463,0.549-0.82c-0.01-0.683-0.129-1.371,0.226-2.08c0.507-0.957-1.051-1.418-1.017-3.513 c0.037-1.567-1.291-1.302-1.969-2.498c-0.381-0.687-0.736-1.065-0.699-1.894c0.145-4.76-1.034-7.896-1.61-8.654 c-0.455-0.587-0.847-0.806-0.414-1.078c2.481-1.632,3.05-3.15,3.05-3.15c1.32-3.081,2.512-5.89,4.15-7.138 c0.332-0.241,1.177-0.88,1.703-1.12c1.527-0.725,2.346-1.156,2.777-1.576c0.711-0.675,1.27-2.107,0.588-2.96H47.689z" />
                    </svg>
                </div>
            </Link>
        );
    }

    renderDetailsStep() {
        const {
            billingAddress: {
                firstname
            },
            email,
            orderID,
            shippingAddress,
            additionalOrderData,
            totals: {
                prices: {
                    grand_total: { value: grandTotal } = {}
                } = {}
            } = {}
        } = this.props;

        return (
            <CheckoutSuccess
              email={ email }
              firstName={ firstname }
              grandTotal={ grandTotal }
              orderID={ orderID }
              shippingAddress={ shippingAddress }
              additionalOrderData={ additionalOrderData }
            />
        );
    }

    /**
     * Added isLoading to the original function
     */
    renderBillingStep() {
        const {
            setLoading,
            isLoading,
            setDetailsStep,
            shippingAddress,
            onEmailChange,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            onChangeEmailRequired
        } = this.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <CheckoutBilling
                  setLoading={ setLoading }
                  isLoading={ isLoading }
                  paymentMethods={ paymentMethods }
                  setDetailsStep={ setDetailsStep }
                  onEmailChange={ onEmailChange }
                  shippingAddress={ shippingAddress }
                  savePaymentInformation={ savePaymentInformation }
                  selectedShippingMethod={ selectedShippingMethod }
                  onChangeEmailRequired={ onChangeEmailRequired }
                />
            </Suspense>
        );
    }

    render() {
        const { checkoutStep, isMobile } = this.props;
        const isSuccess = checkoutStep === DETAILS_STEP;

        return (
            <>
                <div block="Checkout" elem="MainLogoWrapper">
                    { this.renderLogo() }
                </div>
                <main block="Checkout" mods={ { isSuccess } }>
                    <ContentWrapper
                      wrapperMix={ { block: 'Checkout', elem: 'Wrapper' } }
                      label={ __('Checkout page') }
                    >
                        <div>
                            { this.renderNavigation() }
                        </div>
                        <div>
                            { isMobile && this.renderMobileSummary() }
                        </div>
                        <div block="Checkout" elem="Step">
                            { this.renderGuestForm() }
                            { this.renderStep() }
                            { this.renderLoader() }
                        </div>
                        <div>
                            { !isMobile && this.renderSummary() }
                            { this.renderPromo() }
                        </div>
                    </ContentWrapper>
                </main>
            </>
        );
    }
}

export default CheckoutComponent;
