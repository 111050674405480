/* eslint-disable import/no-mutable-exports,fp/no-let,fp/no-loops */
/**
 * @author Abdelrahman Hady <abdelrahman.hady@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-ar
 */

import ProductListQuery from 'Query/ProductList.query';
import DataContainer from 'Util/Request/DataContainer';
import { convertQueryStringToKeyValuePairs } from 'Util/Url';

import { SEARCH_PAGE } from '../../component/GoogleTagManager/GA4_events/ViewItemList.event';
import Event, {
    EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4,
    EVENT_GTM_CATEGORY_SORT_CHANGE_GA4,
    EVENT_GTM_VIEW_ITEM_LIST_GA4
} from '../../util/Event';

export const FETCH_PRODUCTS_DELAY = 3000;
export const SELECT_PRODUCT_CARDS_DELAY = 3000;
export const SEGMENTIFY_LOAD_MORE_BUTTON_CLASS = '.sgmfs-load-more-button';
export const ORDER_TYPE_QUERY_PARAM = 'ordering[orderType]';
export const SEARCH_QUERY_PARAM = 'q';
export const SEGMENTIFY_SEARCH_PATH_NAME = '/segmentifysearch';

/**
 * Count of products that were already added to the dataLayer
 *
 * @type {number}
 */
export let productsCount = 0;

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/handleSearchEvent */
export const handleSearchEvent = () => {
    const productCards = document.querySelectorAll('.ProductListPage .ProductCard');
    let skus = [];

    if (productCards.length) {
        for (let i = productsCount; i < productCards.length; i++) {
            const card = productCards[i];
            const url = card.querySelector('a').getAttribute('href');
            const arr = url.split('.html')[0].split('-');
            const sku = `${arr[arr.length - 2]}_${arr[arr.length - 1]}`;

            skus.push(sku);
        }

        if (skus) {
            skus = skus.slice(0, -1);

            const options = {
                args: {
                    filter: {
                        productsSkuArray: skus
                    }
                }
            };

            (new DataContainer()).fetchData(
                [ProductListQuery.getQuery(options)],
                ({ products: { items } }) => {
                    if (items.length) {
                        Event.dispatch(
                            EVENT_GTM_VIEW_ITEM_LIST_GA4,
                            { pageType: SEARCH_PAGE, items }
                        );

                        productsCount = productCards.length - productsCount;
                    }
                }
            );
        }
    }
};

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/handleFilterSelectedEvent */
export const handleFilterSelectedEvent = (search, prevSearch) => {
    const params = convertQueryStringToKeyValuePairs(search);
    const prevParams = convertQueryStringToKeyValuePairs(prevSearch);

    Object.entries(params).forEach(([filter, value]) => {
        const isFilterParam = filter.search('fields') !== -1;

        if (
            isFilterParam
            && (!prevParams[filter] || prevParams[filter] !== value)
        ) {
            const start = filter.indexOf('[') + 1;
            const end = filter.indexOf(']');
            const filterName = filter.substring(start, end); // e.g. fields[colors][1] --> colors

            Event.dispatch(EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4, { filter: filterName, value });

            productsCount = 0; // Reset count

            setTimeout(handleSearchEvent, FETCH_PRODUCTS_DELAY);
        }
    });
};

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/handleQueryParamsChange */
export const handleQueryParamsChange = () => {
    const { history } = window;
    const { pushState } = history;
    // eslint-disable-next-line no-param-reassign
    history.pushState = function (state) {
        if (typeof history.onpushstate === 'function') {
            history.onpushstate({ state });
        }

        const { location: { search: prevSearch } } = window;

        // eslint-disable-next-line fp/no-arguments,no-undef
        pushState.apply(history, arguments);

        const { location: { search, pathname } } = window;

        if (pathname === SEGMENTIFY_SEARCH_PATH_NAME) {
            const params = convertQueryStringToKeyValuePairs(search);
            const prevParams = convertQueryStringToKeyValuePairs(prevSearch);
            const prevSort = prevParams[ORDER_TYPE_QUERY_PARAM];
            const sort = params[ORDER_TYPE_QUERY_PARAM];
            const prevSearchQuery = prevParams[SEARCH_QUERY_PARAM];
            const searchQuery = params[SEARCH_QUERY_PARAM];

            if (sort !== prevSort) {
                Event.dispatch(EVENT_GTM_CATEGORY_SORT_CHANGE_GA4, sort);

                productsCount = 0; // Reset count

                setTimeout(handleSearchEvent, FETCH_PRODUCTS_DELAY);
            }

            if (searchQuery !== prevSearchQuery) {
                productsCount = 0; // Reset count

                setTimeout(handleSearchEvent, FETCH_PRODUCTS_DELAY);
            }

            if (search !== prevSearch) {
                handleFilterSelectedEvent(search, prevSearch);
            }
        }
    };
};

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/handleSearchEventOnLoadMoreClick */
export const handleSearchEventOnLoadMoreClick = () => {
    setTimeout(handleSearchEvent, SELECT_PRODUCT_CARDS_DELAY);
};

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/componentDidMount */
export const componentDidMount = (args, callback) => {
    callback(...args);

    productsCount = 0; // Reset count

    // To make sure that the handleQueryParamsChange() function only runs once per session
    if (!window.handledQueryParamsChange) {
        window.handledQueryParamsChange = true;

        handleQueryParamsChange();
    }

    setTimeout(() => {
        handleSearchEvent();

        document.querySelectorAll(SEGMENTIFY_LOAD_MORE_BUTTON_CLASS).forEach(
            (el) => el.addEventListener('click', handleSearchEventOnLoadMoreClick)
        );
    }, FETCH_PRODUCTS_DELAY);
};

/** @namespace ScandiPWA/GtmGraphQl/Plugin/Event-dispatches/Plugin/componentWillUnmount */
export const componentWillUnmount = (args, callback) => {
    document.querySelectorAll(SEGMENTIFY_LOAD_MORE_BUTTON_CLASS).forEach(
        (el) => el.removeEventListener('click', handleSearchEventOnLoadMoreClick)
    );

    callback(...args);
};

export default {
    'Scandipwa/Route/SegmentifySearch/Container': {
        'member-function': {
            componentDidMount,
            componentWillUnmount
        }
    }
};
