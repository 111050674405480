/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { ORDER_ID_KEY } from 'Route/Checkout/Checkout.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import { EMARSYS_CART_ITEMS, getCartItems } from '../../../Util/Cart';
import { getCustomerEmail } from '../../../Util/Helper';

export const CASH_ON_DELIVERY_FEE = 'CASH_ON_DELIVERY_FEE';

/**
 * CheckoutSuccess Container plugin
 */
export class CheckoutSuccessContainerPlugin {
    componentDidMount = (args, callback = () => {}, instance) => {
        const orderId = BrowserDatabase.getItem(ORDER_ID_KEY);
        const { orderID: propsOrderId } = instance.props;

        const itemsData = getCartItems();

        if (itemsData.length) {
            const email = getCustomerEmail();
            if (email && !window.webExtendEvents.includes('setEmail')) {
                // Identifying the visitor with email address.
                // Should run before the other commands
                ScarabQueue.push(['setEmail', email]);
                window.webExtendEvents.push('setEmail');
            }

            if (!window.webExtendEvents.includes('cart')) {
                // Passing on order details. The price values passed on here serve as the basis of our revenue and revenue contribution reports.
                ScarabQueue.push(['cart', []]);
                window.webExtendEvents.push('cart');
            }

            if (!window.webExtendEvents.includes('purchase')) {
                // Passing on order details. The price values passed on here serve as the basis of our revenue and revenue contribution reports.
                ScarabQueue.push(['purchase', {
                    orderId: orderId || propsOrderId,
                    items: itemsData
                }]);
                window.webExtendEvents.push('purchase');
            }

            if (!window.webExtendEvents.includes('go') && window.webExtendEvents.length) {
                ScarabQueue.push(['go']);
                window.webExtendEvents.push('go');
            }

            window.webExtendEvents = []; // Resetting the list when page changes
        }

        // Delete EMARSYS_CART_ITEMS
        BrowserDatabase.deleteItem(EMARSYS_CART_ITEMS);

        return callback(...args);
    };
}

const {
    componentDidMount
} = new CheckoutSuccessContainerPlugin();

export const config = {
    'Scandipwa/Component/CheckoutSuccess/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

export default config;
