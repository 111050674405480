/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import {
    lazy, Suspense
} from 'react';

import CartCoupon from 'Component/CartCoupon';
import CartItem from 'Component/CartItem';
import ContentWrapper from 'Component/ContentWrapper';
import EstimatedDeliveryTime from 'Component/EstimatedDeliveryTime';
import Loader from 'Component/Loader';
import PromptFreeShippingMessage from 'Component/PromptFreeShippingMessage';
import {
    CartPage as SourceCartPage
} from 'SourceRoute/CartPage/CartPage.component';
import { formatPrice, roundPrice } from 'Util/Price';

import './CartPage.override.style.scss';

export const CmsBlock = lazy(() => import('Component/CmsBlock'));

/** @namespace Scandipwa/Route/CartPage/Component */
export class CartPageComponent extends SourceCartPage {
    static propTypes = {
        ...super.propTypes,
        onContinueShoppingButtonClick: PropTypes.func.isRequired
    };

    state = {
        isDiscountExpanded: false
    };

    renderTotals() {
        return (
            /** Id is required to measure the element`s height in ExpandableContent.component.js */
            <article id="CartPageSummary" block="CartPage" elem="Summary">
                <h4 block="CartPage" elem="SummaryHeading">{ __('Order Summary') }</h4>
                { this.renderTotalDetails() }
                { this.renderTotal() }
                { this.renderDiscountCode() }
                { this.renderButtons() }
            </article>
        );
    }

    renderTotal() {
        const {
            totals: {
                prices: {
                    grand_total: { value: grand_total = 0 }
                }
            }
        } = this.props;

        return (
            <dl block="CartPage" elem="Total" aria-label="Complete order total">
                <dt>{ __('Order total:') }</dt>
                <dd>{ this.renderPriceLine(grand_total) }</dd>
                <PromptFreeShippingMessage />
                <EstimatedDeliveryTime />
            </dl>
        );
    }

    renderHeading() {
        const { totals: { total_quantity = 0 } } = this.props;

        return (
            <h1 block="CartPage" elem="Heading">
                { __('Shopping cart (%s)', total_quantity) }
            </h1>
        );
    }

    renderButtons() {
        const {
            onCheckoutButtonClick,
            onContinueShoppingButtonClick,
            minimumOrderDescription,
            minimumOrderAmountReached,
            hasOutOfStockProductsInCart
        } = this.props;

        if (hasOutOfStockProductsInCart) {
            return (
                <div block="CartPage" elem="OutOfStockProductsWarning">
                    { __('Please, remove out of stock products from cart') }
                </div>
            );
        }

        if (!minimumOrderAmountReached) {
            return (
                <div block="CartPage" elem="OutOfStockProductsWarning">
                    { minimumOrderDescription }
                </div>
            );
        }

        return (
            <div block="CartPage" elem="CheckoutButtons">
                <button
                  block="CartPage"
                  elem="CheckoutButton"
                  mix={ { block: 'Button' } }
                  onClick={ onCheckoutButtonClick }
                >
                    { __('Proceed to checkout') }
                </button>
                <button
                  block="CartPage"
                  elem="ContinueShoppingButton"
                  mix={ { block: 'Button' } }
                  onClick={ onContinueShoppingButtonClick }
                >
                    { __('Continue shopping') }
                </button>
            </div>
        );
    }

    renderTotalDetails() {
        const {
            totals: {
                prices: {
                    subtotal_including_tax: { value: subtotal_incl_tax = 0 },
                    shipping_incl_tax = 0
                }
            }
        } = this.props;

        return (
            <dl
              block="CartPage"
              elem="TotalDetails"
              aria-label={ __('Order total details') }
            >
                <dt>{ __('Subtotal:') }</dt>
                <dd>{ this.renderPriceLine(subtotal_incl_tax) }</dd>
                { shipping_incl_tax > 0 && <dt>{ __('Shipping Total:') }</dt> }
                { shipping_incl_tax > 0 && <dd>{ this.renderPriceLine(shipping_incl_tax) }</dd> }
                { this.renderDiscount() }
            </dl>
        );
    }

    renderCartItems() {
        const {
            totals: {
                items = [],
                prices: {
                    quote_currency_code = ''
                } = {}
            },
            onCartItemLoading,
            isInitialLoad
        } = this.props;

        if (!items || isInitialLoad) {
            return (
                <div block="CartPage" elem="InitialLoaderContainer">
                    <Loader isLoading />
                </div>
            );
        }

        if (!items.length) {
            return (
                <p block="CartPage" elem="Empty">{ __('There are no products in cart.') }</p>
            );
        }

        return (
            <ul block="CartPage" elem="Items" aria-label="List of items in cart">
                    { items.map((item) => (
                        <CartItem
                          key={ item.item_id }
                          item={ item }
                          currency_code={ quote_currency_code }
                          onCartItemLoading={ onCartItemLoading }
                          showLoader
                          isEditing
                          isLikeTable
                        />
                    )) }
            </ul>
        );
    }

    expandCouponContent() {
        const { isDiscountExpanded } = this.state;

        if (!isDiscountExpanded) {
            this.setState({ isDiscountExpanded: true });
        }

        if (isDiscountExpanded) {
            this.setState({ isDiscountExpanded: false });
        }
    }

    renderDiscountCode() {
        const {
            totals: {
                prices: { coupon_code }
            }
        } = this.props;

        const { isDiscountExpanded } = this.state;

        return (
            <div
              block="CartPage"
              elem="DiscountCode"
              mods={ { isDiscountExpanded } }
            >
                <div
                  block="CartPage"
                  elem="Discount"
                >
                    <span>{ __('Promo code') }</span>
                    <button
                      block="CartPage"
                      elem="DiscountButton"
                      mods={ { isDiscountExpanded } }
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => this.expandCouponContent() }
                    >
                        <span>{ __('Expand Promo') }</span>
                    </button>
                </div>
                <CartCoupon couponCode={ coupon_code } />
            </div>
        );
    }

    renderPriceLine(price) {
        const { totals: { prices: { quote_currency_code } } } = this.props;

        return formatPrice(roundPrice(price), quote_currency_code);
    }

    renderDiscount() {
        const {
            totals: {
                prices,
                prices: {
                    coupon_code
                }
            }
        } = this.props;

        if (!coupon_code) {
            return null;
        }
        const {
            discount: {
                amount: { value: discount_amount = 0 }
            }
        } = prices;

        return (
            <>
                <dt>
                    { __('Coupon ') }
                    <strong block="CartPage" elem="DiscountCoupon">{ coupon_code.toUpperCase() }</strong>
                </dt>
                <dd>{ `-${this.renderPriceLine(Math.abs(discount_amount))}` }</dd>
            </>
        );
    }

    render() {
        const { cart_content: { cart_cms } = {} } = window.contentConfiguration;
        const { totals: { items }, isCartItemLoading, onContinueShoppingButtonClick } = this.props;

        const isCartEmpty = !!(!items || items.length < 1);

        return (
            <main block="CartPage" aria-label="Cart Page">
                <div block="CartPage" elem="Header">
                    <ContentWrapper
                      wrapperMix={ { block: 'PageHeader' } }
                      label="Cart Page Header"
                    >
                        { this.renderHeading() }
                        <button
                          block="CartPage"
                          elem="ContinueShopping"
                          onClick={ onContinueShoppingButtonClick }
                        >
                            { __('Back') }
                        </button>
                    </ContentWrapper>
                </div>
                <ContentWrapper
                  wrapperMix={ { block: 'CartPage', elem: 'Wrapper', mods: { isCartEmpty } } }
                  label="Cart page details"
                >
                    <div block="CartPage" elem="Static" mods={ { isCartEmpty } }>
                        <Loader isLoading={ isCartItemLoading } />
                        { this.renderCartItems() }
                    </div>
                    <div block="CartPage" elem="Floating">
                        { (items && items.length > 0) && this.renderTotals() }
                    </div>
                </ContentWrapper>
                <div id="seg-home-recommendation" />
                <div block="CartPage" elem="CartAdvantages">
                    <Suspense fallback={ null }>
                        <CmsBlock identifier={ cart_cms || 'cart-footer' } />
                    </Suspense>
                </div>
            </main>
        );
    }
}

export default CartPageComponent;
