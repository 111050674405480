/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

export const EMARSYS_CART_ITEMS = 'emarsys_cart_items';

/** @namespace Emarsys/Util/Cart/getCartItems */
/**
 * Maps the cart in the browserdatabase for pushing to Emarsys
 * @returns {Array}
 */
export const getCartItems = () => {
    const cartItems = BrowserDatabase.getItem(CART_TOTALS)?.items || [];

    // eslint-disable-next-line fp/no-let
    let emarsysCartItems = cartItems.map((item) => ({
        item: item.sku,
        price: item?.prices?.row_total_including_tax?.value,
        quantity: item.quantity
    })) || [];

    // Store the cartItems in another localstorage item if it's not empty
    // This is because upon page load the CART_TOTALS item gets emptied
    if (emarsysCartItems.length > 0) {
        BrowserDatabase.setItem(emarsysCartItems, EMARSYS_CART_ITEMS);
    } else {
        emarsysCartItems = BrowserDatabase.getItem(EMARSYS_CART_ITEMS) || [];
    }

    return emarsysCartItems;
};
