/* eslint-disable max-len */
import { createHash } from 'crypto';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { getStore } from 'Util/Store';

dayjs.extend(utc);
dayjs.extend(timezone);

/** @namespace Scandipwa/Util/ProductRelease/sha256Hash */
export function sha256Hash(data) {
    return createHash('sha256').update(data, 'utf8').digest('hex');
}

/** @namespace Scandipwa/Util/ProductRelease/isReleaseProduct */
export const isReleaseProduct = (sku) => {
    const { ConfigReducer: { release_products, timezone } = {} } = getStore().getState();

    if (!release_products || release_products.length === 0) {
        return false; // If no release products are defined, return false.
    }

    // If the "bypass_release" url param is set to true, all products are considered released
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('bypass_release')) {
        return false;
    }

    // Adjust the current time to the specified timezone
    const timeNow = dayjs().tz(timezone).valueOf();

    // Find the release product entry for the given SKU
    const releaseEntry = release_products.find((rp) => rp.sku === sha256Hash(sku));

    if (releaseEntry) {
        // Parse the timestamp as being in the specified timezone
        const productTime = dayjs.tz(releaseEntry.timestamp, timezone).valueOf();
        // Check if the release time has not yet passed
        return productTime > timeNow;
    }

    // If there is no matching SKU in the release products, return false
    return false;
};

/**
 *
 * @param {*} products Array of products to process
 * @param {*} initialTotalCount The total count of the category so it can be decreased by the amount of removed products
 * @param {Int} categoryId The current category id that is being processed
 * @returns { filteredItems: array, totalCount: int }
 * @namespace Scandipwa/Util/ProductRelease/filterReleaseProducts
 */
export const filterReleaseProducts = (products, initialTotalCount, categoryId) => {
    const { ConfigReducer: { release_products, timezone } = {} } = getStore().getState();

    if (!release_products || release_products.length === 0) {
        return { filteredItems: products, totalCount: initialTotalCount }; // Return all products and the unchanged count
    }

    // Adjust the current time to the specified timezone
    const timeNow = dayjs().tz(timezone).valueOf();

    const filteredProducts = products.filter((product) => {
        const releaseEntry = release_products.find((rp) => rp.sku === sha256Hash(product.sku));

        return !releaseEntry || dayjs.tz(releaseEntry.timestamp, timezone).valueOf() <= timeNow;
    });

    // Calculate new total count only considering those release_products with the given categoryId
    const totalCountReduction = release_products.reduce((count, rp) => {
        if (rp.categories && rp.categories.includes(categoryId.toString())) {
            const productTime = dayjs.tz(rp.timestamp, timezone).valueOf();
            if (productTime > timeNow) {
                return count + 1; // Increment the count for each product that should still be released
            }
        }

        return count;
    }, 0);

    const newTotalCount = initialTotalCount - totalCountReduction;

    return { filteredItems: filteredProducts, totalCount: newTotalCount };
};
