/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import { setSiteBaseLoaded } from 'Store/Config/Config.action';
import history from 'Util/History';

import Router from './Router.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const ConfigDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
);
export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);
export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);
export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isSiteBaseHasLoaded: state.ConfigReducer.siteBaseHasLoaded,
    baseLinkUrl: state.ConfigReducer.base_link_url,
    isNotificationListVisible: state.NotificationReducer.isNotificationListVisible,
    isSignedIn: state.MyAccountReducer.isSignedIn
});

/** @namespace Scandipwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setBaseSiteLoaded: () => dispatch(setSiteBaseLoaded()),
    init: async () => {
        ConfigDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch)
        );

        const { default: dispatcher } = await MyAccountDispatcher;

        await dispatcher.handleCustomerDataOnInit(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, undefined, undefined, true)
        );
        ProductCompareDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch)
        );
    }
});

/** @namespace Scandipwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...SourceRouterContainer.propTypes,
        setBaseSiteLoaded: PropTypes.func.isRequired,
        baseLinkUrl: PropTypes.string,
        isSiteBaseHasLoaded: PropTypes.bool,
        isNotificationListVisible: PropTypes.bool.isRequired,
        isSignedIn: PropTypes.bool
    };

    static defaultProps = {
        ...SourceRouterContainer.defaultProps,
        baseLinkUrl: window.location.origin,
        isSiteBaseHasLoaded: false,
        isSignedIn: false
    };

    componentDidMount() {
        const { setBaseSiteLoaded } = this.props;

        window.addEventListener('resize', this.handleResize);

        // Check if it is a bot
        if (navigator.userAgent.includes('bot')
            || navigator.userAgent.includes('facebook')
            || navigator.userAgent.includes('render')// rendertron or prerenderer
        ) {
            setBaseSiteLoaded();
        } else {
            window.addEventListener('click', this.handleFirstInteraction);
            window.addEventListener('touchstart', this.handleFirstInteraction);
            window.addEventListener('mousemove', this.handleFirstInteraction);
            window.addEventListener('mousewheel', this.handleFirstInteraction);
            window.addEventListener('wheel', this.handleFirstInteraction);
            window.addEventListener('scroll', this.handleFirstInteraction);
        }
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta, isSignedIn } = this.props;
        const { isLoading: prevIsLoading, isSignedIn: prevIsSignedIn } = prevProps;
        const elem = document.getElementsByClassName('global-promo-banner');

        if (elem && elem.length > 0) {
            const { pathname } = location;

            if (pathname.match(/checkout/)) {
                elem[0].style.display = 'none';
            } else {
                elem[0].removeAttribute('style');
            }
        }

        const { isAbandonedCart } = this.state;

        // eslint-disable-next-line eqeqeq
        if (isAbandonedCart && isSignedIn && isSignedIn != prevIsSignedIn) {
            this.setState({ isAbandonedCart: false });
            history.replace('/cart');
        }

        const globalPromoBannerList = document.getElementsByClassName('global-promo-banner-list');

        if (globalPromoBannerList && globalPromoBannerList.length > 0) {
            const { pathname } = location;

            if (pathname.match(/checkout/)) {
                globalPromoBannerList[0].style.display = 'none';
            } else {
                globalPromoBannerList[0].removeAttribute('style');
            }
        }

        if (!this.handleCheckIfOnlyMainItemsRender()) {
            this.setRenderAllItems();
        } else {
            this.setRenderOnlyMainItems();
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code
            } = this.props;

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);

        window.removeEventListener('click', this.handleFirstInteraction);
        window.removeEventListener('touchstart', this.handleFirstInteraction);
        window.removeEventListener('mousemove', this.handleFirstInteraction);
        window.removeEventListener('mousewheel', this.handleFirstInteraction);
        window.removeEventListener('wheel', this.handleFirstInteraction);
        window.removeEventListener('scroll', this.handleFirstInteraction);
    }

    __construct(props) {
        super.__construct(props);

        this.state = ({
            currentUrl: window.location.pathname,
            isOnlyMainItems: this.handleCheckIfOnlyMainItemsRender(),
            isLoading: false,
            isAbandonedCart: false
        });
    }

    containerFunctions = {
        setIsLoading: this.setIsLoading.bind(this)
    };

    setIsLoading(isLoading) {
        this.setState({
            isLoading
        });
    }

    handleFirstInteraction = async () => {
        const { setBaseSiteLoaded } = this.props;

        setBaseSiteLoaded();

        window.removeEventListener('click', this.handleFirstInteraction);
        window.removeEventListener('touchstart', this.handleFirstInteraction);
        window.removeEventListener('mousemove', this.handleFirstInteraction);
        window.removeEventListener('mousewheel', this.handleFirstInteraction);
        window.removeEventListener('wheel', this.handleFirstInteraction);
        window.removeEventListener('scroll', this.handleFirstInteraction);
    };

    containerProps() {
        const {
            isSiteBaseHasLoaded,
            isNotificationListVisible,
            baseLinkUrl
        } = this.props;

        const { isLoading } = this.state;

        return {
            ...super.containerProps(),
            isSiteBaseHasLoaded,
            isLoading,
            isNotificationListVisible,
            baseLinkUrl
        };
    }

    redirectFromPartialUrl() {
        const { baseLinkUrl, isSignedIn } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl);
        const { pathname, search } = location;

        if (search.includes('utm_source=DOT') && search.includes('utm_campaign=Abandoned_Cart') && !isSignedIn) {
            this.state.isAbandonedCart = true;
            history.replace('/customer/account/login/');
        }

        if (storePrefix === '/') {
            return;
        }

        if (storePrefix.slice(0, -1) === pathname) {
            if (history.replace) {
                history.replace(storePrefix);
            }
        }
    }

    render() {
        return (
            <Router
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
