/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_META } from 'SourceStore/Meta/Meta.action';
import { filterData, getInitialState as sourceGetInitialState } from 'SourceStore/Meta/Meta.reducer';

export { filterData } from 'SourceStore/Meta/Meta.reducer';
export const updateEveryTime = [
    'title',
    'description',
    'keywords',
    'canonical_url',
    'robots',
    'status_code',
    'og_image'
];

/** @namespace Scandipwa/Store/Meta/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    og_image: ''
});

/** @namespace Scandipwa/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case UPDATE_META:
        const filteredData = filterData(payload);

        return {
            ...state,
            ...filteredData
        };

    default:
        return state;
    }
};

export default MetaReducer;
