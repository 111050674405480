/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import { ProductConfigurableAttributeDropdown as SourceComponent }
from 'SourceComponent/ProductConfigurableAttributeDropdown/ProductConfigurableAttributeDropdown.component';

import './ProductConfigurableAttributeDropdown.extended.style';

/** @namespace Scandipwa/Component/ProductConfigurableAttributeDropdown/Component */
export class ProductConfigurableAttributeDropdownComponent extends SourceComponent {
    static propTypes = {
        ...SourceComponent.propTypes,
        isLabelInner: PropTypes.bool,
        innerLabel: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        attributeValidation: PropTypes.object
    };

    static defaultProps = {
        ...SourceComponent.defaultProps,
        isLabelInner: false,
        innerLabel: '',
        attributeValidation: {}
    };

    render() {
        const {
            selectOptions,
            selectValue,
            selectName,
            onChange,
            isLabelInner,
            innerLabel,
            attributeValidation
        } = this.props;

        const availableOptions = selectOptions.filter((option) => option.isAvailable === true);

        return (
            <Field
              type={ FIELD_TYPE.select }
              attr={ {
                  id: selectName,
                  name: selectName,
                  value: selectValue,
                  noPlaceholder: !availableOptions.length // Don't render placeholder if there are no options to show to match the empty Qty selector
              } }
              mix={ {
                  block: 'ProductConfigurableAttributeDropdown',
                  mods: { Disabled: availableOptions.length === 0 } // Not using isDisabled to match wording used by ProductActions.renderQuantityChanger
              } }
              options={ availableOptions }
              events={ {
                  onChange
              } }
              isLabelInner={ isLabelInner }
              innerLabel={ innerLabel }
              attributeValidation={ attributeValidation }
              value={ null }
            />
        );
    }
}

export default ProductConfigurableAttributeDropdownComponent;
