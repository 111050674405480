/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Form from 'Component/Form';
import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';
import { RefType } from 'Type/Common.type';

/** @namespace Scandipwa/Component/FieldForm/Component */
export class FieldFormComponent extends SourceFieldForm {
    static propTypes = {
        ...SourceFieldForm.propTypes,
        formContainerRef: RefType
    };

    render() {
        const { formContainerRef } = this.props;

        return (
            <Form { ...this.getFormProps() } mix={ { block: 'FieldForm' } } ref={ formContainerRef }>
                { this.renderFormBody() }
            </Form>
        );
    }
}

export default FieldFormComponent;
