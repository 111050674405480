/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { COOKIE_STATE } from './CookiePopup.action';

/** @namespace Scandipwa/Store/CookiePopup/Reducer/getInitialState */
export const getInitialState = () => ({
    cookieState: false
});

/** @namespace Scandipwa/Store/CookiePopup/Reducer/CookiePopupReducer */
export const CookiePopupReducer = (state = getInitialState(), action) => {
    const { type, cookieState = false } = action;

    switch (type) {
    case COOKIE_STATE:
        return { ...state, cookieState };
    default:
        return state;
    }
};

export default CookiePopupReducer;
