/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import { EMARSYS_CART_ITEMS, getCartItems } from '../../../Util/Cart';
import { getCustomerEmail } from '../../../Util/Helper';

/**
 * Cart Dispatcher plugin
 */
export class CartDispatcherPlugin {
    updateInitialCartData = async (args, callback) => {
        const returnData = await callback(...args);

        // Check if there is cart data now, and call getCartItems to adjust the emarsys_cart_items localstorage item inside getCartItems()
        const cartTotals = BrowserDatabase.getItem(CART_TOTALS);

        if (cartTotals?.items.length > 0) {
            getCartItems();
        }

        return returnData;
    };

    addProductToCart = async (args, callback) => {
        await callback(...args);

        this.pushWebExtendData();
    };

    removeProductFromCart = async (args, callback) => {
        await callback(...args);

        const cartItems = BrowserDatabase.getItem(CART_TOTALS)?.items || [];

        if (!cartItems.length) {
            // Delete EMARSYS_CART_ITEMS when cart is empty
            BrowserDatabase.deleteItem(EMARSYS_CART_ITEMS);
        }

        // Only push the cart data after deleting the EMARSYS_CART_ITEMS because otherwise it'll fall back to that data
        this.pushWebExtendData();
    };

    changeItemQty = async (args, callback) => {
        await callback(...args);

        this.pushWebExtendData();
    };

    pushWebExtendData() {
        // If it's a PLP, this should not fire to prevent duplicates. However, if the PLP plugin set noCartData it should still fire.
        const email = getCustomerEmail();

        if (email && !window.webExtendEvents.includes('setEmail')) {
            // Should run before the other commands
            ScarabQueue.push(['setEmail', email]);
            window.webExtendEvents.push('setEmail');
        }

        if (!window.webExtendEvents.includes('cart')) {
            // Passing on visitor's cart contents to feed cart abandonment campaigns
            ScarabQueue.push(['cart', getCartItems()]);
            window.webExtendEvents.push('cart');
        }

        if (!window.webExtendEvents.includes('go') && window.webExtendEvents.length) {
            // Firing the ScarabQueue. Should be the last call on the page.
            ScarabQueue.push(['go']);
            window.webExtendEvents.push('go');
        }

        window.webExtendEvents = []; // Resetting the list
    }

    _createEmptyCart = (args, callback) => {
        // No need to wait on the callback here, since we're just removing the data.
        const returnData = callback(...args);

        // Reset the EMARSYS_CART_ITEMS variable because a new cart was created
        BrowserDatabase.deleteItem(EMARSYS_CART_ITEMS);

        return returnData;
    };

    handle_syncCartWithBESuccess = (args, callback) => {
        const [, { cartData }] = args;

        if (!cartData.items.length) {
            // Reset the EMARSYS_CART_ITEMS variable because a new cart was created
            BrowserDatabase.deleteItem(EMARSYS_CART_ITEMS);
        }

        return callback(...args);
    };
}

const {
    updateInitialCartData,
    addProductToCart,
    removeProductFromCart,
    changeItemQty,
    _createEmptyCart,
    handle_syncCartWithBESuccess
} = new CartDispatcherPlugin();

export const config = {
    'Scandipwa/Store/Cart/Dispatcher': {
        'member-function': {
            updateInitialCartData,
            addProductToCart,
            removeProductFromCart,
            changeItemQty,
            _createEmptyCart,
            handle_syncCartWithBESuccess
        }
    }
};

export default config;
