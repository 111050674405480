/**
 * GTM extension for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

const _getCustomerFields = (args, callback, instance) => [
    ...callback(...args),
    'userExistingCustomer'
];

export default {
    'Query/MyAccount/Query': {
        'member-function': {
            _getCustomerFields
        }
    }
};
