/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
export * from 'SourceComponent/Router/Router.config';

export const NOTIFICATIONS = 'NOTIFICATIONS';
export const MOBILE_WIDTH = 767;
export const HEADER_IS_MOBILE_UNTIL = 1180;
export const CACHE_NAME = 'app-runtime-static';
