export const SHOW_VAT_NUMBER_REQUIRED = 'req';
export const SHOW_VAT_NUMBER_OPTIONAL = 'opt';
export const CONFIRMATION_REQUIRED = 'confirmation_required';
// new configs
export const REGISTER_EMAIL_ID = 'register-email';
export const REGISTER_CONFIRM_EMAIL_ID = 'register-confirm-email';
export const REGISTER_PASSWORD_ID = 'register-password';
export const REGISTER_CONFIRM_PASSWORD_ID = 'register-confirm-password';
export const REGISTER_FIRSTNAME_ID = 'register-firstname';
export const REGISTER_LASTNAME_ID = 'register-lastname';
export const REGISTER_SUBSCRIBE_ID = 'register-is_subscribed';
