/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceKeyValueTable from 'SourceComponent/KeyValueTable/KeyValueTable.component';

import './KeyValueTable.style';

/** @namespace Scandipwa/Component/KeyValueTable/Component */
export class KeyValueTableComponent extends SourceKeyValueTable {
    renderTableRow = (data) => {
        const { key } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        return (
            <div key={ key }>
                { value }
            </div>
        );
    };

    renderTable() {
        return (
            <div block="KeyValueTable" elem="Wrapper">
                <div block="KeyValueTable">
                    { this.dataPairArray.map(this.renderTableRow) }
                </div>
            </div>
        );
    }
}

export default KeyValueTableComponent;
