/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ORDER_ID_KEY, PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { GUEST_QUOTE_ID } from 'Store/Cart/Cart.dispatcher';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

/** @namespace Bigscreen/Util/Session/Index */
export class Session {
    clearBrowserStorage = async () => {
        BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        BrowserDatabase.deleteItem(CART_TOTALS);
        BrowserDatabase.deleteItem(ORDER_ID_KEY);
    };
}

export default new Session();
