/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceFieldSelectContainer from 'SourceComponent/FieldSelect/FieldSelect.container';

/**
 * Field select container
 * @class FieldSelectContainer
 * @namespace Scandipwa/Component/FieldSelect/Container */
export class FieldSelectContainer extends SourceFieldSelectContainer {
    containerProps = () => {
        const {
            attr: {
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            events,
            setRef,
            isDisabled,
            isSortSelect,
            value,
            isLabelInner,
            innerLabel,
            isDatePicker,
            noSort
        } = this.props;

        const {
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable,
            isSelectedOptionAvailable
        } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete
            },
            events,
            setRef,
            isDisabled,
            isExpanded,
            isDropdownOpenUpwards,
            isScrollable,
            isSortSelect,
            isSelectedOptionAvailable,
            value,
            isLabelInner,
            innerLabel,
            isDatePicker,
            noSort,
            unsortedOptions: this.getOptions(),
            options: this.sortSelectOptions()
        };
    };

    handleSelectListOptionClick(option) {
        const { passEventOnChange = false } = this.props;

        const { changeValueOnDoubleClick, events: { onChange } = {} } = this.props;
        const { value, target: { value: targetValue } = {} } = option;

        const fieldValue = value || targetValue || '';

        if (changeValueOnDoubleClick) {
            this.fieldRef.value = this.fieldRef.value === value ? '' : fieldValue;
        } else {
            this.fieldRef.value = fieldValue;
        }

        if (passEventOnChange) {
            onChange({
                target: {
                    name: this.fieldRef.id,
                    value: fieldValue,
                    type: 'select'
                }
            });

            return;
        }

        if (onChange) {
            onChange(fieldValue);
        }
    }

    /**
     * check if all values of the select options are numbers for quantity dropdown
     */
    checkOnlyNumbers(array) {
        return array.every((element) => typeof element.value === 'number');
    }

    sortSelectOptions() {
        const { noSort } = this.props;
        const options = this.getOptions();
        const sizes = ['3XS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL'];
        const ageRegex = /^(\d+)-(\d+)Y$/; // Regex to extract ages

        if (noSort) {
            return options;
        }

        return options.filter((a) => a.label).sort((a, b) => {
            // Numeric sorting
            if (this.checkOnlyNumbers(options)) {
                return parseInt(a.label, 10) - parseInt(b.label, 10);
            }

            // Size sorting
            const textA = a.label.toUpperCase();
            const textB = b.label.toUpperCase();

            if (sizes.includes(textA) && sizes.includes(textB)) {
                return sizes.indexOf(textA) - sizes.indexOf(textB);
            }

            // Age range sorting
            const matchA = textA.match(ageRegex);
            const matchB = textB.match(ageRegex);

            if (matchA && matchB) {
                const startAgeA = parseInt(matchA[1], 10);
                const startAgeB = parseInt(matchB[1], 10);
                return startAgeA - startAgeB;
            }

            // Alphabetical sorting as fallback
            return textA.localeCompare(textB);
        });
    }
}

export default FieldSelectContainer;
