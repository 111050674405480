/** @namespace Scandipwa/Util/Size/sortSelectOptions */
// eslint-disable-next-line import/prefer-default-export
export const sortSelectOptions = (selectOptions) => {
    const sizes = ['3XS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL', '5XL', '6XL', '7XL'];

    return selectOptions.reduce(
        (acc, a) => (a.label ? [...acc, a] : acc), []
    ).sort((a, b) => {
        const textA = a.label.toUpperCase();
        const textB = b.label.toUpperCase();

        if (sizes.includes(textA)) {
            return sizes.indexOf(textA) - sizes.indexOf(textB);
        }

        // eslint-disable-next-line no-nested-ternary
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
};
