import {
    CART_TOTALS,
    CartReducer as SourceCartReducer,
    getInitialState as sourceGetInitialState,
    updateCartTotals as sourceUpdateCartTotals,
    updateShippingPrice
} from 'SourceStore/Cart/Cart.reducer';

export {
    CART_TOTALS,
    updateShippingPrice
};

// TODO: implement updateCartTotals
export const updateCartTotals = sourceUpdateCartTotals;

// TODO: implement getInitialState
export const getInitialState = sourceGetInitialState;

// TODO: implement CartReducer
export const CartReducer = SourceCartReducer;

export default CartReducer;
