/* eslint-disable consistent-return */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

/**
 * Popup Component plugin
 */
export class PopupComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        isUnCloseable: PropTypes.bool
    });

    handleClickOutside = (args, callback, instance) => {
        const { isCloseOnOutsideClick, isKeyboardOpened, isUnCloseable = false } = instance.props;

        if (!isCloseOnOutsideClick || isUnCloseable || isKeyboardOpened) {
            return;
        }

        return callback(...args);
    };

    renderCloseButton = (args, callback, instance) => {
        const { isUnCloseable = false } = instance.props;

        if (isUnCloseable) {
            return null;
        }

        return callback(...args);
    };
}

const {
    propTypes,
    handleClickOutside,
    renderCloseButton
} = new PopupComponentPlugin();

export const config = {
    'Scandipwa/Component/Popup/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            handleClickOutside,
            renderCloseButton
        }
    }
};

export default config;
