/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import CartItem from 'Component/CartItem';
import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';
import {
    CheckoutOrderSummary as SourceCheckoutOrderSummary
} from 'SourceComponent/CheckoutOrderSummary/CheckoutOrderSummary.component';
import {
    CheckoutOrderSummaryPriceLine
} from 'SourceComponent/CheckoutOrderSummaryPriceLine/CheckoutOrderSummaryPriceLine.component';

import './CheckoutOrderSummary.extended.style';

/**
 * Checkout Order Summary component
 * @namespace Scandipwa/Component/CheckoutOrderSummary/Component */
export class CheckoutOrderSummaryComponent extends SourceCheckoutOrderSummary {
    static propTypes = {
        ...SourceCheckoutOrderSummary.propTypes,
        isMobile: PropTypes.bool,
        selectedShippingMethodCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        ...SourceCheckoutOrderSummary.defaultProps,
        isMobile: false
    };

    renderHeading() {
        return (
            <h3
              block="CheckoutOrderSummary"
              elem="Header"
              mix={ { block: 'CheckoutPage', elem: 'Heading', mods: { hasDivider: true } } }
            >
                { __('Order Summary') }
            </h3>
        );
    }

    // renderPriceLine(price, name, mods) {
    //     // eslint-disable-next-line no-restricted-globals
    //     if (isNaN(Number(price))) {
    //         return null;
    //     }

    //     const { totals: { quote_currency_code } } = this.props;
    //     const priceString = formatCurrency(quote_currency_code);

    //     return (
    //         <li block="CheckoutOrderSummary" elem="SummaryItem" mods={ mods }>
    //             <strong block="CheckoutOrderSummary" elem="PriceLineName">
    //                 { name }
    //             </strong>
    //             <strong block="CheckoutOrderSummary" elem="PriceLinePrice">
    //                 { `${priceString}${roundPrice(Number(price))}` }
    //             </strong>
    //         </li>
    //     );
    // }

    // renderTotals() {
    //     const {
    //         totals: {
    //             prices: {
    //                 grand_total: {
    //                     value: grandTotal = 0
    //                 } = {},
    //                 subtotal_including_tax: {
    //                     value: subtotalIncludingTax = 0
    //                 } = {}
    //             } = {},
    //             shipping_incl_tax,
    //             discount_amount
    //         },
    //         isMobile,
    //         selectedShippingMethodCode
    //     } = this.props;

    //     if (isMobile) {
    //         return (
    //             <div block="CheckoutOrderSummary" elem="OrderTotals">
    //                 { this.renderPriceLine(grandTotal, __('Grand Total')) }
    //                 <EstimatedDeliveryTime showOnFeCarrie={ selectedShippingMethodCode } />
    //             </div>
    //         );
    //     }

    //     return (
    //         <div block="CheckoutOrderSummary" elem="OrderTotals">
    //             <ul>
    //                 { this.renderPriceLine(subtotalIncludingTax, __('Cart Subtotal')) }
    //                 { discount_amount !== 0 && this.renderPriceLine(discount_amount, __('Discount')) }
    //                 { shipping_incl_tax > 0 && this.renderPriceLine(shipping_incl_tax, __('Shipping Total')) }
    //                 { this.renderPriceLine(grandTotal, __('Grand Total')) }
    //                 <EstimatedDeliveryTime showOnFeCarrie={ selectedShippingMethodCode } />
    //             </ul>
    //         </div>
    //     );
    // }

    renderOrderTotal() {
        const {
            totals: {
                prices: {
                    grand_total: {
                        value: grand_total = 0
                    } = {},
                    quote_currency_code = null
                } = {}
            }
        } = this.props;
        const title = __('Order total');

        return (
            <CheckoutOrderSummaryPriceLine
              price={ Number(grand_total || 0).toFixed(2) }
              currency={ quote_currency_code }
              title={ title }
              mods={ { isTotal: true } }
            />
        );
    }

    renderTotals() {
        const { children, totals: { items = [] } } = this.props;

        return (
            <div block="CheckoutOrderSummary" elem="OrderTotals">
                <ul>
                    { this.renderSubTotal() }
                    { this.renderDiscount() }
                    { this.renderShipping() }
                    <div block="CheckoutOrderSummary" elem="ButtonWrapper" mods={ { isEmpty: items.length < 1 } }>
                        { this.renderOrderTotal() }
                        { children }
                    </div>
                </ul>
            </div>
        );
    }

    renderItem = (item) => {
        const {
            totals: {
                prices: {
                    quote_currency_code = null
                } = {}
            }
        } = this.props;

        const { item_id } = item;

        return (
            <CartItem
              key={ item_id }
              item={ item }
              currency_code={ quote_currency_code }
              shouldRenderQuantity
            />
        );
    };

    /**
     * Changed to fix core SPWA bug where it would always render USD
     */
    renderDiscount() {
        const {
            totals: {
                prices: {
                    applied_rule_ids,
                    coupon_code,
                    discount
                } = {}
            }
        } = this.props;

        if (!applied_rule_ids) {
            return null;
        }

        const { amount: { value: discount_amount = 0, currency = 'MXN' } = {} } = discount || {};
        const label = coupon_code ? __('Coupon code discount') : __('Discount');
        const discountAmount = -Math.abs(discount_amount);

        return (
            <CheckoutOrderSummaryPriceLine
              price={ discountAmount }
              title={ label }
              coupon_code={ coupon_code }
              currency={ currency }
            />
        );
    }

    renderItems() {
        const { totals: { items = [], total_quantity } } = this.props;

        return (
            <ExpandableContent
              heading={ `${__('Items in Cart') }: ${total_quantity}` }
              mix={ {
                  block: 'CheckoutOrderSummary',
                  elem: 'OrderItems'
              } }
              isContentExpanded
            >
                <ul block="CheckoutOrderSummary" elem="CartItemList">
                    { items.map(this.renderItem) }
                </ul>
            </ExpandableContent>
        );
    }

    renderContent() {
        const { isMobile } = this.props;

        return (
            <>
                { !isMobile && this.renderHeading() }
                { this.renderTotals() }
                { !isMobile && this.renderItems() }
            </>
        );
    }

    render() {
        const { isMobile, isLoading } = this.props;

        return (
            <article block="CheckoutOrderSummary" mods={ { isMobile } } aria-label="Order Summary">
                <Loader isLoading={ isLoading } />
                { this.renderContent() }
            </article>
        );
    }
}

export default CheckoutOrderSummaryComponent;
