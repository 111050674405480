/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Slider from 'Component/Slider/Slider.component';
import { SLIDE_TRANSFORM_CLICK_LIMIT } from 'Component/Slider/Slider.config';
import Draggable from 'SourceComponent/Draggable';
import { ACTIVE_SLIDE_PERCENT } from 'SourceComponent/Slider/Slider.config';
import { DeviceType } from 'Type/Device.type';
import CSS from 'Util/CSS';

import {
    BUTTON_LEFT,
    BUTTON_RIGHT,
    DEFAULT_SLIDES_COUNT,
    DEFAULT_THUMBNAIL_SLIDES_COUNT,
    DESKTOP_WIDTH,
    PRODUCT_CAROUSEL,
    TABLET_WIDTH
} from './SwatchSlider.config';

import './SwatchSlider.style';

/** @namespace Scandipwa/Component/SwatchSlider/Component */
export class SwatchSliderComponent extends Slider {
    static propTypes = {
        ...Slider.propTypes,
        slidesOnDesktop: PropTypes.number,
        slidesOnTablet: PropTypes.number,
        slidesOnMobile: PropTypes.number,
        scrollToStart: PropTypes.bool,
        isSliderDragged: PropTypes.func,
        onActiveImageChange: PropTypes.func.isRequired,
        device: DeviceType.isRequired,
        isUseThumbnailAsSwatch: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...Slider.defaultProps,
        slidesOnDesktop: 0,
        slidesOnTablet: 0,
        slidesOnMobile: 0,
        scrollToStart: true,
        isSliderDragged: () => null
    };

    state = {
        ...Slider.state,
        slidesQuantityPerPage: DEFAULT_SLIDES_COUNT
    };

    updateWindowDimensions = this.updateWindowDimensions.bind(this);

    __construct(props) {
        super.__construct(props);
        const { isUseThumbnailAsSwatch } = this.props;

        this.state = {
            ...this.state,
            slidesQuantityPerPage: isUseThumbnailAsSwatch
                ? DEFAULT_THUMBNAIL_SLIDES_COUNT
                : DEFAULT_SLIDES_COUNT
        };
    }

    componentDidMount() {
        const { slidesOnDesktop, slidesOnTablet, slidesOnMobile } = this.props;

        if (slidesOnDesktop || slidesOnTablet || slidesOnMobile) {
            window.addEventListener('resize', this.updateWindowDimensions);
        }
        setTimeout(() => {
            this.updateWindowDimensions();
        }, 1);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        const {
            slidesOnDesktop,
            slidesOnTablet,
            slidesOnMobile,
            isUseThumbnailAsSwatch,
            mix: { block }
        } = this.props;
        const { offsetWidth: sliderWidth } = this.draggableRef.current || {};
        const { slidesQuantityPerPage } = this.state;

        // eslint-disable-next-line fp/no-let
        let newSlidesQuantityPerPage = isUseThumbnailAsSwatch
            ? DEFAULT_THUMBNAIL_SLIDES_COUNT
            : DEFAULT_SLIDES_COUNT;

        if (window.innerWidth >= DESKTOP_WIDTH && slidesOnDesktop) {
            newSlidesQuantityPerPage = slidesOnDesktop;
        } else if (window.innerWidth >= TABLET_WIDTH && slidesOnTablet) {
            newSlidesQuantityPerPage = slidesOnTablet;
        } else if (slidesOnMobile) {
            newSlidesQuantityPerPage = slidesOnMobile;
        }

        this.sliderWidth = sliderWidth / newSlidesQuantityPerPage;
        if (slidesQuantityPerPage !== newSlidesQuantityPerPage) {
            this.setState({ slidesQuantityPerPage: newSlidesQuantityPerPage });
        }

        this.updateSlideWidth();

        if (block !== PRODUCT_CAROUSEL) {
            this.centerContent();
        }
    }

    updateSlideWidth() {
        CSS.setVariable(
            this.sliderRef,
            'slide-width',
            `${this.sliderWidth}px`
        );
    }

    centerContent() {
        const { children } = this.props;
        const { slidesQuantityPerPage } = this.state;

        const childrenCount = children.filter((child) => child).length;

        if (slidesQuantityPerPage >= childrenCount) {
            CSS.setVariable(this.draggableRef, 'justify-content', 'left');
        } else {
            CSS.setVariable(this.draggableRef, 'justify-content', 'unset');
        }
    }

    calculateNextSlide(e, state) {
        const {
            translateX: translate,
            lastTranslateX: lastTranslate
        } = state;

        const { onActiveImageChange } = this.props;

        const slideSize = this.sliderWidth;

        const fullSliderSize = this.getFullSliderWidth();

        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = translate > lastTranslate;

        if (!translate) {
            return this.onClickChangeSlide(state, slideSize, lastTranslate, fullSliderSize);
        }

        if (translate >= 0) {
            onActiveImageChange(0);
            return 0;
        }

        if (translate < -fullSliderSize) {
            const activeSlide = Math.round(fullSliderSize / -slideSize);
            onActiveImageChange(e, -activeSlide);
            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.ceil(activeSlidePosition);
            onActiveImageChange(e, -activeSlide);
            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.floor(activeSlidePosition);
            onActiveImageChange(e, -activeSlide);
            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(e, -activeSlide);
        return activeSlide;
    }

    handleClick = (state, callback, e) => {
        if (e.type === 'contextmenu') {
            this.handleDragEnd(state, callback, e);
        }
    };

    onClickChangeSlide() {
        const { prevActiveImage: prevActiveSlider } = this.state;
        return -prevActiveSlider;
    }

    handleDrag(state) {
        const { translateX: translate } = state;
        const { children: { length: childrenCount } } = this.props;
        const { slidesQuantityPerPage } = this.state;

        const fullSliderSize = this.getFullSliderWidth();

        if (
            translate < 0 && translate > -fullSliderSize
            && slidesQuantityPerPage <= childrenCount
        ) {
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${translate}px`
            );
        }
    }

    handleDragEnd(state, callback, e) {
        const {
            children: {
                length: childrenCount
            },
            activeImage,
            onActiveImageChange,
            scrollToStart,
            isSliderDragged
        } = this.props;
        const { slidesQuantityPerPage } = this.state;
        const { translateX } = state;

        // Consider small drag a click
        if (Math.abs(translateX) < SLIDE_TRANSFORM_CLICK_LIMIT && isSliderDragged) {
            isSliderDragged(false);
            return;
        }

        isSliderDragged(true);

        const activeSlide = slidesQuantityPerPage <= childrenCount && this.calculateNextSlide(e, state);
        const slideSize = this.sliderWidth;
        const newTranslate = (-activeImage * slideSize) === activeSlide * slideSize && scrollToStart
            ? 0 : activeSlide * slideSize;

        if ((-activeImage * slideSize) === activeSlide * slideSize && scrollToStart) {
            onActiveImageChange(e, 0);
        }

        CSS.setVariable(this.draggableRef, 'animation-speed', '300ms');

        CSS.setVariable(
            this.draggableRef,
            'translateX',
            `${newTranslate}px`
        );

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    getFullSliderWidth() {
        const { slidesQuantityPerPage } = this.state;
        const fullSliderWidth = this.draggableRef.current.scrollWidth;
        const extraWidth = slidesQuantityPerPage * this.sliderWidth;

        return fullSliderWidth - extraWidth;
    }

    getNextImage(btnType) {
        const {
            activeImage,
            children: {
                length: slidesQuantity
            }
        } = this.props;

        const { slidesQuantityPerPage } = this.state;

        if (btnType === BUTTON_LEFT) {
            return !activeImage ? activeImage : activeImage - 1;
        }

        return activeImage === slidesQuantity - slidesQuantityPerPage
            ? activeImage
            : activeImage + 1;
    }

    renderButton = (btnType) => {
        const {
            activeImage,
            children,
            scrollToStart,
            mix: { block },
            device,
            changeActiveSwatch
        } = this.props;
        const { slidesQuantityPerPage } = this.state;

        const childrenCount = children.filter((child) => child).length;

        if (slidesQuantityPerPage >= childrenCount) {
            return null;
        }

        const nextImage = childrenCount - slidesQuantityPerPage === activeImage && scrollToStart
            ? 0 : this.getNextImage(btnType);

        if (nextImage === 0) {
            CSS.setVariable(this.draggableRef, 'animation-speed', '200ms');
        }

        const hasNextImage = nextImage > activeImage || nextImage < activeImage;
        const isDesktop = !device.isMobile;

        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              block="SwatchSlider"
              elem="Arrow"
              mods={ { btnType, isDesktop } }
              mix={ {
                  block,
                  elem: 'Arrow',
                  mods: { btnType, hasNextImage, isDesktop }
              } }
              onClick={ (e) => changeActiveSwatch(e, nextImage) }
            />
        );
    };

    render() {
        const {
            activeImage,
            children,
            mix,
            isSliderDragged
        } = this.props;

        return (
            <div
              block="SwatchSlider"
              elem="Wrapper"
              mix={ mix }
              dir="ltr"
            >
                { this.renderButton(BUTTON_LEFT) }
                <div
                  block="Slider"
                  ref={ this.sliderRef }
                >
                    <Draggable
                      mix={ { block: 'SwatchSlider' } }
                      draggableRef={ this.draggableRef }
                      onDragStart={ this.handleDragStart }
                      onDragEnd={ this.handleDragEnd }
                      onDrag={ this.handleDrag }
                      shiftX={ -activeImage * this.sliderWidth }
                      isSliderDragged={ isSliderDragged }
                    >
                        { children }
                    </Draggable>
                </div>
                { this.renderButton(BUTTON_RIGHT) }
            </div>
        );
    }
}

export default SwatchSliderComponent;
