/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import QrPopup from './QrPopup.component';

/** @namespace Bigscreen/Component/QrPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    secureBaseUrl: state.ConfigReducer.secure_base_url
});

/** @namespace Bigscreen/Component/QrPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Bigscreen/Component/QrPopup/Container */
export class QrPopupContainer extends PureComponent {
    static propTypes = {
        secureBaseUrl: PropTypes.string.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    render() {
        return (
            <QrPopup
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QrPopupContainer);
