/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { getCartId } from 'Util/Cart';
import { fetchQuery } from 'Util/Request';

import { BIG_SCREEN_SESSION_ITEM, NEW_SESSION_POPUP_ID } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import { QR_POPUP_ID } from '../../../component/QrPopup/QrPopup.config';
import BigScreenExperienceQuery from '../../../query/BigScreenExperience.query';
import { showNotification } from '../../../store/Notification/Notification.action';

export const CHECK_IS_QR_SCANNED_AFTER = 3000;

/**
 * Cart Page Container plugin
 */
export class CartPageContainerPlugin {
    stopCheckingIfQrCodeScanned = false;

    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            secureBaseUrl: state.ConfigReducer.secure_base_url
        };
    };

    mapDispatchToProps = (args, callback, _instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            showPopup: (id, payload) => dispatch(showPopup(id, payload)),
            showNotification: (args) => dispatch(showNotification(...args)),
            hideActiveOverlay: () => dispatch(hideActiveOverlay())
        };
    };

    propTypes = (originalMember) => ({
        ...originalMember,
        secureBaseUrl: PropTypes.string.isRequired,
        showPopup: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    });

    componentDidMount = (args, callback, instance) => {
        const { totals: { items: { length = 0 } = {} } } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && length) {
            this.prepareInteraction(instance);
        }

        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const { totals: { items: { length = 0 } = {} } } = instance.props;
        const [prevProps] = args;
        const { totals: { items: { length: prevLength = 0 } = {} } } = prevProps;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && length && length !== prevLength) {
            this.prepareInteraction(instance);
        }

        return callback(...args);
    };

    componentWillUnmount = (args, callback, _instance) => {
        // Stop recursion if user navigates off from cart page
        this.stopCheckingIfQrCodeScanned = true;

        return callback(...args);
    };

    onCheckoutButtonClick = (args, callback, instance) => {
        const { showPopup } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            showPopup(
                QR_POPUP_ID,
                {
                    title: __('Scan the QR and finish your purchase safely from your phone')
                }
            );

            return;
        }

        callback(...args);
    };

    prepareInteraction(instance) {
        this.stopCheckingIfQrCodeScanned = false;
        this.checkIfQrCodeScanned(instance);
    }

    checkIfQrCodeScanned(instance, isQrCodeScanned = false) {
        const { showPopup, hideActiveOverlay } = instance.props;
        const guestCartId = getCartId();

        if (isQrCodeScanned || this.stopCheckingIfQrCodeScanned) {
            return;
        }

        this.timeout = setTimeout(() => {
            fetchQuery(BigScreenExperienceQuery.getIsQrCodeScannedForQuote(guestCartId)).then(
                /** @namespace BigScreenExperienceGraphQl/Scandipwa/App/Plugin/CartPageContainer/Plugin/CartPageContainerPlugin/fetchQueryThen */
                ({ getQrCodeScannedForQuote }) => {
                    if (getQrCodeScannedForQuote) {
                        hideActiveOverlay();
                        showPopup(NEW_SESSION_POPUP_ID, { title: __('New session starts in') });
                    } else {
                        // Recursion to avoid calling the next request before previous has finished
                        this.checkIfQrCodeScanned(instance, getQrCodeScannedForQuote);
                    }
                }
            );
        }, CHECK_IS_QR_SCANNED_AFTER);
    }
}

const {
    mapStateToProps,
    mapDispatchToProps,
    propTypes,
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
    onCheckoutButtonClick
} = new CartPageContainerPlugin();

export const config = {
    'Route/CartPage/Container': {
        'static-member': {
            propTypes
        },
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount,
            onCheckoutButtonClick
        }
    },
    'Route/CartPage/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
