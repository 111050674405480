/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import * as Sentry from '@sentry/react';

export class AppComponent {
    rootComponents = (originalMember) => [
        this.injectSentry.bind(this),
        ...originalMember
    ];

    injectSentry() {
        const currentHostname = window.location.hostname;
        // eslint-disable-next-line fp/no-let
        let environment = '';

        if (currentHostname === 'mx.puma.com' || currentHostname === 'subdomain-wnq-1679395859.readymage.com') {
            environment = 'production';
        } else if (currentHostname === 'stage.mx.puma.com' || currentHostname === 'subdomain-wnq-1679395859-staging-sky.readymage.com') {
            environment = 'staging';
        }

        if (!environment) {
            // eslint-disable-next-line max-len, no-console
            console.log('Does not appear to be running on mx.puma.com or stage.mx.puma.com (or the upgrade instance equivalents), skipping Sentry initialization');

            return;
        }

        Sentry.init({
            dsn: 'https://5227f84d71164c2491858a732f37fc59@o4505160550121472.ingest.sentry.io/4505160552284160',
            environment,
            integrations: [
                new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: [/https:\/\/.*\.puma\.com/, /https:\/\/[^/]+\.readymage\.com/]
                }),
                new Sentry.Replay({
                    networkDetailAllowUrls: [window.location.origin]
                })
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0
        });
    }
}

export const { rootComponents } = new AppComponent();

export const config = {
    'Component/App/Component': {
        'member-property': {
            rootComponents
        }
    }
};

export default config;
