import {
    getInitialState
} from 'SourceStore/NoMatch/NoMatch.reducer';
import { UPDATE_NOMATCH } from 'Store/NoMatch/NoMatch.action';

export {
    getInitialState
};

/** @namespace Scandipwa/Store/NoMatch/Reducer/NoMatchReducer */
export const NoMatchReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_NOMATCH:
        const { noMatch } = action;

        if (state.noMatch === noMatch) {
            return state;
        }

        return { noMatch };

    default:
        return state;
    }
};

export default NoMatchReducer;
