/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import ForgotPassword from 'Component/ForgotPassword';
import Form from 'Component/Form';
import { MyAccountSignIn as SourceMyAccountSignIn } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { SIGN_CONFIRM_EMAIL_ID, SIGN_EMAIL_ID, SIGN_PASSWORD_ID } from './MyAccountSignIn.config';

import './MyAccountSignIn.style';

/** @namespace Scandipwa/Component/MyAccountSignIn/Component */
export class MyAccountSignInComponent extends SourceMyAccountSignIn {
    static propTypes = {
        ...SourceMyAccountSignIn.propTypes,
        isNoTitle: PropTypes.bool.isRequired,
        isSignInDisabled: PropTypes.bool.isRequired,
        onDataChange: PropTypes.func.isRequired,
        showForgotPasswordPopup: PropTypes.bool.isRequired
    };

    renderHeading() {
        const { isNoTitle } = this.props;

        if (isNoTitle) {
            return null;
        }

        return (
            <div block="LoginTitle">
                <h3>{ __('Login') }</h3>
            </div>
        );
    }

    renderMessage() {
        return (
            <div block="LoginMessage">
                <span>{ __('You have a membership with us. Sign in or continue as a guest.') }</span>
            </div>
        );
    }

    renderActions() {
        const {
            handleForgotPassword,
            isCheckout,
            isSignInDisabled,
            showForgotPasswordPopup
        } = this.props;

        return (
            <div block="MyAccountSignIn" elem="LoginButton" mods={ { isCheckout } }>
                <button
                  block="Button"
                  elem={ isCheckout ? 'Checkout' : 'Primary' }
                  mods={ { withArrow: true } }
                  disabled={ isSignInDisabled }
                >
                    { __('Sign in') }
                </button>
                <button
                  block="Button"
                  elem="Forgot"
                  type="Button"
                  mods={ { likeLink: true } }
                  onClick={ handleForgotPassword }
                >
                    { __('Forgot Your Password?') }
                </button>
                { showForgotPasswordPopup ? <ForgotPassword /> : null }
            </div>
        );
    }

    renderFields() {
        const {
            emailValue,
            onDataChange
        } = this.props;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Email') }
                  attr={ {
                      id: SIGN_EMAIL_ID,
                      name: 'email',
                      defaultValue: emailValue,
                      autocomplete: 'email'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email
                  } }
                  events={ {
                      onChange: onDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.email }
                  label={ __('Confirm Your Email') }
                  attr={ {
                      id: SIGN_CONFIRM_EMAIL_ID,
                      name: 'confirm-email',
                      defaultValue: emailValue
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.email,
                      match: (value) => {
                          const email = document.getElementById(SIGN_EMAIL_ID);

                          return value && email.value === value;
                      },
                      customErrorMessages: {
                          onMatchFail: __('Emails do not match!')
                      }
                  } }
                  events={ {
                      onChange: onDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
                <Field
                  type={ FIELD_TYPE.password }
                  label={ __('Password') }
                  attr={ {
                      id: SIGN_PASSWORD_ID,
                      name: 'password',
                      autocomplete: 'current-password'
                  } }
                  validateOn={ ['onChange'] }
                  validationRule={ {
                      isRequired: true,
                      inputType: VALIDATION_INPUT_TYPE.password
                  } }
                  events={ {
                      onChange: onDataChange
                  } }
                  addRequiredTag
                  showCheckMark
                />
            </>
        );
    }

    renderForm() {
        const {
            onSignInSuccess,
            onFormError,
            isCheckout
        } = this.props;

        return (
            <Form
              key="sign-in"
              onSubmit={ onSignInSuccess }
              onError={ onFormError }
            >
                { !isCheckout && this.renderHeading() }
                { this.renderFields() }
                { isCheckout && this.renderMessage() }
                { this.renderActions() }
            </Form>
        );
    }

    render() {
        const { isCheckout } = this.props;

        return (
            <div block="MyAccountSignIn" mods={ { isCheckout } }>
                { this.renderForm() }
            </div>
        );
    }
}

export default MyAccountSignInComponent;
