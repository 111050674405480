/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// exporting all functions and constants from original file
export * from 'SourceComponent/CheckoutGuestForm/CheckoutGuestForm.config.js';

export const GUEST_EMAIL_CONFIRM_FIELD_ID = 'guest_email_confirmation';
