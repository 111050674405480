/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { SHOW_NOTIFICATION } from 'SourceStore/Notification/Notification.action';

export { SHOW_NOTIFICATION, HIDE_NOTIFICATION, hideNotification } from 'SourceStore/Notification/Notification.action';

/**
 * Show notification (append to notification to global notification map).
 * @param  {String} msgType
 * @param  {String} msgText
 * @param  {any} msgDebug
 * @param showDebugInProduction
 * @return {void}
 * @namespace Scandipwa/Store/Notification/Action/showNotification */
export const showNotification = (msgType, msgText, msgDebug, showDebugInProduction = false) => ({
    type: SHOW_NOTIFICATION,
    msgType,
    msgText,
    msgDebug,
    showDebugInProduction
});
