/* eslint-disable react/no-unknown-property */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import { CartItemPrice as SourceCartItemPrice } from 'SourceComponent/CartItemPrice/CartItemPrice.component';
import { formatPrice, roundPrice } from 'Util/Price';

import '../ProductPrice/ProductPrice.style';

/** @namespace Scandipwa/Component/CartItemPrice/Component */
export class CartItemPriceComponent extends SourceCartItemPrice {
    static propTypes = {
        ...SourceCartItemPrice.propTypes,
        regularPriceValue: PropTypes.number.isRequired,
        isDiscounted: PropTypes.bool.isRequired
    };

    renderFinalPrice() {
        const { finalProductPrice, currency_code } = this.props;
        const price = roundPrice(finalProductPrice);

        return (
            <span aria-label={ __('Current product price') }>
                <data value={ finalProductPrice }>{ formatPrice(price, currency_code) }</data>
            </span>
        );
    }

    renderHighPrice() {
        const {
            isDiscounted, regularPriceValue, currency_code, quantity
        } = this.props;

        if (!isDiscounted) {
            return null;
        }

        const value = roundPrice(regularPriceValue * quantity);

        return (
            <span block="ProductPrice" elem="HighPrice" mods={ { isVisible: true } }>
                <data value={ regularPriceValue }>
                    { formatPrice(value, currency_code) }
                </data>
            </span>
        );
    }

    render() {
        const { mix } = this.props;

        return (
            <div block="ProductPrice" aria-label={ __('Product Price') } mix={ mix }>
                { this.renderFinalPrice() }
                { this.renderHighPrice() }
            </div>
        );
    }
}

export default CartItemPriceComponent;
