/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    UPDATE_ADYEN_CHECKOUT_ACTION_DATA, UPDATE_ADYEN_CHECKOUT_STATE_DATA, UPDATE_ORDER_NUMBER
} from '../../store/Adyen.action';

const getInitialState = (args, callback) => (
    {
        ...callback(...args),
        adyenStateData: {
            data: {
                browserInfo: {},
                clientStateDataIndicator: false,
                origin: '',
                paymentMethod: {
                    checkoutAttemptId: '',
                    encryptedCardNumber: '',
                    encryptedExpiryMonth: '',
                    encryptedExpiryYear: '',
                    encryptedSecurityCode: '',
                    holderName: '',
                    taxNumber: '',
                    type: ''
                },
                riskData: {
                    clientData: ''
                }
            },
            isValid: false,
            cardType: ''
        },
        orderNumber: '',
        adyenActionData: {
            paymentMethodType: '',
            type: '',
            reference: '',
            downloadUrl: ''
        }
    }
);

const addAdyenToCheckoutReducer = (args, callback) => {
    const [state, action] = args;

    switch (action.type) {
    case UPDATE_ADYEN_CHECKOUT_STATE_DATA:
        const {
            adyenStateData
        } = action;

        return {
            ...state,
            adyenStateData
        };
    case UPDATE_ORDER_NUMBER:
        const {
            orderNumber
        } = action;

        return {
            ...state,
            orderNumber
        };
    case UPDATE_ADYEN_CHECKOUT_ACTION_DATA:
        const {
            adyenActionData
        } = action;

        return {
            ...state,
            adyenActionData
        };
    default:
        return callback(...args);
    }
};

export default {
    'Scandipwa/Store/Checkout/Reducer/getInitialState': {
        function: getInitialState
    },
    'Scandipwa/Store/Checkout/Reducer/CheckoutReducer': {
        function: addAdyenToCheckoutReducer
    }
};
