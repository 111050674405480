/* eslint-disable react/no-unknown-property */
/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_ForgotPassword
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { DESKTOP_FORGOT_PASSWORD_POPUP, MOBILE_FORGOT_PASSWORD_POPUP } from './ForgotPassword.config';

import './ForgotPassword.style.scss';

/** @namespace Scandipwa/Component/ForgotPassword/Component */
export class ForgotPasswordComponent extends PureComponent {
    static propTypes = {
        isEqualEmails: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        onEmailChange: PropTypes.func.isRequired,
        onConfirmationEmailChange: PropTypes.func.isRequired,
        onPopupClose: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool
    };

    static defaultProps = {
        isMobile: false
    };

    render() {
        const {
            isEqualEmails,
            onSubmit,
            onEmailChange,
            onConfirmationEmailChange,
            onPopupClose,
            isLoading,
            isMobile
        } = this.props;

        return (
            <Popup
              id={ isMobile ? MOBILE_FORGOT_PASSWORD_POPUP : DESKTOP_FORGOT_PASSWORD_POPUP }
              mix={ { block: 'ForgotPassword', elem: 'Popup' } }
              onHide={ onPopupClose }
            >
                <Loader isLoading={ isLoading } />
                <Form
                  mix={ { block: 'ForgotPassword', elem: 'Form', mods: { isValid: isEqualEmails } } }
                  onSubmit={ onSubmit }
                >
                    <div
                      block="ForgotPassword"
                      elem="Note"
                    >
                        { __('Please enter your e-mail address below to receive a password reset instruction:') }
                    </div>
                    <Field
                      type={ FIELD_TYPE.email }
                      label={ __('E-mail') }
                      attr={ {
                          id: 'forgotPasswordEmail',
                          name: 'forgotPasswordEmail'
                      } }
                      validationRule={ {
                          isRequired: true,
                          inputType: VALIDATION_INPUT_TYPE.email
                      } }
                      validateOn={ ['onChange'] }
                      events={ {
                          onChange: onEmailChange
                      } }
                      mix={ { block: 'ForgotPassword', elem: 'Email', mods: { isValid: isEqualEmails } } }
                      addRequiredTag
                      showCheckMark
                    />
                    <div
                      block="ForgotPassword"
                      elem="ConfirmationEmailContainer"
                    >
                        <Field
                          type={ FIELD_TYPE.email }
                          attr={ {
                              id: 'forgotPasswordConfirmationEmail',
                              name: 'forgotPasswordConfirmationEmail'
                          } }
                          label={ __('Confirm your e-mail') }
                          validationRule={ {
                              isRequired: true,
                              inputType: VALIDATION_INPUT_TYPE.email,
                              match: (value) => {
                                  const email = document.getElementById('forgotPasswordEmail');

                                  return value && email.value === value;
                              },
                              customErrorMessages: {
                                  onMatchFail: __('Emails do not match')
                              }
                          } }
                          validateOn={ ['onChange'] }
                          mix={ {
                              block: 'ForgotPassword',
                              elem: 'ConfirmationEmail',
                              mods: { isValid: isEqualEmails }
                          } }
                          events={ {
                              onChange: onConfirmationEmailChange
                          } }
                          addRequiredTag
                          showCheckMark
                        />
                        <button
                          block="ForgotPassword"
                          elem="Submit"
                          mix={ { block: 'Button', elem: 'Primary' } }
                          disabled={ !isEqualEmails }
                          type="submit"
                        >
                            { __('Reset my password') }
                        </button>
                    </div>
                </Form>
            </Popup>
        );
    }
}

export default ForgotPasswordComponent;
