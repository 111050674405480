/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BrowserDatabase from 'Util/BrowserDatabase';

import { setInputs, setIsKeyboardOpened } from '../../store/Keyboard';
import SalesAssociate from './SalesAssociate.component';
import { SALES_ASSOCIATE_ID_COOKIE, SALES_ASSOCIATE_INPUT_ID } from './SalesAssociate.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Bigscreen/Component/SalesAssociate/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    applySalesAssociateIdToCart: (salesAssociateId) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.applySalesAssociateToCart(dispatch, salesAssociateId)
    ),
    setIsKeyboardOpened: (isKeyboardOpened) => dispatch(setIsKeyboardOpened(isKeyboardOpened)),
    setInputs: (inputs) => dispatch(setInputs(inputs))
});

/** @namespace Bigscreen/Component/SalesAssociate/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    inputs: state.KeyboardReducer.inputs,
    inputName: state.KeyboardReducer.inputName,
    isEnterPressed: state.KeyboardReducer.isEnterPressed
});

/** @namespace Bigscreen/Component/SalesAssociate/Container */
export class SalesAssociateContainer extends PureComponent {
    static propTypes = {
        applySalesAssociateIdToCart: PropTypes.func.isRequired,
        inputName: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        inputs: PropTypes.object.isRequired,
        isEnterPressed: PropTypes.bool.isRequired,
        setInputs: PropTypes.func.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired
    };

    state = {
        isLoading: false
    };

    containerFunctions = {
        handleApplySalesAssociateIdToCart: this.handleApplySalesAssociateIdToCart.bind(this)
    };

    componentDidUpdate() {
        const { isEnterPressed, inputName, inputs } = this.props;
        const { isLoading } = this.state;

        if (isEnterPressed && inputName === SALES_ASSOCIATE_INPUT_ID && !isLoading) {
            this.handleApplySalesAssociateIdToCart(inputs[SALES_ASSOCIATE_INPUT_ID]);
        }
    }

    __construct() {
        const { setInputs } = this.props;
        const sessionCookie = BrowserDatabase.getItem(SALES_ASSOCIATE_ID_COOKIE);

        if (!sessionCookie) {
            setInputs({});
        }
    }

    handleApplySalesAssociateIdToCart(salesAssociateId = '') {
        const {
            applySalesAssociateIdToCart,
            inputs,
            setInputs,
            setIsKeyboardOpened
        } = this.props;

        setIsKeyboardOpened(false);
        this.setState({ isLoading: true });

        if (!salesAssociateId) {
            setInputs({ ...inputs, [SALES_ASSOCIATE_INPUT_ID]: '' });
        }

        applySalesAssociateIdToCart(salesAssociateId).then(
            /** @namespace Bigscreen/Component/SalesAssociate/Container/SalesAssociateContainer/handleApplySalesAssociateIdToCart/applySalesAssociateIdToCart/then */
            () => {
                this.setState({ isLoading: false });
                BrowserDatabase.setItem(salesAssociateId, 'salesAssociateId');
            }
        );
    }

    render() {
        return (
          <SalesAssociate
            { ...this.props }
            { ...this.state }
            { ...this.containerFunctions }
          />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesAssociateContainer);
