/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { ORDER_ID_KEY, PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import BrowserDatabase from 'Util/BrowserDatabase';

import GroovinPixels, {
    GROOVIN_PIXELS_CONVERSION
} from '../util/GroovinPixels';

/**
 * Checkout success component plugin
 */
class CheckoutSuccessComponentPlugin {
    aroundComponentDidMount = (args, callback = () => {}, instance) => {
        const totals = BrowserDatabase.getItem(PAYMENT_TOTALS) || {};
        const orderId = BrowserDatabase.getItem(ORDER_ID_KEY) || '';
        const { grand_total } = totals;

        if (grand_total) {
            const params = {
                goalvalue: grand_total.toFixed(2),
                idtransaction: orderId
            };

            GroovinPixels.updateGroovinPixels(params, GROOVIN_PIXELS_CONVERSION);
        }

        return callback.apply(instance, args);
    };

    aroundComponentWillUnmount = (args, callback = () => {}, instance) => {
        GroovinPixels.removeGroovinPixels(GROOVIN_PIXELS_CONVERSION);

        return callback.apply(instance, args);
    };
}

const {
    aroundComponentDidMount,
    aroundComponentWillUnmount
} = new CheckoutSuccessComponentPlugin();

const config = {
    'Component/CheckoutSuccess/Component': {
        'member-function': {
            componentDidMount: aroundComponentDidMount,
            componentWillUnmount: aroundComponentWillUnmount
        }
    }
};

export default config;
