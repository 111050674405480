/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Popup from 'Component/Popup';
import SubscriptionPopupForm from 'Component/SubscriptionPopupForm';
import { STYLEGUIDE_POPUP } from 'Route/StyleGuide/StyleGuide.config';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * SubscriptionPopup Component plugin
 */
export class SubscriptionPopupComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        clearInputs: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        toggleSubscribePopup: PropTypes.func.isRequired,
        showSubscribePopup: PropTypes.bool.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired
    });

    componentDidMount = (args, callback = () => {}, _instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    };

    onPopupHide = (args, callback = () => {}, instance) => {
        const {
            clearInputs,
            hideActiveOverlay,
            showSubscribePopup,
            toggleSubscribePopup
        } = instance.props;

        hideActiveOverlay();

        if (sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM)) {
            toggleSubscribePopup(!showSubscribePopup);
            clearInputs();

            return null;
        }

        return callback.apply(instance, args);
    };

    renderPopup = (args, callback = () => {}, instance) => {
        const { toggleOverlayByKey, activeOverlay } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && activeOverlay !== STYLEGUIDE_POPUP) {
            toggleOverlayByKey(STYLEGUIDE_POPUP);
        }

        return callback.apply(instance, args);
    };

    renderPopUps = (_args, _callback, instance) => {
        const cookie = (`; ${document.cookie}`).split('; CustomerClose=').pop().split(';').shift();
        const { customer, isSignedIn, isSubscriptionPopupActive = true } = instance.props;

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen && (cookie !== '' || !isSubscriptionPopupActive)) {
            return null;
        }

        if ((isSignedIn && customer.is_subscribed_bool === false) || !isSignedIn) {
            return (
                <Popup
                  id={ STYLEGUIDE_POPUP }
                  block="SubscriptionPopupNonRegister"
                  elem="Form"
                  onHide={ instance.onPopupHide }
                >
                    <SubscriptionPopupForm />
                </Popup>
            );
        }

        return null;
    };
}

const {
    propTypes,
    componentDidMount,
    onPopupHide,
    renderPopup,
    renderPopUps
} = new SubscriptionPopupComponentPlugin();

export default {
    'Scandipwa/Component/SubscriptionPopup/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            componentDidMount,
            onPopupHide,
            renderPopup,
            renderPopUps
        }
    }
};
