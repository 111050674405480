/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { getInitialState as sourceGetInitialState } from 'SourceStore/Checkout/Checkout.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    UPDATE_CHECKOUT_STATE,
    UPDATE_CHECKOUT_STATUS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_IS_SAME_AS_SHIPPING,
    UPDATE_PAYMENT_METHODS,
    UPDATE_PAYMENT_TOTALS,
    UPDATE_SELECTED_SHIPPING_METHOD,
    UPDATE_SHIPPING_ADDRESS,
    UPDATE_SHIPPING_FIELDS,
    UPDATE_SHIPPING_METHODS
} from './Checkout.action';

/** @namespace Scandipwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    isLoading: false,
    isDeliveryOptionsLoading: false,
    isPaymentMethodsLoading: false,
    paymentMethods: [],
    adyenMethods: [],
    totals: BrowserDatabase.getItem(PAYMENT_TOTALS) || {},
    shippingMethods: [],
    shippingAddress: {},
    orderId: '',
    action: {},
    isFinal: false,
    resultCode: '',
    selectedShippingMethod: {},
    selectedAddressId: 0,
    selectedBillingId: 0,
    selectedPaymentMethod: {},
    isSameAsShipping: true,
    isProcessed: false,
    incrementId: ''
});

/** @namespace Scandipwa/Store/Checkout/Reducer/getFormatedState */
export const getFormatedState = (state) => {
    const stateKeys = Object.keys(getInitialState());

    return Object.entries(state).reduce((formatedState, [key, value]) => {
        if (stateKeys.indexOf(key) === -1) {
            return formatedState;
        }

        return {
            ...formatedState,
            [key]: value
        };
    }, {});
};

/** @namespace Scandipwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    const {
        status,
        state: newState,
        paymentMethods,
        totals,
        shippingMethods,
        shippingAddress,
        isSameAsShipping,
        selectedShippingMethod
    } = action;

    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields
        };

    case UPDATE_EMAIL:
        const { email } = action;
        return {
            ...state,
            email
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;
        return {
            ...state,
            isEmailAvailable
        };

    case UPDATE_CHECKOUT_STATUS:
        return {
            ...state,
            ...status
        };

    case UPDATE_CHECKOUT_STATE:
        return {
            ...state,
            ...getFormatedState(newState)
        };

    case UPDATE_PAYMENT_METHODS:
        return {
            ...state,
            paymentMethods
        };

    case UPDATE_PAYMENT_TOTALS:
        return {
            ...state,
            totals
        };

    case UPDATE_SHIPPING_METHODS:
        return {
            ...state,
            shippingMethods
        };

    case UPDATE_SHIPPING_ADDRESS:
        return {
            ...state,
            shippingAddress
        };

    case UPDATE_IS_SAME_AS_SHIPPING:
        return {
            ...state,
            isSameAsShipping
        };

    case UPDATE_SELECTED_SHIPPING_METHOD:
        return {
            ...state,
            selectedShippingMethod
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
