/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';

import { TYPE_NOTFOUND, TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { isReleaseProduct } from 'Util/ProductRelease';

/** @namespace Scandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    requestUrlRewrite() {
        // eslint-disable-next-line no-unused-vars
        const { requestUrlRewrite, urlRewrite } = this.props;

        // if (urlRewrite.blockBERequest) { // Do not send request to BE for urlRewrite retreival
        //     return false;
        // }

        return requestUrlRewrite(location.pathname);
    }

    getType() {
        const { urlRewrite: { type, sku } } = this.props;

        if (type === TYPE_PRODUCT && isReleaseProduct(sku)) {
            return TYPE_NOTFOUND;
        }

        return super.getType();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
