/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getCartItems } from '../../../Util/Cart';
import { getCustomerEmail } from '../../../Util/Helper';

/**
 * ProductPage Container plugin
 */
export class ProductPageContainerPlugin {
    componentDidUpdate = async (args, callback, instance) => {
        const [prevProps] = args;
        const { product: { sku: prevSku } } = prevProps;
        const { product: { sku } } = instance.props;

        if (prevSku !== sku) {
            const email = getCustomerEmail();

            if (email && !window.webExtendEvents.includes('setEmail')) {
                // Should run before the other commands
                ScarabQueue.push(['setEmail', email]);
                window.webExtendEvents.push('setEmail');
            }

            if (!window.webExtendEvents.includes('view')) {
                // Passing on item ID to report product view. Item ID should match the value listed in the Product Catalog.
                // Note that we pass the parent sku here because no selection would have been made yet.
                ScarabQueue.push(['view', `g/${sku}`]);
                window.webExtendEvents.push('view');
            }

            if (!window.webExtendEvents.includes('cart')) {
                // Passing on visitor's cart contents to feed cart abandonment campaigns
                ScarabQueue.push(['cart', getCartItems()]);
                window.webExtendEvents.push('cart');
            }

            if (!window.webExtendEvents.includes('go') && window.webExtendEvents.length) {
                ScarabQueue.push(['go']);
                window.webExtendEvents.push('go');
            }

            window.webExtendEvents = []; // Resetting the list
        }

        callback(...args);
    };
}

const {
    componentDidUpdate
} = new ProductPageContainerPlugin();

export const config = {
    'Route/ProductPage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};

export default config;
