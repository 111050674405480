/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-indent-props */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import SearchOverlay from 'Component/SearchOverlay';
import history from 'Util/History';
import isMobile from 'Util/Mobile';
import { appendWithStoreCode } from 'Util/Url';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

export const SEARCH_INPUT_NAME = 'search-field';

/**
 * SearchField Component plugin
 */
export class SearchFieldComponentPlugin {
    state = (original) => ({
        ...original,
        isAllowedToBeClosed: true
    });

    componentDidMount = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        /**
         * on mobile, the Segmentify overlay covers regular one too quickly, so we need the Keyboard appear on load
         */
        if (isBigScreen && isMobile.any()) {
            instance.searchBarRef.current.focus();
        }

        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const {
            isEnterPressed,
            inputName,
            hideActiveOverlay,
            isSegmentifyEnabled,
            inputs,
            isKeyboardOpened
        } = instance.props;
        const [prevProps] = args;
        const { inputs: prevInputs } = prevProps;

        // vvv this for controlling keyboard after close, as component recalculates state only on the next update
        if ((inputs !== prevInputs && isKeyboardOpened) || isKeyboardOpened) {
            instance.setState({ isAllowedToBeClosed: false });
        } else {
            instance.setState({ isAllowedToBeClosed: true });
        }

        if (isEnterPressed && inputName === SEARCH_INPUT_NAME) {
            const search = inputs[SEARCH_INPUT_NAME].trim().replace(/\s\s+/g, '%20');

            if (isSegmentifyEnabled) {
                history.push(appendWithStoreCode(`/segmentifysearch?q=${ search }`));
            } else {
                history.push(appendWithStoreCode(`/search/${ search }`));
            }

            hideActiveOverlay();
            instance.searchBarRef.current.blur();
            instance.closeSearch();
        }

        return callback(...args);
    };

    renderMobileInput = (args, callback, instance) => {
        const { isActive, inputs } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return (
                <input
                  id={ SEARCH_INPUT_NAME }
                  ref={ instance.searchBarRef }
                  block="SearchField"
                  elem="Input"
                  onFocus={ () => this.onFocus(instance) }
                  onChange={ instance.handleChange }
                  onKeyDown={ instance.onSearchEnterPress }
                  value={ inputs[SEARCH_INPUT_NAME] }
                  mods={ { isActive } }
                  autoComplete="off"
                  enterKeyHint="search"
                  inputMode="none"
                />
            );
        }

        return callback(...args);
    };

    renderDesktopInput = (args, callback, instance) => {
        const {
            searchCriteria,
            isActive,
            isVisible,
            inputs
        } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        return (
            <>
                <input
                    id="search-field"
                    ref={ instance.searchBarRef }
                    block="SearchField"
                    elem="Input"
                    onFocus={ () => this.onFocus(instance) }
                    onChange={ instance.handleChange }
                    onKeyDown={ instance.onSearchEnterPress }
                    value={ inputs[SEARCH_INPUT_NAME] }
                    mods={ { isActive } }
                    autoComplete="off"
                    enterKeyHint="search"
                />
                { isVisible && searchCriteria && (
                    <Suspense fallback={ instance.renderOverlayFallback() }>
                        <SearchOverlay clearSearch={ instance.clearSearch } searchCriteria={ searchCriteria } />
                    </Suspense>
                ) }
            </>
        );
    };

    closeSearch = (instance) => (e) => {
        const {
            onSearchOutsideClick,
            handleCloseButtonClick
        } = instance.props;
        const { isAllowedToBeClosed } = instance.state;

        if (isAllowedToBeClosed) {
            onSearchOutsideClick(e);
            instance.setState({ showSearch: false });

            if (handleCloseButtonClick) {
                handleCloseButtonClick(e);
            }
        }
    };

    render = (args, callback, instance) => {
        const {
            isVisible,
            isMobile
        } = instance.props;
        const { isActive } = instance.state;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return (
                <div
                    block="SearchField"
                    mods={ { isVisible, isActive } }
                    style={ { margin: '0 15px 0 0' } }
                >
                    <ClickOutside onClick={ this.closeSearch(instance) }>
                        <div block="SearchField" elem="Wrapper">
                            { isMobile ? (
                                instance.renderMobileContent()
                            ) : (
                                <>
                                    { instance.renderDesktopInput() }
                                    { instance.renderDesktopContent() }
                                    { instance.renderCloseButton() }
                                </>
                            ) }
                        </div>
                    </ClickOutside>
                </div>
            );
        }

        return callback(...args);
    };

    onFocus = (instance) => {
        const { onSearchBarFocus, setInputName, setIsKeyboardOpened } = instance.props;

        if (onSearchBarFocus) {
            onSearchBarFocus();
        }

        setIsKeyboardOpened(true);
        setInputName(SEARCH_INPUT_NAME);
    };
}

const {
    componentDidMount,
    componentDidUpdate,
    renderMobileInput,
    renderDesktopInput,
    render,
    state
} = new SearchFieldComponentPlugin();

export default {
    'Scandipwa/Component/SearchField/Component': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            renderMobileInput,
            renderDesktopInput,
            render
        },
        'member-property': {
            state
        }
    }
};
