/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * FieldEmail Component plugin
 */
export class FieldEmailComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        setInputName: PropTypes.func.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        inputs: PropTypes.object.isRequired,
        inputName: PropTypes.string.isRequired,
        isEnterPressed: PropTypes.bool.isRequired
    });

    componentDidUpdate = (args, callback, instance) => {
        const { id, inputs } = instance.props;
        const [prevProps] = args;
        const { inputs: prevInputs } = prevProps;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && inputs[id] !== undefined && inputs[id] !== prevInputs[id]) {
            instance.handleInputChange({ target: { value: inputs[id] } });
        }

        return callback(...args);
    };

    renderBigScreen(instance) {
        const {
            formRef,
            hasInputChanged,
            invalidMessage,
            isEmailValid,
            isRequired,
            placeholder,
            showCheckMark,
            submitAttempted,
            validateOnChange,
            validateSubscription,
            ...validProps
        } = instance.props;
        const {
            email,
            showMessage
        } = instance.state;

        const isValidAndChanged = hasInputChanged || submitAttempted ? isEmailValid : true;

        return (
            <>
              <div block="FieldInput" elem="Wrapper">
                  <div>
                      <input
                        autoCapitalize="none"
                        ref={ formRef }
                        { ...validProps }
                        spellCheck={ false }
                        value={ email }
                        onChange={ instance.handleInputChange }
                        onFocus={ () => this.onFocus(instance) }
                        block="FieldInput"
                        elem="Input"
                        mods={ {
                            isFilled: email !== '',
                            type: 'email',
                            isValid: isValidAndChanged ? 'true' : 'false'
                        } }
                        onBlur={ instance.handleInputBlur }
                        type="text"
                      />
                      { placeholder
                        && (
                          // eslint-disable-next-line jsx-a11y/label-has-associated-control
                          <label
                            block="FieldInput"
                            elem="Label"
                            mods={ { isRequired, isFilled: email !== '' } }
                          >
                              { placeholder }
                          </label>
                        ) }
                  </div>
              </div>
              <span block="FieldInput" elem="Error">
                    { !isEmailValid && showMessage ? invalidMessage : '' }
              </span>
              { !isEmailValid && validateOnChange && !validateSubscription
                && <span block="FieldInput" elem="Error">{ invalidMessage }</span> }
              { isEmailValid && showCheckMark && hasInputChanged && <div block="Icon" elem="CheckGreen" /> }
            </>
        );
    }

    render = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        const {
            formRef,
            placeholder,
            isRequired,
            isEmailValid,
            invalidMessage,
            message,
            validateOnChange,
            isQuestionMarkNeeded,
            showCheckMark,
            hasInputChanged,
            validateSubscription,
            submitAttempted,
            ...validProps
        } = instance.props;

        const {
            email,
            showMessage,
            isToolTipVisible
        } = instance.state;

        const isValid = !showMessage ? (!(!isEmailValid && validateOnChange && invalidMessage)) : false;
        const isValidAndChanged = hasInputChanged || submitAttempted ? isValid : true;

        if (isBigScreen) {
            return this.renderBigScreen(instance);
        }

        if (isQuestionMarkNeeded) {
            return (
                <>
                  <div block="FieldInput" elem="Wrapper">
                      <div>
                          <input
                            autoCapitalize="none"
                            ref={ formRef }
                            { ...validProps }
                            spellCheck={ false }
                            value={ email }
                            onChange={ instance.handleInputChange }
                            onFocus={ () => this.onFocus(instance) }
                            block="FieldInput"
                            elem="Input"
                            mods={ {
                                isFilled: email !== '',
                                type: 'email',
                                isValid: isValidAndChanged ? 'true' : 'false'
                            } }
                            onBlur={ instance.handleInputBlur }
                            type="text"
                          />
                          { placeholder
                            && (
                              // eslint-disable-next-line jsx-a11y/label-has-associated-control
                              <label
                                block="FieldInput"
                                elem="Label"
                                mods={ { isRequired, isFilled: email !== '' } }
                              >
                                  { placeholder }
                              </label>
                            ) }
                      </div>
                      <div
                        block="FieldInput"
                        elem="QuestionIconWrapper"
                        onMouseEnter={ () => {
                            instance.setState({ isToolTipVisible: true });
                        } }
                        onMouseLeave={ () => {
                            instance.setState({ isToolTipVisible: false });
                        } }
                      >
                          <div
                            block="Icon"
                            elem="Question"
                          />
                          <div block="FieldInput" elem="QuestionIconText" mods={ { isToolTipVisible } }>
                              { ` ${__('We`ll send your order confirmation there')}.` }
                          </div>
                      </div>
                  </div>
                  { !validateOnChange
                    && <span block="FieldInput" elem="Error">{ showMessage ? invalidMessage : '' }</span> }
                  { validateOnChange
                    && <span block="FieldInput" elem="Error">{ !isEmailValid ? invalidMessage : '' }</span> }
                  { showMessage && invalidMessage === '' && message !== ''
                    && <span block="FieldInput" elem="Error">{ message }</span> }
                </>
            );
        }

        return (
            <>
              <div block="FieldInput" elem="Wrapper">
                  <div>
                      <input
                        autoCapitalize="none"
                        ref={ formRef }
                        { ...validProps }
                        spellCheck={ false }
                        value={ email }
                        onChange={ instance.handleInputChange }
                        onFocus={ () => this.onFocus(instance) }
                        block="FieldInput"
                        elem="Input"
                        mods={ {
                            isFilled: email !== '',
                            type: 'email',
                            isValid: isValidAndChanged ? 'true' : 'false'
                        } }
                        onBlur={ instance.handleInputBlur }
                        type="text"
                      />
                      { placeholder
                        && (
                          // eslint-disable-next-line jsx-a11y/label-has-associated-control
                          <label
                            block="FieldInput"
                            elem="Label"
                            mods={ { isRequired, isFilled: email !== '' } }
                          >
                              { placeholder }
                          </label>
                        ) }
                  </div>
              </div>
              <span block="FieldInput" elem="Error">
                    { showMessage && !validateOnChange && !validateSubscription ? invalidMessage : '' }
              </span>
              { !isEmailValid && validateOnChange && !validateSubscription
                && <span block="FieldInput" elem="Error">{ invalidMessage }</span> }
              { !isEmailValid && validateSubscription
                && <span block="FieldInput" elem="Error">{ invalidMessage }</span> }
              { showMessage && invalidMessage === '' && message !== ''
                && <span block="FieldInput" elem="Error">{ message }</span> }
              { isEmailValid && showCheckMark && hasInputChanged && <div block="Icon" elem="CheckGreen" /> }
            </>
        );
    };

    onFocus = (instance) => {
        const { id, setInputName, setIsKeyboardOpened } = instance.props;

        setIsKeyboardOpened(true);
        setInputName(id);
    };
}

const {
    propTypes,
    componentDidUpdate,
    render
} = new FieldEmailComponentPlugin();

export const config = {
    'Component/FieldEmail/Component': {
        'member-function': {
            componentDidUpdate,
            render
        },
        'static-member': {
            propTypes
        }
    }
};

export default config;
