/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CmsBlock from 'Component/CmsBlock';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

export class FooterComponentPlugin {
    renderNewsletter = (args, callback) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    };

    renderContent = (args, callback, instance) => {
        const { isVisible, isPDP } = instance.state;
        const { footer_bse_content: { footer_bse_cms } = {} } = window.contentConfiguration;

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        if (!isVisible) {
            return null;
        }

        if (footer_bse_cms) {
            return (
                <div block="Footer" mods={ { isPDP } }>
                    <CmsBlock
                      identifier={ footer_bse_cms }
                      customComponents={ instance.customComponents }
                      setIsLoading={ instance.handleLoading }
                    />
                </div>
            );
        }

        return null;
    };
}

const {
    renderNewsletter,
    renderContent
} = new FooterComponentPlugin();

export const config = {
    'Scandipwa/Component/Footer/Component': {
        'member-function': {
            renderNewsletter,
            renderContent
        }
    }
};

export default config;
