/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import SourceSubscription from 'Component/Subscription/Subscription.component';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import './SubscriptionPopupForm.style.scss';

/** @namespace Scandipwa/Component/SubscriptionPopupForm/Component */
export class SubscriptionPopupFormComponent extends SourceSubscription {
    static propTypes = {
        subscribe: PropTypes.func.isRequired,
        subscriptionPopupButton: PropTypes.string.isRequired,
        subscriptionPopupCta: PropTypes.string.isRequired,
        subscriptionPopupHeading: PropTypes.string.isRequired,
        subscriptionPopupTerms: PropTypes.string.isRequired
    };

    onSubmitSuccess = () => {
        const { subscribe } = this.props;
        const { isConfirmation, email, confirmationEmail } = this.state;

        if (!isConfirmation) {
            if (email === confirmationEmail) {
                this.setState({ inputAccepted: true });
                subscribe(email, (message) => {
                    this.setState({ message });
                });
            }
        }
    };

    renderForm() {
        const {
            subscriptionPopupHeading,
            subscriptionPopupCta,
            subscriptionPopupTerms,
            subscriptionPopupButton
        } = this.props;
        const {
            email,
            isTermsAndConditionsAccepted,
            confirmationEmail,
            startedInputting,
            inputAccepted
        } = this.state;
        const errorMessage = this.getErrorMessage(email, confirmationEmail);
        const disableSubmit = email.length === 0
            || !(email === confirmationEmail)
            || !isTermsAndConditionsAccepted
            || inputAccepted;

        return (
            <Form
              onSubmit={ this.onSubmitSuccess }
            >
                <div block="Subscription" elem="FieldWrapper" mods={ { isValid: errorMessage === '' } }>
                    <div block="UpperTitle">
                        <p
                          block="Popup"
                          elem="Title"
                        >
                            { subscriptionPopupHeading }
                        </p>
                        <p
                          block="Popup"
                          elem="Text"
                        >
                            { subscriptionPopupCta }
                        </p>
                    </div>
                    <div block="Form">
                        <div block="Envelop">
                            <svg viewBox="0 0 53 53" fill="none" id="envelope" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.5 21.767V52h50V21.767m-50 0l9.302 5.316M1.5
                                    21.767l9.302-7.53m40.698
                                    7.53l-10.465 5.709M51.5 21.767l-10.533-8.14M10.802 27.084l15.117
                                    8.638 15.116-8.245m-30.233-.393V14.237m30.233 13.239V8.977h-6.088m-18.33
                                     6.976H35.22m-18.605 4.652h18.605m-18.605 4.65h18.605m-.274-16.278L25.92
                                     2 17.3 8.977m17.647 0H17.3m-6.498 5.26v-5.26H17.3"
                                  stroke="#fff"
                                  strokeWidth="2"
                                />
                            </svg>
                        </div>
                        <div block="Fields">
                            <Field
                              type={ FIELD_TYPE.email }
                              attr={ {
                                  id: 'subEmail',
                                  name: 'email',
                                  defaultValue: email,
                                  autoComplete: 'off'
                              } }
                              events={ {
                                  onChange: this.onChange
                              } }
                              validationRule={ {
                                  isRequired: true,
                                  inputType: VALIDATION_INPUT_TYPE.email
                              } }
                              validateOn={ ['onChange'] }
                              label={ __('Enter Your Email') }
                            />
                            <Field
                              type={ FIELD_TYPE.email }
                              attr={ {
                                  name: 'confirmationEmail',
                                  defaultValue: confirmationEmail
                              } }
                              events={ {
                                  onChange: this.onChange2
                              } }
                              validationRule={ {
                                  isRequired: true,
                                  inputType: VALIDATION_INPUT_TYPE.email,
                                  match: (value) => {
                                      const email = document.getElementById('subEmail');

                                      return value && email.value === value;
                                  },
                                  customErrorMessages: {
                                      onMatchFail: __('Emails do not match!')
                                  }
                              } }
                              validateOn={ ['onChange'] }
                              label={ __('Confirm your email') }
                            />
                        </div>
                    </div>
                </div>
                <div block="Subscription" elem="CustomError" mods={ { isVisible: startedInputting } }>
                   { errorMessage }
                </div>
                <div block="Subscription" elem="TermsAndConditionsContainer">
                    <label
                      block="Subscription"
                      elem="TACLabel"
                      htmlFor="subscriptionConditions"
                    >
                        { subscriptionPopupTerms }
                    </label>
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: 'subscriptionConditions',
                          name: 'subscriptionConditions',
                          defaultChecked: isTermsAndConditionsAccepted
                      } }
                      events={ {
                          onChange: this.setTACAccepted
                      } }
                      mix={ {
                          block: 'Subscription',
                          elem: 'TermsAndConditions',
                          mods: { isChecked: isTermsAndConditionsAccepted }
                      } }
                    />
                </div>
                <div block="Subscription" elem="SubmitContainer">
                    <button
                      type="submit"
                      disabled={ disableSubmit }
                      mix={ { block: 'Button', elem: 'AddToCart' } }
                    >
                        { subscriptionPopupButton }
                    </button>
                </div>
            </Form>
        );
    }

    renderMessage(message) {
        return (
            <>
                <div block="Icon" elem="Puma" />
                <div block="Subscription" elem="Message">
                    { message }
                </div>
            </>
        );
    }

    render() {
        const { message } = this.state;

        return (
            <div block="SubscriptionPopup" elem="Form">
                { message !== '' ? this.renderMessage(message) : this.renderForm() }
            </div>
        );
    }
}

export default SubscriptionPopupFormComponent;
