/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import CheckoutQuery from 'Query/Checkout.query';
import { showPopup } from 'Store/Popup/Popup.action';
import { getCartId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';
import getStore from 'Util/Store';

import { AWAIT_CODE, THREE_DS2_CODE } from '../../component/AdyenCheckout/AdyenCheckout.config';
import { updateAdyenCheckoutActionData, updateOrderNumber } from '../../store/Adyen.action';
import {
    ADYEN_CC_METHOD_CODE, ADYEN_HPP_METHOD_CODE, ADYEN_OXXO_METHOD_CODE, THREE_DS2_DIV_ID, THREE_DS2_POPUP_ID
} from '../../util/Adyen';

class CheckoutContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        setOrderId: this.setOrderId.bind(instance),
        setCheckoutStep: this.setCheckoutStep.bind(instance)
    });

    _handleError = (args, callback, instance) => {
        // If adyen is used, unlock the drop-in loading state
        // Perhaps use the reducer for this since the CheckoutContainer does not know the selected payment method?
        if (window.adyenDropinComponent) {
            window.adyenDropinComponent.setStatus('ready'); // set back to the initial state
        }

        return callback(...args);
    };

    /**
     * Because the original function calls this.setDetailsStep(order_id) after the order is submitted to the BE, there is no place to handle a possible 3DS response.
     */
    savePaymentMethodAndPlaceOrder = async (args, callback, instance) => {
        const paymentInformation = args[0];
        const { paymentMethod: { code } } = paymentInformation;

        // If the current payment method is not Adyen, do nothing
        if (code !== ADYEN_CC_METHOD_CODE && code !== ADYEN_HPP_METHOD_CODE && code !== ADYEN_OXXO_METHOD_CODE) {
            return callback(...args);
        }

        // For Adyen, we want to manually call the two mutations and check if the result requires 3DS
        const { paymentMethod: { additional_data, purchase_order_number } } = paymentInformation;
        const cart_id = getCartId();

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id,
                payment_method: {
                    code,
                    [code]: additional_data,
                    purchase_order_number
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(cart_id));
            const { placeOrder: { order: { order_id, adyen_payment_status: { isFinal, action } } } } = orderData;

            if (isFinal) {
                // Make sure the voucher data is accessible for the CheckoutSuccess to render
                if (action) {
                    const parsedAction = JSON.parse(action);
                    const { type, paymentMethodType } = parsedAction;

                    if (type === 'voucher' && paymentMethodType === 'oxxo') {
                        getStore().dispatch(updateAdyenCheckoutActionData(parsedAction));
                    }
                }

                instance.setDetailsStep(order_id);

                return;
            }

            if (!window.adyenDropinComponent || !action) {
                // TODO: Show notification to panik
            }

            // Store the order number in the state for later use when the 3DS response is received
            getStore().dispatch(updateOrderNumber(order_id));

            const parsedAction = JSON.parse(action);
            const { type } = parsedAction;

            // If 3DS is needed, show the popup and re-mount the drop-in element with the 3DS action
            if (type === THREE_DS2_CODE || type === AWAIT_CODE) {
                getStore().dispatch(showPopup(THREE_DS2_POPUP_ID, { title: __('Payment verification') }));
                window.adyenDropinElement.createFromAction(parsedAction).mount(`#${THREE_DS2_DIV_ID}`);
            }

            // TODO: Show error notification if the action type is not supported?
        } catch (e) {
            instance._handleError(e);
        }
    };

    /**
     * This function is used to set the orderID state, which is used by the checks in the constructor when landing on the success page.
     * @param {string} orderId The orderID to set
     */
    setOrderId(orderId) {
        this.setState({ orderID: orderId });
    }

    /**
     * This function is used to set the checkoutStep state, which is used to redirect to the success page
     * @param {string} step The step to set
     */
    setCheckoutStep(step) {
        this.setState({ checkoutStep: step });
    }
}

const { containerFunctions, _handleError, savePaymentMethodAndPlaceOrder } = new CheckoutContainer();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            _handleError,
            savePaymentMethodAndPlaceOrder,
            containerFunctions
        }
    }
};
