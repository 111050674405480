import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    mapDispatchToProps,
    mapStateToProps,
    NoMatchHandlerContainer as SourceNoMatchHandlerContainer
} from 'SourceRoute/NoMatchHandler/NoMatchHandler.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Route/NoMatchHandler/Container */
export class NoMatchHandlerContainer extends SourceNoMatchHandlerContainer {
    static propTypes = {
        ...SourceNoMatchHandlerContainer.propTypes,
        isScrollEnabled: PropTypes.bool
    };

    static defaultProps = {
        ...SourceNoMatchHandlerContainer.defaultProps,
        isScrollEnabled: true
    };

    containerProps() {
        const {
            isScrollEnabled
        } = this.props;

        return {
            ...super.containerProps(),
            isScrollEnabled
        };
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(NoMatchHandlerContainer)
);
