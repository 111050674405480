/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceOverlay from 'SourceComponent/Overlay/Overlay.component';

/**
 * Product measurements
 * @class ProductMeasurements
 * @namespace Scandipwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    componentDidMount() {
        const isVisible = this.getIsVisible();

        if (isVisible) {
            this.onVisible();
        }
    }

    freezeScroll() {
        const { id } = this.props;

        if (id === 'navigation_search') {
            return;
        }

        super.freezeScroll();
    }

    unfreezeScroll() {
        const { id } = this.props;

        if (id === 'navigation_search') {
            return;
        }

        super.unfreezeScroll();
    }

    /**
     * On overlay visible
     * Freezes scroll if isRenderInPortal is true and some overlay, except placeholder is opened
     */
    onVisible() {
        const {
            onVisible,
            isStatic,
            device,
            isRenderInPortal,
            payload
        } = this.props;

        if (isStatic) {
            return;
        }

        if (device.isMobile && isRenderInPortal) {
            this.freezeScroll();
        }

        this.overlayRef.current.focus();
        onVisible(payload);
    }

    /**
     * On overlay hide
     * Unfreezes scroll if isRenderInPortal is true
     */
    onHide() {
        const {
            onHide,
            isStatic,
            device,
            isRenderInPortal
        } = this.props;

        if (isStatic) {
            return;
        }

        if (device.isMobile && isRenderInPortal) {
            this.unfreezeScroll();
        }

        onHide();
    }

    render() {
        const {
            children,
            mix,
            areOtherOverlaysOpen,
            isStatic,
            style
        } = this.props;

        const isVisible = this.getIsVisible();

        return this.renderInMobilePortal(
            <div
              block="Overlay"
              ref={ this.overlayRef }
              mods={ { isVisible, isStatic, isInstant: areOtherOverlaysOpen } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
              style={ style }
            >
                { children && children }
            </div>
        );
    }
}

export default OverlayComponent;
