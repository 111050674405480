/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import getStore from 'Util/Store';

import { IDLE_TIME_POPUP_ID } from '../../component/IdleTimePopup/IdleTimePopup.config';

export const ALLOWED_IDLE_TIME_IN_SECONDS = 120;
export const CHECK_IDLE_TIME_AFTER = 3000;
export const REGISTER_INTERACTION_TIMEOUT = 300;

/** @namespace Bigscreen/Util/IdleTimer/Index */
export class IdleTimer {
    onTimeout = () => {
        getStore().dispatch(hideActiveOverlay());
        getStore().dispatch(showPopup(IDLE_TIME_POPUP_ID, { title: __('Are you still there?') }));
    };

    startTimer = () => {
        this.stopTimer();
        this.tracker();
        this.startInterval();
    };

    stopTimer = () => {
        this.cleanUp();
    };

    updateExpiredTime = () => {
        if (this.timeoutTracker) {
            clearTimeout(this.timeoutTracker);
        }

        this.timeoutTracker = setTimeout(() => {
            // eslint-disable-next-line no-magic-numbers
            localStorage.setItem('expiredTime', Date.now() + ALLOWED_IDLE_TIME_IN_SECONDS * 1000);
        }, REGISTER_INTERACTION_TIMEOUT);
    };

    startInterval() {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const expiredTime = parseInt(localStorage.getItem('expiredTime') || 0, 10);

            if (expiredTime < Date.now()) {
                this.onTimeout();
                this.stopTimer();
            }
        }, CHECK_IDLE_TIME_AFTER);
    }

    tracker() {
        window.addEventListener('click', this.updateExpiredTime);
        window.addEventListener('touchstart', this.updateExpiredTime);
        window.addEventListener('mousemove', this.updateExpiredTime);
        window.addEventListener('mousewheel', this.updateExpiredTime);
        window.addEventListener('wheel', this.updateExpiredTime);
        window.addEventListener('scroll', this.updateExpiredTime);
    }

    cleanUp() {
        localStorage.removeItem('expiredTime');
        clearInterval(this.interval);

        window.removeEventListener('click', this.updateExpiredTime);
        window.removeEventListener('touchstart', this.updateExpiredTime);
        window.removeEventListener('mousemove', this.updateExpiredTime);
        window.removeEventListener('mousewheel', this.updateExpiredTime);
        window.removeEventListener('wheel', this.updateExpiredTime);
        window.removeEventListener('scroll', this.updateExpiredTime);
    }
}

export default new IdleTimer();
