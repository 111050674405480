/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import * as Sentry from '@sentry/react';
import { compose, createStore } from 'redux';

import noopReducer from 'Util/Store';

const configureStore = (args, callback, instance) => {
    // eslint-disable-next-line no-debugger
    debugger;

    const sentryReduxEnhancer = Sentry.createReduxEnhancer();

    // eslint-disable-next-line no-console
    console.log('Sentry Redux Enhancer');

    const composeEnhancers = (
        ( // enable Redux dev-tools only in development
            process.env.NODE_ENV === 'development'
            && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true
        })
    ) || compose;

    const newStore = createStore(
        noopReducer,
        composeEnhancers(
            sentryReduxEnhancer
        )
    );

    return callback(newStore);
};

export const config = {
    'Scandipwa/Util/Store/Index/configureStore': {
        function: configureStore
    }
};

export default config;
