/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Fortergraphql/Scandipwa/App/Plugin/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        appendDataToPaymentMethodData: this.appendDataToPaymentMethodData.bind(instance)
    });

    appendDataToPaymentMethodData(data) {
        const { selectedPaymentCode } = this.state;

        this.setState(({ paymentMethodData }) => {
            const prevData = paymentMethodData?.[selectedPaymentCode];

            return {
                paymentMethodData: {
                    ...paymentMethodData,
                    [selectedPaymentCode]: {
                        ...prevData,
                        ...data
                    }
                }
            };
        });
    }
}

export const { containerFunctions, adyenContainerProps } = new CheckoutPaymentsContainer();

export default {
    'Component/CheckoutPayments/Container': {
        'member-property': {
            containerFunctions
        }
    }
};
