/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_Subscription
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Popup from 'Component/Popup';
import { RefType } from 'Type/Common.type';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { SUBSCRIPTION_POPUP } from './Subscription.config';

import './Subscription.style.scss';

/** @namespace Scandipwa/Component/Subscription/Component */
export class SubscriptionComponent extends PureComponent {
    static propTypes = {
        subscribe: PropTypes.func.isRequired,
        subscribeEmailRef: RefType.isRequired,
        subscriptionPopupTerms: PropTypes.string.isRequired,
        toggleOverlayByKey: PropTypes.func.isRequired
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            email: '',
            confirmationEmail: '',
            isConfirmation: false,
            isTermsAndConditionsAccepted: false,
            message: '',
            startedInputting: false,
            inputAccepted: false
        };

        this.onPopupHide = this.onPopupHide.bind(this);
    }

    onChange = (field) => {
        const { target: { value = '' } } = field;
        this.setState({ email: value });
    };

    onChange2 = (field) => {
        const { target: { value = '' } } = field;
        this.setState({ confirmationEmail: value, startedInputting: true });
    };

    onSubmit = () => {
        const { isConfirmation } = this.state;
        const { toggleOverlayByKey } = this.props;

        if (!isConfirmation) {
            this.setState({ isConfirmation: true });
            toggleOverlayByKey(SUBSCRIPTION_POPUP);
        }
    };

    onSubmit2 = () => {
        const { isConfirmation, email, confirmationEmail } = this.state;
        const { subscribe } = this.props;

        if (isConfirmation) {
            if (email === confirmationEmail) {
                this.setState({ inputAccepted: true });

                subscribe(email, (message) => {
                    this.setState({ message });
                });
            }
        }
    };

    setTACAccepted = () => {
        this.setState(({ isTermsAndConditionsAccepted: oldIsTACAccepted }) => ({
            isTermsAndConditionsAccepted: !oldIsTACAccepted
        }));
    };

    getErrorMessage(email, confirmationEmail) {
        if (email !== confirmationEmail) {
            return __('Please enter the same value again.');
        }

        return '';
    }

    renderForm() {
        const {
            subscriptionPopupTerms
        } = this.props;
        const {
            email,
            isTermsAndConditionsAccepted,
            confirmationEmail,
            startedInputting,
            inputAccepted
        } = this.state;
        const errorMessage = this.getErrorMessage(email, confirmationEmail);

        return (
            <Form onSubmit={ this.onSubmit2 }>
                <div block="Subscription" elem="FieldWrapper" mods={ { isValid: errorMessage === '' } }>
                    <Field
                      type={ FIELD_TYPE.text }
                      attr={ {
                          name: 'confirmationEmail',
                          defaultValue: confirmationEmail
                      } }
                      validationRule={ {
                          isRequired: true
                      } }
                      validateOn={ ['onSubmit'] }
                      events={ {
                          onChange: this.onChange2
                      } }
                      label={ __('Confirm your email') }
                    />
                </div>
                <div block="Subscription" elem="CustomError" mods={ { isVisible: startedInputting } }>
                    { errorMessage }
                </div>
                <div block="Subscription" elem="TermsAndConditionsContainer">
                    <label
                      block="Subscription"
                      elem="TACLabel"
                      htmlFor="subscriptionConditions"
                    >
                        { subscriptionPopupTerms }
                    </label>
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: 'subscriptionConditions',
                          name: 'subscriptionConditions',
                          defaultChecked: isTermsAndConditionsAccepted
                      } }
                      events={ {
                          onChange: this.setTACAccepted
                      } }
                      mix={ {
                          block: 'Subscription',
                          elem: 'TermsAndConditions',
                          mods: { isChecked: isTermsAndConditionsAccepted }
                      } }
                    />
                </div>
                <div block="Subscription" elem="SubmitContainer">
                    <button
                      type="submit"
                      disabled={ !(email === confirmationEmail) || !isTermsAndConditionsAccepted || inputAccepted }
                      mix={ { block: 'Button', elem: 'Primary' } }
                    >
                        { __('Subscribe') }
                    </button>
                </div>
            </Form>
        );
    }

    renderMessage(message) {
        return (
            <div block="Subscription" elem="Message">
                { message }
            </div>
        );
    }

    renderPopup() {
        const { message } = this.state;

        return (
            <Popup
              id={ SUBSCRIPTION_POPUP }
              title="bbb"
              mix={ { block: 'Subscription', elem: 'Popup' } }
              onHide={ this.onPopupHide }
            >
                <div block="Subscription" elem="Logo" />
                { message !== '' ? this.renderMessage(message) : this.renderForm() }
            </Popup>
        );
    }

    onPopupHide() {
        const { subscribeEmailRef: { current = {} } } = this.props;

        current.value = '';

        this.setState({
            confirmationEmail: '',
            email: '',
            inputAccepted: false,
            isConfirmation: false,
            isTermsAndConditionsAccepted: false,
            message: '',
            startedInputting: false
        });
    }

    render() {
        const { subscribeEmailRef } = this.props;
        const {
            email,
            isConfirmation
        } = this.state;

        return (
            <div block="Subscription">
                <div block="Subscription" elem="Title">
                    { __('Sign up and get 15% OFF in your first order') }
                </div>
                <Form
                  onSubmit={ this.onSubmit }
                  mix={ { block: 'Subscription', elem: 'Form' } }
                >
                    <Field
                      type={ FIELD_TYPE.email }
                      label={ __('Email') }
                      attr={ {
                          name: 'email',
                          autoComplete: 'off',
                          defaultValue: email
                      } }
                      elemRef={ subscribeEmailRef }
                      events={ {
                          onChange: this.onChange
                      } }
                      validationRule={ {
                          inputType: VALIDATION_INPUT_TYPE.email
                      } }
                      validateOn={ ['onSubmit'] }
                    />
                    <button
                      block="Subscription"
                      elem="Submit"
                      type="submit"
                      disabled={ !email || isConfirmation }
                      aria-label={ __('Submit') }
                    />
                    { this.renderPopup() }
                </Form>
            </div>
        );
    }
}

export default SubscriptionComponent;
