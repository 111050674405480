/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import SourceSearchItem from 'SourceComponent/SearchItem/SearchItem.component';

import './SearchItem.extended.style';

/** @namespace Scandipwa/Component/SearchItem/Component */
export class SearchItemComponent extends SourceSearchItem {
    renderImage() {
        const {
            product: { name, thumbnail },
            imgSrc
        } = this.props;

        const src = imgSrc || (thumbnail ? thumbnail.url : null);

        if (name && !src) {
            return (
                <div block="SearchItem" elem="Image" />
            );
        }

        return (
            <Image
              block="SearchItem"
              elem="Image"
              src={ src }
              alt={ __('Product %s thumbnail.', name) }
              isPlaceholder={ !name }
            />
        );
    }

    renderProductPrice() {
        const { product: { price_range } } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        return (
            <ProductPrice
              price={ price_range }
              mix={ { block: 'ProductCard', elem: 'Price' } }
            />
        );
    }

    renderContent() {
        const { product: { name } } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                { this.renderCustomAttribute() }
                <TextPlaceholder content={ name } length="long" />
                { this.renderProductPrice() }
            </figcaption>
        );
    }

    renderLink() {
        const { linkTo, onClick } = this.props;

        return (
            <Link
              block="SearchItem"
              elem="Link"
              to={ linkTo }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ (e) => {
                  // Clears search results, hides active search overlay and redirects to given link
                  onClick(e, linkTo);
              } }
            >
                <figure
                  block="SearchItem"
                  elem="Wrapper"
                >
                    { this.renderImage() }
                    { this.renderContent() }
                </figure>
            </Link>
        );
    }
}

export default SearchItemComponent;
