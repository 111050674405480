import BaseMeta from 'SourceComponent/Meta/Meta.component';
import { TYPE_CATEGORY } from 'SourceRoute/UrlRewrites/UrlRewrites.config';

/** @namespace Scandipwa/Component/Meta/Component */
export class MetaComponent extends BaseMeta {
    renderCanonical() {
        const { canonical_url, urlRewriteType } = this.props;
        const rootUrl = window.location.origin + window.location.pathname;
        const isCategoryPage = urlRewriteType === TYPE_CATEGORY;
        const canonical = isCategoryPage ? rootUrl : canonical_url || rootUrl;

        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <link rel="canonical" href={ canonical } />
        );
    }
}

export default MetaComponent;
