/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { POPUP } from 'Component/Header/Header.config';
import { STYLEGUIDE_POPUP } from 'Route/StyleGuide/StyleGuide.config';
import { hideActiveOverlay, showSubscribePopup, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

/**
 * Subscription Container plugin
 */
export class NavigationTabsContainerPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        showSubscribePopup: PropTypes.bool.isRequired,
        toggleSubscribePopup: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    });

    mapStateToProps = (args, callback, _instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            showSubscribePopup: state.OverlayReducer.showSubscribePopup
        };
    };

    mapDispatchToProps = (args, callback, _instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            toggleSubscribePopup: (value) => dispatch(showSubscribePopup(value)),
            showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey))
        };
    };

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onSubscribeClick: this.onSubscribeClick.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const {
            showOverlay,
            showSubscribePopup,
            toggleSubscribePopup,
            hideActiveOverlay
        } = instance.props;

        return {
            ...callback(...args),
            showOverlay,
            showSubscribePopup,
            toggleSubscribePopup,
            hideActiveOverlay
        };
    };

    onSubscribeClick() {
        const {
            setNavigationState,
            showOverlay,
            showSubscribePopup,
            toggleSubscribePopup
        } = this.props;
        const {
            isItemClicked,
            isMenuClicked,
            isSearchClicked
        } = this.state;

        if (isMenuClicked) {
            this.setState({
                iconColor: 'white',
                isMenuClicked: false
            });
        }

        if (isSearchClicked) {
            this.setState({
                iconColor: 'white',
                isSearchClicked: false
            });
        }

        if (isItemClicked) {
            this.setState({ isItemClicked: false });
        }

        // Adding logic from popup component to be used in BSE.
        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${location.pathname}${location.search}${location.hash}`
        );

        toggleSubscribePopup(!showSubscribePopup);
        showOverlay(STYLEGUIDE_POPUP);
        setNavigationState({ name: POPUP });
    }
}

const {
    propTypes,
    mapStateToProps,
    mapDispatchToProps,
    containerFunctions,
    containerProps
} = new NavigationTabsContainerPlugin();

export const config = {
    'Component/NavigationTabs/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/NavigationTabs/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/NavigationTabs/Container': {
        'static-member': {
            propTypes
        },
        'member-property': {
            containerFunctions
        },
        'member-function': {
            containerProps
        }
    }
};

export default config;
