/* eslint-disable fp/no-delete */
/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @author Janis Verins <janis.verins@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { updateEventData } from 'Store/Event/Event.action';

import { CATEGORY_REFINEMENTS_DATA_OBJECT }
from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/CategoryRefinementsDataEvent.handler';
import { PLP_DATA_OBJECT }
from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/ProductsPageDataEvent.handler';
import {
    CATEGORY_REFINEMENTS_TYPE,
    PLP_TYPE
} from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, { EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4, EVENT_GTM_PAGE_DATA } from '../../util/Event';

export const mapDispatchToProps = (args, callback, context) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        updateEventData: (eventData) => dispatch(updateEventData(eventData))
    };
};

export const state = (prop, instance) => ({
    isMounted: false,
    searchChanged: false,
    ...prop
});

export const dispatchCategoryFilterChangeEvent = (filter, value) => {
    Event.dispatch(EVENT_GTM_CATEGORY_REFINEMENT_SELECT_GA4, { filter, value });
};

export const getNewValue = (prevValues, values) => values.reduce((diffValue, value) => {
    if (!prevValues.includes(value)) {
        return value;
    }

    return diffValue;
}, '');

export const handleSearchChanged = (instance) => {
    instance.setState(({ searchChanged }) => ({
        searchChanged: !searchChanged
    }));
};

export const getEventData = () => ({
    eventType: 'refinement',
    widget: 'page',
    name: 'category_refinement'
});

export const dispatchCategoryRefinementsPageDataEvent = (instance) => {
    const { updateEventData } = instance.props;

    Event.dispatch(EVENT_GTM_PAGE_DATA, {
        type: CATEGORY_REFINEMENTS_TYPE,
        updateEventData,
        eventData: getEventData()
    });
};

export const componentDidUpdate = (args, callback, instance) => {
    const {
        location: {
            search
        },
        areProductsLoading,
        isMatchingListFilter,
        category: {
            id = ''
        } = {},
        filter: {
            customFilters: selectedFilters,
            priceRange
        },
        filters
    } = instance.props;
    const [prevProps] = args;
    const {
        location: {
            search: prevSearch
        },
        category: {
            id: prevId = ''
        } = {},
        filter: {
            customFilters: prevSelectedFilters,
            priceRange: prevPriceRange
        }
    } = prevProps;
    const { isMounted, searchChanged } = instance.state;
    const prevSearchParams = new URLSearchParams(prevSearch);
    const searchParams = new URLSearchParams(search);
    const prevCustomFilters = prevSearchParams.get('customFilters');
    const customFilters = searchParams.get('customFilters');

    if (prevCustomFilters !== customFilters) {
        for (const [filter, values] of Object.entries(selectedFilters)) {
            const prevValues = prevSelectedFilters?.[filter] || [];
            const isNewValue = values.length > prevValues.length;

            if (isNewValue) {
                const newValue = getNewValue(prevValues, values);
                const filterValue = filters[filter]?.attribute_options[newValue]?.label;

                dispatchCategoryFilterChangeEvent(filter, filterValue);

                break;
            }
        }
    }

    const prevPriceMin = prevSearchParams.get('priceMin');
    const priceMin = searchParams.get('priceMin');
    const prevPriceMax = prevSearchParams.get('priceMax');
    const priceMax = searchParams.get('priceMax');

    if (
        prevPriceMin !== priceMin
        || prevPriceMax !== priceMax
    ) {
        for (const [filter, value] of Object.entries(priceRange)) {
            if (prevPriceRange[filter] !== value) {
                dispatchCategoryFilterChangeEvent(`${filter } price`, value);

                break;
            }
        }
    }

    if (search !== prevSearch) {
        // As search !== prevSearch was instantly changing from true to false
        handleSearchChanged(instance);
    }

    // Faking component mount to dispatch category refinements event as it was not working on componentDidMount
    if ((!isMounted && isMatchingListFilter) || (id !== prevId && !areProductsLoading)) {
        dispatchCategoryRefinementsPageDataEvent(instance);
        Event.dispatch(EVENT_GTM_PAGE_DATA, { type: PLP_TYPE });

        instance.setState({ isMounted: true });
    }

    if (searchChanged && isMatchingListFilter) {
        dispatchCategoryRefinementsPageDataEvent(instance);
        handleSearchChanged(instance);
    }

    return callback(...args);
};

export const componentWillUnmount = (args, callback) => {
    if (window.pageData) {
        delete window.pageData[PLP_DATA_OBJECT];
        delete window.pageData[CATEGORY_REFINEMENTS_DATA_OBJECT];
    }

    return callback(...args);
};

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            'componentDidUpdate': componentDidUpdate,
            'componentWillUnmount': componentWillUnmount
        },
        'member-property': {
            'state': state
        }
    },
    'Route/CategoryPage/Container/mapDispatchToProps': {
        'function': mapDispatchToProps
    }
};
