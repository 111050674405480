/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getInitialState } from 'SourceStore/Popup/Popup.reducer';

import { CLEAR_POPUP_STATE, SHOW_POPUP } from './Popup.action';

/** @namespace Scandipwa/Store/Popup/Reducer/PopupReducer */
export const PopupReducer = (
    state = getInitialState(),
    action
) => {
    const { payload, type } = action;

    switch (type) {
    case SHOW_POPUP:
        return { ...state, popupPayload: payload };
    case CLEAR_POPUP_STATE:
        return getInitialState();
    default:
        return state;
    }
};

export default PopupReducer;
