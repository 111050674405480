/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
import { lazy } from 'react';

import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
// export const FieldZip = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/FieldZip'));
import FieldZipContainer from 'Component/FieldZip/FieldZip.container';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import { FIELD_TYPE } from './Field.config';

import 'SourceComponent/Field/Field.style';
import './Field.extended.style';

export const FieldAddress = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/FieldAddress'));
export const FieldEmail = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/FieldEmail'));
export const FieldPassword = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/FieldPassword'));
export const FieldPhone = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/FieldPhone'));
export const FieldSelect = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Component/FieldSelect'));

/** @namespace Scandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    state = {
        isToolTipVisible: false
    };

    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.address]: this.renderAddress.bind(this),
        [FIELD_TYPE.tel]: this.renderPhone.bind(this),
        [FIELD_TYPE.zip]: this.renderZip.bind(this)
    };

    renderAddress() {
        const {
            attr,
            events,
            resetFieldValue,
            setRef,
            validate
        } = this.props;

        return (
            <FieldAddress
              attr={ attr }
              events={ events }
              setRef={ setRef }
              validate={ validate }
              resetFieldValue={ resetFieldValue }
            />
        );
    }

    renderPhone() {
        const {
            attr,
            events,
            resetFieldValue,
            setRef,
            validate
        } = this.props;

        return (
            <FieldPhone
              attr={ attr }
              events={ events }
              setRef={ setRef }
              validate={ validate }
              resetFieldValue={ resetFieldValue }
            />
        );
    }

    renderZip() {
        const {
            attr,
            countryId,
            events,
            regionId,
            resetFieldValue,
            setRef,
            validate,
            setIsValidByCustomRules,
            label,
            value
        } = this.props;

        return (
            <FieldZipContainer
              attr={ attr }
              countryId={ countryId }
              events={ events }
              regionId={ regionId }
              resetFieldValue={ resetFieldValue }
              setRef={ setRef }
              validate={ validate }
              label={ label }
              setIsValidByCustomRules={ setIsValidByCustomRules }
              value={ value }
            />
        );
    }

    /**
     * Override to add the required tag render
     */
    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!value.match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  type={ type }
                  { ...newAttr }
                  { ...inputEvents }
                  // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ isChecked }
                />
                <div block="input-control" disabled={ isDisabled } />
                { label }
                { this.renderRequiredTag() }
            </label>
        );
    }

    renderDefaultInput() {
        const {
            attr,
            attr: { defaultValue = '' },
            events,
            fieldRef,
            isDisabled,
            setRef,
            type,
            isQuestionMarkNeeded
        } = this.props;

        const { isToolTipVisible } = this.state;

        const isFilled = fieldRef?.value || defaultValue !== '';

        return (
            <>
            <input
              ref={ (elem) => setRef(elem) }
              block="FieldInput"
              elem="Input"
              mods={ {
                  isFilled: !!isFilled
                  //   isValid: !isValid ? 'false' : 'true'
              } }
              disabled={ isDisabled }
              type={ type }
              { ...attr }
              { ...events }
            />
            { isQuestionMarkNeeded
            && <div
              block="FieldInput"
              elem="QuestionIconWrapper"
              onMouseEnter={ () => {
                  this.setState({ isToolTipVisible: true });
              } }
              onMouseLeave={ () => {
                  this.setState({ isToolTipVisible: false });
              } }
            >
                <div
                  block="Icon"
                  elem="Question"
                />
                <div block="FieldInput" elem="QuestionIconText" mods={ { isToolTipVisible } }>
                    { ` ${__('We`ll send your order confirmation there')}.` }
                </div>
               </div> }
            </>
        );
    }

    renderErrorMessage(message, key) {
        return <div block="Field" elem="ErrorMessage" key={ key }>{ message }</div>;
    }

    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name }
        } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return (
                <span block="FieldInput" elem="Error" />
            );
        }

        const { errorMessages = [] } = validationResponse;

        if (!errorMessages) {
            return (
                <span block="FieldInput" elem="Error" />
            );
        }

        return (
            <span block="FieldInput" elem="Error">
                { errorMessages.map((message, index) => this.renderErrorMessage.call(this, message, name + index)) }
            </span>
        );
    }

    renderLabel() {
        const {
            attr: { name, defaultValue = '' } = {},
            fieldRef,
            label,
            type
        } = this.props;

        if (!label) {
            return null;
        }

        const isFilled = fieldRef?.value || defaultValue !== '';

        return (
            <label
              block="FieldInput"
              elem="Label"
              mods={ { isFilled: !!isFilled } }
              htmlFor={ name || `input-${type}` }
            >
                { label }
                { this.renderRequiredTag() }
            </label>
        );
    }

    renderCheckMark() {
        const {
            attr: { defaultValue = '' } = {},
            fieldRef,
            showCheckMark,
            validationResponse
        } = this.props;

        const fieldValue = fieldRef?.value || defaultValue !== '';

        if (
            showCheckMark
            && fieldValue
            && (!validationResponse || validationResponse === true)
        ) {
            return (
                <div block="Icon" elem="CheckGreen" />
            );
        }

        return null;
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isDisabled = false,
            changeValueOnDoubleClick,
            isSortSelect,
            isLabelInner,
            innerLabel,
            id,
            name,
            placeholder,
            isDatePicker,
            noSort,
            passEventOnChange
        } = this.props;

        return (
            <FieldSelectContainer
              id={ id }
              name={ name }
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              isSortSelect={ isSortSelect }
              changeValueOnDoubleClick={ changeValueOnDoubleClick }
              isLabelInner={ isLabelInner }
              innerLabel={ innerLabel }
              placeholder={ placeholder }
              isDatePicker={ isDatePicker }
              noSort={ noSort }
              passEventOnChange={ passEventOnChange }
            />
        );
    }

    render() {
        const {
            type, validationResponse, mix
        } = this.props;
        const inputRenderer = this.renderMap[type] ?? this.renderDefaultInput.bind(this);
        const { mods: { hasError = false } = {} } = mix;

        return (
            <div block="Field" mods={ { type } }>
                <div
                  block="FieldInput"
                  elem="Wrapper"
                  mods={ {
                      type,
                      isValid: !hasError && validationResponse === true,
                      hasError: type !== FIELD_TYPE.zip && validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >
                    { inputRenderer && inputRenderer() }
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && type !== FIELD_TYPE.zip && this.renderLabel() }
                </div>
                { type !== FIELD_TYPE.zip && this.renderErrorMessages() }
                { this.renderCheckMark() }
            </div>
        );
    }
}

export default FieldComponent;
