/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ChooseLanguageSelector from 'Component/ChooseLanguageSelector';
import Link from 'Component/Link';
import { isSignedIn } from 'Util/Auth';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * Quick Links Component plugin
 */
export class QuickLinksComponentPlugin {
    renderMobile = (args, callback, instance) => {
        const { handleLogout, changeHeaderState } = instance.props;

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        return (
            <div block="QuickLinksOverlay" elem="Mobile">
                <div block="QuickLinksOverlay" elem="Heading">
                    <div>{ __('quicklinks') }</div>
                </div>
                <div block="QuickLinksOverlay" elem="LinksWrapper">
                    <div block="QuickLinksOverlay" elem="TopLinks">
                        { !isBigScreen && (
                            <Link
                              block="QuickLink"
                              mix={ { block: 'Link', elem: 'QuickLinks' } }
                              to="/customer/account/"
                              onClick={ changeHeaderState }
                            >
                                { __('my account') }
                            </Link>
                        ) }
                        <Link
                          block="QuickLink"
                          mix={ { block: 'Link', elem: 'QuickLinks' } }
                          to="faq.html"
                          onClick={ changeHeaderState }
                        >
                            { __('support') }
                        </Link>
                    </div>
                    <div block="QuickLink" elem="MobileLanguage">
                        <ChooseLanguageSelector />
                    </div>
                </div>
                { !isBigScreen && (
                    <div block="QuickLinksOverlay" elem="Authorization">
                        { isSignedIn() ? (
                            <button
                              block="Button"
                              elem="Primary"
                              mods={ { color: 'brown' } }
                              onClick={ handleLogout }
                            >
                                { __('logout') }
                            </button>
                        ) : (
                            <>
                                <Link
                                  block="Button"
                                  elem="Primary"
                                  mods={ { color: 'brown' } }
                                  mix={ { block: 'Link', elem: 'Login' } }
                                  to="/customer/account/login/"
                                  onClick={ changeHeaderState }
                                >
                                    { __('login') }
                                </Link>
                                <div
                                  block="QuickLinksOverlay"
                                  elem="Registration"
                                >
                                    <span>{ __('No account yet?') }</span>
                                    <Link
                                      mix={ {
                                          block: 'Link',
                                          elem: 'Animated'
                                      } }
                                      to="/customer/account/login/"
                                      onClick={ changeHeaderState }
                                    >
                                        { __('register here') }
                                    </Link>
                                </div>
                            </>
                        ) }
                    </div>
                ) }
            </div>
        );
    };
}

const {
    renderMobile
} = new QuickLinksComponentPlugin();

export const config = {
    'Scandipwa/Component/QuickLinks/Component': {
        'member-function': {
            renderMobile
        }
    }
};

export default config;
