/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Product page component plugin
 */
class ProductPageComponentPlugin {
    aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
        const { product, areDetailsLoaded, parameters } = instance.props;
        const [prevProps] = args;
        const {
            areDetailsLoaded: prevAreDetailsLoaded,
            parameters: prevParameters
        } = prevProps;

        const { color, size } = parameters;
        const { color: prevColor, size: prevSize } = prevParameters;

        if (areDetailsLoaded !== prevAreDetailsLoaded && typeof window.dmPt === 'function') {
            this.dotMailerTrackViewedProduct(product, parameters);
        }

        if (+color !== +prevColor || +size !== +prevSize) {
            this.dotMailerTrackViewedProduct(product, parameters);
        }

        return callback(...args);
    };

    dotMailerTrackViewedProduct(product, parameters) {
        const {
            name,
            url,
            description: { html } = {},
            categories
        } = product;

        const variant = this.getActiveVariant(product, parameters);

        if (variant === null) {
            return;
        }

        const {
            sku,
            stock_status,
            thumbnail: { url: imageUrl } = {},
            price_range: {
                minimum_price: {
                    regular_price: { value: regularPriceValue } = {},
                    final_price: {
                        value: finalPriceValue = '',
                        currency = ''
                    } = {}
                } = {}
            } = {}
        } = variant;

        const isDiscounted = regularPriceValue.toFixed(2) !== finalPriceValue.toFixed(2);

        const viewedProduct = {
            product_name: name,
            product_url: window.location.origin + url,
            product_image_path: imageUrl,
            product_description: html,
            product_sku: sku,
            product_price: regularPriceValue.toString(),
            product_status: stock_status,
            product_currency: currency,
            product_specialPrice: isDiscounted ? finalPriceValue.toString() : '',
            product_categories: this.getProductCategories(categories).join(),
            product_brand: 'Puma'
        };

        window.dmPt('track', viewedProduct);
    }

    getActiveVariant(product, parameters) {
        const { variants } = product;
        const { color } = parameters;
        const { size } = parameters;

        if (!variants) {
            return null;
        }

        if (color && size) {
            const index = variants.findIndex(
                ({ color: vColor, size: vSize }) => (+color === +vColor && +size === +vSize)
            );

            if (index !== -1) {
                return variants[index];
            }

            return null;
        }

        if (color) {
            const index = variants.findIndex(
                ({ color: vColor }) => (+color === +vColor)
            );

            if (index !== -1) {
                return variants[index];
            }

            return null;
        }

        return null;
    }

    getProductCategories(categories) {
        return categories.reduce((acc, { name }) => {
            if (name) {
                acc.push(name);
            }

            return acc;
        }, []);
    }
}

const {
    aroundComponentDidUpdate
} = new ProductPageComponentPlugin();

const config = {
    'Route/ProductPage/Component': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate
        }
    }
};

export default config;
