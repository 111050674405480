/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import ContentWrapper from 'Component/ContentWrapper';
import SimpleSearch from 'Component/SimpleSearch';
import { NoMatch as SourceNoMatch } from 'SourceRoute/NoMatch/NoMatch.component';
import { scrollToTop } from 'Util/Browser';

import './NoMatch.style';
import './NoMatch.extended.style';

/** @namespace Scandipwa/Route/NoMatch/Component */
export class NoMatchComponent extends SourceNoMatch {
    componentDidMount() {
        const { cleanUpTransition } = this.props;

        cleanUpTransition();
        scrollToTop();
    }

    render() {
        return (
            <main block="NoMatch" aria-label={ __('Page not found') }>
                <ContentWrapper
                  mix={ { block: 'NoMatch' } }
                  wrapperMix={ { block: 'NoMatch', elem: 'Wrapper' } }
                  label={ __('Page Not Found Content') }
                >
                    <h1
                      block="NoMatch"
                      elem="Title"
                    >
                        { __('Hmm. We couldn\'t find the page you were looking for.') }
                    </h1>
                    <div>
                        <h4
                          block="NoMatch"
                          elem="Subtitle"
                        >
                            { __('You can try searching for the information you want:') }
                        </h4>
                        <SimpleSearch />
                    </div>
                </ContentWrapper>
                <div id="seg-home-recommendation" />
            </main>
        );
    }
}

export default NoMatchComponent;
