/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceLoader from 'SourceComponent/Loader/Loader.component';

import './Loader.extended.style';

/**
 * Loader component
 * Loaders overlay to identify loading
 * @class Loader
 * @namespace Scandipwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <div block="Loader" elem="Dot1" />
                <div block="Loader" elem="Dot2" />
            </div>
        );
    }
}

export default LoaderComponent;
