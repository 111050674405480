/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Loader from 'Component/Loader';

import { SALES_ASSOCIATE_INPUT_ID } from './SalesAssociate.config';

import './SalesAssociate.style.scss';

/** @namespace Bigscreen/Component/SalesAssociate/Component */
export class SalesAssociateComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        handleApplySalesAssociateIdToCart: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        inputs: PropTypes.object.isRequired,
        salesAssociate: PropTypes.string.isRequired
    };

    state = {
        salesAssociateId: ''
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    static getDerivedStateFromProps(props, state) {
        const { salesAssociateId } = state;
        const { inputs } = props;
        const newSalesAssociateId = inputs[SALES_ASSOCIATE_INPUT_ID];

        if (salesAssociateId !== newSalesAssociateId) {
            return {
                salesAssociateId: newSalesAssociateId
            };
        }

        return null;
    }

    handleSalesAssociateIdChange = (salesAssociateId) => {
        this.setState({
            salesAssociateId
        });
    };

    renderApplySalesAssociate() {
        const { salesAssociateId } = this.state;
        const { handleApplySalesAssociateIdToCart, inputs } = this.props;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.text }
                  id={ SALES_ASSOCIATE_INPUT_ID }
                  attr={ {
                      id: SALES_ASSOCIATE_INPUT_ID,
                      name: 'sales-associate-id',
                      placeholder: __('Enter Sales Associate ID'),
                      'aria-label': __('Enter Sales Associate ID')
                  } }
                  value={ inputs[SALES_ASSOCIATE_INPUT_ID] || '' }
                  events={ {
                      onChange: this.handleSalesAssociateIdChange
                  } }
                  mix={ { block: 'CartCoupon', elem: 'Input' } }
                />
                <button
                  block="CartCoupon"
                  elem="Button"
                  type={ FIELD_TYPE.button }
                  mix={ { block: 'Button' } }
                  disabled={ !salesAssociateId }
                  onClick={ () => handleApplySalesAssociateIdToCart(salesAssociateId) }
                >
                    { __('Apply') }
                </button>
            </>
        );
    }

    renderRemoveSalesAssociate() {
        const { salesAssociate, handleApplySalesAssociateIdToCart } = this.props;

        return (
            <>
              <p
                block="SalesAssociate"
                elem="Message"
                mix={ { block: 'CartCoupon', elem: 'Message' } }
              >
                  { __('Applied sales associate ID:') }
                  <strong>{ ` ${ salesAssociate }` }</strong>
              </p>
              <button
                block="SalesAssociate"
                elem="Button"
                type="button"
                mix={ { block: 'Button' } }
                onClick={ () => handleApplySalesAssociateIdToCart('') }
              >
                  { __('Remove Sales Associate ID') }
              </button>
            </>
        );
    }

    render() {
        const { isLoading, salesAssociate } = this.props;
        return (
            <div
              block="SalesAssociate"
            >
                <Loader isLoading={ isLoading } />
                { (salesAssociate
                    ? this.renderRemoveSalesAssociate()
                    : this.renderApplySalesAssociate()
                ) }
            </div>
        );
    }
}

export default SalesAssociateComponent;
