/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @author Janis Verins <janis.verins@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import ProductsPageDataEventHandler from './ProductsPageDataEvent.handler';

export class CheckoutProductsPageDataEventHandler extends ProductsPageDataEventHandler {
    async getCheckoutProducts() {
        await this.setupProductAttributes();

        const { items = [] } = BrowserDatabase.getItem(CART_TOTALS) || {};
        const dataLayerProducts = [];

        items.forEach((item) => {
            const { product } = item;
            const productVariant = this.getVariantOfProduct(item);
            const { inStock, inventory, orderable } = this.getProductStatusData(product);
            const productPrimaryCategory = this.getProductAttribute(
                productVariant, product, 'primary_category_pim_id'
            ) || '';

            dataLayerProducts.push({
                discount: this.getDiscountAmount(product),
                inStock,
                inventory,
                orderable,
                price: this.getPrice(product),
                productCategory: this.getProductCategoryName(productPrimaryCategory),
                category: productPrimaryCategory,
                productID: product.id.toString(),
                productName: this.getProductName(product),
                styleID: this.getProductAttributeValue(product, 'style_number'),
                localName: '', // TODO
                quantity: this.getItemQuantity(item),
                wasQuantity: '', // For now it stays empty
                pricing: {
                    basePrice: this.getProductBasePrice(product),
                    markdownAmount: this.getMarkdownAmount(productVariant),
                    discount: this.getDiscountAmount(product),
                    price: this.getPrice(product),
                    subtotal: this.getProductAttribute(productVariant, product, 'subtotal')
                },
                promos: {
                    id: '', // TODO
                    code: '', // TODO
                    name: '', // TODO
                    type: '', // TODO
                    amount: '' // TODO
                },
                discounted: this.getProductDiscountedStatus(product),
                skuID: this.getProductSku(product),
                bundle: this.isProductBundle(product),
                set: '', // For now it stays empty
                markdown: this.getMarkdownStatus(productVariant), // TODO
                EAN: this.getProductAttribute(productVariant, product, 'ean') || '',
                UPC: this.getProductAttribute(productVariant, product, 'upc') || '',
                imageURL: this.getProductImageUrl(product),
                season: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'season'),
                searchColor: '', // TODO
                pumaColorDescription: this.getAttributeLabelFromBrowserDatabase(
                    productVariant, product, 'color_description'
                ),
                division: this.getAttributeLabelFromBrowserDatabase(
                    productVariant, product, 'product_division'
                ),
                lineName: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'line_name'),
                gender: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'gender'),
                colorCode: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'color'),
                labelColor: '', // For now it stays empty
                ageGroup: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'age_group'),
                department: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'dept_code'),
                sport: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'sport_code'),
                class: '', // TODO
                subCat: this.getAttributeLabelFromBrowserDatabase(productVariant, product, 'subcat_id'),
                productCollection: this.getAttributeLabelFromBrowserDatabase(
                    productVariant, product, 'collection'
                ),
                technology: this.getAttributeLabelFromBrowserDatabase(
                    productVariant, product, 'technology'
                ),
                mainStyle: '' // TODO
            });
        });

        return dataLayerProducts;
    }

    getProductDiscountedStatus(product) {
        return this.getDiscountAmount(product) === 0 ? 'false' : 'true';
    }

    getItemQuantity(item) {
        const { qty } = item;

        return qty;
    }
}

export default CheckoutProductsPageDataEventHandler;
