import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SomethingWentWrongContainer as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.container';
import { ONE_HOUR_IN_SECONDS } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

/** @namespace Scandipwa/Route/SomethingWentWrong/Container */
export class SomethingWentWrongContainer extends SourceSomethingWentWrong {
    componentDidMount() {
        const { onClick = () => {} } = this.props;
        const reloadAttemptedKey = 'OOPS_RELOAD_ATTEMPTED';
        const reloadAttempted = BrowserDatabase.getItem(reloadAttemptedKey);

        if (!reloadAttempted) {
            BrowserDatabase.setItem(true, reloadAttemptedKey, ONE_HOUR_IN_SECONDS);
            onClick();
        } else {
            BrowserDatabase.setItem(false, reloadAttemptedKey, ONE_HOUR_IN_SECONDS);
        }

        super.componentDidMount();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
