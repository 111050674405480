/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/** @namespace BigScreenExperienceGraphQl/Scandipwa/App/Plugin/CartQuery/Plugin/CartQueryPlugin/addBigScreenExperienceFields */
export const addBigScreenExperienceFields = (args, callback) => [
    ...callback(...args),
    'sales_associate_id'
];

export default {
    'Query/Cart/Query': {
        'member-function': {
            _getCartTotalsFields: addBigScreenExperienceFields
        }
    }
};
