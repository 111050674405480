/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import MyAccountQuery from 'Query/MyAccount.query';
import { updateCustomerDetails } from 'SourceStore/MyAccount/MyAccount.action';
import { CUSTOMER, MyAccountDispatcher as SourceMyAccountDispatcher, ONE_MONTH_IN_SECONDS }
from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { updateIsLocked } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { getAuthorizationToken, GRAPHQL_AUTH } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { prepareQuery } from 'Util/Query';
import { executePost, getErrorMessage } from 'Util/Request';

export {
    CartDispatcher, WishlistDispatcher, ProductCompareDispatcher, CUSTOMER, ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';

/**
 * My account actions
 * @class MyAccount
 * @namespace Scandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        return executePost(prepareQuery([query])).then(
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                const customerData = this.mergePrevAndCurrentCustomerData(customer);
                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customerData));
                BrowserDatabase.setItem(customerData, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace Scandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
            (error) => {
                const { extensions: { category } } = error[0];

                if (category === GRAPHQL_AUTH) {
                    dispatch(updateIsLocked(true));
                }
                dispatch(showNotification('error', getErrorMessage(error)));
            }
        );
    }

    mergePrevAndCurrentCustomerData(currentCustomer) {
        // return currentCustomer;
        const prevCustomerData = BrowserDatabase.getItem(CUSTOMER);

        if (prevCustomerData && prevCustomerData.addresses && prevCustomerData.addresses.length > 0) {
            const tmpAddress = prevCustomerData.addresses.find((item) => item.id === -1);

            if (tmpAddress && !currentCustomer.addresses.find((item) => item.id === -1)) {
                return {
                    ...currentCustomer,
                    addresses: [...currentCustomer.addresses, tmpAddress],
                    newAddressId: -1,
                    selectedAddressId: prevCustomerData.selectedAddressId
                };
            }

            return {
                ...currentCustomer,
                newAddressId: prevCustomerData.newAddressId,
                selectedAddressId: prevCustomerData.selectedAddressId
            };
        }

        return currentCustomer;
    }
}

export default new MyAccountDispatcher();
