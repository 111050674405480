/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Config Query plugin
 */
export class ConfigQueryPlugin {
    _getStoreConfigFields = (args, callback, instance) => [
        ...callback.apply(instance, args),
        'bse_screen_resolution',
        'is_big_screen',
        'secure_base_url'
    ];
}

const {
    _getStoreConfigFields
} = new ConfigQueryPlugin();

export const config = {
    'Scandipwa/Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};

export default config;
