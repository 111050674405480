/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Link from 'Component/Link';
import Event from '../../util/Event/Event';
import { EVENT_GTM_PAGE_DATA } from '../../util/Event';
import { ON_SITE_SEARCH_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';

// eslint-disable-next-line fp/no-let
export let searchOverlayData = {};

export const renderSearchSuggestions = (args, callback, instance) => {
    const {
        suggestions,
        clearSearchResults,
        hideActiveOverlay,
        searchCriteria
    } = instance.props;

    if (!suggestions) {
        return null;
    }

    return suggestions.map((suggestion) => {
        const adjustedSuggestion = suggestion.replace(/(<([^>]+)>)/ig, '');

        return (
            <Link
                block="SearchOverlay"
                elem="SuggestionsLink"
                to={ `/search/${ adjustedSuggestion }` }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                    // Clears search results, closes active search overlay and redirects to given link
                    clearSearchResults();
                    hideActiveOverlay();
                    // Saving search data in variable to dispatch search event only when search page is loaded
                    searchOverlayData = {
                        searchCriteria: searchCriteria,
                        searchTerm: adjustedSuggestion
                    };
                }}
            >
                { /* eslint-disable-next-line react/no-danger */}
                <span dangerouslySetInnerHTML={ { __html: suggestion } }/>
            </Link>
        );
    });
};

export const componentDidUpdate = (args, callback, instance) => {
    const { isInfoLoading } = instance.props;
    const [prevProps] = args;
    const { isInfoLoading: prevIsInfoLoading } = prevProps;

    if (isInfoLoading !== prevIsInfoLoading) {
        dispatchGtmOnSiteSearchEvent(searchOverlayData);
    }

    return callback(...args);
};

export const componentWillUnmount = (args, callback, instance) => {
    return callback(...args);
};

export const dispatchGtmOnSiteSearchEvent = ({
    searchCriteria = '',
    searchTerm = ''
} = {}) => {
    Event.dispatch(EVENT_GTM_PAGE_DATA, {
        type: ON_SITE_SEARCH_TYPE,
        autoCompleteTerm: searchTerm,
        searchTerm: searchCriteria
    });
};

export default {
    'Component/SearchOverlay/Component': {
        'member-function': {
            'renderSearchSuggestions': renderSearchSuggestions
        }
    },
    'Route/SearchPage/Container': {
        'member-function': {
            'componentDidUpdate': componentDidUpdate,
            'componentWillUnmount': componentWillUnmount
        }
    }
};
