/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Notification from 'Component/Notification';
import { TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import { NotificationList as SourceNotificationListComponent } from 'SourceComponent/NotificationList/NotificationList.component';

import './NotificationList.extended.style';

/**
 * Notification List
 * @class NotificationList
 * @namespace Scandipwa/Component/NotificationList/Component */
export class NotificationListComponent extends SourceNotificationListComponent {
    static propTypes = {
        ...SourceNotificationListComponent.propTypes,
        urlType: PropTypes.bool.isRequired
    };

    render() {
        const { onHideNotification, notifications, urlType } = this.props;

        const isPLP = urlType === TYPE_PRODUCT;

        return (
            <div block="NotificationList" mods={ { isPLP } }>
                { Object.keys(notifications).map((id) => (
                    <Notification
                      key={ id }
                      notificationId={ id }
                      notification={ notifications[id] }
                      onHideNotification={ onHideNotification }
                    />
                )) }
            </div>
        );
    }
}

export default NotificationListComponent;
