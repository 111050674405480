/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';

import Loader from 'Component/Loader';

export const CheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/CheckoutBilling'
));

class CheckoutComponent {
    propTypes = (originalMember) => ({
        ...originalMember,
        setOrderId: PropTypes.func.isRequired,
        setCheckoutStep: PropTypes.func.isRequired
    });

    /**
     * 1:1 copy of the theme function (which added isLoading), but with the addition of the setCheckoutContainerOrderId and setCheckoutContainerCheckoutStep props
     */
    renderBillingStep = (args, callback, instance) => {
        const {
            setLoading,
            isLoading,
            setDetailsStep,
            shippingAddress,
            onEmailChange,
            paymentMethods = [],
            savePaymentInformation,
            selectedShippingMethod,
            onChangeEmailRequired,
            setOrderId: setCheckoutContainerOrderId,
            setCheckoutStep: setCheckoutContainerCheckoutStep
        } = instance.props;

        return (
            <Suspense fallback={ <Loader /> }>
                <CheckoutBilling
                  setLoading={ setLoading }
                  isLoading={ isLoading }
                  paymentMethods={ paymentMethods }
                  setDetailsStep={ setDetailsStep }
                  onEmailChange={ onEmailChange }
                  shippingAddress={ shippingAddress }
                  savePaymentInformation={ savePaymentInformation }
                  selectedShippingMethod={ selectedShippingMethod }
                  onChangeEmailRequired={ onChangeEmailRequired }
                  setCheckoutContainerOrderId={ setCheckoutContainerOrderId }
                  setCheckoutContainerCheckoutStep={ setCheckoutContainerCheckoutStep }
                />
            </Suspense>
        );
    };
}

const {
    renderBillingStep
} = new CheckoutComponent();

export default {
    'Route/Checkout/Component': {
        'member-function': {
            renderBillingStep
        }
    }
};
