import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    STATE_GUEST,
    STATE_SIGN_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import {
    CheckoutGuestFormContainer as SourceCheckoutGuestFormContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutGuestForm/CheckoutGuestForm.container';
import { showNotification } from 'Store/Notification/Notification.action';

import CheckoutGuestForm from './CheckoutGuestForm.component';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/CheckoutGuestForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Scandipwa/Component/CheckoutGuestForm/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    signIn: (options) => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.signIn(options, dispatch)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/CheckoutGuestForm/Container */
export class CheckoutGuestFormContainer extends SourceCheckoutGuestFormContainer {
    static propTypes = {
        ...SourceCheckoutGuestFormContainer.propTypes,
        isEmailRecognitionLoading: PropTypes.bool,
        onConfirmationEmailChange: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...SourceCheckoutGuestFormContainer.defaultProps,
        onSignIn: () => {},
        isEmailRecognitionLoading: false
    };

    state = {
        isLoading: false,
        signInState: STATE_GUEST
    };

    containerProps() {
        const {
            isCreateUser, isEmailRecognitionLoading, onEmailChange, onConfirmationEmailChange
        } = this.props;
        const { isLoading, signInState } = this.state;

        return {
            ...super.containerProps(),
            isCreateUser,
            isEmailRecognitionLoading,
            isLoading,
            signInState,
            onEmailChange,
            onConfirmationEmailChange
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        handlePasswordInput: this.handlePasswordInput.bind(this),
        handleSignIn: this.handleSignIn.bind(this),
        onFormError: this.onFormError.bind(this),
        setSignInState: this.setSignInState.bind(this),
        setLoadingState: this.setLoadingState.bind(this)
    };

    onFormError() {
        this.setState({ isLoading: false });
    }

    handleSignIn(e) {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        this.setState({ signInState: STATE_SIGN_IN });
    }

    setSignInState(signInState) {
        this.setState({ signInState });
    }

    setLoadingState(isLoading) {
        this.setState({ isLoading });
    }

    render() {
        const { isSignedIn } = this.props;

        if (isSignedIn) {
            return null;
        }

        return (
            <CheckoutGuestForm
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutGuestFormContainer);
