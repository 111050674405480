/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { isSignedIn } from 'Util/Auth';

/** @namespace Scandipwa/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            showNotification,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId
        } = this.props;

        const {
            isWishListToggle
        } = this.state;

        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        // Custom check to ensure at least two options (= size & qty) are selected for each product
        // Without this, it will let you add a product to the wishlist without a size selected - meaning the add to cart operation will fail
        const isOptionSelectionIncomplete = magentoProduct.some((product) => product.selected_options.length < 2);

        if (isOptionSelectionIncomplete) {
            return showNotification('error', __('Please select all options before adding an item to your wishlist.'));
        }

        this.setWishlistButtonLoading(true);

        if (isWishListToggle) {
            // eslint-disable-next-line consistent-return
            return;
        }

        this.setState({ isWishListToggle: true });

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId
            });

            this.setState({ isWishListToggle: false });

            // eslint-disable-next-line consistent-return
            return;
        }

        const wishlistItem = this.getWishlistItem(sku);

        if (!wishlistItem) {
            this.setState({ isWishListToggle: false });

            // eslint-disable-next-line consistent-return
            return;
        }

        const {
            wishlist: {
                id: itemId
            }
        } = wishlistItem;

        this.setState({ isWishListToggle: false });

        return removeProductFromWishlist({ item_id: itemId });
    }

    getWishlistItem() {
        const {
            productsInWishlist,
            magentoProduct,
            selectedProduct: { variants = [] },
            parameters
        } = this.props;

        if (!productsInWishlist || !magentoProduct || variants.length === 0) {
            return null;
        }

        if (parameters.color && parameters.size) {
            const variant = variants.find(
                ({ color, size }) => +color === +parameters.color && +size === +parameters.size
            );

            if (variant) {
                const { sku } = variant;

                return Object.values(productsInWishlist).find(
                    ({ wishlist: { sku: wishlistSku } }) => sku === wishlistSku
                );
            }
        }

        if (parameters.color) {
            return Object.values(productsInWishlist).find(
                ({ wishlist: { sku: wishlistSku } }) => variants.find(
                    ({ color, sku }) => +color === +parameters.color && sku === wishlistSku
                )
            );
        }

        return false;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
