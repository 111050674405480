/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import EstimatedDelivery from './EstimatedDeliveryTime.component';

/** @namespace Scandipwa/Component/EstimatedDeliveryTime/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    carrierDelivery: state.ConfigReducer.carriers_delivery,
    storeCode: state.ConfigReducer.code
});

/** @namespace Scandipwa/Component/EstimatedDeliveryTime/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (state) => ({});

/** @namespace Scandipwa/Component/EstimatedDeliveryTime/Container */
export class EstimatedDeliveryTimeContainer extends PureComponent {
    static propTypes = {
        carrierDelivery: PropTypes.shape({
            carriers_delivery_time: PropTypes.shape({
                carrier_code: PropTypes.string.isRequired,
                shipping_time: PropTypes.string.isRequired
            }),
            show_on_fe: PropTypes.string.isRequired,
            weekend_days: PropTypes.string.isRequired,
            holidays: PropTypes.string.isRequired
        }).isRequired,
        showOnFeCarrie: PropTypes.string,
        storeCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        showOnFeCarrie: ''
    };

    containerProps = () => ({
        estimatedDeliveryDates: this.getEstimatedDeliveryDates()
    });

    getShowOnFeCarrier() {
        const {
            carrierDelivery: {
                carriers_delivery_time: carriersDeliveryTime,
                show_on_fe
            },
            showOnFeCarrie
        } = this.props;

        return carriersDeliveryTime.find(({ carrier_code }) => (carrier_code === (showOnFeCarrie || show_on_fe)));
    }

    getEstimatedDeliveryDates() {
        const {
            carrierDelivery
        } = this.props || null;

        if (!carrierDelivery) {
            return null;
        }

        const carriersDeliveryTime = carrierDelivery.carriers_delivery_time;
        const showOnFeCarrier = this.getShowOnFeCarrier();

        if (!carriersDeliveryTime.length || !showOnFeCarrier) {
            return '';
        }

        const [from, to] = showOnFeCarrier.shipping_time;
        const fromDate = this.getDeliveryDate(parseInt(from, 10));
        const toDate = this.getDeliveryDate(parseInt(to, 10));
        const sameMonth = fromDate.getMonth() === toDate.getMonth();

        return `${this.getAdjustedDateFormat(fromDate)} - ${this.getAdjustedDateFormat(toDate, sameMonth)}`;
    }

    getAdjustedDateFormat(date, sameMonth = false) {
        const { storeCode } = this.props;

        const month = !sameMonth ? date.toLocaleString(storeCode, { month: 'long' }) : '';

        return `${month} ${date.getDate()}`;
    }

    getHolidays() {
        const { carrierDelivery: { holidays } } = this.props;

        // Checking if holidays are removed or not set
        if (!holidays || holidays === '[]') {
            return [];
        }

        const holidayArray = JSON.parse(holidays);
        const today = new Date();

        return holidayArray.reduce((acc, [from, to]) => {
            const splitFromDate = from.split('/');
            const splitToDate = to.split('/');
            const fromDate = new Date(from);
            const toDate = new Date(to);

            // If no year set, we use current year
            if (!splitFromDate[2]) {
                fromDate.setFullYear(today.getFullYear());
            }

            // If no year set, we use current year
            if (!splitToDate[2]) {
                toDate.setFullYear(today.getFullYear());
            }

            acc.push([fromDate, toDate]);

            return acc;
        }, []);
    }

    getDeliveryDate(daysToAdd) {
        return this.countDeliveryDays(0, daysToAdd, new Date());
    }

    isHoliday(date, holidays) {
        return holidays.some(([fromDate, toDate]) => date > fromDate && date < toDate);
    }

    countDeliveryDays(count, daysToAdd, endDate) {
        const { carrierDelivery: { weekend_days } } = this.props;
        const holidays = this.getHolidays();
        const weekendDays = weekend_days.split(',');

        if (count <= daysToAdd) {
            endDate.setDate(endDate.getDate() + 1);

            if (!weekendDays.includes(endDate.getDay().toString()) && !this.isHoliday(endDate, holidays)) {
                return this.countDeliveryDays(count + 1, daysToAdd, endDate);
            }

            return this.countDeliveryDays(count, daysToAdd, endDate);
        }

        return endDate;
    }

    render() {
        return (
            <EstimatedDelivery
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedDeliveryTimeContainer);
