/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './EstimatedDeliveyTime.style';

/** @namespace Scandipwa/Component/EstimatedDeliveryTime/Component */
export class EstimatedDeliveryTimeComponent extends PureComponent {
    static propTypes = {
        estimatedDeliveryDates: PropTypes.string.isRequired
    };

    renderEstimatedDeliveryTime() {
        const { estimatedDeliveryDates } = this.props;

        return (
            <span
              block="EstimatedDeliveryTime"
              elem="Message"
            >
                { __('Estimated date of delivery: %s', estimatedDeliveryDates) }
            </span>
        );
    }

    render() {
        const { estimatedDeliveryDates } = this.props;

        if (!estimatedDeliveryDates) {
            return null;
        }

        return (
            <div block="EstimatedDeliveryTime">
                { this.renderEstimatedDeliveryTime() }
            </div>
        );
    }
}

export default EstimatedDeliveryTimeComponent;
