/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { setInputName, setIsKeyboardOpened } from '../../../store/Keyboard/Keyboard.action';
import { COUPON_CODE_INPUT_ID } from './CartCouponComponent.plugin';

/**
 * CartCoupon Container plugin
 */
export class CartCouponContainerPlugin {
    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            setIsKeyboardOpened: (isKeyboardOpened) => dispatch(setIsKeyboardOpened(isKeyboardOpened)),
            setInputName: (inputName) => dispatch(setInputName(inputName))
        };
    };

    mapStateToProps = (args, callback) => {
        const [state] = args;

        return {
            ...callback(...args),
            inputs: state.KeyboardReducer.inputs,
            inputName: state.KeyboardReducer.inputName,
            isEnterPressed: state.KeyboardReducer.isEnterPressed
        };
    };

    propTypes = (originalMember) => ({
        ...originalMember,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        setInputName: PropTypes.func.isRequired,
        inputs: PropTypes.object.isRequired,
        isEnterPressed: PropTypes.bool.isRequired,
        inputName: PropTypes.string.isRequired
    });

    componentDidUpdate = (args, callback, instance) => {
        const { isEnterPressed, inputs, inputName } = instance.props;
        const { isLoading } = instance.state;

        if (isEnterPressed && inputName === COUPON_CODE_INPUT_ID && !isLoading) {
            instance.handleApplyCouponToCart(inputs[COUPON_CODE_INPUT_ID]);
        }
    };

    containerProps = (args, callback, instance) => {
        const {
            setIsKeyboardOpened,
            setInputName,
            inputs,
            inputName,
            isEnterPressed
        } = instance.props;

        return {
            ...callback(...args),
            setIsKeyboardOpened,
            setInputName,
            inputName,
            inputs,
            isEnterPressed
        };
    };
}

const {
    mapStateToProps,
    mapDispatchToProps,
    propTypes,
    componentDidUpdate,
    containerProps
} = new CartCouponContainerPlugin();

export const config = {
    'Component/CartCoupon/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Component/CartCoupon/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CartCoupon/Container': {
        'member-function': {
            componentDidUpdate,
            containerProps
        },
        'static-member': {
            propTypes
        }
    }
};

export default config;
