/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CheckoutPayment as SourceCheckoutPayment
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import './CheckoutPayment.style';
import './CheckoutPayment.style.override';

/** @namespace Scandipwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceCheckoutPayment {
    /**
     * Modified render to use the same radiobutton system as the deliveryOptions
     */
    render() {
        const {
            isSelected,
            method: { title }
        } = this.props;

        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  onClick={ this.onClick }
                  type="button"
                >
                    <div block="CheckoutPaymentButton" />
                    <div block="CheckoutPaymentOption" elem="RowWrapper">
                      { title }
                    </div>
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
