/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    SET_ENTER_PRESSED,
    SET_INPUT_NAME,
    SET_INPUTS,
    SET_IS_KEYBOARD_OPENED,
    SET_KEYBOARD_CLICKED
} from './Keyboard.action';

/** @namespace Bigscreen/Store/Keyboard/Reducer/getInitialState */
export const getInitialState = () => ({
    inputs: {},
    inputName: '',
    isEnterPressed: false,
    isKeyboardClicked: false,
    isKeyboardOpened: false
});

/** @namespace Bigscreen/Store/Keyboard/Reducer/KeyboardReducer */
export const KeyboardReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        inputName,
        inputs,
        isEnterPressed,
        isKeyboardClicked,
        isKeyboardOpened
    } = action;

    switch (type) {
    case SET_INPUTS:
        return {
            ...state,
            inputs
        };
    case SET_INPUT_NAME:
        return {
            ...state,
            inputName
        };
    case SET_ENTER_PRESSED:
        return {
            ...state,
            isEnterPressed
        };
    case SET_KEYBOARD_CLICKED:
        return {
            ...state,
            isKeyboardClicked
        };
    case SET_IS_KEYBOARD_OPENED:
        return {
            ...state,
            isKeyboardOpened
        };
    default:
        return state;
    }
};

export default KeyboardReducer;
