/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';
import getStore from 'Util/Store';

import {
    ADYEN_CC_METHOD_CODE
} from '../../util/Adyen';

export class CheckoutQuery {
    getSetPaymentMethodOnCartMutation = (args, callback, instance) => {
        const input = args[0];

        const paymentData = getStore().getState().CheckoutReducer.adyenStateData.data;

        // TODO: Check the payment method code, and set the correct adyen_additional_data field

        switch (input.payment_method.code) {
        case ADYEN_CC_METHOD_CODE:
            input.payment_method.adyen_additional_data_cc = {
                cc_type: paymentData?.paymentMethod?.brand ?? '',
                stateData: JSON.stringify(paymentData)
            };

            break;
        default:
            input.payment_method.adyen_additional_data = {
                brand_code: paymentData?.paymentMethod?.type ?? '',
                stateData: JSON.stringify(paymentData)
            };

            break;
        }

        return callback(input);
    };

    _getOrderField = (args, callback, instance) => {
        /**
         * @type {Field}
         */
        const field = callback(...args);

        /**
         *  adyen_payment_status {
               isFinal
               resultCode
               additionalData
               action
           }
         */
        field.addFieldList([
            new Field('adyen_payment_status')
                .addFieldList([
                    'isFinal',
                    'resultCode',
                    'additionalData',
                    'action'
                ])
        ]);

        return field;
    };
}

export const { getSetPaymentMethodOnCartMutation, _getOrderField } = new CheckoutQuery();

export default {
    'Query/Checkout/Query': {
        'member-function': {
            getSetPaymentMethodOnCartMutation: [
                {
                    position: 100,
                    implementation: getSetPaymentMethodOnCartMutation
                }
            ],
            _getOrderField
        }
    }
};
