/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { TotalsType } from 'Type/MiniCart.type';
import { isSignedIn } from 'Util/Auth';
import { getCartId, setCartId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import BigScreenExperienceQuery from '../../../query/BigScreenExperience.query';
import idleTimer from '../../../util/IdleTimer';

/**
 * Plugin for Router Container
 */
export class RouterContainerPlugin {
    mapStateToProps = (args, callback, _instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            bseScreenResolution: state.ConfigReducer.bse_screen_resolution || '',
            is_big_screen: state.ConfigReducer.is_big_screen || false,
            isKeyboardOpened: state.KeyboardReducer.isKeyboardOpened,
            totals: state.CartReducer.cartTotals
        };
    };

    propTypes = (originalMember) => ({
        ...originalMember,
        bseScreenResolution: PropTypes.string.isRequired,
        is_big_screen: PropTypes.bool.isRequired,
        isKeyboardOpened: PropTypes.bool.isRequired,
        totals: TotalsType.isRequired
    });

    containerProps = (args, callback, instance) => {
        const {
            isKeyboardOpened
        } = instance.props;

        return {
            ...callback(args),
            isKeyboardOpened
        };
    };

    componentDidMount = (args, callback, instance) => {
        const { totals: { items_qty } } = instance.props;

        this.handleIsBigScreen(instance);

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && items_qty) {
            idleTimer.startTimer();
        }

        return callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const { is_big_screen, totals: { total_quantity } } = instance.props;
        const [prevProps] = args;
        const { prevIsBigScreen, totals: { total_quantity: prevItemsQty } } = prevProps;

        if (is_big_screen !== prevIsBigScreen) {
            this.handleIsBigScreen(instance);
        }

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && total_quantity !== prevItemsQty) {
            if (total_quantity) {
                idleTimer.startTimer();
            } else {
                idleTimer.stopTimer();
            }
        }

        return callback(...args);
    };

    componentWillUnmount = (args, callback, _instance) => {
        idleTimer.stopTimer();

        return callback(...args);
    };

    beforeInitializeApplication = async (args, callback, _instance) => {
        const { search, pathname } = location;

        const params = new URLSearchParams(search);
        const quoteHashParam = params.get('quote_hash');

        if (pathname.includes('/qr/mobile') && quoteHashParam) {
            await fetchMutation(BigScreenExperienceQuery.getSetQrCodeScannedForQuoteMutation(quoteHashParam));

            if (isSignedIn()) {
                const customerToken = getCartId();

                await fetchMutation(
                    BigScreenExperienceQuery.mergeCustomerAndGuestQuotes(customerToken, quoteHashParam)
                );
            } else {
                setCartId(quoteHashParam);
            }
        }

        return callback(...args);
    };

    handleIsBigScreen = (instance) => {
        const { bseScreenResolution } = instance.props;
        const [, bigScreenCookie = 'false'] = document.cookie.match(/isBigScreen=(.*?);/) || [];

        if (bigScreenCookie === 'false') {
            return;
        }

        if (bseScreenResolution !== '0') {
            const [width, height] = bseScreenResolution.replace(/\s/, '').split('x');
            const screenHeight = window.screen.height;
            const screenWidth = window.screen.width;

            // eslint-disable-next-line no-console
            console.log(screenHeight, 'screenHeight', screenWidth, 'screenWidth', width, 'BSE width', height, 'BSE height');

            // eslint-disable-next-line eqeqeq
            if (screenHeight == height && screenWidth == width) {
                sessionStorage.setItem(BIG_SCREEN_SESSION_ITEM, '1');
            }
        } else {
            sessionStorage.setItem(BIG_SCREEN_SESSION_ITEM, '1');
        }
    };
}

const {
    mapStateToProps,
    propTypes,
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
    containerProps,
    beforeInitializeApplication
} = new RouterContainerPlugin();

export const config = {
    'Scandipwa/Component/Router/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            componentWillUnmount,
            containerProps,
            initializeApplication: beforeInitializeApplication
        },
        'static-member': {
            propTypes
        }
    },
    'Scandipwa/Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

export default config;
