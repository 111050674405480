/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    filterStoreConfig,
    getCheckoutAgreementData,
    getCountryData,
    getCurrencyData,
    getCurrencyRates,
    getIndexedRatings,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Config/Config.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    SET_SITE_BASE_LOADED,
    UPDATE_CONFIG,
    UPDATE_CONFIG_DEVICE,
    UPDATE_CURRENT_CURRENCY
} from './Config.action';

export * from 'SourceStore/Config/Config.reducer';

export const {
    countries, reviewRatings, storeConfig, currencyData, currency, cartDisplayConfig
} = BrowserDatabase.getItem('config') || {
    countries: [],
    reviewRatings: [],
    storeConfig: {},
    currencyData: {},
    currency: {},
    cartDisplayConfig: {
        display_tax_in_price: '',
        display_tax_in_subtotal: '',
        display_tax_in_shipping_amount: '',
        include_tax_in_order_total: false,
        display_full_tax_summary: false,
        display_zero_tax_subtotal: false
    }
};

/** @namespace Scandipwa/Store/Config/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    // this flag is used to load below-the fold and invisible components
    // after minimal main site has loaded
    siteBaseHasLoaded: false
});

/** @namespace Scandipwa/Store/Config/Reducer/ConfigReducer */
export const ConfigReducer = (
    state = getInitialState(),
    action
) => {
    const {
        type,
        config: {
            countries,
            reviewRatings,
            checkoutAgreements,
            currencyData,
            storeConfig = {},
            cartDisplayConfig = {}
        } = {},
        device
    } = action;

    const { currentCurrency = '' } = action;
    const { currencyData: prevCurrencyData } = state;

    switch (type) {
    case UPDATE_CONFIG:
        const filteredStoreConfig = filterStoreConfig(storeConfig);
        const { secure_base_media_url } = filteredStoreConfig;

        window.secure_base_media_url = secure_base_media_url;

        return {
            ...state,
            countries: getCountryData(countries, state),
            reviewRatings: getIndexedRatings(reviewRatings),
            checkoutAgreements: getCheckoutAgreementData(checkoutAgreements, state),
            currency: getCurrencyRates(currency, state),
            currencyData: getCurrencyData(currencyData, state),
            ...filteredStoreConfig,
            // Should be updated manually as filteredStoreConfig does not contain header_logo_src when it is null
            // and header_logo_src takes old value
            isLoading: false,
            cartDisplayConfig
        };

    case UPDATE_CONFIG_DEVICE:
        return {
            ...state,
            device: {
                ...state.device,
                ...device
            }
        };

    case UPDATE_CURRENT_CURRENCY:
        return {
            ...state,
            currencyData: {
                ...prevCurrencyData,
                current_currency_code: currentCurrency
            }
        };

    case SET_SITE_BASE_LOADED:
        return {
            ...state,
            siteBaseHasLoaded: true
        };

    default:
        return state;
    }
};

export default ConfigReducer;
