/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { roundPrice } from 'Util/Price';

import Event, { EVENT_GTM_PURCHASE } from '../../../util/Event';
import BaseEventEvent from './BaseEvent.event';

export const PURCHASE_EVENT_HANDLE_DELAY = 700;
export const SPAM_PROTECTION_DELAY = 10000;

/**
 * On order success page "Purchase"
 * @namespace Gtm/Component/GoogleTagManager/Events/SoretoPurchase/Event
 */
export class SoretoPurchaseEvent extends BaseEventEvent {
    /**
     * Event delay
     *
     * @type {number}
     */
    eventHandleDelay = PURCHASE_EVENT_HANDLE_DELAY;

    /**
     * Bind on product detail
     */
    bindEvent() {
        Event.observer(EVENT_GTM_PURCHASE, ({ orderID: orderId, totals }) => {
            this.handle(
                orderId,
                totals
            );
        });
    }

    /**
     * Handle
     *
     * @param orderId
     * @param totals
     * @param cartData
     */
    handler(orderId, totals) {
        if (this.spamProtection(SPAM_PROTECTION_DELAY)) {
            return;
        }

        const {
            email,
            firstname,
            prices: { grand_total: { value: grandTotal } }
        } = totals;

        this.pushEventData({
            email,
            first_name: firstname,
            order_total: +roundPrice(grandTotal),
            order_id: orderId
        });
    }
}

export default SoretoPurchaseEvent;
