/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-unused-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PropTypes } from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import validationConfig from 'Component/Form/Form.config';
import { CountriesType } from 'Type/Config.type';
import { EventsType, FieldAttrType } from 'Type/Field.type';

import FieldZip from './FieldZip.component';

/** @namespace Scandipwa/Component/FieldZip/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    minLength: state.ConfigReducer.postcode_minlength,
    postcodeStateMapping: state.ConfigReducer.postcode_state_mapping,
    countries: state.ConfigReducer.countries
});

/** @namespace Scandipwa/Component/FieldZip/Container */
export class FieldZipContainer extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        countries: CountriesType.isRequired,
        countryId: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        minLength: PropTypes.number.isRequired,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
        postcodeStateMapping: PropTypes.string.isRequired,
        regionId: PropTypes.string.isRequired,
        setIsValidByCustomRules: PropTypes.func.isRequired,
        setRef: PropTypes.func.isRequired,
        isValidateForm: PropTypes.bool,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    };

    static defaultProps = {
        isRequired: false,
        placeholder: '',
        onChange: () => {},
        isValidateForm: false
    };

    state = {
        isZipValid: false,
        invalidMessage: ''
    };

    containerFunctions = {
        setRef: this.setRef.bind(this),
        validateZip: this.validateZip.bind(this)
    };

    fieldRef = createRef();

    setRef(elem) {
        const { setRef } = this.props;

        setRef(elem);

        if (elem && this.fieldRef !== elem) {
            this.fieldRef = elem;
        }
    }

    containerProps() {
        const {
            attr,
            countries,
            countryId,
            events,
            regionId,
            minLength,
            onChange,
            label,
            value
        } = this.props;
        const {
            invalidMessage,
            isZipValid
        } = this.state;

        return {
            attr,
            countryId,
            countries,
            events,
            fieldRef: this.fieldRef,
            invalidMessage,
            isZipValid,
            regionId,
            minLength,
            onChange,
            label,
            value
        };
    }

    validateZip(zip) {
        const { minLength } = this.props;
        const isValidLength = zip.replace(/_/g, '').length >= minLength;

        if (!isValidLength) {
            this.setState({ isZipValid: false, invalidMessage: validationConfig.notEmpty.message });
            return false;
        }

        if (!this.validZipAgainstState(zip)) {
            return false;
        }

        this.setState({ isZipValid: true });
        return true;
    }

    validZipAgainstState(input) {
        const {
            postcodeStateMapping,
            regionId,
            countries,
            countryId,
            setIsValidByCustomRules
        } = this.props;

        if (!postcodeStateMapping || !postcodeStateMapping.trim()) {
            return true;
        }

        const { postalcodeToState: { validate, message } } = validationConfig;

        const selectedCountry = countries.find(({ id }) => id === countryId);

        if (!selectedCountry) {
            return false;
        }

        const { available_regions } = selectedCountry;
        const selectedState = available_regions.find(({ id }) => id === parseInt(regionId, 10));
        const props = {
            value: input,
            postcodeStateMapping: JSON.parse(postcodeStateMapping),
            selectedState
        };
        const isValid = validate(props);

        if (!isValid) {
            this.setState({ isZipValid: false, invalidMessage: message });

            setIsValidByCustomRules(false);
            return false;
        }

        setIsValidByCustomRules(true);
        return true;
    }

    render() {
        return (
            <FieldZip
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, null)(FieldZipContainer);
