/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { STYLEGUIDE_POPUP } from 'Route/StyleGuide/StyleGuide.config';
import { showSubscribePopup, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * Subscription Container plugin
 */
export class HeaderContainerPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        showSubscribePopup: PropTypes.bool.isRequired,
        toggleSubscribePopup: PropTypes.func.isRequired,
        showOverlay: PropTypes.func.isRequired
    });

    mapStateToProps = (args, callback, _instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            showSubscribePopup: state.OverlayReducer.showSubscribePopup
        };
    };

    mapDispatchToProps = (args, callback, _instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            toggleSubscribePopup: (value) => dispatch(showSubscribePopup(value)),
            showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey))
        };
    };

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onSubscribeClick: this.onSubscribeClick.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const {
            showOverlay,
            showSubscribePopup,
            toggleSubscribePopup
        } = instance.props;

        return {
            ...callback(...args),
            showOverlay,
            showSubscribePopup,
            toggleSubscribePopup
        };
    };

    componentDidUpdate = (args, callback, instance) => {
        const [prevProps] = args;
        const { activeOverlay } = instance.props;
        const { activeOverlay: prevActiveOverlay } = prevProps;

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && prevActiveOverlay === STYLEGUIDE_POPUP && activeOverlay !== prevActiveOverlay) {
            instance.setState({ showSubscribePopup: false });
        }

        return callback(...args);
    };

    onSubscribeClick() {
        const {
            showOverlay,
            showSubscribePopup,
            toggleSubscribePopup
        } = this.props;

        // Adding logic from popup component to be used in BSE.
        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${location.pathname}${location.search}${location.hash}`
        );

        toggleSubscribePopup(!showSubscribePopup);
        showOverlay(STYLEGUIDE_POPUP);
    }
}

const {
    propTypes,
    mapStateToProps,
    mapDispatchToProps,
    containerFunctions,
    containerProps,
    componentDidUpdate
} = new HeaderContainerPlugin();

export const config = {
    'Component/Header/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/Header/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/Header/Container': {
        'static-member': {
            propTypes
        },
        'member-property': {
            containerFunctions
        },
        'member-function': {
            containerProps,
            componentDidUpdate
        }
    }
};

export default config;
