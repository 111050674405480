/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Link from 'Component/Link';

import './CheckoutTermsAndConditions.style';

/** @namespace Scandipwa/Component/CheckoutTermsAndConditions/Component */
export class CheckoutTermsAndConditionsComponent extends PureComponent {
    static propTypes = {
        termsAndConditionsLink: PropTypes.string.isRequired,
        privacyPolicyLink: PropTypes.string.isRequired,
        // eslint-disable-next-line react/boolean-prop-naming
        termsAreEnabled: PropTypes.bool.isRequired,
        isTACAccepted: PropTypes.bool.isRequired,
        setTACStatus: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string,
        isCartOverlay: PropTypes.bool,
        isPaypal: PropTypes.bool
    };

    static defaultProps = {
        activeOverlay: '',
        isCartOverlay: false,
        isPaypal: false
    };

    renderPrivacyPolicy() {
        const {
            termsAreEnabled,
            privacyPolicyLink
        } = this.props;
        // eslint-disable-next-line max-len
        const text = `${ __('Your personal data will be used to process your order, improve your experience on this website, and for other purposes described in our') } `;

        if (!termsAreEnabled) {
            return null;
        }

        return (
            <div block="CheckoutButtons" elem="PrivacyPolicy">
                <span>
                    { text }
                    <Link
                      block="CheckoutButtons"
                      elem="TermsAndConditions-Button"
                      to={ privacyPolicyLink }
                      target="_blank"
                    >
                        { __('privacy policy.') }
                    </Link>
                </span>
            </div>
        );
    }

    render() {
        const {
            termsAreEnabled,
            termsAndConditionsLink,
            setTACStatus,
            isTACAccepted,
            activeOverlay,
            isCartOverlay,
            isPaypal
        } = this.props;

        if (!termsAreEnabled || (isCartOverlay && !activeOverlay)) {
            return null;
        }

        return (
            <div>
                { this.renderPrivacyPolicy() }
                <div
                  block="CheckoutButtons"
                  elem="TermsAndConditions"
                >
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: isPaypal ? 'CheckoutTermsAndConditionsPaypal' : 'CheckoutTermsAndConditions',
                          name: 'termsAndConditions',
                          value: 'termsAndConditions',
                          checked: isTACAccepted
                      } }
                      events={ {
                          onChange: setTACStatus
                      } }
                      mix={ { block: 'CheckoutButtons', elem: 'PayPalTermsAndConditions-Checkbox' } }
                    />
                    <label
                      block="CheckoutButtons"
                      elem="TACLabel"
                      htmlFor={ isPaypal ? 'CheckoutTermsAndConditionsPaypal' : 'CheckoutTermsAndConditions' }
                    >
                        { `${ __('I have read and agree to the') } ` }
                        <Link
                          block="CheckoutButtons"
                          elem="TermsAndConditions-Button"
                          to={ termsAndConditionsLink }
                          target="_blank"
                        >
                            { `${ __('terms and conditions') } ` }
                        </Link>
                        { __('of puma.com') }
                    </label>
                </div>
            </div>
        );
    }
}

export default CheckoutTermsAndConditionsComponent;
