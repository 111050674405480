/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * PayPal Component plugin
 */
export class PayPalComponentPlugin {
    render = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    };
}

const {
    render
} = new PayPalComponentPlugin();

export const config = {
    'Paypal/Component/PayPal/Component': {
        'member-function': {
            render
        }
    }
};

export default config;
