/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable consistent-return */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    ADD_TO_CART_POPUP
} from 'Component/AddToCartPopup/AddToCartPopup.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import {
    CART_OVERLAY
} from 'SourceComponent/Header/Header.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

import AddToCart from './AddToCart.component';

/** @namespace Scandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps,
    totals: state.CartReducer.cartTotals
});

/** @namespace Scandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showOverlay: (overlayKey, payload) => dispatch(toggleOverlayByKey(overlayKey, payload)),
    showPopup: (payload) => dispatch(showPopup('title', payload))
});

/** @namespace Scandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static propTypes = {
        ...SourceAddToCartContainer.propTypes,
        showOverlay: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        salable_qty: PropTypes.number.isRequired
    };

    state = {
        ...SourceAddToCartContainer.state,
        shouldShowAddToCartPopup: false,
        search: ''
    };

    async addProductToCart() {
        const {
            product,
            product: { sku },
            totals: { items },
            quantity,
            showNotification,
            addToCart,
            updateSelectedValues
        } = this.props;

        const restrictedSku = '705249';

        if (restrictedSku === sku) {
            if (quantity > 2) {
                showNotification('error', __('The requested qty exceeds the maximum qty allowed in the shopping cart'));

                return null;
            }

            // eslint-disable-next-line fp/no-let
            let count = 0;
            items.map((item) => {
                if (item.product.sku === sku) {
                    count += item.qty;
                }

                return null;
            });

            if (count + quantity > 2) {
                showNotification('error', __('The requested qty exceeds the maximum qty allowed in the shopping cart'));

                return null;
            }
        }

        if (updateSelectedValues) {
            await updateSelectedValues();
        }

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        if (!this.validate()) {
            return;
        }

        this.setState({ isAdding: true });

        if (typeof addToCart === 'function') {
            addToCart().then(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch/addToCart/then */
                () => {
                    this.afterAddToCart();
                    this.setState({ isAdding: false });
                }
            ).catch(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch */
                () => this.setState({ isAdding: false })
            );
        } else {
            const {
                quantity,
                cartId,
                fallbackAddToCart
            } = this.props;

            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            fallbackAddToCart({
                products: magentoProduct,
                cartId
            }).then(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch/fallbackAddToCart/then */
                () => {
                    this.afterAddToCart();
                    this.setState({ isAdding: false });
                }
            ).catch(
                /** @namespace Scandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/then/catch */
                () => this.setState({ isAdding: false })
            );
        }
    }

    containerProps() {
        const {
            areDetailsLoaded
        } = this.props;

        const {
            shouldShowAddToCartPopup,
            search
        } = this.state;

        return {
            ...super.containerProps(),
            shouldShowAddToCartPopup,
            search,
            areDetailsLoaded
        };
    }

    afterAddToCart() {
        const {
            showPopup,
            showOverlay,
            quantity
        } = this.props;

        if (window.matchMedia('(min-width: 1180px)').matches) {
            showOverlay(CART_OVERLAY);
        } else if (window.matchMedia('(min-width: 1024px)').matches) {
            showOverlay(CART_OVERLAY, {
                isAfterAddToCart: true
            });
        } else {
            this.setState({ shouldShowAddToCartPopup: true }, () => {
                showPopup(__('%s product has been added to cart', quantity));
                showOverlay(ADD_TO_CART_POPUP);
            });
        }
    }

    render() {
        return (
            <AddToCart
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
