/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Loader from 'Component/Loader/Loader.component';
import SourceMyAccountAddressTable from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import './MyAccountAddressTable.style';

/** @namespace Scandipwa/Component/MyAccountAddressTable/Component */
export class MyAccountAddressTableComponent extends SourceMyAccountAddressTable {
    static propTypes = {
        ...SourceMyAccountAddressTable.propTypes,
        onDeleteClick: PropTypes.func
    };

    static defaultProps = {
        ...SourceMyAccountAddressTable.defaultProps,
        onDeleteClick: () => {}
    };

    renderActions() {
        const {
            onEditClick,
            showActions,
            onDelete,
            isLoading
        } = this.props;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <button
                  block="Link"
                  mix={ { block: 'Link', elem: 'EditAddressButton' } }
                  onClick={ onEditClick }
                >
                    { __('Edit address') }
                </button>
                <Loader isLoading={ isLoading } />
                <button
                  block="Link"
                  elem="DeleteAddressButton"
                  onClick={ onDelete }
                >
                    { __('Delete') }
                </button>
            </>
        );
    }
}

export default MyAccountAddressTableComponent;
