/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';
import Popup from 'Component/Popup';

import { LANG_BLOCK_POPUP } from './ChooseLanguageSelector.config';

import './ChooseLanguageSelector.style';

/** @namespace Scandipwa/Component/ChooseLanguageSelector/Component */
export class ChooseLanguageSelectorComponent extends PureComponent {
    static propTypes = {
        ...this.props,
        toggleOverlayByKey: PropTypes.func.isRequired,
        storeName: PropTypes.string.isRequired,
        closePrevActiveOverlay: PropTypes.func,
        isFooter: PropTypes.bool
    };

    static defaultProps = {
        closePrevActiveOverlay: () => {},
        isFooter: false
    };

    state = {
        isLoading: true
    };

    customComponents = {
        // eslint-disable-next-line quote-props
        'countries__item': this.renderDropdown
    };

    setIsLoading = (status) => {
        this.setState({ isLoading: status });
    };

    renderDropdown(attributes, children) {
        return (
            <ExpandableContent heading={ children[0]?.props?.children || children[0] }>
                { children.find((child) => child.type === 'ul') }
            </ExpandableContent>
        );
    }

    renderContent() {
        const { isLoading } = this.state;

        return (
            <Popup id={ LANG_BLOCK_POPUP } block="ChooseLangBlock" clickOutside={ false }>
                <Loader isLoading={ isLoading } />
                <CmsBlock
                  identifier="country-selector"
                  customComponents={ this.customComponents }
                  setIsLoading={ this.setIsLoading }
                />
            </Popup>
        );
    }

    renderDefaultButton() {
        const { toggleOverlayByKey, closePrevActiveOverlay, storeName } = this.props;

        return (
            <button
              block="ChooseLangBlock"
              elem="PopupBtn"
              onClick={ () => {
                  closePrevActiveOverlay();
                  toggleOverlayByKey(LANG_BLOCK_POPUP);
              } }
            >
                <span>
                    { __('language') }
                </span>
                <div>
                    <span block="ChooseLangBlock" elem="Prefix">{ storeName }</span>
                    <span block="Icon" elem="Flag" />
                </div>
            </button>
        );
    }

    renderFooterButton() {
        const { toggleOverlayByKey, storeName } = this.props;

        return (
            <button
              block="ChooseLangBlock"
              elem="PopupBtn"
              onClick={ () => {
                  toggleOverlayByKey(LANG_BLOCK_POPUP);
              } }
            >
                <div block="ChooseLangBlock" elem="FlagWrapper">
                    <div block="Icon" elem="Flag" />
                    <span block="ChooseLangBlock" elem="Prefix">{ storeName }</span>
                </div>
                <div block="Icon" elem="Bottom" mods={ { color: 'white' } } />
            </button>
        );
    }

    renderButton() {
        const { isFooter } = this.props;

        if (isFooter) {
            return this.renderFooterButton();
        }

        return this.renderDefaultButton();
    }

    render() {
        return (
            <div block="ChooseLangBlock">
                { this.renderButton() }
                { this.renderContent() }
            </div>
        );
    }
}

export default ChooseLanguageSelectorComponent;
