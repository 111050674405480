/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/**
 * Attaches to the CheckoutReducer
 */

export const UPDATE_ADYEN_CHECKOUT_STATE_DATA = 'UPDATE_ADYEN_CHECKOUT_STATE_DATA';
export const UPDATE_ORDER_NUMBER = 'UPDATE_ORDER_NUMBER';
export const UPDATE_ADYEN_CHECKOUT_ACTION_DATA = 'UPDATE_ADYEN_CHECKOUT_ACTION_DATA';

/** @namespace Scandiweb/AdyenPayments/Store/Adyen/Action/updateAdyenCheckoutStateData */
export const updateAdyenCheckoutStateData = (adyenStateData) => ({
    type: UPDATE_ADYEN_CHECKOUT_STATE_DATA,
    adyenStateData
});

/** @namespace Scandiweb/AdyenPayments/Store/Adyen/Action/updateOrderNumber */
export const updateOrderNumber = (orderNumber) => ({
    type: UPDATE_ORDER_NUMBER,
    orderNumber
});

/** @namespace Scandiweb/AdyenPayments/Store/Adyen/Action/updateAdyenCheckoutActionData */
export const updateAdyenCheckoutActionData = (adyenActionData) => ({
    type: UPDATE_ADYEN_CHECKOUT_ACTION_DATA,
    adyenActionData
});
