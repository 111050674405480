/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import GroovinPixels, {
    DEFAULT_SKU,
    DEFAULT_USER_ID,
    GROOVIN_PIXELS_RETARGETING
} from '../util/GroovinPixels';

/**
 * Category page component plugin
 */
class CategoryPageComponentPlugin {
    aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
        const [prevProps] = args;
        const { category: { id: categoryId } } = instance.props;
        const { category: { id: prevCategoryId } } = prevProps;

        const params = {
            sku: DEFAULT_SKU,
            idcategory: categoryId,
            uid: DEFAULT_USER_ID
        };

        if (categoryId && categoryId !== prevCategoryId) {
            GroovinPixels.updateGroovinPixels(params, GROOVIN_PIXELS_RETARGETING);
        }

        return callback(...args);
    };

    aroundComponentWillUnmount= (args, callback = () => {}) => {
        GroovinPixels.updateGroovinPixels({}, GROOVIN_PIXELS_RETARGETING);

        return callback(...args);
    };
}

const {
    aroundComponentDidUpdate,
    aroundComponentWillUnmount
} = new CategoryPageComponentPlugin();

const config = {
    'Route/CategoryPage/Component': {
        'member-function': {
            componentDidUpdate: aroundComponentDidUpdate,
            componentWillUnmount: aroundComponentWillUnmount
        }
    }
};

export default config;
