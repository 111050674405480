/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    CHECKOUT_PRODUCTS_TYPE,
    ORDER_TYPE
} from '@scandipwa/gtm/src/component/GoogleTagManager/events/PageDataEvent/PageData.event';
import Event, { EVENT_GTM_PAGE_DATA } from '@scandipwa/gtm/src/util/Event';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { CART_OVERLAY } from 'Component/Header/Header.config';
import CheckoutQuery from 'Query/Checkout.query';
import { ORDER_ID_KEY, PAYMENT_METHOD_CODE } from 'Route/Checkout/Checkout.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { TotalsType } from 'Type/MiniCart.type';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { deleteCartId, getCartId } from 'Util/Cart';
import browserHistory from 'Util/History';
import { fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { SORETO_DATA } from '../../../../soreto/src/component/Soreto/Soreto.config';
import PayPalQuery from '../../query/PayPal.query';
import PayPal from './PayPal.component';
import {
    PAYPAL_EXPRESS,
    PAYPAL_SCRIPT,
    PAYPAL_TAC_BLOCK_CLASSNAME
} from './PayPal.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Paypal/Component/PayPal/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    cartTotals: state.CartReducer.cartTotals,
    clientId: state.ConfigReducer.paypal_client_id,
    isSandboxEnabled: state.ConfigReducer.paypal_sandbox_flag,
    code: state.ConfigReducer.code
});

/** @namespace Paypal/Component/PayPal/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    // eslint-disable-next-line max-len
    showNotification: (type, message, e, showDebugInProduction) => dispatch(showNotification(type, message, e, showDebugInProduction)),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, getAuthorizationToken())
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        }
    ),
    getGuestQuoteId: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher._getGuestQuoteId()
    )
});

/** @namespace Paypal/Component/PayPal/Container */
export class PayPalContainer extends PureComponent {
    state = {
        isTACAccepted: false,
        shouldRedirect: false,
        orderId: ''
    };

    static propTypes = {
        clientId: PropTypes.string,
        isSandboxEnabled: PropTypes.bool,
        setLoading: PropTypes.func,
        setDetailsStep: PropTypes.func,
        showNotification: PropTypes.func.isRequired,
        selectedPaymentCode: PropTypes.string.isRequired,
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        getGuestQuoteId: PropTypes.func.isRequired,
        code: PropTypes.string,
        isButton: PropTypes.bool,
        activeOverlay: PropTypes.string.isRequired,
        location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
        cartTotals: TotalsType.isRequired
    };

    static defaultProps = {
        setLoading: () => {},
        setDetailsStep: () => {},
        clientId: '',
        isSandboxEnabled: false,
        code: 'en',
        isButton: false
    };

    componentDidMount() {
        const script = document.getElementById(PAYPAL_SCRIPT);

        if (script) {
            script.onload = () => this.forceUpdate();
        }
    }

    componentDidUpdate(prevProps) {
        const { activeOverlay, location: { pathname = '' } } = this.props;
        const { location: { pathname: prevPathname } } = prevProps;

        const pathChanged = pathname !== prevPathname;

        // removing PayPal's TAC block in order to fully reset it on Cart page with unique ID
        if (pathChanged && pathname.includes('/cart')) {
            const tacBlock = document.getElementsByClassName(PAYPAL_TAC_BLOCK_CLASSNAME)[0];

            if (tacBlock) {
                tacBlock.remove();
            }

            this.setState({ isTACAccepted: false });
        }

        if (activeOverlay === CART_OVERLAY) {
            const script = document.getElementById(PAYPAL_SCRIPT);
            if (script) {
                script.onload = () => this.forceUpdate();
            }
        }
    }

    componentWillUnmount() {
        // Resetting all pay-pal related properties
        Object.keys(window).forEach((key) => {
            if (/paypal|zoid|post_robot/.test(key)) {
                // eslint-disable-next-line fp/no-delete
                delete window[key];
            }
        });

        // Removes paypal script
        const paypalScript = document.getElementById(PAYPAL_SCRIPT);
        if (paypalScript) {
            paypalScript.parentNode.removeChild(paypalScript);
        }
    }

    containerProps = () => ({
        paypal: this.getPayPal(),
        environment: this.getEnvironment(),
        isDisabled: this.getIsDisabled(),
        isTACAccepted: this.isTACAccepted(),
        shouldRedirect: this.getShouldRedirect(),
        orderId: this.getOrderId()
    });

    getIsDisabled = () => {
        const { selectedPaymentCode } = this.props;
        return selectedPaymentCode !== PAYPAL_EXPRESS;
    };

    isTACAccepted() {
        const { isTACAccepted } = this.state;

        return isTACAccepted;
    }

    getShouldRedirect() {
        const { shouldRedirect } = this.state;

        return shouldRedirect;
    }

    getOrderId() {
        const { orderId } = this.state;

        return orderId;
    }

    containerFunctions = () => ({
        onError: this.onError,
        onCancel: this.onCancel,
        onApprove: this.onApprove,
        createOrder: this.createOrder,
        setTACStatus: this.setTACStatus
    });

    setTACStatus = () => {
        this.setState(({ isTACAccepted: previsTACAccepted }) => ({
            isTACAccepted: !previsTACAccepted
        }));
    };

    onApprove = async (data) => {
        const {
            showNotification,
            setDetailsStep,
            isButton,
            resetCart,
            resetGuestCart
        } = this.props;
        const { orderID, payerID } = data;
        const guest_cart_id = getCartId();

        if (!isSignedIn() && !guest_cart_id) {
            return;
        }

        try {
            await fetchMutation(CheckoutQuery.getSetPaymentMethodOnCartMutation({
                cart_id: guest_cart_id,
                payment_method: {
                    code: 'paypal_express',
                    additional_data: {
                        is_button: isButton
                    },
                    paypal_express: {
                        token: orderID,
                        payer_id: payerID
                    }
                }
            }));

            const orderData = await fetchMutation(CheckoutQuery.getPlaceOrderMutation(guest_cart_id));
            const {
                placeOrder: {
                    order: {
                        order_id,
                        paypalAdditionalData: {
                            shipping,
                            country_id,
                            method_title,
                            paypal_payer_name,
                            paypal_payer_email,
                            shipping_description
                        } = {}
                    }
                }
            } = orderData;

            BrowserDatabase.setItem(order_id, ORDER_ID_KEY);

            /** Soreto integration */
            const { cartTotals: { prices: { grand_total: { value: grand_total } } } } = this.props;

            BrowserDatabase.setSessionItem({
                firstname: paypal_payer_name,
                email: paypal_payer_email,
                grand_total,
                country_id
            }, SORETO_DATA, ONE_MONTH_IN_SECONDS);
            /** End Soreto integration */

            deleteCartId();

            if (isButton) {
                // Notify GTM
                Event.dispatch(
                    EVENT_GTM_PAGE_DATA,
                    {
                        type: ORDER_TYPE,
                        shipping,
                        id: order_id,
                        paymentType: method_title,
                        email: paypal_payer_email,
                        shippingType: shipping_description,
                        shipToCountry: country_id?.toLowerCase()
                    }
                );

                // Notify GTM
                Event.dispatch(
                    EVENT_GTM_PAGE_DATA,
                    { type: CHECKOUT_PRODUCTS_TYPE }
                );

                BrowserDatabase.setSessionItem(PAYPAL_EXPRESS, PAYMENT_METHOD_CODE);

                if (isSignedIn()) {
                    resetCart();
                } else {
                    resetGuestCart();
                }

                this.setState({ orderId: order_id, shouldRedirect: true });
            }

            if (setDetailsStep) {
                setDetailsStep(order_id);
            }
        } catch (e) {
            if (Array.isArray(e) && e.length) {
                const [{ message }] = e;

                if (message) {
                    showNotification('error', message);

                    return;
                }
            }

            showNotification('error', __('Something went wrong. Please check your PayPal account.'));
        }
    };

    // eslint-disable-next-line no-unused-vars
    onCancel = (data) => {
        const { showNotification, setLoading } = this.props;
        setLoading(false);
        showNotification('info', 'Your payment has been canceled');
    };

    // eslint-disable-next-line no-unused-vars
    onError = (err) => {
        const { showNotification, setLoading } = this.props;
        setLoading(false);
        showNotification('error', 'Some error appeared with PayPal');
    };

    getPayPal = () => {
        const { paypal } = window;
        return paypal || false;
    };

    getEnvironment = () => {
        const { isSandboxEnabled } = this.props;
        return isSandboxEnabled ? 'sandbox' : 'production';
    };

    getGaCid = () => {
        const [, result = ''] = document.cookie.match(/_ga=([^;]+);/) || [];
        return result;
    };

    createOrder = async () => {
        const { setLoading, selectedPaymentCode } = this.props;
        const guest_cart_id = getCartId();

        setLoading(true);

        const {
            paypalExpress: { token }
        } = await fetchMutation(PayPalQuery.getCreatePaypalExpressTokenMutation({
            guest_cart_id,
            express_button: false,
            code: selectedPaymentCode,
            // use_paypal_credit: this.getIsCredit(),
            urls: {
                cancel_url: 'www.paypal.com/checkoutnow/error',
                return_url: 'www.paypal.com/checkoutnow/error'
            },
            ga_cid: this.getGaCid()
        }));

        return token;
    };

    render() {
        return (
            <PayPal
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions() }
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PayPalContainer));
