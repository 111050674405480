/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import SearchItem from 'Component/SearchItem';
import {
    AMOUNT_OF_PLACEHOLDERS,
    SEARCH_TIMEOUT
} from 'Component/SearchOverlay/SearchOverlay.config';
import SourceSearchOverlay from 'SourceComponent/SearchOverlay/SearchOverlay.component';

import './SearchOverlay.style';

/** @namespace Scandipwa/Component/SearchOverlay/Component */
export class SearchOverlayComponent extends SourceSearchOverlay {
    static propTypes = {
        ...this.props,
        isMobile: PropTypes.bool,
        suggestions: PropTypes.arrayOf(PropTypes.string),
        display_suggestions: PropTypes.number,
        display_results: PropTypes.number
    };

    static defaultProps = {
        ...this.defaultProps,
        isMobile: false
    };

    componentDidUpdate(prevProps) {
        const { searchCriteria: prevSearchCriteria } = prevProps;
        const { searchCriteria, makeSearchRequest } = this.props;

        if (searchCriteria !== prevSearchCriteria) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.timeout = null;
                makeSearchRequest();
            }, SEARCH_TIMEOUT);
        }
    }

    renderSearchResults() {
        const {
            searchResults,
            isLoading,
            display_results
        } = this.props;

        if (isLoading || !searchResults.length || !display_results) {
            return null;
        }

        const resultsToRender = (isLoading || this.timeout) ? Array(AMOUNT_OF_PLACEHOLDERS).fill({}) : searchResults;

        return (
            <div block="SearchOverlay" elem="ItemsWrapper">
                <div
                  block="SearchOverlay"
                  elem="Criteria"
                  mods={ { isVisible: true } }
                >
                    { __('Products') }
                </div>
                <ul>
                    { resultsToRender.map((item, i) => this.renderSearchItem(item, i)) }
                </ul>
            </div>
        );
    }

    renderSearchItem(product, i) {
        const { clearSearchResults, clearSearch, closeSearch } = this.props;
        return (
            <SearchItem
              product={ product }
              key={ i }
              clearSearchResults={ clearSearchResults }
              clearSearchField={ clearSearch }
              closeSearch={ closeSearch }
            />
        );
    }

    renderMobileContent() {
        const { suggestions } = this.props;

        const hasSuggestions = suggestions && suggestions.length > 0;

        return (
            <article
              block="SearchOverlay"
              elem="Results"
              aria-label="Search results"
            >
                <div block="SearchOverlay" elem="ItemsWrapper">
                    { this.renderSearchResults() }
                </div>
                { hasSuggestions && (
                    <div block="SearchOverlay" elem="SuggestionsWrapper">
                            <div>{ __('Did you mean') }</div>
                            { this.renderSearchSuggestions() }
                    </div>
                ) }
            </article>
        );
    }

    renderSuggestions() {
        const {
            suggestions,
            handleSuggestionClick,
            display_suggestions
        } = this.props;

        if (!suggestions || !display_suggestions) {
            return null;
        }

        return suggestions.map((suggestion) => (
            <Link
              block="SearchOverlay"
              elem="SuggestionsLink"
              to={ `/search/${suggestion.replace(/(<([^>]+)>)/ig, '')}` }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ (e) => {
                  // Clears search results, closes active search overlay and redirects to given link
                  handleSuggestionClick(e, `/search/${suggestion.replace(/(<([^>]+)>)/ig, '')}`);
              } }
            >
                { /* eslint-disable-next-line react/no-danger */ }
                <span dangerouslySetInnerHTML={ { __html: suggestion } } />
            </Link>
        ));
    }

    render() {
        const {
            isMobile,
            suggestions,
            isLoading
        } = this.props;

        if (isLoading) {
            return null;
        }

        if (isMobile) {
            return this.renderMobileContent();
        }

        return (
            <Overlay
              id="search"
              mix={ { block: 'SearchOverlay' } }
            >
                <article
                  block="SearchOverlay"
                  elem="Results"
                  aria-label="Search results"
                >
                    { this.renderSearchResults() }
                    { suggestions && suggestions.length > 0 && (
                        <div block="SearchOverlay" elem="SuggestionsWrapper">
                            <div>{ __('Did you mean') }</div>
                            { this.renderSuggestions() }
                        </div>
                    ) }
                </article>
            </Overlay>
        );
    }
}

export default SearchOverlayComponent;
