import { connect } from 'react-redux';

import {
    LinkContainer as SourceLinkContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/Link/Link.container';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Scandipwa/Component/Link/Container */
export class LinkContainer extends SourceLinkContainer {
    onClick(e) {
        const { updateNoMatch, onClick } = this.props;

        updateNoMatch({ noMatch: false });

        if (onClick) {
            onClick(e);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer);
