/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isSignedIn } from 'Util/Auth';

import { getCartItems } from '../../../Util/Cart';

export const PAGE_TYPE_CHECKOUT_PAGE = 'checkout';

/**
 * Checkout Container plugin
 */
export class CheckoutContainerPlugin {
    componentDidMount = (args, callback) => {
        window.pageType = PAGE_TYPE_CHECKOUT_PAGE;

        return callback(...args);
    };

    saveAddressInformation = async (args, callback, instance) => {
        const result = await callback(...args);

        if (!window.webExtendEvents.includes('setEmail')) {
            if (isSignedIn()) {
                const { customer: { email } } = instance.props;

                ScarabQueue.push(['setEmail', email]);
            } else {
                const { email } = instance.state;

                ScarabQueue.push(['setEmail', email]);
            }

            window.webExtendEvents.push('setEmail');
        }

        if (!window.webExtendEvents.includes('cart')) {
            ScarabQueue.push(['cart', getCartItems()]);
            window.webExtendEvents.push('cart');
        }

        if (!window.webExtendEvents.includes('go') && window.webExtendEvents.length) {
            ScarabQueue.push(['go']);
            window.webExtendEvents.push('go');
        }

        window.webExtendEvents = []; // Resetting the list

        return result;
    };
}

const {
    componentDidMount,
    saveAddressInformation
} = new CheckoutContainerPlugin();

export const config = {
    'Scandipwa/Route/Checkout/Container': {
        'member-function': {
            componentDidMount,
            saveAddressInformation
        }
    }
};

export default config;
