/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { Suspense } from 'react';

import Link from 'Component/Link';
import {
    SUBSCRIBE_TAB
} from 'Component/NavigationTabs/NavigationTabs.config';
import SubscriptionPopup from 'Component/SubscriptionPopup';
import browserHistory from 'Util/History';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import { BIG_SCREEN_HOMEPAGE } from '../../../route/QrPage/QrPage.config';

export class NavigationTabsComponentPlugin {
    aroundStateMap = (originalMember) => ({
        ...originalMember,
        [SUBSCRIBE_TAB]: {
            subscribe: true
        }
    });

    aroundRenderMap = (originalMember, instance) => ({
        ...originalMember,
        subscribe: this.renderSubscribe.bind(instance)
    });

    closeMenuAndGoToHomePage = (props) => (e) => {
        const { handleCloseButtonClick } = props;

        handleCloseButtonClick(e);
        browserHistory.push(BIG_SCREEN_HOMEPAGE);
    };

    renderLogo = (args, callback, instance) => {
        const {
            props: {
                iconColor
            },
            props
        } = instance;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        return (
            <Link
              aria-label="Go to homepage by clicking on Puma logo"
              key="logo"
              to={ BIG_SCREEN_HOMEPAGE }
              onClick={ this.closeMenuAndGoToHomePage(props) }
            >
                <div block="NavigationTabs" elem="MainLogo">
                    <svg fill={ iconColor } version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 37" enableBackground="new 0 0 48 37" xmlSpace="preserve">
                        <path d="M47.689,0.517c-0.834-1.066-2.291-0.213-2.933,0.16c-4.569,2.692-5.243,7.432-6.834,10.154 c-1.253,2.178-3.304,3.779-5.159,3.903c-1.373,0.098-2.861-0.167-4.338-0.81c-3.613-1.562-5.56-3.583-6.034-3.94 c-0.973-0.739-8.459-8.03-14.559-8.327c0,0-0.744-1.5-0.93-1.526C6.457,0.08,6,1.033,5.669,1.133c-0.3,0.105-0.825-1.024-1.13-0.975 C4.233,0.2,3.936,1.33,3.34,1.913C2.901,2.338,2.367,2.311,2.065,2.839c-0.104,0.192-0.068,0.53-0.186,0.84 C1.626,4.32,0.777,4.387,0.769,5.073c0,0.762,0.714,0.907,1.338,1.438c0.496,0.425,0.53,0.725,1.109,0.924 C3.731,7.611,4.48,7.061,5.144,7.258c0.553,0.163,1.085,0.279,1.204,0.846c0.108,0.513,0,1.316-0.682,1.226 C5.444,9.3,4.472,8.982,3.271,9.11c-1.45,0.154-3.105,0.618-3.267,2.22c-0.083,0.895,1.028,1.942,2.11,1.733 c0.742-0.143,0.392-1.013,0.797-1.433c0.535-0.541,3.545,1.888,6.344,1.888c1.186,0,2.063-0.3,2.935-1.21 c0.078-0.057,0.185-0.203,0.31-0.218c0.113,0.015,0.324,0.128,0.39,0.175c2.262,1.793,3.967,5.399,12.26,5.441 c1.164,0.014,2.498,0.558,3.591,1.553c0.96,0.866,1.528,2.251,2.075,3.65c0.836,2.106,2.322,4.139,4.584,6.407 c0.119,0.135,1.98,1.561,2.119,1.666c0.025,0.021,0.168,0.334,0.106,0.51c-0.039,1.38-0.245,5.34,2.731,5.506 c0.731,0.04,0.549-0.463,0.549-0.82c-0.01-0.683-0.129-1.371,0.226-2.08c0.507-0.957-1.051-1.418-1.017-3.513 c0.037-1.567-1.291-1.302-1.969-2.498c-0.381-0.687-0.736-1.065-0.699-1.894c0.145-4.76-1.034-7.896-1.61-8.654 c-0.455-0.587-0.847-0.806-0.414-1.078c2.481-1.632,3.05-3.15,3.05-3.15c1.32-3.081,2.512-5.89,4.15-7.138 c0.332-0.241,1.177-0.88,1.703-1.12c1.527-0.725,2.346-1.156,2.777-1.576c0.711-0.675,1.27-2.107,0.588-2.96H47.689z" />
                    </svg>
                </div>
            </Link>
        );
    };

    renderSubscribe() {
        const { onSubscribeClick, showSubscribePopup, iconColor } = this.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        return (
            <>
                { isBigScreen
                    && (
                        <button
                          block="NavigationTabs"
                          elem="Button"
                          mods={ { subscribe: true, iconColor } }
                          aria-label="Subscribe now"
                          onClick={ onSubscribeClick }
                        >
                            { __('SUBSCRIBE HERE') }
                        </button>
                    ) }
                { showSubscribePopup
                    && (
                        <Suspense fallback={ null }>
                            <SubscriptionPopup />
                        </Suspense>
                    ) }
            </>
        );
    }

    renderMiniWishlist = (args, callback) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    };

    renderAdditionalButton = (args, callback) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    };
}

const {
    renderLogo,
    aroundRenderMap,
    aroundStateMap,
    renderMiniWishlist,
    renderAdditionalButton
} = new NavigationTabsComponentPlugin();

export const config = {
    'Scandipwa/Component/NavigationTabs/Component': {
        'member-function': {
            renderLogo,
            renderMiniWishlist,
            renderAdditionalButton
        },
        'member-property': {
            renderMap: aroundRenderMap,
            stateMap: aroundStateMap
        }
    }
};

export default config;
