/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import QRCode from 'react-qr-code';

import Popup from 'Component/Popup';
import { getCartId } from 'Util/Cart';

import { QR_POPUP_ID } from './QrPopup.config';

import './QrPopup.style.scss';

/** @namespace Bigscreen/Component/QrPopup/Component */
export class QrPopupComponent extends PureComponent {
    static propTypes = {
        secureBaseUrl: PropTypes.string.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    renderQR() {
        const { secureBaseUrl } = this.props;
        const cart_id = getCartId();
        const value = `${secureBaseUrl}qr/mobile?quote_hash=${cart_id}`;

        return (
            <div block="QrPopup" elem="QRCodeWrapper">
                <QRCode value={ value } />
            </div>
        );
    }

    renderContent() {
        const { hideActiveOverlay } = this.props;

        return (
            <div>
                { this.renderQR() }
                <button
                  block="QrPopup"
                  elem="Button"
                  mix={ { block: 'Button' } }
                  onClick={ () => hideActiveOverlay() }
                >
                    { __('Back to Cart') }
                </button>
            </div>
        );
    }

    render() {
        return (
            <Popup
              id={ QR_POPUP_ID }
              mix={ { block: 'QrPopup' } }
            >
                { this.renderContent() }
            </Popup>
        );
    }
}

export default QrPopupComponent;
