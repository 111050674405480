/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { setInputs } from '../../../store/Keyboard';

/**
 * SubscriptionPopupForm Container plugin
 */
export class SubscriptionPopupFormContainerPlugin {
    mapStateToProps = (args, callback, instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            inputs: state.KeyboardReducer.inputs
        };
    };

    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            setInputs: (input) => dispatch(setInputs(input))
        };
    };

    containerProps = (args, callback, instance) => {
        const {
            setInputs,
            inputs
        } = instance.props;

        return {
            ...callback(...args),
            setInputs,
            inputs
        };
    };
}

const {
    mapStateToProps,
    mapDispatchToProps,
    containerProps
} = new SubscriptionPopupFormContainerPlugin();

export const config = {
    'Scandipwa/Component/SubscriptionPopupForm/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/SubscriptionPopupForm/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/SubscriptionPopupForm/Container': {
        'member-function': {
            containerProps
        }
    }
};

export default config;
