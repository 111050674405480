/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PDP_TYPE } from '../../component/GoogleTagManager/events/PageDataEvent/PageData.event';
import { EVENT_GTM_PAGE_DATA } from '../../util/Event';
import Event from '../../util/Event/Event';

export const dispatchProductPageDataEvent = (quantity, prevQuantity) => {
    Event.dispatch(
        EVENT_GTM_PAGE_DATA,
        {
            type: PDP_TYPE,
            quantity,
            prevQuantity
        }
    );
};
export const componentDidUpdate = (args, callback, instance) => {
    const { quantity, areDetailsLoaded } = instance.props;
    const [prevProps] = args;

    const { areDetailsLoaded: prevAreDetailsLoaded } = prevProps;

    if (areDetailsLoaded !== prevAreDetailsLoaded) {
        dispatchProductPageDataEvent(quantity, quantity);
    }

    return callback(...args);
};

export const componentDidMount = (args, callback, instance) => {
    const { quantity, areDetailsLoaded } = instance.props;

    // happens when user is navigating back to PDP from another page - state is preserved and details are loaded
    if (areDetailsLoaded) {
        dispatchProductPageDataEvent(quantity, quantity);
    }

    return callback(...args);
};

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            componentDidUpdate,
            componentDidMount
        }
    }
};
