/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import SubscriptionPopupForm from 'Component/SubscriptionPopupForm';
import { STYLEGUIDE_POPUP } from 'Route/StyleGuide/StyleGuide.config';

import './SubscriptionPopup.style.scss';

/** @namespace Scandipwa/Component/SubscriptionPopup/Component */
export class SubscriptionPopupComponent extends PureComponent {
    static propTypes = {
        toggleOverlayByKey: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        customer: PropTypes.object.isRequired,
        isSignedIn: PropTypes.func.isRequired,
        isSubscriptionPopupActive: PropTypes.bool.isRequired
    };

    componentDidMount() {
        const cookie = (`; ${document.cookie}`).split('; CustomerClose=').pop().split(';').shift();
        if (!cookie) {
            this.renderPopup();
        }
    }

    renderPopup() {
        const { toggleOverlayByKey, showPopup } = this.props;
        showPopup();
        toggleOverlayByKey(STYLEGUIDE_POPUP);
    }

    onPopupHide() {
        const cookie = (`; ${document.cookie}`).split('; CustomerClose=').pop().split(';').shift();

        if (cookie) {
            return null;
        }
        document.cookie = 'CustomerClose=CustomerExpire; max-age=604800; path=/;';

        return true;
    }

    renderPopUps() {
        const cookie = (`; ${document.cookie}`).split('; CustomerClose=').pop().split(';').shift();
        const { customer, isSignedIn, isSubscriptionPopupActive = true } = this.props;

        if (cookie !== '' || !isSubscriptionPopupActive) {
            return null;
        }

        if ((isSignedIn && customer.is_subscribed_bool === false) || !isSignedIn) {
            return (
                <Popup
                  id={ STYLEGUIDE_POPUP }
                  block="SubscriptionPopupNonRegister"
                  elem="Form"
                  onHide={ this.onPopupHide }
                >
                    <SubscriptionPopupForm />
                </Popup>
            );
        }

        return null;
    }

    render() {
        return (
            <div
              block="StyleGuide"
            >
                { this.renderPopUps() }
            </div>
        );
    }
}
export default SubscriptionPopupComponent;
