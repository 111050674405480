/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_CART_IS_LOADED = 'UPDATE_CART_IS_LOADED';

/** @namespace Bigscreen/Store/Cart/Action/isCartLoaded */
export const isCartLoaded = (isLoaded) => ({
    type: UPDATE_CART_IS_LOADED,
    isLoaded
});
