/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export * from 'SourceRoute/Checkout/Checkout.config';

export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';

export const CHECKOUT_URL = '/checkout';
export const CHECKOUT_SUCCESS = '/checkout/success';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';
export const ORDER_ID_KEY = 'ORDER_ID';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500;

export const PAGE_DATA_ORDER_STORAGE = 'PAGE_DATA_ORDER_STORAGE';
export const PAGE_DATA_PRODUCTS_STORAGE = 'PAGE_DATA_PRODUCTS_STORAGE';
export const TRACKING_SCRIPT_BODY = 'TRACKING_SCRIPT_BODY';
export const TRACKING_SCRIPT_ID = 'dmtrack';

export const PAYMENT_METHOD_CODE = 'PAYMENT_METHOD_CODE';
