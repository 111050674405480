import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import DatePicker from 'Component/DatePicker/DatePicker.component';
import {
    AGE_LIMIT,
    AGE_VALIDATION,
    DAYS_30,
    DEFAULT_DAYS, FEB_LEAP, FEB_NORM,
    MIN_YEAR, MONTHS,
    MONTHS_WITH_30_DAYS,
    MONTHS_WITH_31_DAYS
} from 'Component/DatePicker/DatePicker.config';

/** @namespace Scandipwa/Component/DatePicker/Container */
export class DatePickerContainer extends PureComponent {
    static propTypes = {
        getDate: PropTypes.func.isRequired,
        validation: PropTypes.arrayOf('string'),
        errorMessage: PropTypes.string
    };

    static defaultProps = {
        validation: [],
        errorMessage: ''
    };

    containerFunctions = {
        changeIfLeapYear: this.changeIfLeapYear.bind(this),
        changeDays: this.changeDays.bind(this),
        getDay: this.getDay.bind(this)
    };

    __construct(props) {
        super.__construct(props);

        const year = (new Date()).getFullYear();
        const yearCount = year - MIN_YEAR;

        this.state = {
            years: Array.from(new Array(yearCount + 1), (val, index) => MIN_YEAR + index).reverse(),
            days: Array.from(new Array(DEFAULT_DAYS), (val, index) => index + 1),
            months: MONTHS,
            selectedYear: MIN_YEAR,
            selectedMonth: 1,
            selectedDay: 1,
            yearWasChanged: false,
            monthWasChanged: false,
            dayWasChanged: false,
            isUnderage: false
        };
    }

    containerProps = () => {
        const { getDate, errorMessage } = this.props;

        const {
            dayWasChanged,
            days,
            monthWasChanged,
            months,
            selectedDay,
            selectedMonth,
            selectedYear,
            yearWasChanged,
            years,
            isUnderage
        } = this.state;

        return {
            getDate,
            errorMessage,
            dayWasChanged,
            days,
            monthWasChanged,
            months,
            selectedDay,
            selectedMonth,
            selectedYear,
            yearWasChanged,
            years,
            isUnderage
        };
    };

    isUnderage() {
        const {
            selectedDay,
            selectedMonth,
            selectedYear,
            yearWasChanged,
            monthWasChanged,
            dayWasChanged
        } = this.state;
        const { validation } = this.props;

        if (!yearWasChanged || !monthWasChanged || !dayWasChanged || !validation.includes(AGE_VALIDATION)) {
            return false;
        }

        const today = new Date();
        const age = today.getFullYear() - selectedYear;

        if (today.getMonth() + 1 < selectedMonth
            || (today.getMonth() + 1 === selectedMonth && today.getDate() < selectedDay)) {
            if (age - 1 < AGE_LIMIT) {
                this.setState({ isUnderage: true });

                return true;
            }
        } else if (age < AGE_LIMIT) {
            this.setState({ isUnderage: true });

            return true;
        }

        this.setState({ isUnderage: false });

        return false;
    }

    checkIfLeapYear(year) {
        // eslint-disable-next-line no-magic-numbers
        const isLeapCheckStages = [400, 100, 4];

        if (year % isLeapCheckStages[0] === 0) {
            return true;
        }

        if (year % isLeapCheckStages[1] === 0) {
            return false;
        }

        return year % isLeapCheckStages[2] === 0;
    }

    async changeIfLeapYear(year) {
        await this.setState({
            selectedYear: parseInt(year, 10),
            yearWasChanged: true
        });

        await this.changeDays(null);
        this.isUnderage();
    }

    setDays(days) {
        this.setState({
            days: Array.from(new Array(days), (val, index) => index + 1)
        });
    }

    changeDaysState() {
        const { selectedYear, selectedMonth } = this.state;
        const isLeapYear = this.checkIfLeapYear(selectedYear);

        if (MONTHS_WITH_30_DAYS.includes(selectedMonth)) {
            this.setDays(DAYS_30);
        } else if (MONTHS_WITH_31_DAYS.includes(selectedMonth)) {
            this.setDays(DEFAULT_DAYS);
        } else if (isLeapYear) {
            this.setDays(FEB_LEAP);
        } else {
            this.setDays(FEB_NORM);
        }
    }

    async getDay(day) {
        await this.setState({
            selectedDay: parseInt(day, 10),
            dayWasChanged: true
        });

        this.isUnderage();
    }

    async changeDays(month) {
        if (month) {
            await this.setState({
                selectedMonth: parseInt(month, 10),
                monthWasChanged: true
            });

            this.changeDaysState();
        }

        this.isUnderage();
    }

    render() {
        return (
            <DatePicker
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default (DatePickerContainer);
