/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from 'Route/UrlRewrites/UrlRewrites.config';

export const PAGE_TYPE_DEFAULT = 'default';
export const PAGE_TYPE_PRODUCT = 'product';
export const PAGE_TYPE_CATEGORY = 'category';
export const PAGE_TYPE_CMS_PAGE = 'cms-page';
export const PAGE_TYPE_NOTFOUND = 'notfound';

/**
 * Checkout Container plugin
 */
export class UrlRewritesComponentPlugin {
    renderContent = (args, callback, instance) => {
        const { type } = instance.props;

        switch (type) {
        case TYPE_PRODUCT:
            window.pageType = PAGE_TYPE_PRODUCT;
            break;
        case TYPE_CMS_PAGE:
            window.pageType = PAGE_TYPE_CMS_PAGE;
            break;
        case TYPE_CATEGORY:
            window.pageType = PAGE_TYPE_CATEGORY;
            break;
        case TYPE_NOTFOUND:
            window.pageType = PAGE_TYPE_NOTFOUND;
            break;
        default:
            window.pageType = PAGE_TYPE_DEFAULT;
        }

        return callback(...args);
    };
}

const {
    renderContent
} = new UrlRewritesComponentPlugin();

export const config = {
    'Scandipwa/Route/UrlRewrites/Component': {
        'member-function': {
            renderContent
        }
    }
};

export default config;
