/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CART_TOTALS }
from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase';

import GroovinPixels, {
    DEFAULT_CHECKOUT_STEP,
    DEFAULT_USER_ID,
    GROOVIN_PIXELS_CART
} from '../util/GroovinPixels';
import Product from '../util/Product';

/**
 * Checkout container plugin
 */
class CartPageContainerPlugin {
    aroundComponentDidMount = (args, callback = () => {}) => {
        const { items = [] } = BrowserDatabase.getItem(CART_TOTALS) || {};

        if (items) {
            const categories = items.map(({ product: { categories } }) => categories && Product.getProductCategoryIds(categories));

            const params = {
                sku: Product.getProductsData(items, 'sku'),
                idcategory: categories,
                uid: DEFAULT_USER_ID,
                cart: DEFAULT_CHECKOUT_STEP
            };

            GroovinPixels.updateGroovinPixels(params, GROOVIN_PIXELS_CART);
        } else {
            GroovinPixels.updateGroovinPixels({}, GROOVIN_PIXELS_CART);
        }

        return callback(...args);
    };

    aroundComponentWillUnmount = (args, callback = () => {}) => {
        GroovinPixels.removeGroovinPixels(GROOVIN_PIXELS_CART);

        return callback(...args);
    };
}

const {
    aroundComponentDidMount,
    aroundComponentWillUnmount
} = new CartPageContainerPlugin();

const config = {
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount: aroundComponentDidMount,
            componentWillUnmount: aroundComponentWillUnmount
        }
    }
};

export default config;
