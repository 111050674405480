/* eslint-disable react/jsx-indent-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * Field Component plugin
 */
export class FieldComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        setInputName: PropTypes.func.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        inputs: PropTypes.object.isRequired,
        inputName: PropTypes.string.isRequired,
        isEnterPressed: PropTypes.bool.isRequired
    });

    componentDidUpdate = (args, callback, instance) => {
        const { id, inputs = {} } = instance.props;
        const [prevProps] = args;
        const { inputs: prevInputs } = prevProps;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen && inputs[id] !== prevInputs[id]) {
            instance.setState({ inputData: inputs[id] });
        }

        return callback(...args);
    };

    renderDefaultInput = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        const {
            inputs = {},
            attr,
            attr: { defaultValue = '', id },
            events,
            fieldRef,
            isDisabled,
            setRef,
            type,
            isQuestionMarkNeeded
        } = instance.props;

        const isFilled = fieldRef?.value || defaultValue !== '';

        const { isToolTipVisible } = instance.state;

        return (
            <>
            <input
              ref={ (elem) => setRef(elem) }
              block="FieldInput"
              elem="Input"
              mods={ {
                  isFilled: !!isFilled
              } }
              disabled={ isDisabled }
              type={ type }
              onClick={ () => this.onFocus(instance) }
              onChange
              value={ inputs[id] || '' }
              { ...attr }
              { ...events }
            />
            { isQuestionMarkNeeded
            && (
                <div
                    block="FieldInput"
                    elem="QuestionIconWrapper"
                    onMouseEnter={ () => {
                        instance.setState({ isToolTipVisible: true });
                    } }
                    onMouseLeave={ () => {
                        instance.setState({ isToolTipVisible: false });
                    } }
                >
                    <div
                    block="Icon"
                    elem="Question"
                    />
                    <div block="FieldInput" elem="QuestionIconText" mods={ { isToolTipVisible } }>
                        { ` ${__('We`ll send your order confirmation there')}.` }
                    </div>
                </div>
            ) }
            </>
        );
    };

    onFocus = (instance) => {
        const { attr: { id }, setInputName, setIsKeyboardOpened } = instance.props;

        setIsKeyboardOpened(true);
        setInputName(id);
    };
}

const {
    propTypes,
    componentDidUpdate,
    renderDefaultInput
} = new FieldComponentPlugin();

export const config = {
    'Scandipwa/Component/Field/Component': {
        'member-function': {
            componentDidUpdate,
            renderDefaultInput
        },
        'static-member': {
            propTypes
        }
    }
};

export default config;
