/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

export const COUPON_CODE_INPUT_ID = 'couponCode';

/**
 * CartCoupon Component plugin
 */
export class CartCouponComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        inputs: PropTypes.object.isRequired,
        setIsKeyboardOpened: PropTypes.func.isRequired,
        setInputName: PropTypes.func.isRequired
    });

    getDerivedStateFromProps = (args, _originalMember) => {
        const [props, state] = args;
        const { enteredCouponCode } = state;
        const { inputs } = props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return null;
        }

        const newCouponCode = inputs ? inputs[COUPON_CODE_INPUT_ID] : '';

        if (enteredCouponCode !== newCouponCode) {
            return {
                enteredCouponCode: newCouponCode
            };
        }

        return null;
    };

    onInputClick = (instance) => {
        const { setInputName, setIsKeyboardOpened } = instance.props;

        setIsKeyboardOpened(true);
        setInputName(COUPON_CODE_INPUT_ID);
    };

    renderApplyCoupon = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        const { enteredCouponCode } = instance.state;

        return (
            <>
                <Field
                  type={ FIELD_TYPE.text }
                  id={ COUPON_CODE_INPUT_ID }
                  attr={ {
                      id: COUPON_CODE_INPUT_ID,
                      name: 'couponCode',
                      defaultValue: enteredCouponCode || '',
                      placeholder: __('Enter code (maximum one)'),
                      'aria-label': __('Enter code (maximum one)')
                  } }
                  events={ {
                      onChange: instance.handleCouponCodeChange
                  } }
                  validateOn={ ['onChange'] }
                  mix={ { block: 'CartCoupon', elem: 'Input' } }
                />
                <button
                  block="CartCoupon"
                  elem="Button"
                  type={ FIELD_TYPE.button }
                  mix={ { block: 'Button' } }
                  disabled={ !enteredCouponCode }
                  onClick={ instance.handleApplyCoupon }
                >
                    { __('Apply') }
                </button>
            </>
        );
    };
}

const {
    renderApplyCoupon,
    propTypes,
    getDerivedStateFromProps
} = new CartCouponComponentPlugin();

export const config = {
    'Component/CartCoupon/Component': {
        'member-function': {
            renderApplyCoupon
        },
        'static-member': {
            propTypes,
            getDerivedStateFromProps
        }
    }
};

export default config;
