/* eslint-disable react/no-unknown-property */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { connect } from 'react-redux';

import {
    CartItemPriceContainer as SourceCartItemPriceContainer,
    mapDispatchToProps,
    mapStateToProps
} from 'SourceComponent/CartItemPrice/CartItemPrice.container';

/** @namespace Scandipwa/Component/CartItemPrice/Container */
export class CartItemPriceContainer extends SourceCartItemPriceContainer {
    containerProps() {
        const {
            discount_amount,
            regularPriceValue,
            finalProductPrice,
            isDiscounted,
            row_total,
            quantity,
            currency_code,
            mix
        } = this.props;

        return {
            discount_amount,
            regularPriceValue,
            finalProductPrice,
            isDiscounted,
            row_total,
            quantity,
            currency_code,
            mix
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemPriceContainer);
