/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import Link from 'Component/Link';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import {
    ORDER_ID_KEY,
    PAYMENT_METHOD_CODE,
    PAYMENT_TOTALS
} from 'Route/Checkout/Checkout.config';
import {
    CheckoutSuccess as SourceCheckoutSuccess
} from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { deleteCartId } from 'Util/Cart';

import './CheckoutSuccess.extended.style';

/** @namespace Scandipwa/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    componentDidMount() {}

    componentWillUnmount() {
        // Just to attach plugins
    }

    resetCart() {
        const { createGuestEmptyCart } = this.props;
        deleteCartId();
        BrowserDatabase.deleteItem(PAYMENT_METHOD_CODE);
        BrowserDatabase.deleteItem(PAYMENT_TOTALS);
        BrowserDatabase.deleteItem(CART_TOTALS);
        BrowserDatabase.deleteItem(ORDER_ID_KEY);
        createGuestEmptyCart();
    }

    renderShippingAddress() {
        const { shippingAddress } = this.props;

        const formattedAddress = { ...shippingAddress };

        if (!formattedAddress.region && formattedAddress.region_id) {
            formattedAddress.region = { region_id: shippingAddress.region_id };
        }

        if (!formattedAddress.region && !formattedAddress.region_id) {
            return null;
        }

        return (
            <div block="CheckoutSuccess" elem="ShippingAddress">
                <h3>{ __('Shipping Address') }</h3>
                <MyAccountAddressTable
                  address={ formattedAddress }
                  mix={ { block: 'CheckoutSuccess', elem: 'ShippingAddressTable' } }
                />
            </div>
        );
    }

    renderButtons() {
        return (
            <div block="CheckoutSuccess" elem="Buttons">
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Link
                      block="Button"
                      elem="Checkout"
                      mods={ { withArrow: true } }
                      to="/"
                      onClick={ () => {
                          this.resetCart();
                      } }
                    >
                        { __('Continue shopping') }
                    </Link>
                </div>
                { this.renderCreateAccountButton() }
            </div>
        );
    }

    renderCreateAccountButton() {
        const { isSignedIn = false } = this.props;

        const accountButtonsText = isSignedIn ? __('My account') : __('Create an account');

        return (
            <div
              block="CheckoutSuccess"
              elem="ButtonWrapper"
              mods={ { isCreateAccountButton: true } }
            >
                <Link
                  block="Button"
                  elem="Checkout"
                  mods={ { withArrow: true } }
                  to="/customer/account/"
                  onClick={ () => {
                      this.resetCart();
                  } }
                >
                    { accountButtonsText }
                </Link>
            </div>
        );
    }

    render() {
        const { orderID } = this.props;

        return (
            <div block="CheckoutSuccess">
                <p>{ __('Thank you for your purchase at Puma Mexico.') }</p>
                <h3>
                    { __(
                        'The number of your order is: %s',
                        // We should rely only on prop, however PayPal still uses the browser storage
                        orderID || BrowserDatabase.getItem(ORDER_ID_KEY) || '-'
                    ) }
                </h3>
                <p>
                    { /* eslint-disable-next-line max-len */ }
                    { __('You will get the first email with the order`s details and the email later once your order is shipped.') }
                </p>
                { this.renderButtons() }
                { this.renderShippingAddress() }
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
