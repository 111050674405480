/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_FORTER_FIRST_SIX, UPDATE_FORTER_LAST_FOUR } from '../../store/Forter.action';

const getInitialState = (args, callback) => (
    {
        ...callback(...args),
        firstSix: '',
        lastFour: ''
    }
);

const addForterToCheckoutReducer = (args, callback) => {
    const [state, action] = args;

    switch (action.type) {
    case UPDATE_FORTER_FIRST_SIX:
        const {
            firstSix
        } = action;

        return {
            ...state,
            firstSix
        };
    case UPDATE_FORTER_LAST_FOUR:
        const {
            lastFour
        } = action;

        return {
            ...state,
            lastFour
        };
    default:
        return callback(...args);
    }
};

export default {
    'Scandipwa/Store/Checkout/Reducer/getInitialState': {
        function: getInitialState
    },
    'Scandipwa/Store/Checkout/Reducer/CheckoutReducer': {
        function: addForterToCheckoutReducer
    }
};
