/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import ExpandableContent from 'Component/ExpandableContent';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import ProductConfigurableAttributeDropdown from 'Component/ProductConfigurableAttributeDropdown';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductConfigurableAttributes as SourceComponent }
from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';
import { ProductType } from 'Type/ProductList.type';

import './ProductConfigurableAttributes.extended.style';

/** @namespace Scandipwa/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends SourceComponent {
    static propTypes = {
        ...SourceComponent.propTypes,
        isLabelInner: PropTypes.bool.isRequired,
        product: ProductType.isRequired,
        isDropdown: PropTypes.bool.isRequired
    };

    static defaultProps = {
        ...super.defaultProps,
        attributeValidation: PropTypes.object
    };

    renderPlaceholders() {
        const { numberOfPlaceholders, isContentExpanded } = this.props;

        return numberOfPlaceholders.map((length, i) => (
            <ExpandableContent
                // eslint-disable-next-line react/no-array-index-key
              key={ i }
              mix={ {
                  block: 'ProductConfigurableAttributes',
                  elem: 'Expandable'
              } }
              isContentExpanded={ isContentExpanded }
            >
                <div
                    // eslint-disable-next-line react/no-array-index-key
                  key={ i }
                  block="ProductConfigurableAttributes"
                  elem="SwatchList"
                >
                    { Array.from({ length }, (_, i) => (
                        <li
                            // eslint-disable-next-line react/no-array-index-key
                          key={ i }
                          block="ProductConfigurableAttributes"
                          elem="ListItem"
                        >
                            <TextPlaceholder length="block" />
                        </li>
                    )) }
                </div>
            </ExpandableContent>
        ));
    }

    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            getLink,
            isSelected,
            product,
            parameters
        } = this.props;

        const { attribute_value } = attribute;

        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ getIsConfigurableAttributeAvailable(attribute) }
              onClick={ handleOptionClick }
              getLink={ getLink }
              product={ product }
              parameters={ parameters }
            />
        );
    }

    renderConfigurableAttributes() {
        const {
            configurable_options,
            isContentExpanded,
            getSubHeading,
            isDropdown
        } = this.props;

        return Object.values(configurable_options).map((option) => {
            const {
                attribute_label,
                attribute_code,
                attribute_options
            } = option;
            const hasOptions = Object.values(attribute_options).length;

            const [{ swatch_data }] = hasOptions ? Object.values(attribute_options) : [{}];
            const isSwatch = (!swatch_data && attribute_code === 'color')
                || (!!swatch_data && attribute_code !== 'category_id');

            return (
                <ExpandableContent
                  key={ attribute_code }
                  heading={ attribute_label }
                  subHeading={ getSubHeading(option) }
                  mix={ {
                      block: 'ProductConfigurableAttributes',
                      elem: 'Expandable'
                  } }
                  isContentExpanded={ isContentExpanded }
                >
                    { isSwatch && !isDropdown ? this.renderSwatch(option) : this.renderDropdown(option) }
                </ExpandableContent>
            );
        });
    }

    renderDropdown(option) {
        const {
            updateConfigurableVariant,
            getIsConfigurableAttributeAvailable,
            parameters,
            isLabelInner,
            innerLabel,
            attribute_label,
            attributeValidation
        } = this.props;

        const attributeInnerLabel = (attribute_label && attribute_label.innerLabel) ? attribute_label.innerLabel : '';
        const label = innerLabel || attributeInnerLabel;

        return (
            <ProductConfigurableAttributeDropdown
              option={ option }
              updateConfigurableVariant={ updateConfigurableVariant }
              getIsConfigurableAttributeAvailable={ getIsConfigurableAttributeAvailable }
              parameters={ parameters }
              isLabelInner={ isLabelInner }
              innerLabel={ label }
              attributeValidation={ attributeValidation }
            />
        );
    }
}

export default ProductConfigurableAttributesComponent;
