/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { AMOUNT_PLACEHOLDER } from './PromptFreeShippingMessage.config';

import './PromptFreeShippingMessage.style.scss';

/**
 * Prompt free shipping message
 * @class PromptFreeShippingMessageComponent
 * @namespace Scandipwa/Component/PromptFreeShippingMessage/Component */
export class PromptFreeShippingMessageComponent extends PureComponent {
    static propTypes = {
        promptedSum: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
        messageStyle: PropTypes.string.isRequired
    };

    getPromptMessage() {
        const { promptedSum, message, messageStyle } = this.props;

        const style = messageStyle ? JSON.parse(messageStyle) : {};

        if (!promptedSum) {
            return null;
        }

        return (
            <span block="PromptFreeShippingMessage" elem="Message" style={ style }>
                { message ? this.prepareMessage(promptedSum) : __('Spend %s to receive free shipping', promptedSum) }
            </span>
        );
    }

    prepareMessage(promptedSum) {
        const { message } = this.props;

        return message.replace(AMOUNT_PLACEHOLDER, promptedSum);
    }

    render() {
        return (
            <div block="PromptFreeShippingMessage">
                { this.getPromptMessage() }
            </div>
        );
    }
}

export default PromptFreeShippingMessageComponent;
