/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { connect } from 'react-redux';

import FIELD_TYPE from 'Component/Field/Field.config';
import {
    FormContainer as SourceFormContainer
} from 'SourceComponent/Form/Form.container';
import getFieldsData from 'Util/Form/Extract';
import { validateGroup } from 'Util/Validator';

/** @namespace Scandipwa/Component/Form/Container */
export class FormContainer extends SourceFormContainer {
    async onSubmit(e) {
        e.preventDefault();
        // added e.stopPropagation() to prevent situation, when submit of one form calls the submit even of another form
        e.stopPropagation();

        const {
            onSubmit,
            onError,
            returnAsObject = false,
            validationRule
        } = this.props;

        const fields = getFieldsData(
            this.formRef, false, [FIELD_TYPE.numberWithControls, FIELD_TYPE.button], returnAsObject
        );
        const isValid = validateGroup(this.formRef, validationRule);

        if (isValid !== true) {
            if (typeof onError === 'function') {
                onError(this.formRef, fields, isValid);
            }

            this.setState({ validationResponse: isValid });

            return;
        }

        if (typeof onSubmit === 'function') {
            onSubmit(this.formRef, fields);
        }
    }
}

// Forwarding the ref so the FieldForm can ref this element
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(null, {}, null, { forwardRef: true })(FormContainer);
