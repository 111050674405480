/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';

/** @namespace Bigscreen/Store/Notification/Action/showNotification */
export const showNotification = (
    msgType,
    msgText,
    msgDebug,
    showDebugInProduction = false,
    showHideCustomId = null
) => ({
    type: SHOW_NOTIFICATION,
    msgType,
    msgText,
    msgDebug,
    showDebugInProduction,
    showHideCustomId
});
