/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Product data helper
 * @namespace GroovinPixels/Util/Product/Index
 */
export class Product {
    getProductsData(items, field) {
        return items.reduce((acc, item) => {
            if (item[field]) {
                acc.push(item[field]);
            }

            return acc;
        }, []);
    }

    getProductCategoryIds(categories) {
        return categories.reduce((acc, { id }) => {
            if (id) {
                acc.push(id);
            }

            return acc;
        }, []);
    }
}

export default new Product();
