/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { setInputName, setIsKeyboardOpened } from '../../../store/Keyboard';

/**
 * SearchField Container plugin
 */
export class SearchFieldContainerPlugin {
    mapStateToProps = (args, callback, _instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            inputs: state.KeyboardReducer.inputs,
            inputName: state.KeyboardReducer.inputName,
            isEnterPressed: state.KeyboardReducer.isEnterPressed,
            isKeyboardClicked: state.KeyboardReducer.isKeyboardClicked,
            isKeyboardOpened: state.KeyboardReducer.isKeyboardOpened
        };
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            setInputName: (inputName) => dispatch(setInputName(inputName)),
            setIsKeyboardOpened: (isKeyboardOpened) => dispatch(setIsKeyboardOpened(isKeyboardOpened))
        };
    };
}

const {
    mapStateToProps,
    mapDispatchToProps
} = new SearchFieldContainerPlugin();

export const config = {
    'Scandipwa/Component/SearchField/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/SearchField/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};

export default config;
