/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { hideActiveOverlay, showSubscribePopup, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import { setInputs } from '../../../store/Keyboard';

/**
 * SubscriptionPopup Container plugin
 */
export class SubscriptionPopupContainerPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        setInputs: PropTypes.func.isRequired
    });

    mapDispatchToProps = (args, callback, _instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            setInputs: (input) => dispatch(setInputs(input)),
            hideActiveOverlay: () => dispatch(hideActiveOverlay()),
            toggleSubscribePopup: (value) => dispatch(showSubscribePopup(value)),
            toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key))
        };
    };

    mapStateToProps = (args, callback, _instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            activeOverlay: state.OverlayReducer.activeOverlay,
            showSubscribePopup: state.OverlayReducer.showSubscribePopup
        };
    };

    clearInputs() {
        const { setInputs } = this.props;

        setInputs({});
    }

    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        clearInputs: this.clearInputs.bind(instance)
    });

    containerProps = (args, callback, instance) => {
        const {
            setInputs,
            hideActiveOverlay,
            toggleSubscribePopup,
            activeOverlay,
            showSubscribePopup,
            toggleOverlayByKey
        } = instance.props;

        return {
            ...callback(...args),
            setInputs,
            hideActiveOverlay,
            toggleSubscribePopup,
            activeOverlay,
            showSubscribePopup,
            toggleOverlayByKey
        };
    };
}

const {
    propTypes,
    mapDispatchToProps,
    mapStateToProps,
    containerFunctions,
    containerProps
} = new SubscriptionPopupContainerPlugin();

export const config = {
    'Scandipwa/Component/SubscriptionPopup/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Scandipwa/Component/SubscriptionPopup/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/SubscriptionPopup/Container': {
        'member-property': {
            containerFunctions
        },
        'static-member': {
            propTypes
        },
        'member-function': {
            containerProps
        }
    }
};

export default config;
