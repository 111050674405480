/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AdyenCheckoutContainer from '../../../../../../adyen-payments/src/component/AdyenCheckout/AdyenCheckout.container';

/**
 * Overridden function to add the appendDataToPaymentMethodData prop
 */
/** @namespace Fortergraphql/Scandipwa/App/Plugin/Component/CheckoutPayments/Component */
export class CheckoutPaymentsComponent {
    renderAdyenCCMethod = (args, callback, instance) => {
        const {
            setOrderButtonEnableStatus,
            setCheckoutContainerLoading,
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep,
            appendDataToPaymentMethodData
        } = args[0];

        return (
            <AdyenCheckoutContainer
              code="card"
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
              setCheckoutContainerLoading={ setCheckoutContainerLoading }
              setCheckoutContainerOrderId={ setCheckoutContainerOrderId }
              setCheckoutContainerCheckoutStep={ setCheckoutContainerCheckoutStep }
              appendDataToPaymentMethodData={ appendDataToPaymentMethodData }
            />
        );
    };

    /**
     * Overridden to pass the props to the AdyenCheckout component, so they'll be available above
     * TODO: This should be matching theme changes!
     */
    renderSelectedPayment = (args, callback, instance) => {
        const { selectedPaymentCode } = instance.props;
        const render = instance.paymentRenderMap[selectedPaymentCode];

        if (!render) {
            return null;
        }

        return render(instance.props);
    };
}

export const { renderAdyenCCMethod, renderSelectedPayment } = new CheckoutPaymentsComponent();

export default {
    'Component/CheckoutPayments/Component': {
        'member-function': {
            renderSelectedPayment
        }
    },
    'AdyenPayments/Scandipwa/Plugin/Component/CheckoutPayments/Component': {
        'member-function': {
            renderAdyenCCMethod
        }
    }
};
