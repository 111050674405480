/* eslint-disable quote-props,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { PDP_DATA_OBJECT }
from '../../component/GoogleTagManager/events/PageDataEvent/Handlers/ProductPageDataEvent.handler';
import Event, { EVENT_GTM_PRODUCT_DETAIL, EVENT_GTM_VIEW_ITEM_GA4 } from '../../util/Event';

/** ProductPage */
export const _gtmProductDetail = (instance) => {
    const { product, location: { pathname }, configurableVariantIndex } = instance.props;

    if (product && product.variants && product.attributes) {
        Event.dispatch(EVENT_GTM_VIEW_ITEM_GA4, {
            product: { ...product, configurableVariantIndex },
            pathname
        });

        Event.dispatch(EVENT_GTM_PRODUCT_DETAIL, {
            product: { ...product, configurableVariantIndex },
            pathname
        });
    }
};

export const componentDidMount = (args, callback, instance) => {
    const { areDetailsLoaded } = instance.props;

    if (areDetailsLoaded) {
        _gtmProductDetail(instance);
    }

    return callback(...args);
};

export const componentDidUpdate = (args, callback, instance) => {
    const [prevProps] = args;
    const shouldTriggerGtm = () => {
        const {
            areDetailsLoaded,
            location: { pathname }
        } = instance.props;

        const {
            areDetailsLoaded: prevAreDetailsLoaded,
            location: { pathname: prevPathname }
        } = prevProps;

        return areDetailsLoaded && (
            (areDetailsLoaded !== prevAreDetailsLoaded)
            || (pathname !== prevPathname)
        );
    };

    if (shouldTriggerGtm()) {
        _gtmProductDetail(instance);
    }

    return callback(...args);
};

export const componentWillUnmount = (args, callback) => {
    if (window.pageData) {
        delete window.pageData[PDP_DATA_OBJECT];
    }

    return callback(...args);
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            'componentDidMount': componentDidMount,
            'componentDidUpdate': componentDidUpdate,
            'componentWillUnmount': componentWillUnmount
        }
    }
};
