import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import ChooseLanguageSelector from './ChooseLanguageSelector.component';

/** @namespace Scandipwa/Component/ChooseLanguageSelector/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    storeName: state.ConfigReducer.store_name
});

/** @namespace Scandipwa/Component/ChooseLanguageSelector/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    toggleOverlayByKey: (key) => dispatch(toggleOverlayByKey(key))
});

/** @namespace Scandipwa/Component/ChooseLanguageSelector/Container */
export class ChooseLanguageSelectorContainer extends PureComponent {
    static propTypes = {
        toggleOverlayByKey: PropTypes.func.isRequired,
        closePrevActiveOverlay: PropTypes.func,
        storeName: PropTypes.string.isRequired,
        isFooter: PropTypes.bool
    };

    static defaultProps = {
        closePrevActiveOverlay: () => {},
        isFooter: false
    };

    render() {
        return (
            <ChooseLanguageSelector
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLanguageSelectorContainer);
