/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup/Popup.container';

import { THREE_DS2_DIV_ID, THREE_DS2_POPUP_ID } from '../../util/Adyen';

/** @namespace Scandiweb/AdyenPayments/Component/AdyenCheckout/Component */
export class AdyenCheckoutComponent extends PureComponent {
    static propTypes = {
        fieldRef: PropTypes.shape({
            current: PropTypes.instanceOf(Element)
        }).isRequired,
        onThreeDsPopupClose: PropTypes.func.isRequired,
        onThreeDsPopupHide: PropTypes.func.isRequired
    };

    render() {
        const { fieldRef, onThreeDsPopupClose, onThreeDsPopupHide } = this.props;

        return (
            <div className="adyenwrapper">
                <div ref={ fieldRef } />
                <div id={ THREE_DS2_POPUP_ID } />
                <Popup
                  id={ THREE_DS2_POPUP_ID }
                  mix={ { block: 'AdyenCC', elem: 'Popup' } }
                  onClose={ onThreeDsPopupClose }
                  onHide={ onThreeDsPopupHide }
                >
                    <div id={ THREE_DS2_DIV_ID } />
                </Popup>
            </div>
        );
    }
}

export default AdyenCheckoutComponent;
