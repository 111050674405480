/* eslint-disable spaced-comment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';

import { FieldGroupContainer as SourceFieldGroupContainer } from 'SourceComponent/FieldGroup/FieldGroup.container';
import { ChildrenType, ModsType, RefType } from 'Type/Common.type';
import { EventsType, FieldAttrType, ValidationRuleType } from 'Type/Field.type';

/**
 * Override to allow the addRequiredTag prop to be passed on to the component.
 *
 * Field Group
 * @class FieldGroupContainer
 * @namespace Scandipwa/Component/FieldGroup/Container */
export class FieldGroupContainer extends SourceFieldGroupContainer {
    static propTypes = {
        // Group attributes
        children: ChildrenType,
        attr: FieldAttrType,
        events: EventsType,
        elemRef: RefType,

        // Validation
        validationRule: ValidationRuleType,
        validateOn: PropTypes.arrayOf(PropTypes.string),
        showErrorAsLabel: PropTypes.bool,

        // Labels
        label: PropTypes.string,
        subLabel: PropTypes.string,
        addRequiredTag: PropTypes.bool,

        mods: ModsType
    };

    static defaultProps = {
        attr: {},
        events: {},
        validationRule: {},
        validateOn: [],
        showErrorAsLabel: true,
        label: '',
        subLabel: '',
        addRequiredTag: false,
        children: [],
        mods: {},
        elemRef: null
    };

    containerProps() {
        const {
            events,
            validateOn,
            children,
            attr,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            mods
        } = this.props;
        const { validate } = this.containerFunctions;
        const { validationResponse } = this.state;

        // Surrounds events with validation
        const newEvents = {};

        Object.keys(events).forEach((eventName) => {
            const { [eventName]: event } = events;

            newEvents[eventName] = this.surroundEvent.bind(this, event);
        });

        validateOn.forEach((eventName) => {
            const { [eventName]: baseEvent } = events;

            newEvents[eventName] = baseEvent ? this.validateOnEvent.bind(this, baseEvent) : validate;
        });

        return {
            validationResponse,
            children,
            attr,
            showErrorAsLabel,
            label,
            subLabel,
            addRequiredTag,
            mods,
            events: newEvents,
            setRef: this.setRef.bind(this)
        };
    }
}

export default FieldGroupContainer;
