/* eslint-disable react/no-unknown-property */
// import Loader from 'Component/Loader';
import {
    CheckoutPayments as SourceCheckoutPayments
} from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

/** @namespace Scandipwa/Component/CheckoutPayments/Component */
export class CheckoutPaymentsComponent extends SourceCheckoutPayments {
    renderContent() {
        // const { isAdyenLoaded } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <p>{ __('The error occurred during initializing payment methods. Please try again later!') }</p>
            );
        }

        return (
            <>
                { this.renderHeading() }
                { /* <Loader isLoading={ !isAdyenLoaded } /> */ }
                <ul block="CheckoutPayments" elem="Methods">
                    { this.renderPayments() }
                </ul>
                { this.renderSelectedPayment() }
            </>
        );
    }
}

export default CheckoutPaymentsComponent;
