/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import CartQuery from 'Query/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Bigscreen/Query/BigScreenExperience/Query */
export class BigScreenExperienceQuery {
    getUpdateSalesAssociateIdMutation(salesAssociateId, guestCartId) {
        const mutation = new Field('applySalesAssociateId')
            .addArgument('sales_associate_id', 'String!', salesAssociateId)
            .addField(CartQuery.getCartQuery(guestCartId));

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    getSetQrCodeScannedForQuoteMutation(guestCartId) {
        return new Field('setQrCodeScannedForQuote')
            .addArgument(...this.getGuestCartIdMandatoryArgument(guestCartId));
    }

    getIsQrCodeScannedForQuote(guestCartId) {
        return new Field('getQrCodeScannedForQuote')
            .addArgument(...this.getGuestCartIdMandatoryArgument(guestCartId));
    }

    getGuestCartIdMandatoryArgument(guestCartId) {
        return ['guestCartId', 'String!', guestCartId];
    }

    mergeCustomerAndGuestQuotes(customerToken, guestCartId) {
        return new Field('mergeCustomerAndGuestQuotes')
            .addArgument('customerToken', 'String!', customerToken)
            .addArgument('guestCartId', 'String!', guestCartId);
    }
}

export default new BigScreenExperienceQuery();
