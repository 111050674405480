/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { UPDATE_CART_IS_LOADED } from '../../../store/Cart/Cart.action';

/**
 * Cart Reducer plugin
 */
export class CartReducerPlugin {
    getInitialState = (args, callback, _instance) => (
        {
            ...callback(...args),
            isLoaded: false
        }
    );

    updateCartTotals = (args, callback, _instance) => {
        const [_, state] = args;

        const originalResult = callback(...args);

        return {
            ...state,
            ...originalResult
        };
    };

    CartReducer = (args, callback, _instance) => {
        const [state, action] = args;
        const { type, isLoaded } = action;

        if (type === UPDATE_CART_IS_LOADED) {
            return {
                ...state,
                isLoaded
            };
        }

        return callback(...args);
    };
}

const {
    getInitialState,
    updateCartTotals,
    CartReducer
} = new CartReducerPlugin();

export const config = {
    'Store/Cart/Reducer/getInitialState': {
        function: getInitialState
    },
    'Scandipwa/Gtm/Plugin/Purchase/Plugin/updateCartTotals': {
        function: updateCartTotals
    },
    'Store/Cart/Reducer': {
        function: CartReducer
    }
};

export default config;
