/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { showNotification } from 'Store/Notification/Notification.action';
import { fetchMutation } from 'Util/Request';

import BigScreenExperienceQuery from '../../../query/BigScreenExperience.query';
import { isCartLoaded } from '../../../store/Cart/Cart.action';

/**
 * Cart Dispatcher plugin
 */
export class CartDispatcherPlugin {
    createGuestEmptyCart = async (args, callback, instance) => {
        const [dispatch] = args;

        return callback(...args).then(
            /** @namespace BigScreenExperienceGraphQl/Scandipwa/App/Plugin/CartDispatcher/Plugin/CartDispatcherPlugin/callbackThen */
            (callbackReturnValue) => {
                dispatch(isCartLoaded(true));

                return callbackReturnValue;
            }
        );
    };

    applySalesAssociateToCart = (args, _callback, instance) => {
        const [dispatch, salesAssociateId] = args;

        const mutation = BigScreenExperienceQuery.getUpdateSalesAssociateIdMutation(salesAssociateId, instance._getCartId(dispatch));

        return fetchMutation(mutation).then(
            /** @namespace BigScreenExperienceGraphQl/Scandipwa/App/Plugin/CartDispatcher/Plugin/CartDispatcherPlugin/fetchMutationThen */
            ({ applySalesAssociateId: { cartData } }) => {
                if (salesAssociateId) {
                    dispatch(showNotification(
                        'success', __('Sales Associate ID "%s" has been applied!', salesAssociateId)
                    ));
                } else {
                    dispatch(showNotification('success', __('Sales Associate ID was removed!')));
                }

                const updatedCartData = {
                    ...cartData,
                    sales_associate_id: salesAssociateId
                };

                instance._updateCartData(updatedCartData, dispatch);
            },
            /** @namespace BigScreenExperienceGraphQl/Scandipwa/App/Plugin/CartDispatcher/Plugin/CartDispatcherPlugin/fetchMutationThen */
            (error) => dispatch(showNotification('error', error[0].message))
        );
    };
}

const {
    createGuestEmptyCart,
    applySalesAssociateToCart
} = new CartDispatcherPlugin();

export const config = {
    'Store/Cart/Dispatcher': {
        'member-function': {
            createGuestEmptyCart,
            applySalesAssociateToCart
        }
    }
};

export default config;
