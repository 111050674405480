/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getCartItems } from '../../../Util/Cart';
import { getCustomerEmail } from '../../../Util/Helper';
import { PAGE_TYPE_CATEGORY, PAGE_TYPE_PRODUCT } from '../../route/UrlRewrites/UrlRewritesComponent.plugin';

/**
 * Router Container plugin
 */
export class RouterContainerPlugin {
    mapStateToProps = (args, callback) => {
        const [state] = args;

        return {
            isSignedIn: state.MyAccountReducer.isSignedIn,
            ...callback(...args)
        };
    };

    __construct = (args, callback, instance) => {
        window.webExtendEvents = [];
        const email = getCustomerEmail();

        if (email && !window.webExtendEvents.includes('setEmail')) {
            // Identifying the visitor with email address.
            // Should run before the other commands
            ScarabQueue.push(['setEmail', email]);
            window.webExtendEvents.push('setEmail');
        }

        callback(...args);
    };

    componentDidUpdate = (args, callback, instance) => {
        const [, prevState] = args;
        const { currentLocation: prevLocation } = prevState;
        const { currentLocation } = instance.state;

        setTimeout(() => {
            const { pageType } = window;
            const isProductOrCategoryPage = [PAGE_TYPE_CATEGORY, PAGE_TYPE_PRODUCT].includes(pageType);

            // If page changed
            if (currentLocation !== prevLocation
                && !isProductOrCategoryPage
                && !currentLocation.match(/\/checkout\/success.*/)
                && !prevLocation.match(/\/checkout\/success.*/)
            ) {
                const email = getCustomerEmail();

                if (email && !window.webExtendEvents.includes('setEmail')) {
                    // Identifying the visitor with email address.
                    // Should run before the other commands
                    ScarabQueue.push(['setEmail', email]);
                    window.webExtendEvents.push('setEmail');
                }

                if (!window.webExtendEvents.includes('cart')) {
                    // Passing on visitor's cart contents to feed cart abandonment campaigns
                    ScarabQueue.push(['cart', getCartItems()]);
                    window.webExtendEvents.push('cart');
                }

                if (!window.webExtendEvents.includes('go') && window.webExtendEvents.length) {
                    ScarabQueue.push(['go']);
                    window.webExtendEvents.push('go');
                }

                window.webExtendEvents = []; // Resetting the list when page changes
            }
        }, 2000);

        callback(...args);
    };
}

const {
    __construct,
    componentDidUpdate,
    mapStateToProps
} = new RouterContainerPlugin();

export const config = {
    'Scandipwa/Component/Router/Container': {
        'member-function': {
            __construct,
            componentDidUpdate
        }
    },
    'Component/Router/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

export default config;
