/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { lazy } from 'react';

import CheckoutPayments from 'Component/CheckoutPayments';

export const CheckoutBilling = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "checkout-billing" */
    'Component/CheckoutBilling'
));

class CheckoutBillingComponent {
    propTypes = (originalMember) => ({
        ...originalMember,
        setCheckoutContainerOrderId: PropTypes.func.isRequired,
        setCheckoutContainerCheckoutStep: PropTypes.func.isRequired
    });

    /**
     * 1:1 copy of the original function, but with the addition of the setCheckoutContainerOrderId and setCheckoutContainerCheckoutStep props
     * TODO: If your theme makes changes to this render logic, it needs to be reflected here
     */
    renderPayments = (args, callback, instance) => {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        } = instance.props;

        if (!paymentMethods.length) {
            return null;
        }

        return (
            <CheckoutPayments
              setLoading={ setLoading }
              setDetailsStep={ setDetailsStep }
              paymentMethods={ paymentMethods }
              onPaymentMethodSelect={ onPaymentMethodSelect }
              setOrderButtonVisibility={ instance.setOrderButtonVisibility }
              billingAddress={ shippingAddress }
              setOrderButtonEnableStatus={ instance.setOrderButtonEnableStatus }
              setCheckoutContainerOrderId={ setCheckoutContainerOrderId }
              setCheckoutContainerCheckoutStep={ setCheckoutContainerCheckoutStep }
            />
        );
    };
}

const {
    propTypes,
    renderPayments
} = new CheckoutBillingComponent();

export default {
    'Component/CheckoutBilling/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            renderPayments
        }
    }
};
