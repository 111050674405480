/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * SubscriptionPopupForm Component plugin
 */
export class SubscriptionPopupFormComponentPlugin {
    getDerivedStateFromProps = (args) => {
        const { inputs = [] } = args[0]; // props
        const { email, confirmationEmail } = args[1]; // state

        if (!Object.keys(inputs).length) {
            return null;
        }

        const newEmail = inputs.email;
        const newConfirmationEmail = inputs.confirmationEmail;

        if (newEmail !== undefined && email !== newEmail) {
            return {
                email: newEmail || ''
            };
        }

        if (
            newConfirmationEmail !== undefined
            && confirmationEmail !== newConfirmationEmail
        ) {
            return {
                confirmationEmail: newConfirmationEmail
            };
        }

        return null;
    };

    onSubmitSuccess(args, callback, instance) {
        const { setInputs } = instance.props;
        const { isConfirmation, email, confirmationEmail } = instance.state;

        if (!isConfirmation) {
            if (email === confirmationEmail) {
                setInputs({});

                callback(...args);
            }
        }
    }

    onChange = (value, instance) => {
        instance.setState({ email: value });
    };

    onChange2 = (value, instance) => {
        instance.setState({ confirmationEmail: value, startedInputting: true });
    };

    renderForm = (args, callback, instance) => {
        const {
            subscriptionPopupHeading,
            subscriptionPopupCta,
            subscriptionPopupTerms,
            subscriptionPopupButton,
            inputs = {}
        } = instance.props;
        const {
            email = '',
            isTermsAndConditionsAccepted,
            confirmationEmail,
            startedInputting,
            inputAccepted
        } = instance.state;
        const errorMessage = instance.getErrorMessage(email, confirmationEmail);
        const disableSubmit = email.length === 0
            || !(email === confirmationEmail)
            || !isTermsAndConditionsAccepted
            || inputAccepted;

        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        return (
            <Form
              onSubmit={ instance.onSubmitSuccess }
            >
                <div block="Subscription" elem="FieldWrapper" mods={ { isValid: errorMessage === '' } }>
                    <div block="UpperTitle">
                        <p
                          block="Popup"
                          elem="Title"
                        >
                            { subscriptionPopupHeading }
                        </p>
                        <p
                          block="Popup"
                          elem="Text"
                        >
                            { subscriptionPopupCta }
                        </p>
                    </div>
                    <div block="Form">
                        <div block="Envelop">
                            <svg viewBox="0 0 53 53" fill="none" id="envelope" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.5 21.767V52h50V21.767m-50 0l9.302 5.316M1.5
                                    21.767l9.302-7.53m40.698
                                    7.53l-10.465 5.709M51.5 21.767l-10.533-8.14M10.802 27.084l15.117
                                    8.638 15.116-8.245m-30.233-.393V14.237m30.233 13.239V8.977h-6.088m-18.33
                                     6.976H35.22m-18.605 4.652h18.605m-18.605 4.65h18.605m-.274-16.278L25.92
                                     2 17.3 8.977m17.647 0H17.3m-6.498 5.26v-5.26H17.3"
                                  stroke="#fff"
                                  strokeWidth="2"
                                />
                            </svg>
                        </div>
                        <div block="Fields">
                            <Field
                              type={ FIELD_TYPE.email }
                              attr={ {
                                  id: 'subEmail',
                                  name: 'subEmail',
                                  defaultValue: email,
                                  autoComplete: 'off'
                              } }
                              events={ {
                                  onChange: this.onChange(inputs.subEmail, instance)
                              } }
                              validationRule={ {
                                  isRequired: true,
                                  inputType: VALIDATION_INPUT_TYPE.email
                              } }
                              validateOn={ ['onChange'] }
                              label={ __('Enter Your Email') }
                            />
                            <Field
                              type={ FIELD_TYPE.email }
                              attr={ {
                                  id: 'confirmationEmail',
                                  name: 'confirmationEmail',
                                  defaultValue: confirmationEmail
                              } }
                              events={ {
                                  onChange: this.onChange2(inputs.confirmationEmail, instance)
                              } }
                              validationRule={ {
                                  isRequired: true,
                                  inputType: VALIDATION_INPUT_TYPE.email,
                                  match: (value) => {
                                      const email = document.getElementById('subEmail');

                                      return value && email.value === value;
                                  },
                                  customErrorMessages: {
                                      onMatchFail: __('Emails do not match!')
                                  }
                              } }
                              validateOn={ ['onChange'] }
                              label={ __('Confirm your email') }
                            />
                        </div>
                    </div>
                </div>
                <div block="Subscription" elem="CustomError" mods={ { isVisible: startedInputting } }>
                    { errorMessage }
                </div>
                <div block="Subscription" elem="TermsAndConditionsContainer">
                    <label
                      block="Subscription"
                      elem="TACLabel"
                      htmlFor="subscriptionConditions"
                    >
                        { subscriptionPopupTerms }
                    </label>
                    <Field
                      type={ FIELD_TYPE.checkbox }
                      attr={ {
                          id: 'subscriptionConditions',
                          name: 'subscriptionConditions',
                          defaultChecked: isTermsAndConditionsAccepted
                      } }
                      events={ {
                          onChange: instance.setTACAccepted
                      } }
                      mix={ {
                          block: 'Subscription',
                          elem: 'TermsAndConditions',
                          mods: { isChecked: isTermsAndConditionsAccepted }
                      } }
                    />
                </div>
                <div block="Subscription" elem="SubmitContainer">
                    <button
                      type="submit"
                      disabled={ disableSubmit }
                      mix={ { block: 'Button', elem: 'AddToCart' } }
                    >
                        { subscriptionPopupButton }
                    </button>
                </div>
            </Form>
        );
    };
}

const {
    getDerivedStateFromProps,
    onSubmitSuccess,
    renderForm
} = new SubscriptionPopupFormComponentPlugin();

export default {
    'Scandipwa/Component/SubscriptionPopupForm/Component': {
        'static-member': {
            getDerivedStateFromProps
        },
        'member-function': {
            onSubmitSuccess,
            renderForm
        }
    }
};
