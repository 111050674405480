/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * Checkout Address Form plugin
 */
export class CheckoutAddressFormComponentPlugin {
    componentDidUpdate = (args, callback, instance) => {
        const { default_country, countries, address: { country_id } } = instance.props;
        const [prevProps] = args;
        const { default_country: prevDefaultCountry } = prevProps;

        if (default_country !== prevDefaultCountry) {
            const defaultCountry = country_id || default_country;
            const country = countries.find(({ id }) => id === defaultCountry);
            const { available_regions: availableRegions } = country || {};

            this.updateRegionDetails(instance, default_country, availableRegions);
        }

        return callback(...args);
    };

    updateRegionDetails = (instance, default_country, availableRegions) => {
        instance.setState({
            countryId: default_country,
            availableRegions
        });
    };
}

const {
    componentDidUpdate
} = new CheckoutAddressFormComponentPlugin();

export const config = {
    'Component/CheckoutAddressForm/Component': {
        'member-function': {
            componentDidUpdate
        }
    }
};

export default config;
