import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Scandipwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    code: state.ConfigReducer.code,
    country: state.ConfigReducer.default_country,
    og_image: state.MetaReducer.og_image,
    urlRewriteType: state.UrlRewritesReducer.urlRewrite.type
});

/** @namespace Scandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        ...SourceMetaContainer.propTypes,
        code: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        og_image: PropTypes.string,
        urlRewriteType: PropTypes.string
    };

    static defaultProps = {
        ...SourceMetaContainer.defaultProps,
        og_image: '',
        urlRewriteType: ''
    };

    containerProps() {
        const { urlRewriteType } = this.props;

        return {
            ...super.containerProps(),
            urlRewriteType
        };
    }

    _generateOgMetaFromMetadata(metadata, param = 'property') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }

    getOgImage() {
        const { og_image } = this.props;
        const default_img = window.publicPath === undefined ? '/assets/images/global/puma-og-logo.png'
            : `${window.location.origin}${window.publicPath}assets/images/global/puma-og-logo.png`;

        if (og_image === '') {
            return default_img;
        }

        return og_image || default_img;
    }

    _getMetadata() {
        const { code, country } = this.props;
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots()
        };

        const locale = `${code}_${country}`;

        const ogmeta = {
            'og:title': this._getTitle(),
            'og:description': this._getDescription(),
            'og:url': window.location.href,
            'og:locale': locale,
            'og:image': this.getOgImage()
        };

        return [...this._generateMetaFromMetadata(meta), ...this._generateOgMetaFromMetadata(ogmeta)];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
