/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getCartItems } from '../../../Util/Cart';
import { getCustomerEmail } from '../../../Util/Helper';

/**
 * CategoryPage Container plugin
 * @namespace Puma/EmarsysGraphQl/Plugin/Route/CategoryPage/Plugin
 */
export class CategoryPageContainerPlugin {
    updateBreadcrumbs = async (args, callback, instance) => {
        const result = await callback(...args);
        const { breadcrumbs, category: { id } } = instance.props;

        if (id && breadcrumbs.length) {
            let categoryPath = '';

            for (const { name } of breadcrumbs) {
                categoryPath = categoryPath && categoryPath !== name ? `${name} > ${categoryPath}` : name;
            }

            if (categoryPath) {
                const email = getCustomerEmail();
                if (email && !window.webExtendEvents.includes('setEmail')) {
                    // Should run before the other commands
                    ScarabQueue.push(['setEmail', email]);
                    window.webExtendEvents.push('setEmail');
                }

                if (!window.webExtendEvents.includes('category')) {
                    // Passing on the category path being visited.
                    // Must match the 'category' values listed in the Product Catalog
                    ScarabQueue.push(['category', categoryPath]);
                    window.webExtendEvents.push('category');
                }

                if (!window.webExtendEvents.includes('cart')) {
                    // Passing on visitor's cart contents to feed cart abandonment campaigns
                    ScarabQueue.push(['cart', getCartItems()]);
                    window.webExtendEvents.push('cart');
                }

                if (!window.webExtendEvents.includes('go') && window.webExtendEvents.length) {
                    ScarabQueue.push(['go']);
                    window.webExtendEvents.push('go');
                }

                window.webExtendEvents = []; // Resetting the list
            }
        }

        return result;
    };
}

const {
    updateBreadcrumbs
} = new CategoryPageContainerPlugin();

export const config = {
    'Route/CategoryPage/Container': {
        'member-function': {
            updateBreadcrumbs
        }
    }
};

export default config;
