/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export class ConfigQueryPlugin {
    getForterConfigData = (args, callback) => {
        const callbackData = callback(...args);

        if (!callbackData.includes('secure_base_url')) {
            callbackData.push('secure_base_url');
        }

        return [
            ...callbackData,
            'forter_is_enabled',
            'forter_site_id'
        ];
    };
}

export const { getForterConfigData } = new ConfigQueryPlugin();

export default {
    'Query/Config/Query': {
        'member-function': {
            _getStoreConfigFields: getForterConfigData
        }
    }
};
