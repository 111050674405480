/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import getStore from 'Util/Store';

import {
    ADYEN_CC_METHOD_CODE,
    ADYEN_HPP_METHOD_CODE,
    ADYEN_OXXO_METHOD_CODE
} from '../../../util/Adyen';

class CheckoutBillingContainer {
    propTypes = (originalMember) => ({
        ...originalMember,
        setCheckoutContainerOrderId: PropTypes.func.isRequired,
        setCheckoutContainerCheckoutStep: PropTypes.func.isRequired
    });

    containerProps = (args, callback, instance) => {
        const {
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        } = instance.props;

        return {
            ...callback(args),
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        };
    };

    addAdyenCCToPaymentData = (args, callback, instance) => {
        const { paymentMethod: code } = instance.state;

        if (code === ADYEN_CC_METHOD_CODE) {
            const { adyenDropinComponent } = window;

            if (!adyenDropinComponent) {
                // TODO: Show notification to panik
            }

            // Manually submit the payment as per https://docs.adyen.com/online-payments/build-your-integration/additional-use-cases/advanced-flow-integration?platform=Web&integration=Drop-in&version=5.39.1#configuring-drop-in under the "showPayButton" item
            adyenDropinComponent.submit();

            const {
                cardType: cc_type, data: stateData
            } = getStore().getState().CheckoutReducer.adyenStateData;
            const cartId = getStore().getState().CartReducer.cartTotals.id;

            const additionalData = {
                cartId,
                stateData: JSON.stringify(stateData),
                cc_type,
                guestEmail: '',
                combo_card_type: 'credit',
                number_of_installments: '',
                is_active_payment_token_enabler: false
            };

            return {
                code,
                additional_data: additionalData
            };
        }

        if (code === ADYEN_OXXO_METHOD_CODE) {
            const { adyenDropinComponent } = window;

            if (!adyenDropinComponent) {
                // TODO: Show notification to panik
            }

            // Manually submit the payment as per https://docs.adyen.com/online-payments/build-your-integration/additional-use-cases/advanced-flow-integration?platform=Web&integration=Drop-in&version=5.39.1#configuring-drop-in under the "showPayButton" item
            adyenDropinComponent.submit();

            const {
                data: stateData
            } = getStore().getState().CheckoutReducer.adyenStateData;
            const cartId = getStore().getState().CartReducer.cartTotals.id;

            const additionalData = {
                cartId,
                stateData: JSON.stringify(stateData),
                guestEmail: ''
            };

            return {
                code,
                additional_data: additionalData
            };
        }

        if (code === ADYEN_HPP_METHOD_CODE) {
            const stateData = JSON.stringify(getStore().getState().CheckoutReducer.adyenStateData.data);
            const cartId = getStore().getState().CartReducer.cartTotals.id;

            const additionalData = {
                cartId,
                stateData,
                guestEmail: '',
                brand_code: code
            };

            return {
                code,
                additional_data: additionalData
            };
        }

        return callback(...args);
    };
}

const { propTypes, containerProps, addAdyenCCToPaymentData } = new CheckoutBillingContainer();

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData: addAdyenCCToPaymentData,
            containerProps
        },
        'static-member': {
            propTypes
        }
    }
};
