/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import Image from 'Component/Image';
import Link from 'Component/Link';
import {
    ORDER_ID_KEY
} from 'Route/Checkout/Checkout.config';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import './CheckoutSuccess.extended.style';

class CheckoutSuccessComponent {
    propTypes = (originalMember) => ({
        ...originalMember,
        adyenActionData: PropTypes.object
    });

    renderOxxoData(instance) {
        const { adyenActionData: { expiresAt, reference, downloadUrl } = {} } = instance.props;

        if (!expiresAt || !reference) {
            return null;
        }

        return (
            <div block="CheckoutSuccess" elem="OxxoWrapper">
                <div>
                    <b>{ `${__('Reference Number')}: ` }</b>
                    <span>{ reference }</span>
                </div>
                <div>
                    <b>{ `${__('Expires at')}: ` }</b>
                    <span>{ expiresAt }</span>
                </div>
                <div block="CheckoutSuccess" elem="BarcodeWrapper">
                    <b>{ `${__('Barcode')}: ` }</b>
                    <Image
                      block="CheckoutSuccess"
                      elem="OxxoBarcode"
                      src={ `https://checkoutshopper-live.adyen.com/checkoutshopper/barcode.shtml?data=${reference}&barcodeType=BT_Code128C&fileType=png` }
                      alt={ __('oxxo barcode') }
                      ratio="custom"
                      height="40px"
                    />
                </div>
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Link
                      block="Button"
                      elem="Checkout"
                      mods={ { withArrow: true } }
                      to={ downloadUrl }
                      isOpenInNewTab
                    >
                            { __('Download voucher') }
                    </Link>
                </div>
            </div>
        );
    }

    render = (args, callback, instance) => {
        const { orderID, adyenActionData } = instance.props;

        if (adyenActionData) {
            const { type, paymentMethodType } = adyenActionData;

            if (type !== 'voucher' && paymentMethodType !== 'oxxo') {
                return callback(args);
            }
        } else {
            return callback(args);
        }

        return (
            <div block="CheckoutSuccess">
                <p>{ __('Thank you for your purchase at Puma Mexico.') }</p>
                <h3>
                    { __(
                        'The number of your order is: %s',
                        // We should rely only on prop, however PayPal still uses the browser storage
                        orderID || BrowserDatabase.getItem(ORDER_ID_KEY) || '-'
                    ) }
                </h3>
                { this.renderOxxoData(instance) }
                <p>
                    { /* eslint-disable-next-line max-len */ }
                    { __('You will get the first email with the order`s details and the email later once your order is shipped.') }
                </p>

                { instance.renderButtons() }
                { instance.renderShippingAddress() }
            </div>
        );
    };
}

const { propTypes, render } = new CheckoutSuccessComponent();

export default {
    'Component/CheckoutSuccess/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            render
        }
    }
};
