import PropTypes from 'prop-types';

import { Draggable as SourceDraggable } from 'SourceComponent/Draggable/Draggable.component';

/** @namespace Scandipwa/Component/Draggable/Component */
export class DraggableComponent extends SourceDraggable {
    static propTypes = {
        ...super.propTypes,
        isSliderOverlay: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isSliderOverlay: false
    };

    _handleDragStart = ({ clientX, clientY }) => {
        const { onDragStart, isSliderOverlay, isMobile } = this.props;

        if (onDragStart) {
            onDragStart();
        }

        // Slider overlays picture width is centered and 70% of window.innerWidth
        // so drag is disabled when cursor is on slider overlay image
        if (!isMobile && isSliderOverlay
            // eslint-disable-next-line no-magic-numbers
            && (clientX >= window.innerWidth * 0.15 && clientX <= window.innerWidth * 0.85)) {
            this.setState({
                isDragging: false
            });
        } else {
            this.setState({
                isDragging: true
            });
        }

        this.setState({
            originalX: clientX,
            originalY: clientY
        });
    };
}

export default DraggableComponent;
