import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import SubscriptionQuery from 'Query/Subscription.query';
import { fetchMutation } from 'Util/Request';

import SubscriptionPopupForm from './SubscriptionPopupForm.component';

/** @namespace Scandipwa/Component/SubscriptionPopupForm/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/SubscriptionPopupForm/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    subscriptionPopupHeading: state.ConfigReducer.subscriptionPopupHeading,
    subscriptionPopupCta: state.ConfigReducer.subscriptionPopupCta,
    subscriptionPopupTerms: state.ConfigReducer.subscriptionPopupTerms,
    subscriptionPopupButton: state.ConfigReducer.subscriptionPopupButton
});

/** @namespace Scandipwa/Component/SubscriptionPopupForm/Container */
export class SubscriptionPopupFormContainer extends PureComponent {
    static propTypes = {
        subscriptionPopupButton: PropTypes.string.isRequired,
        subscriptionPopupCta: PropTypes.string.isRequired,
        subscriptionPopupHeading: PropTypes.string.isRequired,
        subscriptionPopupTerms: PropTypes.string.isRequired
    };

    containerFunctions = {
        subscribe: this.subscribe.bind(this)
    };

    containerProps() {
        const {
            subscriptionPopupButton,
            subscriptionPopupCta,
            subscriptionPopupHeading,
            subscriptionPopupTerms
        } = this.props;

        return {
            subscriptionPopupButton,
            subscriptionPopupCta,
            subscriptionPopupHeading,
            subscriptionPopupTerms
        };
    }

    subscribe(email, response) {
        const requestField = SubscriptionQuery.getSubscriptionEmailQuery(email);

        fetchMutation(requestField).then(
            /** @namespace Scandipwa/Component/SubscriptionPopupForm/Container/SubscriptionPopupFormContainer/subscribe/fetchMutation/then */
            ({ addEmailToSubscription }) => {
                response(addEmailToSubscription);
            },
            /** @namespace Scandipwa/Component/SubscriptionPopupForm/Container/SubscriptionPopupFormContainer/subscribe/fetchMutation/then/catch */
            ([{ message }]) => {
                response(message);
            }
        );
    }

    render() {
        return (
            <SubscriptionPopupForm
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPopupFormContainer);
