/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateAdyenCheckoutActionData } from '../../../store/Adyen.action';

/**
 * Pass the action result down to the Success container so it can render the OXXO barcode
 *
 * ** THIS CLASS IS NOT AVAILABLE IN THE DEFAULT SPWA. IT SHOULD BE ADDED MANUALLY **
 *
 * @namespace AdyenPayments/Scandipwa/Plugin/Component/CheckoutPayments/Container
 */
class CheckoutSuccessContainer {
    mapStateToProps = (args, callback) => {
        const [state] = args;

        return {
            ...callback(...args),
            adyenActionData: state.CheckoutReducer.adyenActionData
        };
    };

    mapDispatchToProps = (args, callback) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            updateAdyenCheckoutActionData: (data) => dispatch(updateAdyenCheckoutActionData(data))
        };
    };

    /**
     * Reset the adyenActionData when the component unmounts to prevent it from showing old Oxxo data upon next checkout
     */
    componentWillUnmount = (args, callback, instance) => {
        const { updateAdyenCheckoutActionData } = instance.props;

        updateAdyenCheckoutActionData({});
    };
}

const { mapStateToProps, mapDispatchToProps, componentWillUnmount } = new CheckoutSuccessContainer();

export default {
    'Scandipwa/Component/CheckoutSuccess/Container/mapStateToProps': {
        function: mapStateToProps
    },
    'Scandipwa/Component/CheckoutSuccess/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    },
    'Component/CheckoutSuccess/Container': {
        'member-function': {
            componentWillUnmount
        }
    }
};
