/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const SET_IS_KEYBOARD_OPENED = 'SET_IS_KEYBOARD_OPENED';

/** @namespace Bigscreen/Store/Keyboard/Action/setIsKeyboardOpened */
export const setIsKeyboardOpened = (isKeyboardOpened) => ({
    type: SET_IS_KEYBOARD_OPENED,
    isKeyboardOpened
});

export const SET_INPUTS = 'SET_INPUTS';

/** @namespace Bigscreen/Store/Keyboard/Action/setInputs */
export const setInputs = (inputs) => ({
    type: SET_INPUTS,
    inputs
});

export const SET_INPUT_NAME = 'SET_INPUT_NAME';

/** @namespace Bigscreen/Store/Keyboard/Action/setInputName */
export const setInputName = (inputName) => ({
    type: SET_INPUT_NAME,
    inputName
});

export const SET_ENTER_PRESSED = 'SET_ENTER_PRESSED';

/** @namespace Bigscreen/Store/Keyboard/Action/setEnterPressed */
export const setEnterPressed = (isEnterPressed) => ({
    type: SET_ENTER_PRESSED,
    isEnterPressed
});

export const SET_KEYBOARD_CLICKED = 'SET_KEYBOARD_CLICKED';

/** @namespace Bigscreen/Store/Keyboard/Action/setIsKeyboardClicked */
export const setIsKeyboardClicked = (isKeyboardClicked) => ({
    type: SET_KEYBOARD_CLICKED,
    isKeyboardClicked
});
