/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import {
    NoMatchHandler as SourceNoMatchHandler
} from 'SourceRoute/NoMatchHandler/NoMatchHandler.component';
import { scrollToTop } from 'Util/Browser';

/** @namespace Scandipwa/Route/NoMatchHandler/Component */
export class NoMatchHandlerComponent extends SourceNoMatchHandler {
    static propTypes = {
        ...SourceNoMatchHandler.propTypes,
        isScrollEnabled: PropTypes.bool.isRequired
    };

    componentDidMount() {
        const { isScrollEnabled } = this.props;
        if (isScrollEnabled) {
            scrollToTop();
        }
    }

    componentDidUpdate(prevProps) {
        const { location: { pathname: newPathname }, isScrollEnabled } = this.props;
        const { location: { pathname } } = prevProps;

        if (newPathname !== pathname) {
            if (isScrollEnabled) {
                scrollToTop();
            }
            this.onRouteChanged();
        }
    }
}

export default NoMatchHandlerComponent;
