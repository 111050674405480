/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { createRef, PureComponent } from 'react';

import './DraggableElement.style.scss';

/** @namespace Bigscreen/Component/DraggableElement/Component */
export class DraggableElementComponent extends PureComponent {
    state = {
        diffX: 0,
        diffY: 0,
        dragging: false,
        styles: {
            // Default postion from top
            // eslint-disable-next-line no-magic-numbers
            top: 250,
            left: 0,
            position: 'fixed'
        }
    };

    draggableRef = createRef();

    handleTouchStart = ({ touches }) => {
        window.addEventListener('touchmove', this.handleTouchMove);
        window.addEventListener('touchend', this.handleTouchEnd);

        if (touches.length === 1) {
            this.dragStart(touches[0]);
        }
    };

    handleTouchMove = ({ touches }) => {
        if (touches.length === 1) {
            this.dragging(touches[0]);
        }
    };

    handleTouchEnd = () => {
        window.removeEventListener('touchmove', this.handleTouchMove);
        window.removeEventListener('touchend', this.handleTouchEnd);

        this.dragEnd();
    };

    dragStart = (e) => {
        const { screenX, screenY } = e;
        const { current: target } = this.draggableRef;

        this.setState({
            diffX: screenX - target.getBoundingClientRect().left,
            diffY: screenY - target.getBoundingClientRect().top + document.body.getBoundingClientRect().top,
            dragging: true
        });
    };

    handleMouseDown = (e) => {
        window.addEventListener('mousemove', this.dragging);
        window.addEventListener('mouseup', this.handleMouseUp);

        this.dragStart(e);
    };

    handleMouseUp = () => {
        window.removeEventListener('mousemove', this.dragging);
        window.removeEventListener('mouseup', this.handleMouseUp);

        this.dragEnd();
    };

    dragging = (e) => {
        const { dragging, diffX, diffY } = this.state;
        const { screenX, screenY } = e;

        if (dragging) {
            const left = screenX - diffX;
            const top = screenY - diffY;

            this.setState({
                styles: {
                    left,
                    top,
                    position: 'absolute'
                }
            });
        }
    };

    dragEnd = () => {
        this.setState({
            dragging: false
        });
    };

    handlePositionOnScroll = () => {
        const { styles: { left } } = this.state;
        const { current: target } = this.draggableRef;

        window.onscroll = (_event) => {
            this.setState({
                styles: {
                    top: target.getBoundingClientRect().top,
                    left,
                    position: 'fixed'
                }
            });
        };
    };

    render() {
        const { children } = this.props;
        const { styles } = this.state;

        this.handlePositionOnScroll();

        return (
            <div
              // eslint-disable-next-line react/no-unknown-property
              block="DraggableElement"
              ref={ this.draggableRef }
              style={ styles }
              onTouchStart={ this.handleTouchStart }
              onTouchEnd={ this.dragEnd }
              onMouseDown={ this.handleMouseDown }
              onMouseUp={ this.dragEnd }
            >
                { children }
            </div>
        );
    }
}

export default DraggableElementComponent;
