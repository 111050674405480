/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

import { getCartItems } from '../../../Util/Cart';
import { GUEST_EMAIL_KEY } from '../../../Util/Helper';

/**
 * SubscriptionPopupForm Container plugin
 */
export class SubscriptionPopupFormContainerPlugin {
    subscribe = (args, callback) => {
        const [email] = args;

        const result = callback(...args);

        BrowserDatabase.setItem(email, GUEST_EMAIL_KEY);

        if (!window.webExtendEvents.includes('setEmail')) {
            // Identifying the visitor with email address.
            // Should run before the other commands
            ScarabQueue.push(['setEmail', email]);
            window.webExtendEvents.push('setEmail');
        }

        if (!window.webExtendEvents.includes('cart')) {
            // Passing on visitor's cart contents to feed cart abandonment campaigns
            ScarabQueue.push(['cart', getCartItems()]);
            window.webExtendEvents.push('cart');
        }

        if (window.webExtendEvents.length) {
            // Firing the ScarabQueue. Should be the last call on the page.
            ScarabQueue.push(['go']);
            window.webExtendEvents = []; // Resetting the list after the 'go' command
        }

        return result;
    };
}

const {
    subscribe
} = new SubscriptionPopupFormContainerPlugin();

export const config = {
    'Scandipwa/Component/SubscriptionPopupForm/Container': {
        'member-function': {
            subscribe
        }
    }
};

export default config;
