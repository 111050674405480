/* eslint-disable max-len */
import { PureComponent } from 'react';

import './ProductCardPlaceholder.style';

/** @namespace Scandipwa/Component/ProductCardPlaceholder/Component */
export class ProductCardPlaceholderComponent extends PureComponent {
    static propTypes = {
    };

    render() {
        return (
            <div className="ProductCardPlaceholder ProductCard-Link">
                <figure className="ProductCard-Figure">
                    <div className="Image Image_ratio_custom Image_imageStatus_image_loading Image_isPlaceholder ProductCard-Picture ProductCard-Picture_isPlaceholder  Image-WidthFull Image-HeightFull" />
                </figure>
                <div className="ProductCard-Content ProductCard-Content_isThumbnail">
                    <ul className="ProductCard ProductCard-Slider ProductCard_isLoading ProductCard_isThumbnail">
                        <li className="ProductCard-ListItem">
                            <span className="TextPlaceholder TextPlaceholder_length_block " />
                        </li>
                        <li className="ProductCard-ListItem">
                            <span className="TextPlaceholder TextPlaceholder_length_block " />
                        </li>
                        <li className="ProductCard-ListItem">
                            <span className="TextPlaceholder TextPlaceholder_length_block " />
                        </li>
                    </ul>
                    <p className="ProductCard-Name ProductCard-Name_isPlaceholder">
                        <span className="TextPlaceholder TextPlaceholder_length_long " />
                    </p>
                    <span className="ProductCard-PricePlaceholder TextPlaceholder TextPlaceholder_length_long " />
                    <div className="ProductCard-AddToCart">
                        <span className="TextPlaceholder TextPlaceholder_length_short " />
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductCardPlaceholderComponent;
