/* eslint-disable react/prop-types */
import {
    Children,
    useMemo,
    useState
} from 'react';

/**
 * Renders the children on the next animation frame or idle period.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children to render.
 * @param {Function} props.placeholder - The placeholder function to call before rendering.
 * @returns {ReactNode} The rendered children.
 * @namespace Scandipwa/Util/Render/NextFrameRender/NextFrameRender
 */
export const NextFrameRender = ({ children, placeholder }) => {
    const [shouldRender, setShouldRender] = useState(false);

    const childrenArray = useMemo(() => Children.toArray(children), [
        children
    ]);

    if (shouldRender) {
        return childrenArray;
    }
    if (window.requestIdleCallback) {
        window.requestIdleCallback(() => {
            setShouldRender(true);
        }, { timeout: 100 });
    } else {
        window.requestAnimationFrame(() => {
            setShouldRender(true);
        });
    }

    return placeholder ? placeholder() : null;
};

NextFrameRender.displayName = 'NextFrameRender';

export default NextFrameRender;
