/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import { STOP_SLIDE_COUNT } from 'Component/AdditionalPictures/AdditionalPictures.config';
import Draggable from 'Component/Draggable';
import { ANIMATION_DURATION } from 'Component/Slider/Slider.config';
import { Slider as SourceSlider } from 'SourceComponent/Slider/Slider.component';
import { ChildrenType, MixType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import CSS from 'Util/CSS';

import {
    ACTIVE_SLIDE_PERCENT,
    SLIDE_TRANSFORM_CLICK_LIMIT,
    START_SLIDE_COUNT
} from './Slider.config';

import './Slider.extended.style';

/** @namespace Scandipwa/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    static propTypes = {
        showCrumbs: PropTypes.bool,
        showArrows: PropTypes.bool,
        activeImage: PropTypes.number,
        onActiveImageChange: PropTypes.func,
        mix: MixType,
        children: ChildrenType.isRequired,
        isInteractionDisabled: PropTypes.bool,
        device: DeviceType.isRequired,
        isPDPSlider: PropTypes.bool,
        isSliderOverlay: PropTypes.bool
    };

    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: () => {},
        showCrumbs: false,
        showArrows: false,
        isInteractionDisabled: false,
        mix: {},
        isPDPSlider: false,
        isSliderOverlay: false
    };

    __construct(props) {
        super.__construct(props);

        const { activeImage } = this.props;

        this.state = {
            prevActiveImage: activeImage
        };
    }

    updateWindowDimensions = this.updateWindowDimensions.bind(this);

    componentDidMount() {
        super.componentDidMount();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentDidUpdate(prevProps) {
        const { activeImage: prevActiveImage, isDesktop: prevIsDesktop } = prevProps;
        const { activeImage, isDesktop } = this.props;

        const sliderWidth = isDesktop !== prevIsDesktop ? this.draggableRef.current.offsetWidth : this.sliderWidth;
        const newTranslate = -activeImage * sliderWidth;
        const prevTranslate = -prevActiveImage * sliderWidth;

        this.updateDraggableRefStyles(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION), newTranslate);

        if (isDesktop !== prevIsDesktop) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ lastTranslateX: prevTranslate });

            this.sliderWidth = sliderWidth;
        }

        if (activeImage !== prevActiveImage) {
            this.updateDraggableRefStyles(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION), newTranslate);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        const sliderWidth = this.draggableRef.current.offsetWidth;
        this.sliderWidth = sliderWidth;
    }

    updateDraggableRefStyles(animationSpeed, translateX) {
        CSS.setVariable(
            this.draggableRef,
            'animation-speed',
            `${ animationSpeed }ms`
        );

        CSS.setVariable(
            this.draggableRef,
            'translateX',
            `${ translateX }px`
        );
    }

    calculateNextSlide(state) {
        const {
            translateX: translate,
            lastTranslateX: lastTranslate
        } = state;

        const { onActiveImageChange } = this.props;

        const slideSize = this.sliderWidth;

        const fullSliderSize = this.getFullSliderWidth();

        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = translate > lastTranslate;

        if (translate >= 0) {
            onActiveImageChange(0);
            return 0;
        }

        if (translate < -fullSliderSize) {
            const activeSlide = Math.round(fullSliderSize / -slideSize);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.ceil(activeSlidePosition);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.floor(activeSlidePosition);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide);

        return activeSlide;
    }

    getNextImage(btnType) {
        const { activeImage, children } = this.props;

        // This is possible during the load.
        if (!children) {
            return activeImage;
        }

        const childrenCount = children.reduce((acc, child) => {
            if (child && child.length) {
                return acc + child.length;
            }

            return acc;
        }, 0);

        if (btnType === 'left') {
            return !activeImage ? activeImage : activeImage - 1;
        }

        if (activeImage === childrenCount - 1) {
            return activeImage;
        }

        return activeImage + 1;
    }

    renderButton(btnType, slideCount = START_SLIDE_COUNT, lastImage = false) {
        const { onActiveImageChange, children, activeImage } = this.props;

        if (!children) {
            return null;
        }

        const image = this.getNextImage(btnType);
        const childrenCount = children.reduce((acc, child) => {
            if (child && (child.length || Object.keys(child).length > 0)) {
                return child.length ? acc + child.length : acc + 1;
            }

            return acc;
        }, 0);

        const noNextSlide = (btnType === 'left' && activeImage === 0)
            || (btnType === 'right' && (activeImage === childrenCount - 1 || childrenCount === 0));

        if (slideCount <= STOP_SLIDE_COUNT || lastImage) {
            return null;
        }

        return (
            <button
              block="Slider"
              elem="Arrow"
              mods={ { type: btnType, noNextSlide } }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => onActiveImageChange(image) }
            >
                <span>{ __('Change Slide') }</span>
            </button>
        );
    }

    handleDragEnd(state, callback) {
        const { onSliderClick } = this.props;
        const { translateX, translateY } = state;

        // Consider small drag a click
        if ((Math.abs(translateX) < SLIDE_TRANSFORM_CLICK_LIMIT && Math.abs(translateY) < SLIDE_TRANSFORM_CLICK_LIMIT)
            && onSliderClick) {
            onSliderClick();
            return;
        }

        const activeSlide = this.calculateNextSlide(state);
        const slideSize = this.sliderWidth;
        const newTranslate = activeSlide * slideSize;

        CSS.setVariable(this.draggableRef, 'animation-speed', '300ms');

        CSS.setVariable(
            this.draggableRef,
            'translateX',
            `${newTranslate}px`
        );

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    render() {
        const {
            showCrumbs,
            showArrows,
            mix,
            activeImage,
            children: childrenRaw,
            sliderOverlayWidth,
            isSliderOverlay,
            device
        } = this.props;

        const children = childrenRaw.flat();
        const sliderWidth = sliderOverlayWidth !== 0 ? sliderOverlayWidth : this.sliderWidth;

        return (
            <>
                { showArrows && this.renderButton('left') }
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ this.sliderRef }
                >
                    <Draggable
                      mix={ { block: 'Slider', elem: 'Wrapper' } }
                      draggableRef={ this.draggableRef }
                      onDragStart={ this.handleDragStart }
                      onDragEnd={ this.handleDragEnd }
                      onDrag={ this.handleDrag }
                      shiftX={ -activeImage * sliderWidth }
                      isSliderOverlay={ isSliderOverlay }
                      isMobile={ device.isMobile }
                    >
                        { children }
                    </Draggable>
                    { showCrumbs && this.renderCrumbs() }
                </div>
                { showArrows && this.renderButton('right') }
            </>
        );
    }
}

export default SliderComponent;
