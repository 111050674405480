/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateForterFirstSix, updateForterLastFour } from '../../../store/Forter.action';

/** @namespace Fortergraphql/Scandipwa/App/Plugin/Component/AdyenCard/Container */
export class AdyenCheckoutContainer {
    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;

        return {
            ...callback(...args),
            updateFirstSix: (firstSix) => dispatch(updateForterFirstSix(firstSix)),
            updateLastFour: (lastFour) => dispatch(updateForterLastFour(lastFour))
        };
    };

    handleBinValue = (args, callback, instance) => {
        const { binValue } = args[0];

        if (binValue) {
            const { updateFirstSix } = instance.props;

            updateFirstSix(binValue);
        }
    };

    handleFieldValid = (args, callback, instance) => {
        const { fieldType } = args[0];

        // TODO: Should probably be a const in a .config.js file
        if (fieldType === 'encryptedCardNumber') {
            const { endDigits } = args[0];

            if (endDigits) {
                const { updateLastFour } = instance.props;

                updateLastFour(endDigits);
            }
        }
    };
}

export const { mapDispatchToProps, handleBinValue, handleFieldValid } = new AdyenCheckoutContainer();

export default {
    'Scandiweb/AdyenPayments/Component/AdyenCheckout/Container': {
        'member-function': {
            handleBinValue,
            handleFieldValid
        }
    },
    'Scandiweb/AdyenPayments/Component/AdyenCheckout/Container/mapDispatchToProps': {
        function: mapDispatchToProps
    }
};
