/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const DEFAULT_SLIDES_COUNT = 4;
export const DEFAULT_THUMBNAIL_SLIDES_COUNT = 3;
export const BUTTON_LEFT = 'left';
export const BUTTON_RIGHT = 'right';
export const DESKTOP_WIDTH = 1023;
export const TABLET_WIDTH = 768;
export const PRODUCT_CAROUSEL = 'ProductCarousel';
