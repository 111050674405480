/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import ClickOutside from 'Component/ClickOutside/ClickOutside.component';
import { ChildrenType } from 'Type/Common.type';

/** @namespace Bigscreen/Component/ClickOutSideWithExtraNodes/ClickOutsideWithExtraNodes/Component */
export class ClickOutsideWithExtraNodesComponent extends ClickOutside {
    static propTypes = {
        onClick: PropTypes.func,
        children: ChildrenType,
        // eslint-disable-next-line react/forbid-prop-types
        nodeIds: PropTypes.array.isRequired
    };

    static defaultProps = {
        onClick: () => {},
        children: []
    };

    state = {
        childClicked: false
    };

    handleClick = ({ target }) => {
        const { onClick, nodeIds } = this.props;

        if (this.childrenRefs.every(
            ({ current }) => {
                const isTargetExtraNode = nodeIds.some((nodeId) => {
                    const node = document.getElementById(nodeId);

                    return target.isEqualNode(node);
                });

                return !current.contains(target) && !isTargetExtraNode;
            }
        )) {
            onClick();
        }
    };
}

export default ClickOutsideWithExtraNodesComponent;
