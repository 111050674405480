/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import CheckoutTermsAndConditions from './CheckoutTermsAndConditions.component';

/** @namespace Scandipwa/Component/CheckoutTermsAndConditions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    termsAndConditionsLink: state.ConfigReducer.terms_and_conditions_link,
    privacyPolicyLink: state.ConfigReducer.privacy_policy_link,
    termsAreEnabled: state.ConfigReducer.terms_are_enabled
});

/** @namespace Scandipwa/Component/CheckoutTermsAndConditions/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Scandipwa/Component/CheckoutTermsAndConditions/Container */
export class CheckoutTermsAndConditionsContainer extends PureComponent {
    static propTypes = {
        termsAndConditionsLink: PropTypes.string,
        privacyPolicyLink: PropTypes.string,
        // eslint-disable-next-line react/boolean-prop-naming
        termsAreEnabled: PropTypes.bool,
        setTACStatus: PropTypes.func.isRequired,
        isTACAccepted: PropTypes.bool.isRequired
    };

    static defaultProps = {
        termsAreEnabled: false,
        termsAndConditionsLink: '/',
        privacyPolicyLink: '/'
    };

    componentDidMount() {
        const { termsAreEnabled, setTACStatus } = this.props;

        if (!termsAreEnabled) {
            setTACStatus();
        }
    }

    render() {
        return (
            <CheckoutTermsAndConditions { ...this.props } />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTermsAndConditionsContainer);
