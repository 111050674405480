/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import SourceNotification from 'SourceComponent/Notification/Notification.component';

import './Notification.extended.style';

/**
 * Notification
 * @class Notification
 * @namespace Scandipwa/Component/Notification/Component */
export class NotificationComponent extends SourceNotification {
    renderDebug() {
        const { notification: { msgDebug, showDebugInProduction } } = this.props;

        // eslint-disable-next-line no-console
        console.warn(msgDebug); // so we know what was in notification

        if (!msgDebug) {
            return null;
        }

        if (process.env.NODE_ENV === 'production' && !showDebugInProduction) {
            return null;
        }

        return (
            <pre block="Notification" elem="Debug">
                { JSON.stringify(msgDebug) }
            </pre>
        );
    }

    render() {
        const { notification } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing'
        };

        return (
            <div block="Notification" mods={ mods } ref={ this.notification }>
                <p block="Notification" elem="Text">{ msgText }</p>
                { this.renderDebug() }
            </div>
        );
    }
}

export default NotificationComponent;
