/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';

import { CATEGORY_SORT } from 'Component/CategorySort/CategorySort.config';
import ClickOutside from 'Component/ClickOutside';
import SourceFieldSelect from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.extended.style';

/** @namespace Scandipwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    static propTypes = {
        ...SourceFieldSelect.propTypes,
        labelPlaceholder: PropTypes.string,
        isRequired: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        attributeValidation: PropTypes.object,
        isDatePicker: PropTypes.bool
    };

    static defaultProps = {
        ...SourceFieldSelect.defaultProps,
        labelPlaceholder: '',
        isRequired: false,
        attributeValidation: {},
        isDatePicker: false
    };

    renderOption(option) {
        const {
            id,
            label,
            isPlaceholder = false,
            isHovered,
            isAvailable = true
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ {
                  isDisabled: !isAvailable,
                  isExpanded,
                  isPlaceholder,
                  isHovered
              } }
              key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
              id={ `o${ id }` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                <span>{ label }</span>
            </li>
        );
    }

    renderLabelPlaceholder() {
        const { labelPlaceholder, isRequired } = this.props;

        if (labelPlaceholder === '') {
            return null;
        }

        return (
            <span
              block="FieldSelect"
              elem="LabelPlaceholder"
              mods={ { isRequired } }
            >
                { labelPlaceholder }
            </span>
        );
    }

    renderPlaceholder() {
        const { placeholder } = this.props;

        if (!placeholder) {
            return null;
        }

        return (
            <option value="" label={ placeholder } />
        );
    }

    renderNativeSelect() {
        const {
            attr: {
                id,
                name
            },
            attr,
            autocomplete,
            events,
            handleSelectListOptionClick,
            innerLabel,
            isDisabled,
            isExpanded,
            isLabelInner,
            options,
            setRef,
            skipValue,
            unsortedOptions,
            isDatePicker
        } = this.props;

        const labelForSelect = isLabelInner && innerLabel
            ? (
                <label
                  htmlFor={ id ? id.toString() : null }
                  block="FieldSelect"
                  elem="InnerLabel"
                >
                    { innerLabel }
                </label>
            ) : null;

        const shouldRemovePlaceholder = (name && name !== 'size') && !isDatePicker;
        const optionsWithPlaceholder = name === CATEGORY_SORT ? unsortedOptions : options;
        const optionsWithNoPlaceholder = name === CATEGORY_SORT
            ? unsortedOptions.filter((option) => option.isPlaceholder !== true)
            : options.filter((option) => option.isPlaceholder !== true);

        return (
            <>
                { labelForSelect }
                <select
                  block="FieldSelect"
                  elem="Select"
                  autoComplete={ autocomplete }
                  mods={ { isExpanded } }
                  ref={ (elem) => setRef(elem) }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  disabled={ isDisabled }
                  tabIndex="0"
                  onChange={ handleSelectListOptionClick }
                  data-skip-value={ skipValue }
                >
                    { shouldRemovePlaceholder
                        ? optionsWithNoPlaceholder.map(this.renderNativeOption.bind(this))
                        : optionsWithPlaceholder.map(this.renderNativeOption.bind(this)) }
                </select>
            </>
        );
    }

    renderNativeOption(option) {
        const {
            id,
            value,
            disabled,
            label,
            selected
        } = option;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled }
              selected={ selected }
            >
                { label }
            </option>
        );
    }

    renderOptions() {
        const {
            options,
            isExpanded,
            attr: {
                noPlaceholder
            }
        } = this.props;

        const optionsWithNoPlaceholder = options.filter((option) => option.isPlaceholder !== true);
        // eslint-disable-next-line no-magic-numbers
        const noScrollBar = optionsWithNoPlaceholder.length < 5;
        const shouldRemovePlaceholder = !noPlaceholder;

        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded, noScrollBar } }
            >
                { shouldRemovePlaceholder
                    ? optionsWithNoPlaceholder.map(this.renderOption.bind(this))
                    : options.map(this.renderOption.bind(this)) }
            </ul>
        );
    }

    render() {
        const {
            attr: { name },
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            value,
            message,
            attributeValidation: {
                isSizeValid
            }
        } = this.props;

        const sizeValidation = name === 'size' && !isSizeValid && value === '';

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  block="FieldSelect"
                  mods={ { isNotValid: (!value && !!message) || sizeValidation, isExpanded } }
                  onClick={ handleSelectExpand }
                  onKeyPress={ handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select drop-down"
                  aria-expanded={ isExpanded }
                >
                    { this.renderLabelPlaceholder() }
                    { this.renderNativeSelect() }
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}
export default FieldSelectComponent;
