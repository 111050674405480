/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import AdyenCheckout from '@adyen/adyen-web';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import { CART_TAB } from 'Component/NavigationTabs/NavigationTabs.config';
import { DETAILS_STEP, PAYMENT_TOTALS } from 'Route/Checkout/Checkout.config';
import { changeNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { TotalsType } from 'Type/MiniCart.type';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteCartId } from 'Util/Cart';
import { prepareQuery } from 'Util/Query';
import { executeGet, fetchMutation } from 'Util/Request';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
import getStore from 'Util/Store';

import AdyenPaymentsQuery from '../../query/AdyenPayments.query';
import { updateAdyenCheckoutActionData, updateAdyenCheckoutStateData } from '../../store/Adyen.action';
import {
    ADYEN_HPP_METHOD_CODE
} from '../../util/Adyen';
import AdyenCheckoutComponent from './AdyenCheckout.component';

import '@adyen/adyen-web/dist/adyen.css';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Scandiweb/AdyenPayments/Component/AdyenCheckout/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    adyenClientKeyTest: state.ConfigReducer.adyen_client_key_test,
    adyenClientKeyLive: state.ConfigReducer.adyen_client_key_live,
    isDemoMode: state.ConfigReducer.adyen_demo_mode,
    hasHolderName: state.ConfigReducer.adyen_has_holder_name,
    isHolderNameRequired: state.ConfigReducer.adyen_holder_name_required,
    orderNumber: state.CartReducer.orderNumber,
    isMobile: state.ConfigReducer.device.isMobile,
    languageCode: state.ConfigReducer.code,
    countryCode: state.ConfigReducer.default_country
});

/** @namespace Scandiweb/AdyenPayments/Component/AdyenCheckout/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateAdyenCheckoutStateData: (stateData) => dispatch(updateAdyenCheckoutStateData(stateData)),
    updateAdyenCheckoutActionData: (actionData) => dispatch(updateAdyenCheckoutActionData(actionData)),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, getAuthorizationToken())
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        }
    ),
    setNavigationState: (stateName) => dispatch(changeNavigationState(BOTTOM_NAVIGATION_TYPE, stateName))
});

/** @namespace Scandiweb/AdyenPayments/Component/AdyenCheckout/Container */
export class AdyenCheckoutContainer extends PureComponent {
    static propTypes = {
        code: PropTypes.string.isRequired,
        setOrderButtonEnableStatus: PropTypes.func.isRequired,
        totals: TotalsType.isRequired,
        adyenClientKeyTest: PropTypes.string,
        adyenClientKeyLive: PropTypes.string,
        isDemoMode: PropTypes.bool.isRequired,
        hasHolderName: PropTypes.bool.isRequired,
        isHolderNameRequired: PropTypes.bool.isRequired,
        updateAdyenCheckoutStateData: PropTypes.func.isRequired,
        orderNumber: PropTypes.string,
        showNotification: PropTypes.func.isRequired,
        showErrorNotification: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        setNavigationState: PropTypes.func.isRequired,
        setCheckoutContainerLoading: PropTypes.func.isRequired,
        setCheckoutContainerOrderId: PropTypes.func.isRequired,
        setCheckoutContainerCheckoutStep: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        languageCode: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired
    };

    static defaultProps = {
        adyenClientKeyTest: '',
        adyenClientKeyLive: '',
        orderNumber: ''
    };

    fieldRef = createRef();

    containerFunctions = {
        onThreeDsPopupClose: this.onThreeDsPopupClose.bind(this),
        onThreeDsPopupHide: this.onThreeDsPopupHide.bind(this)
    };

    async componentDidMount() {
        const { setOrderButtonEnableStatus } = this.props;

        // Disable the "complete order" button
        setOrderButtonEnableStatus(false);
        await this.initializeAdyenPaymentComponent();

        if (!setOrderButtonEnableStatus) {
            return;
        }

        // TODO: Not sure why needed
        window.setOrderButtonEnableStatus = setOrderButtonEnableStatus;
    }

    /**
     * When switching between Adyen payment methods (e.g. card & oxxo) it doesn't unmount and remount, it just updates the component
     */
    componentDidUpdate(prevProps) {
        const { code } = this.props;
        const { code: prevCode } = prevProps;

        if (code === prevCode) {
            return;
        }

        const { adyenCheckout } = this.state;

        if (adyenCheckout) {
            const { current } = this.fieldRef;

            const dropinComponent = adyenCheckout.create(code ?? 'card');
            const mountedDropinComponent = dropinComponent.mount(current);

            window.adyenDropinComponent = mountedDropinComponent;

            // Empty out the actionData so we don't show old Oxxo info after order completion
            updateAdyenCheckoutActionData({});
        }
    }

    /**
     * To allow for payment method switching, we have to fully unmount the Adyen component
     * Possible improvement would be to create the AdyenCheckout instance somewhere up above and pass it down
     */
    componentWillUnmount() {
        const { dropinComponent } = this.state;

        if (dropinComponent) {
            dropinComponent.unmount();
        }

        window.adyenDropinElement = null;
        window.adyenDropinComponent = null;
        window.setOrderButtonEnableStatus = false;
        updateAdyenCheckoutActionData({});
    }

    async initializeAdyenPaymentComponent() {
        const {
            code,
            totals: {
                id: cartId
            }
        } = this.props;

        const { current } = this.fieldRef;

        const query = AdyenPaymentsQuery.getPaymentMethods(cartId);

        const {
            adyenPaymentMethods: {
                paymentMethodsResponse,
                paymentMethodsExtraDetails
            }
        } = await executeGet(prepareQuery(query), 'AdyenPaymentMethods', ONE_MONTH_IN_SECONDS);

        // Create an instance of AdyenCheckout using the configuration object.
        const adyenConfig = this.getAdyenConfigObject(paymentMethodsResponse);
        // eslint-disable-next-line new-cap
        const checkout = await AdyenCheckout(adyenConfig);

        // Create an instance of Drop-in and mount it to the container you created.
        const dropinComponent = checkout.create(code ?? 'card');
        const mountedDropinComponent = dropinComponent.mount(current);

        // Push it to window to allow other components to call the .submit() method
        // Note that there is a difference and that the unmounted element can be re-used for the 3DS popup
        // Without this, the createFromAction function is not exposed
        window.adyenDropinElement = checkout;
        window.adyenDropinComponent = mountedDropinComponent;

        this.setState({ adyenCheckout: checkout });

        // Oxxo doesn't trigger the handleChange event, so we have to manually set the order button status
        if (code === 'oxxo') {
            const { setOrderButtonEnableStatus } = this.props;

            setOrderButtonEnableStatus(true);
        }
    }

    getAdyenConfigObject(paymentMethodsResponse) {
        const {
            adyenClientKeyTest,
            adyenClientKeyLive,
            isDemoMode,
            hasHolderName,
            isHolderNameRequired,
            isMobile,
            languageCode,
            countryCode
        } = this.props;

        return {
            paymentMethodsResponse,
            environment: isDemoMode ? 'test' : 'live',
            clientKey: isDemoMode ? adyenClientKeyTest : adyenClientKeyLive,
            analytics: {
                enabled: true // Set to false to not send analytics data to Adyen.
            },
            onPaymentCompleted: this.handlePaymentCompleted.bind(this),
            onError: this.handleError.bind(this),
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName,
                    holderNameRequired: isHolderNameRequired ?? true,
                    billingAddressRequired: false,
                    onChange: this.handleChange.bind(this),
                    onBrand: this.handleBrand.bind(this),
                    onFieldValid: this.handleFieldValid.bind(this),
                    onBinValue: this.handleBinValue.bind(this),
                    onBinLookup: this.handleBinLookup.bind(this),
                    onAdditionalDetails: this.handleAdditionalDetails.bind(this),
                    onSubmit: this.handleSubmit.bind(this)
                },
                threeDS2: { // Web Components 4.0.0 and above: sample configuration for the threeDS2 action type
                    challengeWindowSize: isMobile ? '02' : '04'
                    // Set to any of the following:
                    // '02': ['390px', '400px'] -  The default window size
                    // '01': ['250px', '400px']
                    // '03': ['500px', '600px']
                    // '04': ['600px', '400px']
                    // '05': ['100%', '100%']
                },
                oxxo: {
                    billingAddressRequired: false,
                    onChange: this.handleChange.bind(this),
                    onFieldValid: this.handleFieldValid.bind(this),
                    onAdditionalDetails: this.handleAdditionalDetails.bind(this),
                    onSubmit: this.handleSubmit.bind(this)
                }
            },
            showPayButton: false, // We want to use the SPWA pay button instead
            onChange: this.handleChange.bind(this),
            onAdditionalDetails: this.handleAdditionalDetails.bind(this),
            onSubmit: this.handleSubmit.bind(this),
            locale: `${languageCode}_${countryCode}`
        };
    }

    handleChange(state) {
        const {
            data: {
                paymentMethod: { brand }
            },
            isValid
        } = state;

        const { setOrderButtonEnableStatus } = this.props;

        if (!isValid) {
            setOrderButtonEnableStatus(false);

            return;
        }

        setOrderButtonEnableStatus(true);

        const stateData = JSON.stringify(state.data);

        const details = {
            stateData,
            brand
        };

        // this.setPaymentMethodData(details);
    }

    handleBrand(state) {
        const { brand: cc_type } = state;
        const { updateAdyenCheckoutStateData } = this.props;

        updateAdyenCheckoutStateData({ cardType: cc_type });

        // this.setPaymentMethodData({ cc_type });
    }

    /**
     * Empty function to allow plugins.
     * Used by the Forter integration.
     */
    handleBinValue(value) {}

    /**
     * Empty function to allow plugins.
     * Used by the Forter integration.
     */
    handleFieldValid(value) {}

    /**
     * Empty function to allow plugins.
     * Used by the Forter integration.
     */
    handleBinLookup(value) {}

    /**
     * This handler is used when 3DS completes
     * @param {*} value
     */
    async handleAdditionalDetails(value) {
        const { data } = value;
        // TODO: Handle 3DS response by calling adyenPaymentDetails mutation
        const { showErrorNotification, totals: { id: cartId }, hideActiveOverlay } = this.props;
        // TODO: Push this to mapStateToProps?
        // This value doesn't update properly when injected with mapStateToProps
        const { orderNumber } = getStore().getState().CheckoutReducer;

        const payload = {
            ...data,
            orderId: orderNumber
        };

        try {
            // Fetch the 3DS result from the BE
            const {
                adyenPaymentDetails
            } = await fetchMutation(AdyenPaymentsQuery.handleAdyenPaymentDetails(JSON.stringify(payload), cartId));

            // Close the 3DS popup
            hideActiveOverlay();

            console.log(adyenPaymentDetails);

            // TODO: If successful, it should redirect to the success page

            const { isFinal, resultCode } = adyenPaymentDetails;

            if (isFinal && resultCode === 'Authorised') {
                // TODO: Clean data and redirect
                // This part mimics the setDetailsStep() function in CheckoutContainer
                const {
                    resetCart, resetGuestCart, setNavigationState,
                    setCheckoutContainerOrderId, setCheckoutContainerCheckoutStep
                } = this.props;

                // Setting the orderId in the CheckoutContainer to prevent it from redirecting away from the success page
                setCheckoutContainerOrderId(orderNumber);

                // Mimics the setDetailsStep() function in CheckoutContainer
                deleteCartId();
                BrowserDatabase.deleteItem(PAYMENT_TOTALS);

                if (isSignedIn()) {
                    resetCart();
                } else {
                    resetGuestCart();
                }

                setNavigationState({
                    name: CART_TAB
                });

                // window.location.replace(`${window.location.origin }/checkout/success`);
                setCheckoutContainerCheckoutStep(DETAILS_STEP);
            }
        } catch (error) {
            showErrorNotification(__('The payment was refused'));

            // We can land here if the customer clicked on the cancel button inside the 3DS window

            // Close the 3DS popup
            hideActiveOverlay();

            // Unlock the drop-in
            if (window.adyenDropinComponent) {
                window.adyenDropinComponent.setStatus('ready');
            }

            const { setCheckoutContainerLoading } = this.props;

            // Set the loading status on CheckoutContainer
            setCheckoutContainerLoading(false);
        }
    }

    handlePaymentCompleted(result, component) {
        console.info(result, component);
    }

    handleSubmit(stateData) {
        console.log(stateData);

        // Save the checkout data to the store so it can be used in the rest of the checkout flow
        const { updateAdyenCheckoutStateData } = this.props;
        updateAdyenCheckoutStateData(stateData);
    }

    handleError(error, component) {
        console.error(error.name, error.message, error.stack, component);
    }

    getActivePaymentMethod() {
        const {
            code
        } = this.props;
        const {
            selectedPaymentType
        } = this.context;

        return code === ADYEN_HPP_METHOD_CODE ? selectedPaymentType : code;
    }

    /**
     * Event handler for the case where the user closes the 3DS popup via the cross / clicking next to it
     */
    onThreeDsPopupClose() {
        const {
            setCheckoutContainerLoading
        } = this.props;

        // Unlock the drop-in
        if (window.adyenDropinComponent) {
            window.adyenDropinComponent.setStatus('ready');
        }

        // Set the loading status on CheckoutContainer
        setCheckoutContainerLoading(false);
    }

    onThreeDsPopupHide() {
        const {
            setCheckoutContainerLoading
        } = this.props;

        setCheckoutContainerLoading(false);
    }

    containerProps() {
        return {
            fieldRef: this.fieldRef
        };
    }

    render() {
        return (
            <AdyenCheckoutComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdyenCheckoutContainer);
