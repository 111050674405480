/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Loader from 'Component/Loader';
import {
    STATE_GUEST,
    STATE_SIGN_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import MyAccountSignIn from 'Component/MyAccountSignIn/MyAccountSignIn.container';
import SourceCheckoutGuestForm from 'SourceComponent/CheckoutGuestForm/CheckoutGuestForm.component';

import checkoutGuestForm from './CheckoutGuestForm.form';

import './CheckoutGuestForm.style.override.scss';

/** @namespace Scandipwa/Component/CheckoutGuestForm/Component */
export class CheckoutGuestFormComponent extends SourceCheckoutGuestForm {
    static propTypes = {
        ...SourceCheckoutGuestForm.propTypes,
        isEmailRecognitionLoading: PropTypes.bool,
        onConfirmationEmailChange: PropTypes.func.isRequired,
        onEmailChange: PropTypes.func.isRequired
    };

    state = {
        ...this.state,
        isEmailRecognitionLoading: false,
        confirmedEmail: ''
    };

    /**
     * Added else to the if statement to flip back to guest checkout if the email address changes to a non-existing account
     */
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps) {
        const { isEmailAvailable, setSignInState, signInState } = this.props;
        const { isEmailAvailable: prevIsEmailAvailable } = prevProps;

        if (!isEmailAvailable && prevIsEmailAvailable && signInState !== STATE_SIGN_IN) {
            setSignInState(STATE_SIGN_IN);
        } else if (isEmailAvailable && !prevIsEmailAvailable && signInState === STATE_SIGN_IN) {
            setSignInState(STATE_GUEST);

            // Trigger validation again on the formRef
            // Otherwise it would not update the states of CheckoutContainer and its children with the new email values
            // As a side-effect this sets the email confirmation field equal to the current email, erasing the previously stored state
            const { formRef } = this.props;

            if (formRef && formRef.current) {
                formRef.current.validate();
            }
        }
    }

    componentDidMount() {
        const { emailValue } = this.props;
        const { confirmedEmail } = this.state;

        if (emailValue.length && !confirmedEmail.length) {
            this.setState({ confirmedEmail: emailValue });
        }
    }

    handleSecondEmailInput = ({ target: { value } }) => {
        const { onConfirmationEmailChange } = this.props;

        this.setState({ confirmedEmail: value });
        onConfirmationEmailChange(value);
    };

    renderMap = {
        [STATE_SIGN_IN]: {
            render: () => this.renderSignIn(),
            title: __('Sign in to your account')
        },
        '': {
            title: __('test')
        }
        // [STATE_GUEST]: {
        //     render: () => this.renderGuestForm(),
        //     title: __('Enter personal information')
        // }
    };

    get fieldMap() {
        const {
            handleEmailInput,
            handlePasswordInput,
            isCreateUser,
            emailValue,
            range,
            minimunPasswordCharacter
        } = this.props;

        const { confirmedEmail } = this.state;

        return checkoutGuestForm({
            isCreateUser,
            emailValue,
            range,
            minimunPasswordCharacter,
            confirmedEmail
        }, {
            handleEmailInput,
            handlePasswordInput,
            range,
            minimunPasswordCharacter,
            handleSecondEmailInput: this.handleSecondEmailInput
        });
    }

    renderHeading() {
        return (
            <h2 block="Checkout" elem="Heading">
                { __('Shipping Address') }
            </h2>
        );
    }

    renderGuestForm() {
        return this.renderFormBody();
    }

    renderSignIn() {
        const {
            emailValue,
            handleCreateAccount,
            handleEmailInput,
            handleForgotPassword,
            isLoading,
            onFormError,
            onSignIn,
            setLoadingState,
            signInState,
            onEmailChange,
            onConfirmationEmailChange
        } = this.props;

        return (
            <MyAccountSignIn
              emailValue={ emailValue }
              handleCreateAccount={ handleCreateAccount }
              handleEmailInput={ handleEmailInput }
              handleForgotPassword={ handleForgotPassword }
              isCheckout
              isLoading={ isLoading }
              onFormError={ onFormError }
              onSignIn={ onSignIn }
              setLoadingState={ setLoadingState }
              state={ signInState }
              onEmailChange={ onEmailChange }
              onConfirmationEmailChange={ onConfirmationEmailChange }
            />
        );
    }

    renderFormBody() {
        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="Fields">
                    { this.fieldMap.map(this.renderSection.bind(this)) }
                </div>
                { this.renderActions() }
            </div>
        );
    }

    renderCreateUserCheckbox() {
        const {
            handleCreateUser,
            isCreateUser,
            isEmailConfirmationRequired,
            signInState
        } = this.props;

        // if email confirmation required and user is not logged in
        // the user is 100% not logged in (we are in the guest form)
        // do not show the checkbox to create the user account
        if (isEmailConfirmationRequired || signInState === 'signIn') {
            return null;
        }

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              label={ __('Create free account and keep track of your orders') }
              attr={ {
                  id: 'guest_create_user',
                  name: 'guest_create_user',
                  defaultValue: isCreateUser
              } }
              events={ {
                  onChange: handleCreateUser
              } }
              skipValue
            />
        );
    }

    getFormProps() {
        const { formRef, onFormError } = this.props;

        return {
            elemRef: formRef,
            onError: onFormError
        };
    }

    render() {
        const { isEmailRecognitionLoading } = this.props;

        return (
            <div
              block="CheckoutGuestForm"
              mix={ { block: 'FieldForm' } }
            >
                <div
                  block="CheckoutGuestForm"
                  mix={ { block: 'FieldForm' } }
                >
                    <Loader isLoading={ isEmailRecognitionLoading } />
                    { this.renderHeading() }
                    { this.renderForm() }
                    { this.renderCreateUserCheckbox() }
                </div>
            </div>
        );
    }
}

export default CheckoutGuestFormComponent;
