/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

import AdyenCheckout from '../../../component/AdyenCheckout';
import {
    ADYEN_CC_METHOD_CODE,
    ADYEN_OXXO_METHOD_CODE
} from '../../../util/Adyen';

/** @namespace AdyenPayments/Scandipwa/Plugin/Component/CheckoutPayments/Component */
class CheckoutPaymentsComponent {
    propTypes = (originalMember) => ({
        ...originalMember,
        setCheckoutContainerLoading: PropTypes.func.isRequired,
        setCheckoutContainerOrderId: PropTypes.func.isRequired,
        setCheckoutContainerCheckoutStep: PropTypes.func.isRequired
    });

    addAdyenPayments = (member, instance) => ({
        ...member,
        [ADYEN_CC_METHOD_CODE]: this.renderAdyenCCMethod.bind(instance),
        [ADYEN_OXXO_METHOD_CODE]: this.renderAdyenOxxoMethod.bind(instance)
    });

    /**
     * A possible trick to render a payment method for every payment method inside Ayden is to take the paymentMethodsResponse and add one line to the render map here for every method.
     * Downside is that it will delay the rendering of the payment methods until the paymentMethodsResponse is received.
     */
    renderAdyenCCMethod() {
        const {
            setOrderButtonEnableStatus,
            setCheckoutContainerLoading,
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        } = this.props;

        return (
            <AdyenCheckout
              code="card"
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
              setCheckoutContainerLoading={ setCheckoutContainerLoading }
              setCheckoutContainerOrderId={ setCheckoutContainerOrderId }
              setCheckoutContainerCheckoutStep={ setCheckoutContainerCheckoutStep }
            />
        );
    }

    renderAdyenOxxoMethod() {
        const {
            setOrderButtonEnableStatus,
            setCheckoutContainerLoading,
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        } = this.props;

        return (
            <AdyenCheckout
              code="oxxo"
              setOrderButtonEnableStatus={ setOrderButtonEnableStatus }
              setCheckoutContainerLoading={ setCheckoutContainerLoading }
              setCheckoutContainerOrderId={ setCheckoutContainerOrderId }
              setCheckoutContainerCheckoutStep={ setCheckoutContainerCheckoutStep }
            />
        );
    }
}

const { propTypes, addAdyenPayments } = new CheckoutPaymentsComponent();

export default {
    'Component/CheckoutPayments/Component': {
        'member-property': {
            paymentRenderMap: addAdyenPayments
        },
        'static-member': {
            propTypes
        }
    }
};
