/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';

import { STOCK_TYPE } from 'Component/Product/Stock.config';
import { ProductConfigurableAttributesContainer as SourceContainer }
from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';
import { ProductType } from 'Type/ProductList.type';

/** @namespace Scandipwa/Component/ProductConfigurableAttributes/Container */
export class ProductConfigurableAttributesContainer extends SourceContainer {
    static PropTypes = {
        // eslint-disable-next-line react/forbid-foreign-prop-types
        ...super.propTypes,
        isLabelInner: PropTypes.bool,
        product: ProductType.isRequired,
        isDropdown: PropTypes.bool
    };

    static defaultProps = {
        ...super.defaultProps,
        isLabelInner: false,
        isDropdown: false
    };

    containerProps() {
        const {
            isLabelInner,
            innerLabel,
            product,
            isDropdown
        } = this.props;

        return {
            ...super.containerProps(),
            isLabelInner,
            innerLabel,
            product,
            isDropdown
        };
    }

    /**
     * Overridden to remove the dependency on the parameter but instead just use a prop
     */
    isSelected({ attribute_code, attribute_value }) {
        if (attribute_code === 'color') {
            const { variantColor } = this.props;

            if (variantColor === null) {
                return false;
            }

            // attribute_value is a string
            // eslint-disable-next-line eqeqeq
            return variantColor == attribute_value;
        }

        // If the attribute is not color, fall back to core SPWA logic
        return super.isSelected({ attribute_code, attribute_value });
    }

    getIsConfigurableAttributeAvailable({ attribute_code, attribute_value }) {
        const { parameters, variants } = this.props;

        // skip out of stock check, if variants data has not been provided
        if (!variants) {
            return true;
        }

        const isAttributeSelected = Object.hasOwnProperty.call(parameters, attribute_code);

        // If value matches current attribute_value, option should be enabled
        if (isAttributeSelected && parameters[attribute_code] === attribute_value) {
            return true;
        }

        // Filter out attributes starting with "_sgm" (Segmentify) because they should not be matched down below
        const parameterPairs = Object.entries(parameters).filter(([key]) => !key.startsWith('_sgm'));

        const selectedAttributes = isAttributeSelected
            // Need to exclude itself, otherwise different attribute_values of the same attribute_code will always be disabled
            ? parameterPairs.filter(([key]) => key !== attribute_code)
            : parameterPairs;

        return variants
            .some(({ stock_status, attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return (
                    stock_status === STOCK_TYPE.IN_STOCK
                    // Variant must have currently checked attribute_code and attribute_value
                    && foundValue === attribute_value
                    // Variant must have all currently selected attributes
                    && selectedAttributes.every(([key, value]) => (attributes[key] || {}).attribute_value === value)
                );
            });
    }
}

export default ProductConfigurableAttributesContainer;
