/* eslint-disable react/no-unknown-property */
import Html from 'Component/Html';
import { CmsBlock as SourceCmsBlock } from 'SourceComponent/CmsBlock/CmsBlock.component';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Scandipwa/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    render() {
        const {
            cmsBlock: {
                identifier,
                content,
                disabled
            }
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        // eslint-disable-next-line no-unused-vars
        const { blockType, customComponents = {} } = this.props;

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
            //   mods={ { type: blockType } }
            >
                <Html content={ content } customComponents={ customComponents } />
            </div>
        );
    }
}

export default CmsBlockComponent;
