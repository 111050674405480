/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/**
 * My account dispatcher plugin
 */
class MyAccountDispatcherPlugin {
    signIn = async (args, callback) => callback(...args).then(() => {
        const [{ email = '' }] = args;

        if (email && typeof window.dmPt === 'function') {
            window.dmPt('identify', email);
        }
    });
}

const {
    signIn
} = new MyAccountDispatcherPlugin();

const config = {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn
        }
    }
};

export default config;
