/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { createPortal } from 'react-dom';

import { LANG_BLOCK_POPUP } from 'Component/ChooseLanguageSelector/ChooseLanguageSelector.config';
import { MOBILE_MENU } from 'Component/NavigationTabs/NavigationTabs.config';
import SourcePopup from 'SourceComponent/Popup/Popup.component';
import CSS from 'Util/CSS';
import isMobile from 'Util/Mobile';

import './Popup.extended.style';

/** @namespace Scandipwa/Component/Popup/Component */
export class PopupComponent extends SourcePopup {
    initialScrollPos;

    onHide() {
        const { clearPopupState } = this.props;

        clearPopupState();

        super.onHide();
    }

    renderTitle() {
        const { title, payload } = this.props;
        if (!title && !payload && !payload?.title) {
            return null;
        }

        return (
            <h2 block="Popup" elem="Heading">
                { title || payload.title }
            </h2>
        );
    }

    setInnerHeightVariable() {
        document.documentElement.style.setProperty(
            '--window-inner-height-checkout',
            `${ window.innerHeight }px`
        );
    }

    componentDidMount() {
        this.handleScroll();
        this.setInnerHeightVariable();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.setInnerHeightVariable);

        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        this.handleScroll();

        const prevWasVisible = this.getIsVisible(prevProps);
        const isVisible = this.getIsVisible();

        const { activeOverlay } = this.props;
        const { activeOverlay: prevActiveOverlay } = prevProps;

        if (isVisible && !prevWasVisible) {
            this.onVisible();
        }

        if (!isVisible && prevWasVisible) {
            this.onHide();
        }

        if (activeOverlay === '' && prevActiveOverlay !== '') {
            this.onHide();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.setInnerHeightVariable);

        super.componentWillUnmount();
    }

    onVisible() {
        const { onVisible } = this.props;
        this.freezeScroll();
        this.overlayRef.current.focus();

        window.addEventListener('popstate', this.hidePopUp);

        window.history.pushState(
            {
                popupOpen: true
            },
            '',
            `${location.pathname}${location.search}${location.hash}`
        );

        onVisible();
    }

    handleScroll = () => {
        const { block, activeOverlay, id } = this.props;
        const { topBannerHeight = 0 } = window;

        if (!this.getIsVisible()) {
            return;
        }
        const navBarHeight = 60;
        const pageYOffset = isMobile.iOS() && document.body.style.top
            ? -1 * parseInt(document.body.style.top, 10) : window.pageYOffset;
        const top = (pageYOffset || window.pageYOffset > topBannerHeight)
            ? navBarHeight
            : topBannerHeight + navBarHeight;

        if (block === 'MobileMenu' && id === activeOverlay) {
            CSS.setVariable(
                this.overlayRef,
                'mobilemenu-popup-top',
                `${ top }px`
            );
        }

        if (block === 'ChooseLangBlock') {
            CSS.setVariable(
                this.overlayRef,
                'mobile-langpopup-top',
                `${ top }px`
            );

            CSS.setVariable(
                this.overlayRef,
                'mobile-langpopup-max-height',
                `${ window.innerHeight - top }px`
            );
        }
    };

    freezeScroll() {
        document.body.style.overflow = 'hidden';

        if (isMobile.iOS()) {
            this.initialScrollPos = window.pageYOffset;
            const scrollPos = this.initialScrollPos * -1;

            document.body.style.top = `${ scrollPos }px`;
            document.body.style.position = 'fixed';
            document.body.style.right = '0';
            document.body.style.left = '0';
            document.body.style.bottom = '0';
        }
    }

    unfreezeScroll() {
        const { activeOverlay } = this.props;

        if (activeOverlay !== MOBILE_MENU) {
            document.body.style.overflow = 'initial';
        }

        if (isMobile.iOS()) {
            this.initialScrollPos = window.pageYOffset;

            document.body.style.position = 'relative';
            document.body.style.top = 'initial';
            window.scrollTo(0, this.initialScrollPos);
            document.body.style.right = 'initial';
            document.body.style.left = 'initial';
            document.body.style.bottom = 'initial';
        }
    }

    renderCloseButton() {
        const { changeHeaderState, activeOverlay } = this.props;

        const isLanguagePopup = activeOverlay === LANG_BLOCK_POPUP;

        return (
            <button
              block="Popup"
              elem="CloseBtn"
              aria-label={ __('Close') }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => {
                  this.hidePopUp(); changeHeaderState(isLanguagePopup);
              } }
            />
        );
    }

    // Removed notification render from popup because it causes notifications to show up multiple times
    renderNotifications() {
        return null;
    }

    render() {
        const { mix, areOtherOverlaysOpen, block } = this.props;
        const isVisible = this.getIsVisible();

        return createPortal(
            <div
              ref={ this.overlayRef }
              block="Popup"
              mods={ { isVisible, isInstant: areOtherOverlaysOpen, block } }
              mix={ { ...mix, mods: { ...mix.mods, isVisible } } }
            >
                { this.renderContent() }
            </div>,
            document.body
        );
    }
}

export default PopupComponent;
