/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CART_OVERLAY } from 'Component/Header/Header.config';

import {
    PAYPAL_SCRIPT
} from '../../../../../paypal/src/component/PayPal/PayPal.config';
import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';

/**
 * PayPal Component plugin
 * Fixes the issue when PayPAl tries to load script while in Big Screen
 */
export class PayPalContainerPlugin {
    componentDidMount = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);
        const script = document.getElementById(PAYPAL_SCRIPT);

        if (script && !isBigScreen) {
            script.onload = () => instance.forceUpdate();
        }
    };

    componentDidUpdate = (args, callback, instance) => {
        const { activeOverlay } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (activeOverlay === CART_OVERLAY && !isBigScreen) {
            const script = document.getElementById(PAYPAL_SCRIPT);
            script.onload = () => instance.forceUpdate();
        }
    };
}

const {
    componentDidMount,
    componentDidUpdate
} = new PayPalContainerPlugin();

export const config = {
    'Paypal/Component/PayPal/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate
        }
    }
};

export default config;
