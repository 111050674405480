/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import parser from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';
import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';

import Image from 'Component/Image';
import { TRENDING_INLINE_CAROUSEL_ITEM } from 'Component/TrendingInlineCarouselItem/TrendingInlineCarouselItem.config';
import { Html as SourceHtml } from 'SourceComponent/Html/Html.component';
import browserHistory from 'Util/History';
import { hash } from 'Util/Request/Hash';

import './common.style';
import './feature-grid.style';
import './feature-hero.style';
import './full-bleed-hero.style';
import './global-promo-banner.style';
import './info-hotspot-hero.style';
import './mixed-content-list.style';
import './navigation-promo-banner.style';
import './product-carousel.style';
import './product-group-features.style';
import './product-hotspot-hero.style';
import './side-by-side.style';
import './split-hero.style';
import './trending-inline.style';
import './video.style';
import './promo-tile.style';
import './text-block.style';

export const FullBleedHeroCounter = lazy(() => import('Component/FullBleedHeroCounter'));
export const ExpandableContent = lazy(() => import('Component/ExpandableContent'));
export const VideoCarouselItem = lazy(() => import('Component/VideoCarouselItem'));
export const VideoCarousel = lazy(() => import('Component/VideoCarousel'));
export const ProductCarousel = lazy(() => import('Component/ProductCarousel'));
export const FullBleedHeroVideo = lazy(() => import('Component/FullBleedHeroVideo'));
export const TrendingInlineCarousel = lazy(() => import('Component/TrendingInlineCarousel'));
export const Gocertify = lazy(() => import('Component/Gocertify'));
export const StudentBeans = lazy(() => import('Component/StudentBeans'));
export const SubscriptionBlockFormCMS = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/SubscriptionBlockFormCMS')); // So it's packed in the same chunk as the success page
export const NpsBlockFormCMS = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Component/NpsBlockFormCMS'));

/** @namespace Scandipwa/Component/Html/Component */
export class HtmlComponent extends SourceHtml {
    static propTypes = {
        ...SourceHtml.propTypes,
        // eslint-disable-next-line react/forbid-prop-types
        customComponents: PropTypes.any,
        isSubscriptionBlockActive: PropTypes.bool.isRequired
    };

    static defaultProps = {
        customComponents: undefined
    };

    state = {
        ...super.state,
        shouldExpandTextBlock: false,
        shouldExpandFooterBlock: false
    };

    rules = [
        {
            query: { attribs: [{ class: 'locale-selector-language-link' }] },
            replace: this.replaceLanguageSwitcherLink
        },
        {
            query: { attribs: [{ class: 'accordion' }] },
            replace: this.replaceExpand
        },
        {
            query: { attribs: [{ id: 'gocertify-root' }] },
            replace: this.replaceGocertify
        },
        {
            query: { attribs: [{ id: 'stb-connect' }] },
            replace: this.replaceStudentBeans
        },
        {
            query: { attribs: [{ id: 'cms-subscription-block' }] },
            replace: this.replaceCmsSubscriptionBlock
        },
        {
            query: { attribs: [{ id: 'cms-nps-block' }] },
            replace: this.replaceCmsNpsBlock
        },
        {
            query: { attribs: [{ class: 'footer-content' }] },
            replace: this.replaceFooterContent
        },
        {
            query: { name: ['a'] },
            replace: this.replaceLinks
        },
        {
            query: { name: ['img'] },
            replace: this.replaceImages
        },
        {
            query: { name: ['input'] },
            replace: this.replaceInput
        },
        {
            query: { name: ['script'] },
            replace: this.replaceScript
        },
        {
            query: { name: ['style'] },
            replace: this.replaceStyle
        },
        {
            query: { name: ['table'] },
            replace: this.wrapTable
        },
        {
            query: { name: ['div', 'span', 'li'] },
            replace: this.replaceCustomComponent
        },
        {
            query: { name: ['productlistcarousel'] },
            replace: this.replaceProductCarousel
        },
        {
            query: { name: ['trendinginlinecarousel'] },
            replace: this.replaceTrendingInlineCarousel
        },
        {
            query: { name: ['counter'] },
            replace: this.replaceFullBleedHeroCounter
        },
        {
            query: { name: ['videocarousel'] },
            replace: this.replaceVideoCarousel
        },
        {
            query: { name: ['videohero'] },
            replace: this.replaceVideoHero
        },
        {
            query: { name: ['fbhvideo'] },
            replace: this.replaceFullBleedHeroVideo
        }
    ];

    onClickShowMore = this.onClickShowMore.bind(this);

    onClickFooterBlock = this.onClickFooterBlock.bind(this);

    replaceStyle(elem) {
        const { children } = elem;

        if (!children || !children[0]) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }

        const elemHash = hash(children[0].data);

        if (this.createdOutsideElements[elemHash]) {
            // eslint-disable-next-line react/jsx-no-useless-fragment
            return <></>;
        }

        const style = document.createElement('style');

        if (children && children[0]) {
            style.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(style);
        this.createdOutsideElements[elemHash] = true;

        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }

    replaceImages({ attribs }) {
        const attributes = attributesToProps(attribs);

        if (attribs['data-ignore-replacement'] !== undefined) {
            return null;
        }

        if (attribs.src) {
            return <Image { ...attributes } />;
        }

        return null;
    }

    replaceCustomComponent({ attribs, children }) {
        const { customComponents } = this.props;
        const attributes = attributesToProps(attribs);
        const customComponent = (customComponents || {})[attributes.className];

        if (customComponent) {
            return customComponent(attributes, domToReact(children, this.parserOptions));
        }

        return null;
    }

    replaceLanguageSwitcherLink({ attribs, children }) {
        const { href } = attribs;

        return (
            <button
              onClick={ () => {
                  browserHistory.push(href);
                  browserHistory.go(0);
              } }
              block="Link"
              elem="LanguageSwitcher"
            >
                { domToReact(children, this.parserOptions) }
            </button>
        );
    }

    replaceProductCarousel({ attribs }) {
        const { history } = this.props;

        const {
            productskuarray,
            productbuttonstyle,
            title,
            contenttitle,
            button,
            carouselid,
            datacomponentsubposition = null
        } = attribs;

        if (productskuarray.length === 0) {
            return null;
        }

        return (
            <ProductCarousel
              productSkuArray={ productskuarray.split(',') }
              productButtonStyle={ productbuttonstyle }
              title={ title }
              contentTitle={ contenttitle }
              button={ button }
              carouselId={ carouselid }
              history={ history }
              dataComponentSubPosition={ datacomponentsubposition }
            />
        );
    }

    replaceTrendingInlineCarousel({ attribs, children }) {
        const { history } = this.props;

        const {
            title,
            contenttitle,
            carouselid,
            datacomponentsubposition = null
        } = attribs;

        return (
            <TrendingInlineCarousel
              title={ title }
              contentTitle={ contenttitle }
              carouselId={ carouselid }
              history={ history }
              dataComponentSubPosition={ datacomponentsubposition }
            >
                { domToReact(
                    children.filter((child) => child.name === TRENDING_INLINE_CAROUSEL_ITEM), this.parserOptions
                ) }
            </TrendingInlineCarousel>
        );
    }

    replaceFullBleedHeroCounter({ attribs }) {
        const {
            counter_id,
            time_stamp,
            color,
            color_mobile,
            counter_alignment,
            counter_title
        } = attribs;

        return (
            <FullBleedHeroCounter
              counterId={ counter_id }
              timeStamp={ time_stamp }
              color={ color }
              mobileColor={ color_mobile }
              alignment={ counter_alignment }
              counterTitle={ counter_title }
            />
        );
    }

    replaceVideoHero({ attribs }) {
        const {
            id,
            videoid,
            options,
            title,
            text,
            desktoppreviewimage,
            mobilepreviewimage,
            desktopvideourl,
            desktopvideofile,
            mobilevideofile,
            mobilevideourl,
            contentTitle
        } = attribs;

        return (
            <VideoCarouselItem
              id={ id }
              title={ title }
              videoId={ videoid }
              options={ options }
              text={ text }
              desktopPreviewImage={ desktoppreviewimage }
              mobilePreviewImage={ mobilepreviewimage }
              desktopVideoUrl={ desktopvideourl }
              desktopVideoFile={ desktopvideofile }
              mobileVideoFile={ mobilevideofile }
              mobileVideoUrl={ mobilevideourl }
              contentTitle={ contentTitle }
            />
        );
    }

    replaceVideoCarousel({ attribs, children }) {
        const {
            id,
            title,
            text,
            autoplay,
            contentTitle
        } = attribs;

        return (
            <VideoCarousel
              id={ id }
              title={ title }
              text={ text }
              autoplay={ autoplay }
              contentTitle={ contentTitle }
            >
                { domToReact(children.filter((child) => child.name === 'videocarouselitem'), this.parserOptions) }
            </VideoCarousel>
        );
    }

    replaceFullBleedHeroVideo({ attribs }) {
        const {
            options,
            youtubevideoid,
            desktopvideourl,
            mobilevideourl,
            desktopvideofile,
            mobilevideofile
        } = attribs;

        return (
            <FullBleedHeroVideo
              options={ options }
              youtubeID={ youtubevideoid }
              desktopVideoUrl={ desktopvideourl }
              mobileVideoUrl={ mobilevideourl }
              desktopVideoFile={ desktopvideofile }
              mobileVideoFile={ mobilevideofile }
            />
        );
    }

    replaceGocertify({ attribs }) {
        const { 'data-location': dataLocation } = attribs;

        return (
            <Gocertify
              dataLocation={ dataLocation }
            />
        );
    }

    replaceStudentBeans({ attribs }) {
        const { 'data-connect': dataConnect } = attribs;

        console.log('Studentbeans attribs', attribs);

        return (
            <StudentBeans
              dataConnect={ dataConnect }
            />
        );
    }

    replaceExpand({ children }) {
        // Get heading from h3 tag = child 2
        const headingText = children[1].children[0].data;

        return (
            <ExpandableContent heading={ headingText }>
                { domToReact(children.slice(2), this.parserOptions) }
            </ExpandableContent>
        );
    }

    /**
     * --- Unused ----
     */
    replaceTextBlock({ children }) {
        const { content } = this.props;
        const { shouldExpandTextBlock } = this.state;

        let showMore = false; // eslint-disable-line fp/no-let

        try {
            showMore = children[1].attribs['data-showmore'] === '1';
        } catch (error) {
            // Do nothing here
        }

        const regex = new RegExp('id="([^"]*?)"');
        const id = content.match(regex);

        return (
            <div
              data-component-sub-position="1"
              className="promo-content-custom-class pagecontainer text-block"
              block="TextBlock"
              mods={ { expanded: shouldExpandTextBlock } }
              onClick={ this.onClickShowMore }
              id={ id[1] }
            >
                { domToReact(children, this.parserOptions) }
                { showMore && (
                    <span
                      className="text-block-link"
                      onClick={ this.onClickShowMore }
                    >
                        { shouldExpandTextBlock ? __('Read less') : __('Read more') }
                    </span>
                ) }
            </div>
        );
    }

    /**
     * --- Unused ----
     */
    onClickShowMore() {
        this.setState({ shouldExpandTextBlock: !this.state.shouldExpandTextBlock });
    }

    replaceScript() {
        // Will not inject script tags, components should be refactored to use React functions
    }

    replaceCmsSubscriptionBlock({ attribs }) {
        // Having to destruct this way because magento doesn't show attributes not starting with 'data-' in admin panel html cms content
        const {
            'data-header': header,
            'data-subheader': subHeader,
            'data-img-url': imgUrl,
            'data-mobile-img-url': mobileImgUrl,
            'data-emarsys-campaign-name': emarsysCampaignName,
            'data-emarsys-campaign-lead': emarsysCampaignLead,
            'data-success-header': successHeader,
            'data-success-subheader': successSubHeader,
            'data-success-text': successText
        } = attribs;

        return (
            <SubscriptionBlockFormCMS
              header={ header }
              subHeader={ subHeader }
              imgUrl={ imgUrl }
              mobileImgUrl={ mobileImgUrl }
              emarsysCampaignName={ emarsysCampaignName }
              emarsysCampaignLead={ emarsysCampaignLead }
              successHeader={ successHeader }
              successSubHeader={ successSubHeader }
              successText={ successText }
            />
        );
    }

    replaceCmsNpsBlock({ attribs, children }) {
        const {
            'data-header': header,
            'data-subheader': subHeader,
            'data-img-url': imgUrl,
            'data-mobile-img-url': mobileImgUrl,
            'data-success-header': successHeader,
            'data-success-subheader': successSubHeader,
            'data-success-text': successText,
            'data-text-color': textColor,
            'data-contact-list-ids': contactListIds
        } = attribs;

        return (
            <NpsBlockFormCMS
              header={ header }
              subHeader={ subHeader }
              imgUrl={ imgUrl }
              mobileImgUrl={ mobileImgUrl }
              successHeader={ successHeader }
              successSubHeader={ successSubHeader }
              successText={ successText }
              textColor={ textColor }
              contactListIds={ contactListIds }
            >
                { children }
            </NpsBlockFormCMS>
        );
    }

    /**
     * Used for rendering the disclaimer at the footer
     */
    findNecessaryChildren(children) {
        const divs = children.reduce(
            (acc, child) => (child?.name === 'div' ? child?.children : acc),
            []
        );

        if (Array.isArray(divs) && divs.length > 1) {
            // use "find" to prevent unnecessary recursion into rabbithole
            const result = divs.find(
                (child) => child?.name === 'div' && child?.attribs?.class.includes('ct-field-area')
            );

            return result?.children || this.findNecessaryChildren(divs);
        }

        return divs;
    }

    /**
     * Used for rendering the disclaimer at the footer
     */
    replaceFooterContent({ attribs, children }) {
        const childrens = this.findNecessaryChildren(children);

        const { shouldExpandFooterBlock } = this.state;

        if (childrens) {
            const expandP = childrens.find((child) => child.attribs?.id === 'Footer-Expand-P');
            const expandBtn = expandP?.children.find((child) => child.attribs?.id === 'Footer-Expand-Btn');

            if (expandBtn) {
                expandBtn.attribs.onClick = this.onClickFooterBlock;
            }
        }

        return (
            <div
              block="Footer"
              elem="Content"
              { ...attributesToProps({ ...attribs }) }
              mods={ { expanded: shouldExpandFooterBlock } }
            >
                { domToReact(childrens, this.parserOptions) }
            </div>
        );
    }

    onClickFooterBlock(e) {
        const { shouldExpandFooterBlock } = this.state;
        const expandBtn = e.target;

        if (!shouldExpandFooterBlock) {
            expandBtn.innerText = __('Ver menos');
        } else {
            expandBtn.innerText = __('Ver más');
        }

        this.setState((prevState) => ({
            shouldExpandFooterBlock: !prevState.shouldExpandFooterBlock
        }));
    }

    render() {
        const { content } = this.props;

        return (
            <Suspense fallback={ null }>
                { parser(content, this.parserOptions) }
            </Suspense>
        );
    }
}

export default HtmlComponent;
