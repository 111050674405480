/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CartQuery from 'Query/Cart.query';
import {
    CartDispatcher as SourceCartDispatcher,
    CURRENT_WEBSITE as SOURCE_CURRENT_WEBSITE
} from 'SourceStore/Cart/Cart.dispatcher';
import { setPromosData } from 'Store/Analytics/Analytics.action';
import { updateIsLoadingCart } from 'Store/Cart/Cart.action';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import { updateEmail, updateShippingFields } from 'Store/Checkout/Checkout.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { getAuthorizationToken, isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartId, setCartId } from 'Util/Cart';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

export const CURRENT_WEBSITE = SOURCE_CURRENT_WEBSITE;
export const GUEST_QUOTE_ID = 'guest_quote_id';

/** @namespace Scandipwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async updateInitialCartData(
        dispatch,
        isForCustomer = false,
        disableLoader = false,
        isInitRequest = false,
        isAfterCreateEmptyCart = false
    ) {
        // Need to get current cart from BE, update cart
        try {
            const { pathname } = window.location;

            if (pathname.includes('success')) {
                window.cart_totals = BrowserDatabase.getItem(CART_TOTALS);
            }

            // ! Get quote token first (local or from the backend) just to make sure it exists
            if (!disableLoader && !isAfterCreateEmptyCart) {
                dispatch(updateIsLoadingCart(true));
            }
            // ! Get quote token first (local or from the backend) just to make sure it exists
            const quoteId = await this._getCartId(dispatch);
            const {
                cartData = {},
                cartData: {
                    is_virtual = false,
                    shipping_address: {
                        selected_shipping_method: {
                            address,
                            address: {
                                street = null,
                                email = ''
                            } = {}
                        } = {},
                        method_code
                    } = {}
                } = {}
            } = await fetchQuery(
                CartQuery.getCartQuery(
                    quoteId
                )
            );

            if (address && street) {
                if (!is_virtual) {
                    await dispatch(
                        updateShippingFields({
                            ...this.prepareCheckoutAddressFormat(address),
                            method_code
                        })
                    );
                }

                await dispatch(updateEmail(email));
            }

            if (isForCustomer && !getAuthorizationToken()) {
                dispatch(updateIsLoadingCart(false));

                return null;
            }

            await this._updateCartData(cartData, dispatch);
            await this.removeOutdatedItems(cartData, dispatch);

            if (!disableLoader || isAfterCreateEmptyCart) {
                dispatch(updateIsLoadingCart(false));
            }

            return null;
        } catch (error) {
            dispatch(updateIsLoadingCart(false));

            if (isInitRequest && !isSignedIn() && !isAfterCreateEmptyCart) {
                return this.createGuestEmptyCart(dispatch);
            }

            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }

    isVariantOutOfStock(variants, sku) {
        const index = variants.findIndex(({ product: { sku: vSku } }) => sku === vSku);

        if (index !== -1) {
            return variants[index].product.stock_status === 'OUT_OF_STOCK';
        }

        return true;
    }

    removeOutdatedItems(data, dispatch) {
        try {
            const { items = [] } = data;

            items.map((item) => {
                if (
                    (item.product.type_id === 'configurable'
                && item.product.variants.length === 0)
                || this.isVariantOutOfStock(item.product.variants, item.sku)
                ) {
                    return fetchMutation(CartQuery.getRemoveCartItemMutation(
                        item.id,
                        getCartId()
                    )).then(
                        /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeOutdatedItems/items/map/fetchMutation/then */
                        ({ removeItemFromCart: { cartData } }) => {
                            this._updateCartData(cartData, dispatch);
                            dispatch(showNotification(
                                'info',
                                `Product ${item.product.name} was removed from the cart, as it is not longer available`
                            ));
                        },
                        /** @namespace Scandipwa/Store/Cart/Dispatcher/CartDispatcher/removeOutdatedItems/items/map/fetchMutation/then/dispatch/catch */
                        (error) => dispatch(showNotification('error', error[0].message))
                    );
                }

                return null;
            });
        } catch (error) {
            // Do nothing
        }
    }

    async applyCouponToCart(dispatch, couponCode) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId();

            if (!isCustomerSignedIn && !cartId) {
                return false;
            }

            const { applyCouponToCart: { cartData = {}, getCouponData = {} } = {} } = await fetchMutation(
                CartQuery.getApplyCouponMutation(couponCode, cartId)
            );

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was applied!')));
            dispatch(setPromosData(getCouponData));

            return true;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return false;
        }
    }

    async removeCouponFromCart(dispatch) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId();

            if (!isCustomerSignedIn && !cartId) {
                return;
            }

            const { removeCouponFromCart: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getRemoveCouponMutation(cartId)
            );

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification('success', __('Coupon was removed!')));
            dispatch(setPromosData({}));
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }

    async createGuestEmptyCart(dispatch) {
        try {
            dispatch(updateIsLoadingCart(true));

            const quoteId = await this._getNewQuoteId(dispatch);

            setCartId(quoteId);

            // to fetch the cart data and change the cart's isLoading to false
            this.updateInitialCartData(
                dispatch,
                false,
                false,
                false,
                true
            );

            return quoteId;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));

            return null;
        }
    }
}

export default new CartDispatcher();
