/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'Component/Router/Router.component';
import {
    CHECKOUT,
    HOME,
    MY_ACCOUNT
} from 'Component/Router/Router.config';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import DraggableKeyboard from '../../../component/DraggableKeyboard';
import IdleTimePopup from '../../../component/IdleTimePopup';
import { BIG_SCREEN_HOMEPAGE } from '../../../route/QrPage/QrPage.config';

export const CartPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'));
export const Checkout = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'));
export const Footer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'));
export const NoMatch = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "contact" */ 'Route/NoMatch'));
export const MyAccount = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'));
export const QrPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "qr" */ '../../../route/QrPage'));
export const HomePage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'));

/**
 * Router Component plugin
 * @namespace BigScreenExperienceGraphQl/Scandipwa/App/Plugin/RouterComponent/Plugin/RouterComponentPlugin/adjustedRoutes
 */
export const adjustedRoutes = (originalMember) => {
    const minPosition = originalMember.reduce(
        ({ position: prePosition }, { position: currPosition }) => (
            prePosition && prePosition < currPosition ? prePosition : currPosition
        )
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        {
            component: <Route
              path={ withStoreRegex('/') }
              exact
              render={ (props) => {
                  const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

                  // We won't show regular Home page on "Big Screen"
                  if (isBigScreen) {
                      history.replace(appendWithStoreCode(BIG_SCREEN_HOMEPAGE));

                      return <HomePage { ...props } />;
                  }

                  return <HomePage { ...props } />;
              } }
            />,
            position: minPosition - 2,
            name: HOME
        },
        {
            component: <Route
              path={ withStoreRegex('/qr/mobile') }
              render={ (props) => <QrPage { ...props } /> }
            />,
            position: minPosition - 2,
            name: 'QR'
        },
        {
            component: <Route
              path={ withStoreRegex('/checkout/:step?') }
              render={ (props) => {
                  const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

                  // We won't show checkout steps on "Big Screen"
                  if (!isBigScreen) {
                      return <Checkout { ...props } />;
                  }

                  history.replace(appendWithStoreCode('/cart'));

                  return <CartPage { ...props } />;
              } }
            />,
            position: 54,
            name: CHECKOUT
        },
        {
            component: <Route
              path={ withStoreRegex('/customer/account') }
              exact
              render={ (props) => {
                  const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

                  if (isBigScreen) {
                      return <NoMatch { ...props } />;
                  }

                  return <MyAccount { ...props } />;
              } }
            />,
            position: 70,
            name: MY_ACCOUNT
        },
        {
            component: <Route
              path={ withStoreRegex('/forgot-password') }
              render={ (props) => {
                  const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

                  if (isBigScreen) {
                      return <NoMatch { ...props } />;
                  }

                  return <MyAccount { ...props } />;
              } }
            />,
            position: minPosition - 1
        },
        {
            component: <Route
              path={ withStoreRegex(BIG_SCREEN_HOMEPAGE) }
              render={ (props) => {
                  const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

                  if (!isBigScreen) {
                      return <NoMatch { ...props } />;
                  }

                  return (
                    <HomePage { ...props } />
                  );
              } }
            />
        },
        ...originalMember
    ];
};

/**
 * Router Component plugin
 */
export class RouterComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        isKeyboardOpened: PropTypes.bool.isRequired
    });

    renderDefaultRouterContent = (args, callback, instance) => {
        const { isKeyboardOpened } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return (
                <>
                    { callback(...args) }
                    { this.renderIdleTimePopup() }
                    { isKeyboardOpened && this.renderDraggableKeyBoard() }
                </>
            );
        }

        return callback(...args);
    };

    renderIdleTimePopup() {
        return (
            <IdleTimePopup />
        );
    }

    renderDraggableKeyBoard() {
        return (
            <DraggableKeyboard />
        );
    }
}

const {
    propTypes,
    renderDefaultRouterContent
} = new RouterComponentPlugin();

export const config = {
    'Scandipwa/Component/Router/Component': {
        'member-property': {
            SWITCH_ITEMS_TYPE: adjustedRoutes
        },
        'member-function': {
            renderDefaultRouterContent
        },
        'static-member': {
            propTypes
        }
    }
};

export default config;
