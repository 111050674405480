/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import './DatePicker.styles.scss';

/** @namespace Scandipwa/Component/DatePicker/Component */
export class DatePickerComponent extends PureComponent {
    static propTypes = {
        days: PropTypes.array.isRequired,
        years: PropTypes.array.isRequired,
        months: PropTypes.array.isRequired,
        changeIfLeapYear: PropTypes.func.isRequired,
        changeDays: PropTypes.func.isRequired,
        getDate: PropTypes.func.isRequired,
        getDay: PropTypes.func.isRequired,
        dayWasChanged: PropTypes.bool.isRequired,
        monthWasChanged: PropTypes.bool.isRequired,
        yearWasChanged: PropTypes.bool.isRequired,
        selectedYear: PropTypes.number.isRequired,
        selectedMonth: PropTypes.number.isRequired,
        selectedDay: PropTypes.number.isRequired,
        isUnderage: PropTypes.bool.isRequired,
        errorMessage: PropTypes.string
    };

    static defaultProps = {
        errorMessage: ''
    };

    state = {
        isYearsOpened: false,
        isMonthsOpened: false,
        isDaysOpened: false
    };

    changeYearState = () => {
        const { isYearsOpened } = this.state;
        this.setState({
            isYearsOpened: !isYearsOpened
        });
    };

    changeMonthState = () => {
        const { isMonthsOpened } = this.state;

        this.setState({
            isMonthsOpened: !isMonthsOpened
        });
    };

    changeDayState = () => {
        const { isDaysOpened } = this.state;

        this.setState({
            isDaysOpened: !isDaysOpened
        });
    };

    checkIfNaN(num) {
        // eslint-disable-next-line no-restricted-globals
        return isNaN(num);
    }

    getOptionsWithPlaceholder = (array, placeholder, isMonth = false) => {
        const optionArray = array.map((item, index) => ({ label: item, value: isMonth ? index + 1 : item }));
        optionArray.unshift({
            label: placeholder,
            value: placeholder,
            isPlaceholder: true,
            isAvailable: false
        });

        return optionArray;
    };

    render() {
        const {
            days,
            years,
            months,
            changeIfLeapYear,
            changeDays,
            getDate,
            dayWasChanged,
            monthWasChanged,
            yearWasChanged,
            selectedYear,
            selectedMonth,
            selectedDay,
            getDay,
            isUnderage,
            errorMessage
        } = this.props;

        if (dayWasChanged && monthWasChanged && yearWasChanged) {
            if (isUnderage || this.checkIfNaN(selectedYear) || this.checkIfNaN(selectedMonth)
                || this.checkIfNaN(selectedDay)) {
                getDate('');
            } else {
                getDate(`${ selectedYear }-${ selectedMonth }-${ selectedDay }`);
            }
        }

        return (
            <div block="DatePicker" elem="Wrapper">
                <div
                  id="DatePicker"
                  block="DatePicker"
                  mods={ { isUnderage } }
                >
                    <div
                      block="DatePicker"
                      elem="Years"
                    >
                        <Field
                          type={ FIELD_TYPE.select }
                          attr={ {
                              id: 'years',
                              name: 'years',
                              placeholder: 'YYYY',
                              noPlaceholder: true
                          } }
                          mods={ { isValid: isUnderage } }
                          mix={ {
                              block: 'DatePicker',
                              elem: 'Dropdown',
                              mods: { isYearSelected: yearWasChanged }
                          } }
                          events={ {
                              onChange: changeIfLeapYear
                          } }
                          isRequired
                          options={ this.getOptionsWithPlaceholder(years, 'YYYY') }
                          isDatePicker
                          noSort
                          placeholder="YYYY"
                        />
                    </div>
                    <div
                      block="DatePicker"
                      elem="Months"
                    >
                        <Field
                          type={ FIELD_TYPE.select }
                          attr={ {
                              id: 'months',
                              name: 'months',
                              placeholder: 'MM',
                              noPlaceholder: true
                          } }
                          mix={ {
                              block: 'DatePicker',
                              elem: 'Dropdown',
                              mods: { isMonthSelected: monthWasChanged }
                          } }
                          events={ {
                              onChange: changeDays
                          } }
                          isRequired
                          // eslint-disable-next-line arrow-body-style
                          options={ this.getOptionsWithPlaceholder(months, 'MM', true) }
                          noSort
                          isDatePicker
                        />
                    </div>
                    <div
                      block="DatePicker"
                      elem="Days"
                    >
                        <Field
                          type={ FIELD_TYPE.select }
                          attr={ {
                              id: 'days',
                              name: 'days',
                              placeholder: 'DD',
                              noPlaceholder: true
                          } }
                          mix={ {
                              block: 'DatePicker',
                              elem: 'Dropdown',
                              mods: { isDaySelected: dayWasChanged }
                          } }
                          events={ {
                              onChange: getDay
                          } }
                          isRequired
                          options={ this.getOptionsWithPlaceholder(days, 'DD') }
                          noSort
                          isDatePicker
                        />
                    </div>
                </div>
                { isUnderage
                    && (
                        <span block="FieldInput" elem="Error">
                            <div block="Field" elem="ErrorMessage">
                                { errorMessage || __('You must be at least 18 years old to register.') }
                            </div>
                        </span>
                    ) }
            </div>
        );
    }
}

export default DatePickerComponent;
