/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { getAuthorizationToken } from 'Util/Auth';

import BigScreenNewSessionPopup from './BigScreenNewSessionPopup.component';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Bigscreen/Component/BigScreenNewSessionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    resetCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, getAuthorizationToken())
    ),
    resetGuestCart: () => CartDispatcher.then(
        ({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        }
    ),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Bigscreen/Component/BigScreenNewSessionPopup/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Bigscreen/Component/BigScreenNewSessionPopup/Container */
export class BigScreenNewSessionPopupContainer extends PureComponent {
    static propTypes = {
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    render() {
        return (
            <BigScreenNewSessionPopup
              { ...this.props }
              { ...this.state }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BigScreenNewSessionPopupContainer);
