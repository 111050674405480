/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/no-did-update-set-state */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import {
    // eslint-disable-next-line no-unused-vars
    IMAGE_LOADED, IMAGE_LOADING, IMAGE_NOT_FOUND, IMAGE_NOT_SPECIFIED
} from 'Component/Image/Image.config';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';

import './Image.extended.style';

/**
 * Image component
 * Images are loaded only when they appear in a viewport
 * @class Image
 * @namespace Scandipwa/Component/Image/Component */
export class ImageComponent extends SourceImage {
    static propTypes = {
        ...SourceImage.propTypes,
        srcset: PropTypes.string,
        sizes: PropTypes.string,
        onLoad: PropTypes.func,
        isPreventBlink: PropTypes.bool
    };

    static defaultProps = {
        ...SourceImage.defaultProps,
        isPreventBlink: false
    };

    // onError() {
    //     const { onError } = this.props;

    //     if (onError) {
    //         onError();
    //     }

    //     this.setState({ imageStatus: IMAGE_NOT_FOUND });
    // }

    // onLoad() {
    //     const { onLoad } = this.props;

    //     if (onLoad) {
    //         onLoad();
    //     }

    //     this.setState({ imageStatus: IMAGE_LOADED });
    // }

    // renderImage() {
    //     const {
    //         alt,
    //         isPlaceholder,
    //         style,
    //         sizes,
    //         imageratio
    //     } = this.props;

    //     const {
    //         imageStatus,
    //         prevSrc,
    //         prevSrcSet
    //     } = this.state;

    //     if (isPlaceholder) {
    //         return null;
    //     }

    //     const loadingStyle = (imageStatus === IMAGE_LOADING && imageratio)
    //         ? { height: `calc(100vw / ${ imageratio })` } : {};

    //     switch (imageStatus) {
    //     case IMAGE_NOT_FOUND:
    //         return this.renderImageNotFound();
    //     case IMAGE_NOT_SPECIFIED:
    //         return (
    //             <span block="Image" elem="Content">{ __('Image not specified') }</span>
    //         );
    //     case IMAGE_LOADED:
    //     case IMAGE_LOADING:
    //         return (
    //             <img
    //               block="Image"
    //               elem="Image"
    //               srcSet={ prevSrcSet }
    //               src={ prevSrc || '' }
    //               sizes={ sizes }
    //               alt={ alt }
    //               mods={ { isLoading: imageStatus === IMAGE_LOADING } }
    //               style={ { ...style, ...loadingStyle } }
    //               onLoad={ this.onLoad }
    //               onError={ this.onError }
    //               loading={ navigator.userAgent.includes('render') ? 'eager' : 'lazy' }
    //             />
    //         );
    //     default:
    //         return null;
    //     }
    // }
}

export default ImageComponent;
