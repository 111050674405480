/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { UPDATE_CURRENT_CATEGORY } from 'SourceStore/Category/Category.action';

import { UPDATE_PREV_CATEGORY_ID } from './Category.action';

/** @namespace Scandipwa/Store/Category/Reducer/getInitialState */
export const getInitialState = () => ({
    category: {},
    hasSubCategories: false,
    prevCategoryId: false
});

/** @namespace Scandipwa/Store/Category/Reducer/CategoryReducer */
export const CategoryReducer = (
    state = getInitialState(),
    {
        type, category, prevCategoryId, category: { children = [] } = {}
    }
) => {
    switch (type) {
    case UPDATE_CURRENT_CATEGORY:
        return {
            ...state,
            category: { ...category },
            hasSubCategories: !!children.length
        };

    case UPDATE_PREV_CATEGORY_ID:
        return {
            ...state,
            prevCategoryId
        };

    default:
        return state;
    }
};

export default CategoryReducer;
