/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const PAGE_TYPE_HOME_PAGE = 'home-page';

export class HomePageContainerPlugin {
    componentDidMount = (args, callback) => {
        window.pageType = PAGE_TYPE_HOME_PAGE;

        return callback(...args);
    };
}

const {
    componentDidMount
} = new HomePageContainerPlugin();

export const config = {
    'Scandipwa/Route/HomePage/Container': {
        'member-function': {
            componentDidMount
        }
    }
};

export default config;
