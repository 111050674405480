/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const ADYEN_PAYMENTS_ID = 'ADYEN_PAYMENTS_ID';
export const THREE_DS2_POPUP_ID = 'adyen-three-ds-popup';
export const THREE_DS2_DIV_ID = 'adyen-three-ds-div';

export const AWAIT_CODE = 'await';
export const THREE_DS2_CODE = 'threeDS2';
