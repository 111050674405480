/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

// TODO: Lazy import?
import ForterContainer from '../component/Forter/Forter.container';

export const MAX_POSITION = 1000;
export const INCREASED_POSITION = 10;

/** @namespace Fortergraphql/Scandipwa/App/Plugin/RouterComponent/Plugin/addForterComponent */
export const addForterComponent = (member) => {
    const maxPosition = Math.max(
        ...member.map((route) => route.position).filter((num) => num <= MAX_POSITION)
    );

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
    return [
        ...member,
        {
            component: <ForterContainer />,
            position: maxPosition + INCREASED_POSITION,
            name: 'FORTER_ANALYTICS_SCRIPT'
        }
    ];
};

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: addForterComponent
        }
    }
};
