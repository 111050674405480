/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';

import DotMailerTracking from '../util/DotMailerTracking';

/**
 * Router container plugin
 */
class RouterContainerPlugin {
    state = (originalMember) => ({
        ...originalMember,
        currentLocation: ''
    });

    aroundComponentDidUpdate = (args, callback = () => {}, instance) => {
        const { currentLocation } = instance.state;
        const { location: { pathname } } = history;

        const isDotMailerScriptLoaded = typeof window.dmPt === 'function';

        if (isDotMailerScriptLoaded && (!currentLocation || currentLocation !== pathname)) {
            instance.setState({ currentLocation: pathname });

            window.dmPt('track');
        }

        return callback(...args);
    };

    aroundComponentDidMount = (args, callback = () => {}) => {
        const { email = '' } = BrowserDatabase.getItem('customer') || {};

        DotMailerTracking.initDotMailerTrackingScript();

        if (email) {
            window.dmPt('identify', email);
        }

        return callback(...args);
    };
}

const {
    state,
    aroundComponentDidMount,
    aroundComponentDidUpdate
} = new RouterContainerPlugin();

const config = {
    'Component/Router/Container': {
        'member-function': {
            componentDidMount: aroundComponentDidMount,
            componentDidUpdate: aroundComponentDidUpdate
        },
        'member-property': {
            state
        }
    }
};

export default config;
