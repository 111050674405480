/**
 * Adyen Payments compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';

/**
 * In order to set the loading status down inside the AdyenCheckoutContainer for when the popup gets closed, we need to drill the setter down
 * @namespace AdyenPayments/Scandipwa/Plugin/Component/CheckoutPayments/Container
 */
class CheckoutPaymentsContainer {
    propTypes = (originalMember) => ({
        ...originalMember,
        setCheckoutContainerOrderId: PropTypes.func.isRequired,
        setCheckoutContainerCheckoutStep: PropTypes.func.isRequired
    });

    containerProps = (args, callback, instance) => {
        const {
            setLoading: setCheckoutContainerLoading,
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        } = instance.props;

        return {
            ...callback(...args),
            setCheckoutContainerLoading,
            setCheckoutContainerOrderId,
            setCheckoutContainerCheckoutStep
        };
    };
}

const { propTypes, containerProps } = new CheckoutPaymentsContainer();

export default {
    'Component/CheckoutPayments/Container': {
        'static-member': {
            propTypes
        },
        'member-function': {
            containerProps
        }
    }
};
