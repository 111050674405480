/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * Copyright © Scandiweb, Inc. All rights reserved.
 *
 * @category    Puma
 * @package     Puma_SimpleSearch
 * @author      Deniss Strombergs <info@scandiweb.com>
 */

import {
    createRef,
    PureComponent
} from 'react';

import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import './SimpleSearch.style';

/** @namespace Scandipwa/Component/SimpleSearch/Component */
export class SimpleSearchComponent extends PureComponent {
    state = {
        searchCriteria: ''
    };

    searchBarRef = createRef();

    handleChange = (e) => {
        this.onSearchBarChange(e);
    };

    onSearchEnterPress = (e) => {
        const { searchCriteria } = this.state;
        const search = searchCriteria.trim().replace(/\s\s+/g, '%20');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search }`));
            this.onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };

    onSearchBarChange({ target: { value: searchCriteria } }) {
        this.setState({ searchCriteria });
    }

    render() {
        const {
            searchCriteria
        } = this.state;

        return (
            <div block="SimpleSearch">
                <input
                  id="simple-search"
                  ref={ this.searchBarRef }
                  block="SimpleSearch"
                  elem="Input"
                  onChange={ this.handleChange }
                  onKeyDown={ this.onSearchEnterPress }
                  value={ searchCriteria }
                  mods={ { isActive: true } }
                  autoComplete="off"
                />
                <button
                  block="SimpleSearch"
                  elem="Submit"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => this.onSearchEnterPress({ key: 'Enter' }) }
                >
                    { __('Find') }
                </button>
            </div>
        );
    }
}

export default SimpleSearchComponent;
