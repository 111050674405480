/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import BrowserDatabase from 'Util/BrowserDatabase';

export const PAGE_TYPE_PDP = 'pdp';
export const PAGE_TYPE_PRODUCT = 'product';
export const PAGE_TYPE_CATEGORY = 'category';
export const PAGE_TYPE_CHECKOUT = 'checkout';
export const PAGE_TYPE_ACCOUNT = 'customer_account';
export const PAGE_TYPE_ACCOUNT_ADD_ADDRESS = 'account_add_address';
export const PAGE_TYPE_CMS = 'cms-page';
export const PAGE_TYPE_CART = 'cart';
export const PAGE_TYPE_SEARCH = 'search';
export const PAGE_TYPE_HOME = 'home';

export const MOBILE_BP = 768;
export const TABLET_BP = 1024;
export const MOBILE_VIEWPORT = 'mobile';
export const TABLET_VIEWPORT = 'tablet';
export const DESKTOP_VIEWPORT = 'desktop';

export const PAGE_CART_UNIQUE_ID = 'checkout_cart_index';
export const PAGE_CHECKOUT_UNIQUE_ID = 'checkout_index_index';
export const PAGE_ACCOUNT_LOGIN_UNIQUE_ID = 'customer_account_login';
export const PAGE_ACCOUNT_DASHBOARD_UNIQUE_ID = 'customer_account_index';
export const PAGE_HOME_UNIQUE_ID = 'cms_index_index';
export const PAGE_CMS_UNIQUE_ID = 'cms_page_view';
export const PAGE_SITEMAP_UNIQUE_ID = 'sitemap_index_index';

/** @namespace Gtm/Component/GoogleTagManager/Events/PageDataEvent/Handlers/NavigationPageDataEvent/Handler */
export class NavigationPageDataEventHandler {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/use-magic-construct
    constructor(appState, pageType, data) {
        const { specialPageType } = data;

        this.appState = appState;
        this.pageType = specialPageType || pageType;
    }

    pageTypeList = [
        {
            type: PAGE_TYPE_PDP,
            modifiedPageType: 'product detail'
        },
        {
            type: PAGE_TYPE_PRODUCT,
            modifiedPageType: 'product detail'
        },
        {
            type: PAGE_TYPE_CATEGORY,
            modifiedPageType: 'product listing'
        },
        {
            type: PAGE_TYPE_CHECKOUT,
            exceptionPageType: 'order confirmation',
            regex: /success/
        },
        {
            type: PAGE_TYPE_ACCOUNT,
            modifiedPageType: 'account'
        },
        {
            type: PAGE_TYPE_ACCOUNT_ADD_ADDRESS,
            modifiedPageType: 'account'
        },
        {
            type: PAGE_TYPE_CMS,
            exceptionPageType: 'contact',
            regex: /contact-us/
        }
    ];

    modifiedSubSections = {
        [PAGE_TYPE_PDP]: {
            subSection1: 'product details'
        },
        [PAGE_TYPE_PRODUCT]: {
            subSection1: 'product details'
        },
        [PAGE_TYPE_CHECKOUT]: {
            subSection1: 'checkout'
        },
        [PAGE_TYPE_CART]: {
            subSection1: 'checkout'
        },
        [PAGE_TYPE_ACCOUNT]: {
            subSection1: 'account'
        },
        [PAGE_TYPE_ACCOUNT_ADD_ADDRESS]: {
            subSection1: 'account',
            subSection2: 'address'
        }
    };

    /**
     * Returns navigation object data for pageData object
     *
     * @returns {{pageType: (*), subSection3: string, subSection4: string, subSection1: string, subSection2: string, contentTitle: *, destinationURL: string, localLanguage: *}}
     */
    getNavigationData(data) {
        this.updateEventData(data);

        return {
            contentTitle: this.getContentTitle(),
            destinationURL: this.getDestinationUrl(),
            localLanguage: this.getLanguage(),
            pageType: this.getPageType() || this.pageType,
            currency: this.getCurrency(),
            environment: this.getEnvironment(),
            localeCountry: this.getLocaleCountry(),
            viewport: this.getViewport(),
            uniquePageId: this.getUniquePageId(),
            site: this.getSite(),
            releaseVersion: this.getReleaseVersion(),
            ...this.getSubSections()
        };
    }

    /**
     * Updates event object data for pageData object as it depends on pageType value
     *
     * @param data
     */
    updateEventData(data) {
        const {
            eventData,
            updateEventData
        } = data;

        if (eventData) {
            eventData.eventType = this.getPageType() || this.pageType;
        }

        if (updateEventData) {
            updateEventData(eventData);
        }
    }

    /**
     * Returns page type
     *
     * @returns {*}
     */
    getPageType() {
        const { location: pathname } = window;

        const pageType = this.pageTypeList.find(({ type }) => type === this.pageType) || {};

        const { regex, exceptionPageType, modifiedPageType } = pageType;

        if (regex && regex.test(pathname)) {
            return exceptionPageType;
        }

        return modifiedPageType;
    }

    /**
     * Returns the entire url where action is taking place
     *
     * @returns {string}
     */
    getDestinationUrl() {
        const { location: { href } } = window;

        return href;
    }

    /**
     * Returns store language
     *
     * @returns {*}
     */
    getLanguage() {
        const {
            ConfigReducer: {
                locale,
                code
            }
        } = this.appState;

        return locale || code;
    }

    /**
     * Returns page currency
     *
     * @return {string}
     */
    getCurrency() {
        const {
            ConfigReducer: {
                default_display_currency_code
            }
        } = this.appState;

        return default_display_currency_code;
    }

    /**
     * Returns ENV type
     *
     * @return {string}
     */
    getEnvironment() {
        return process.env.NODE_ENV || 'development';
    }

    /**
     * Returns locale country
     *
     * @returns {string}
     */
    getLocaleCountry() {
        const {
            ConfigReducer: {
                default_country
            }
        } = this.appState;

        return default_country;
    }

    /**
     * Returns viewport type according to window width
     *
     * @returns {string}
     */
    getViewport() {
        const { innerWidth: windowWidth } = window;

        if (windowWidth < MOBILE_BP) {
            return MOBILE_VIEWPORT;
        }

        if (windowWidth < TABLET_BP) {
            return TABLET_VIEWPORT;
        }

        return DESKTOP_VIEWPORT;
    }

    /**
     * Return unique page id according to page type
     *
     * @returns {string}
     */
    getUniquePageId() {
        const {
            CategoryReducer: {
                category: {
                    name: categoryName
                } = {}
            },
            ProductReducer: {
                product: {
                    name: productName
                } = {}
            },
            UrlRewritesReducer: {
                urlRewrite: {
                    id
                }
            }
        } = this.appState;

        switch (this.pageType) {
        case PAGE_TYPE_PDP:
        case PAGE_TYPE_PRODUCT:
            return productName;
        case PAGE_TYPE_CATEGORY:
            return categoryName;
        case PAGE_TYPE_ACCOUNT:
            // eslint-disable-next-line no-extra-boolean-cast
            return !!BrowserDatabase.getItem('auth_token') // Check if user is authorized
                ? PAGE_ACCOUNT_DASHBOARD_UNIQUE_ID
                : PAGE_ACCOUNT_LOGIN_UNIQUE_ID;
        case PAGE_TYPE_CART:
            return PAGE_CART_UNIQUE_ID;
        case PAGE_TYPE_CHECKOUT:
            return PAGE_CHECKOUT_UNIQUE_ID;
        case PAGE_TYPE_HOME:
            return PAGE_CMS_UNIQUE_ID;
        case PAGE_TYPE_CMS:
            return this.isPageSiteMap()
                ? PAGE_SITEMAP_UNIQUE_ID
                : `${PAGE_CMS_UNIQUE_ID}_page_id=${id}`;
        default:
            return this.pageType;
        }
    }

    /**
     * Returns site
     *
     * @returns {string}
     */
    getSite() {
        return 'mx shop';
    }

    /**
     * Returns release version - version number from a static files
     * Works only in production mode. For dev mode returns null
     *
     * @returns {string|null}
     */
    getReleaseVersion() {
        const { publicPath } = window;

        if (!publicPath || publicPath.length <= 1) {
            return null;
        }

        const regex = '/version(.*)/frontend';
        const versionNumber = publicPath.match(new RegExp(regex));

        return versionNumber?.[1];
    }

    /**
     * Returns content title
     *
     * @returns {*}
     */
    getContentTitle() {
        const {
            MetaReducer: {
                title
            },
            CategoryReducer: {
                category: {
                    name: categoryName
                }
            },
            ProductReducer: {
                product: {
                    name: productName
                }
            }
        } = this.appState;

        switch (this.pageType) {
        case PAGE_TYPE_PDP:
        case PAGE_TYPE_PRODUCT:
            return productName;
        case PAGE_TYPE_CATEGORY:
            return categoryName;
        default:
            return title;
        }
    }

    /**
     * Returns url subsections
     *
     * @returns {{subSection3: string, subSection4: string, subSection1: string, subSection2: string}}
     */
    getSubSections() {
        const { location: { pathname } } = window;
        const { ConfigReducer: { code } } = this.appState;

        const subSections = pathname.split('/').filter((pathname) => pathname !== code);
        const modifiedSubSections = this.modifiedSubSections[this.pageType];

        if (modifiedSubSections) {
            return modifiedSubSections;
        }

        return {
            subSection1: subSections[1] || '',
            subSection2: subSections[2] || '',
            subSection3: subSections[3] || '',
            subSection4: subSections[4] || ''
        };
    }

    /**
     * Return if current cms page is sitemap page
     *
     * @returns {boolean}
     */
    isPageSiteMap() {
        const { location: { pathname } } = window;

        return (new RegExp('sitemap')).test(pathname);
    }
}

export default NavigationPageDataEventHandler;
