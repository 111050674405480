/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

/**
 * Popup Container plugin
 */
export class PopupContainerPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        isUnCloseable: PropTypes.bool
    });

    defaultProps = (originalMember) => ({
        ...originalMember,
        isUnCloseable: false
    });

    mapStateToProps = (args, callback, _instance) => {
        const [state] = args;

        return {
            ...callback(...args),
            isKeyboardOpened: state.KeyboardReducer.isKeyboardOpened
        };
    };

    containerProps = (args, callback, instance) => {
        const {
            isUnCloseable,
            isKeyboardOpened
        } = instance.props;

        return {
            ...callback(...args),
            isUnCloseable,
            isKeyboardOpened
        };
    };
}

const {
    propTypes,
    mapStateToProps,
    defaultProps,
    containerProps
} = new PopupContainerPlugin();

export const config = {
    'Scandipwa/Component/Popup/Container': {
        'static-member': {
            propTypes,
            defaultProps
        },
        'member-function': {
            containerProps
        }
    },
    'Component/Popup/Container/mapStateToProps': {
        function: mapStateToProps
    }
};

export default config;
