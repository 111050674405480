import { MAX_ADDRESS_LENGTH } from 'Component/Form/Form.config';
import { VALIDATION_INPUT_TYPE_NUMBER, VALIDATION_MESSAGES, VALIDATION_RULES } from 'Util/Validator/Config';

export * from 'SourceUtil/Validator/Validator';

/**
 * Validates parameter based on rules
 * @param value
 * @param rule
 * @returns {boolean|{errorMessages: *[], value}}
 * @namespace Scandipwa/Util/Validator/validate */
export const validate = (value, rule) => {
    const {
        isRequired,
        inputType,
        match,
        range,
        fileExtension,
        telephone,
        isAddressMaxLength,
        customErrorMessages: {
            onRequirementFail,
            onInputTypeFail,
            onMatchFail,
            onRangeFailMin,
            onRangeFailMax,
            onExtensionFail
        } = {}
    } = rule;

    const output = {
        value,
        errorMessages: []
    };

    // #region IS REQUIRED
    if (isRequired && !value) {
        output.errorMessages.push(onRequirementFail || VALIDATION_MESSAGES.isRequired);
    }
    // #endregion

    if (isAddressMaxLength && value.length > MAX_ADDRESS_LENGTH) {
        output.errorMessages.push(VALIDATION_MESSAGES.addressMaxLength);
    }

    // #region INPUT TYPE
    if (
        inputType
        && value
        && typeof value === 'string'
        && !value.match(VALIDATION_RULES[inputType])
        && inputType !== 'address'
    ) {
        output.errorMessages.push(onInputTypeFail || VALIDATION_MESSAGES[inputType]);
    }
    // #endregion

    if (inputType === 'address' && !!VALIDATION_RULES[inputType].test(value)) {
        output.errorMessages.push(onInputTypeFail || VALIDATION_MESSAGES.address);
    }

    // #region MATCH
    if (typeof match === 'function') {
        const response = match(value);

        if (response !== true) {
            output.errorMessages.push(response === false ? onMatchFail || VALIDATION_MESSAGES.match : response);
        }
    } else if (match && !value.match(match)) {
        output.errorMessages.push(onMatchFail || VALIDATION_MESSAGES.match);
    }
    // #endregion

    if (telephone && telephone.currentLength < telephone.minLength) {
        output.errorMessages.push(VALIDATION_MESSAGES[inputType]);
    }

    // #region RANGE
    if (range) {
        const { min, max, showLengthError } = range;
        const isNumber = !!VALIDATION_INPUT_TYPE_NUMBER[inputType];

        if (isNumber) {
            if (min && +value < min) {
                output.errorMessages.push(onRangeFailMin || __('Minimal value is %s!', min));
            }

            if (max && +value > max) {
                output.errorMessages.push(onRangeFailMax || __('Maximum value is %s!', max));
            }
        } else {
            if (min && value.length < min && value.length > 0) {
                output.errorMessages.push(onRangeFailMin || __('Minimum %s characters!', min));
            }

            if (max && value.length > max) {
                const tooMany = value.length - max;

                output.errorMessages.push(onRangeFailMax || __('Maximum %s characters (%s too many)', max, tooMany));

                if (showLengthError) {
                    output.errorMessages.unshift(__('Please enter no more than %s characters.', max));
                }
            }
        }
    }

    if (fileExtension && value !== '') {
        const { accept } = fileExtension;
        const acceptedExtensions = accept.split(', ');
        const currentFileExtension = value.split('.').pop();

        if (!acceptedExtensions.includes(currentFileExtension)) {
            output.errorMessages.push(onExtensionFail || VALIDATION_MESSAGES.fileExtension);
        }
    }
    // #endregion

    const { errorMessages } = output;

    return errorMessages.length === 0 ? true : output;
};

/** @namespace Scandipwa/Util/Validator/validatePostalCodeAgainstState */
export const validatePostalCodeAgainstState = (value, props) => {
    const {
        postcodeStateMapping,
        selectedState: { code = '' } = {}
    } = props;

    if (!code || !postcodeStateMapping) {
        return false;
    }

    const statePostCodes = postcodeStateMapping[code];

    if (!statePostCodes) {
        return false;
    }

    return !!statePostCodes.includes(value.substring(0, 2));
};
