/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { noopFn } from '@scandipwa/scandipwa/src/util/Common';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DESKTOP_FORGOT_PASSWORD_POPUP } from 'Component/ForgotPassword/ForgotPassword.config';
import {
    PASSWORD_LENGTH, STATE_LOGGED_IN
} from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL, SHIPPING_URL } from 'Route/Checkout/Checkout.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import transformToNameValuePair from 'Util/Form/Transform';
import history from 'Util/History';
import { getErrorMessage } from 'Util/Request';
import { appendWithStoreCode } from 'Util/Url';

import MyAccountSignIn from './MyAccountSignIn.component';
import { SIGN_CONFIRM_EMAIL_ID, SIGN_EMAIL_ID, SIGN_PASSWORD_ID } from './MyAccountSignIn.config';

export { mapStateToProps } from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Scandipwa/Component/MyAccountSignIn/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showPopup: (payload) => dispatch(showPopup('title', payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Scandipwa/Component/MyAccountSignIn/Container */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...SourceMyAccountSignInContainer.propTypes,
        hideActiveOverlay: PropTypes.func.isRequired,
        isNoTitle: PropTypes.bool,
        isSignedIn: PropTypes.bool.isRequired,
        showOverlay: PropTypes.func.isRequired,
        showPopup: PropTypes.func.isRequired,
        onConfirmationEmailChange: PropTypes.func,
        onEmailChange: PropTypes.func
    };

    static defaultProps = {
        ...SourceMyAccountSignInContainer.defaultProps,
        isCheckout: false,
        isNoTitle: false,
        onEmailChange: noopFn,
        onConfirmationEmailChange: noopFn
    };

    state = {
        emailLogin: '',
        emailMatchLogin: '',
        isSignIn: false,
        isSignInDisabled: true,
        passwordLogin: '',
        showForgotPasswordPopup: false
    };

    stateMap = {
        [SIGN_EMAIL_ID]: 'emailLogin',
        [SIGN_CONFIRM_EMAIL_ID]: 'emailMatchLogin',
        [SIGN_PASSWORD_ID]: 'passwordLogin'
    };

    containerFunctions = {
        ...this.containerFunctions,
        handleForgotPassword: this.handleForgotPassword.bind(this),
        onDataChange: this.onDataChange.bind(this),
        onSignInSuccess: this.onSignInSuccess.bind(this)
    };

    containerProps() {
        const { isNoTitle } = this.props;
        const { isSignInDisabled, showForgotPasswordPopup } = this.state;

        return {
            ...super.containerProps(),
            isNoTitle,
            isSignInDisabled,
            showForgotPasswordPopup
        };
    }

    componentDidMount() {
        const { emailValue } = this.props;

        if (emailValue && emailValue !== '') {
            const { onConfirmationEmailChange } = this.props;

            this.setState({ emailLogin: emailValue, emailMatchLogin: emailValue });
            // Also change the confirmation email in the CheckoutContainer because otherwise it never gets updated if the customer doesn't touch the field anymore
            onConfirmationEmailChange(emailValue);
        }
    }

    componentDidUpdate(prevProps) {
        const { state: oldMyAccountState } = prevProps;
        const { state: newMyAccountState } = this.props;
        const { location: { pathname } } = history;

        const {
            // isSignedIn,
            hideActiveOverlay
        } = this.props;

        this.enableDisableSignIn();

        if (oldMyAccountState === newMyAccountState) {
            return;
        }

        if (oldMyAccountState !== newMyAccountState) {
            hideActiveOverlay();
        }

        if (!pathname.includes(CHECKOUT_URL) && newMyAccountState === STATE_LOGGED_IN) {
            history.push({ pathname: appendWithStoreCode('/customer/account') });
        }
    }

    enableDisableSignIn() {
        const {
            emailLogin,
            emailMatchLogin,
            passwordLogin
        } = this.state;

        if (emailLogin
            && emailMatchLogin
            && passwordLogin
            && passwordLogin.length > PASSWORD_LENGTH) {
            this.setState({ isSignInDisabled: false });
        } else {
            this.setState({ isSignInDisabled: true });
        }
    }

    async onSignInSuccess(form, fields) {
        const {
            isCheckout,
            onSignIn,
            setLoadingState,
            showNotification,
            signIn
        } = this.props;
        const { isSignIn } = this.state;

        setLoadingState(true);
        const fieldPairs = transformToNameValuePair(fields);

        if (!isSignIn) {
            this.setState({ isSignIn: true });

            try {
                await signIn(fieldPairs);
                onSignIn();
            } catch (error) {
                showNotification('error', getErrorMessage(error));
                this.setState({ isSignIn: false });
            } finally {
                setLoadingState(false);
            }
        } else {
            setLoadingState(false);
        }

        window.clarity('event', 'signin');

        if (isCheckout) {
            history.push({ pathname: appendWithStoreCode(SHIPPING_URL) });
        }
    }

    onDataChange(event, field) {
        const { value = '', id } = field;
        const stateField = this.stateMap[id];
        const state = { [stateField]: value };

        // This should call onEmailChange() to update the CheckoutContainer state in case any data is already in there
        // Otherwise it will prevent continuing to the next step
        if (id === SIGN_EMAIL_ID) {
            const { handleEmailInput } = this.props;

            handleEmailInput(event, field);
        }

        // This should call onConfirmationEmailChange() to update the CheckoutContainer state in case any data is already in there
        // Otherwise it will prevent continuing to the next step
        if (id === SIGN_CONFIRM_EMAIL_ID) {
            const { onConfirmationEmailChange } = this.props;

            onConfirmationEmailChange(value);
        }

        this.setState(state);
    }

    handleForgotPassword() {
        const { showOverlay, showPopup } = this.props;

        this.setState({ showForgotPasswordPopup: true }, () => {
            showPopup(__('Forgot your password?'));
            showOverlay(DESKTOP_FORGOT_PASSWORD_POPUP);
        });
    }

    render() {
        return (
            <MyAccountSignIn
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
