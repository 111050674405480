/* eslint-disable indent */
/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { ONE_SECOND_IN_MILLISECONDS } from './Timer.config';

/** @namespace Bigscreen/Component/Timer/Component */
export class TimerComponent extends PureComponent {
    static propTypes = {
        time: PropTypes.number.isRequired,
        onTimeEnd: PropTypes.func
    };

    static defaultProps = {
        onTimeEnd: () => {}
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { onTimeEnd } = this.props;

        this.timeout = setInterval(() => {
            this.setState(({ time }) => ({
                time: time - 1
            }), () => {
                const { time } = this.state;

                if (time === 0) {
                    clearInterval(this.timeout);
                    onTimeEnd();
                }
            });
        }, ONE_SECOND_IN_MILLISECONDS);
    }

        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
        __construct(props) {
            super.__construct(props);

            const { time } = this.props;

            this.state = {
                time
            };
        }

    renderContent() {
        const { time } = this.state;

        return (
          <div>{ `${ time } ${ __('seconds') } ` }</div>
        );
    }

    render() {
        return (
          <div block="Timer">
              { this.renderContent() }
          </div>
        );
    }
}

export default TimerComponent;
