/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @author Janis Verins <janis.verins@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import AttributesQuery from 'Query/Attributes.query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { fetchQuery } from 'Util/Request';

import {
    PAGE_TYPE_CART,
    PAGE_TYPE_CATEGORY,
    PAGE_TYPE_CHECKOUT,
    PAGE_TYPE_PDP,
    PAGE_TYPE_SEARCH
} from './NavigationPageDataEvent.handler';

export const PLP_DATA_OBJECT = 'products';

export class ProductsPageDataEventHandler {
    constructor(appState) {
        this.appState = appState;
    }

    pageProducts = {
        // TODO Fix data retrieval for case when pageType is 'pdp' in this.getProductsPageData()
        [PAGE_TYPE_PDP]: () => [],
        [PAGE_TYPE_CATEGORY]: this.getProducts.bind(this),
        [PAGE_TYPE_SEARCH]: this.getProducts.bind(this),
        [PAGE_TYPE_CART]: this.getChangedQuantityProducts.bind(this),
        [PAGE_TYPE_CHECKOUT]: this.getProductsPurchased.bind(this)
    };

    getProductAttributesDataFromBrowserDatabase() {
        this.browserAttributeData = BrowserDatabase.getItem('productAttributes');

        if (!this.browserAttributeData) {
            return {};
        }

        return this.getProductAttributesData(this.browserAttributeData);
    }

    getProductAttributesData(data) {
        const { customAttributeMetadata: { items } } = data;
        const productAttributesData = {};

        items.forEach((item) => {
            const { attribute_code } = item;
            productAttributesData[attribute_code] = item;
        });

        return productAttributesData;
    }

    async setupProductAttributes() {
        this.productAttributesData = this.getProductAttributesDataFromBrowserDatabase();

        if (Object.keys(this.productAttributesData).length === 0) {
            try {
                const result = await fetchQuery(AttributesQuery.getProductAttributesQuery());

                this.browserAttributeData = result;
                this.productAttributesData = this.getProductAttributesData(result);

                const expireTime = 3600;
                BrowserDatabase.setItem(result, 'productAttributes', expireTime);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.warn(error);
            }
        }
    }

    async getProductsPageData(data, pageType) {
        await this.setupProductAttributes();

        const pageProducts = this.pageProducts[pageType](data);
        const dataLayerProducts = [];

        if (!pageProducts) {
            return [];
        }

        pageProducts.forEach((product) => {
            // If there is no id, it is promo tile
            if (product.id) {
                dataLayerProducts.push(this.getProductData(product));
            }
        });

        return dataLayerProducts;
    }

    getProductData(product) {
        const { oldQuantity = '', product: additionalProduct = {} } = product;
        const { oldQuantity: additionalOldQuantity, newQuantity: additionalNewQuantity } = additionalProduct;

        const hasAdditionalProduct = Object.keys(additionalProduct).length;
        const productData = !hasAdditionalProduct ? product : additionalProduct;
        const pageCategory = this.getPageCategory() || {};
        const isPageCategory = Object.keys(pageCategory).length;

        const { inStock, inventory, orderable } = this.getProductStatusData(productData);
        const productVariant = hasAdditionalProduct ? this.getVariantOfProduct(product) : {};
        const productPrimaryCategory = this.getProductAttribute(
            productVariant, product, 'primary_category_pim_id'
        ) || '';

        return {
            discount: this.getDiscountAmount(productData),
            inStock,
            inventory,
            orderable,
            price: this.getPrice(productData),
            productCategory: isPageCategory
                ? this.getPageCategoryName(pageCategory)
                : this.getProductCategoryName(productPrimaryCategory),
            category: isPageCategory ? this.getPageCategoryPimId(pageCategory) : productPrimaryCategory,
            productID: productData.id.toString(),
            productName: this.getProductName(productData),
            styleID: this.getProductAttributeValue(productData, 'style_number'),
            localName: '', // Not available from BE
            quantity: additionalNewQuantity || this.getQuantity(orderable),
            wasQuantity: additionalOldQuantity || oldQuantity,
            pricing: {
                basePrice: this.getProductBasePrice(productData),
                markdownAmount: this.getMarkdownAmount(productData),
                discount: this.getDiscountAmount(productData),
                price: this.getPrice(productData),
                subtotal: this.getProductAttribute(productVariant, productData, 'subtotal')
            },
            promos: { // Not available from BE(or I just do not know how to get it from there)
                id: '', // TODO
                code: '', // TODO
                name: '', // TODO
                type: '', // TODO
                amount: '' // TODO
            },
            discounted: this.getProductDiscountedStatus(productData),
            skuID: this.getProductSku(productData),
            bundle: this.isProductBundle(productData),
            set: '', // For now it stays empty
            markdown: this.getMarkdownStatus(productData),
            EAN: this.getProductAttribute(productVariant, productData, 'ean') || '',
            UPC: this.getProductAttribute(productVariant, productData, 'upc') || '',
            imageURL: this.getProductImageUrl(productData),
            season: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'season'),
            searchColor: '', // Not available from BE
            pumaColorDescription: this.getAttributeLabelFromBrowserDatabase(
                productVariant, productData, 'color_description'
            ),
            division: this.getAttributeLabelFromBrowserDatabase(
                productVariant, productData, 'product_division'
            ),
            lineName: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'line_name'),
            gender: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'gender'),
            colorCode: this.getAttributeLabelFromBrowserDatabase(
                productVariant, productData, 'refinement_color'
            ),
            labelColor: '', // Not available from BE
            ageGroup: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'age_group'),
            department: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'dept_code'),
            sport: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'sport_code'), // Incorrect value now is being returned
            class: '', // Not available from BE
            subCat: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'subcat_id'),
            productCollection: this.getAttributeLabelFromBrowserDatabase(
                productVariant, productData, 'collection'
            ),
            technology: this.getAttributeLabelFromBrowserDatabase(productVariant, productData, 'technology'),
            mainStyle: '' // Not available from BE
        };
    }

    getProducts() {
        // eslint-disable-next-line fp/no-let
        let products = [];

        const { ProductListReducer: { pages } } = this.appState;
        Object.keys(pages).forEach((page) => {
            products = products.concat(pages[page]);
        });

        return products;
    }

    getPageCategory() {
        const { CategoryReducer: { category } } = this.appState;

        return category;
    }

    getAttributeLabelFromBrowserDatabase(productVariant, product, attribute_code) {
        const attributeOption = this.getProductAttribute(productVariant, product, attribute_code) || '';
        const { attribute_options = [] } = this.productAttributesData[attribute_code] || {};

        if (attributeOption === '' || !attribute_options.length) {
            return '';
        }

        return attribute_options.reduce((accumulator, option) => {
            const { value, label } = option;

            if (value === attributeOption.toString()) {
                return label;
            }

            return accumulator;
        }, '');
    }

    getProductImageUrl(product) {
        const { variants = {}, thumbnail = {} } = product;
        const { url } = thumbnail || {};
        const { thumbnail: { url: firstVariantUrl } = {} } = variants[0] || {};

        if (firstVariantUrl) {
            return firstVariantUrl;
        }

        return url;
    }

    getProductAttributeValue(product, attribute_code) {
        try {
            const { attributes: { [attribute_code]: { attribute_value = '' } = {} } } = product;

            return attribute_value || '';
        } catch (e) {
            return '';
        }
    }

    getProductAttributeLabel(product, attribute_code) {
        try {
            const { attributes: { [attribute_code]: { attribute_value, attribute_options } } } = product;
            const { label } = attribute_options[attribute_value];

            return label;
        } catch (e) {
            return '';
        }
    }

    getProductAttribute(productVariant = {}, product, valueName) {
        if (productVariant && productVariant[valueName]) {
            return productVariant[valueName];
        }

        return product[valueName];
    }

    getProductSku(product) {
        const { sku } = product;

        return sku.toString();
    }

    isProductBundle(product) {
        const { type_id } = product;

        return type_id === 'bundle';
    }

    getProductDiscountedStatus(product) {
        return this.getDiscountAmount(product) === 0 ? 'false' : 'true';
    }

    getProductBasePrice(product) {
        const { price_range, variants = [] } = product;
        const actualProduct = price_range ? product : variants[0];

        const { price_range: { minimum_price: { regular_price: { value } } } } = actualProduct;

        return value.toFixed(2).toString();
    }

    getProductName(product) {
        const { name } = product;

        return name;
    }

    getPageCategoryId(pageCategory) {
        const { id } = pageCategory;

        return id.toString();
    }

    getPageCategoryPimId(pageCategory) {
        const { pim_id } = pageCategory;

        return pim_id;
    }

    getPageCategoryName(pageCategory) {
        const { name } = pageCategory;

        return name;
    }

    getPrice(product) {
        const value = product?.price_range?.minimum_price?.final_price?.value || 0;

        return value.toFixed(2).toString();
    }

    getDiscountAmount(product) {
        return product?.price_range?.minimum_price?.discount?.amount_off || 0;
    }

    getProductStatusData(product) {
        const { variants = [] } = product;
        const productStatusData = {
            inStock: 'false',
            inventory: 'not available',
            orderable: 'false'
        };

        const { length = 0 } = variants;

        // eslint-disable-next-line fp/no-loops,fp/no-let
        for (let i = 0; i < length; i++) {
            const { stock_status } = variants[i];

            if (stock_status === 'IN_STOCK') {
                productStatusData.inStock = 'true';
                productStatusData.inventory = 'available';
                productStatusData.orderable = 'true';

                break;
            }
        }

        return productStatusData;
    }

    getMarkdownAmount(product) {
        return this.getProductBasePrice(product) - this.getPrice(product);
    }

    getQuantity(orderable) {
        return orderable === 'true' ? 1 : 0;
    }

    getMarkdownStatus(product) {
        return this.getDiscountAmount(product) === 0 ? 'Full Price' : 'Promotion';
    }

    getProductsPurchased({ items } = {}) {
        if (!items) {
            return;
        }

        // eslint-disable-next-line consistent-return
        return items.reduce((acc, item) => {
            const { product } = item;

            acc.push(product);

            return acc;
        }, []);
    }

    getProductCategoryName(productPrimaryCategory) {
        if (!productPrimaryCategory) {
            return '';
        }

        return productPrimaryCategory
            .split('-')
            .map((string) => string.charAt(0).toUpperCase() + string.slice(1)).join(' ');
    }

    getChangedQuantityProducts(data) {
        const { changeQuantityProducts } = data;

        return changeQuantityProducts;
    }

    getVariantOfProduct(item) {
        const { product: { variants }, sku } = item;
        const { length } = variants;

        // eslint-disable-next-line fp/no-loops,fp/no-let
        for (let i = 0; i < length; i++) {
            const { sku: variantSku } = variants[i];

            if (variantSku === sku) {
                return variants[i];
            }
        }

        return {};
    }
}

export default ProductsPageDataEventHandler;
