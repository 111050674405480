/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getIndexedProducts } from 'Util/Product';

import {
    CLEAR_SEARCH_RESULTS,
    UPDATE_SEARCH_BAR,
    UPDATE_SEARCH_LOAD_STATUS
} from './SearchBar.action';

/** @namespace Scandipwa/Store/SearchBar/Reducer/getInitialState */
export const getInitialState = () => ({
    productsInSearch: [],
    suggestions: [],
    isLoading: true,
    display_suggestions: 0,
    display_results: 0
});

/** @namespace Scandipwa/Store/SearchBar/Reducer/SearchBarReducer */
export const SearchBarReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case UPDATE_SEARCH_BAR:
        const {
            result: {
                products: {
                    items,
                    suggestions,
                    display_suggestions,
                    display_results
                }
            }
        } = action;

        return {
            ...state,
            suggestions,
            productsInSearch: getIndexedProducts(items),
            display_suggestions,
            display_results
        };

    case UPDATE_SEARCH_LOAD_STATUS:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    case CLEAR_SEARCH_RESULTS:
        return {
            ...state,
            productsInSearch: getInitialState().productsInSearch,
            suggestions: [],
            display_suggestions,
            display_results
        };

    default:
        return state;
    }
};

export default SearchBarReducer;
