/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const UPDATE_FORTER_FIRST_SIX = 'UPDATE_FORTER_FIRST_SIX';
export const UPDATE_FORTER_LAST_FOUR = 'UPDATE_FORTER_LAST_FOUR';

/** @namespace Forter/Store/Forter/Action/updateForterFirstSix */
export const updateForterFirstSix = (firstSix) => ({
    type: UPDATE_FORTER_FIRST_SIX,
    firstSix
});

/** @namespace Forter/Store/Forter/Action/updateForterLastFour */
export const updateForterLastFour = (lastFour) => ({
    type: UPDATE_FORTER_LAST_FOUR,
    lastFour
});
