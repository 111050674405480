/* eslint-disable no-param-reassign */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
*/
import * as Sentry from '@sentry/react';
import { combineReducers, compose, createStore } from 'redux';

/**
 * Configure the store
 * @namespace Scandipwa/Util/Store/Index/configureStore */
export function configureStore(store) {
    // Add a dictionary to keep track of the registered async reducers
    store.asyncReducers = {};

    // eslint-disable-next-line no-console
    console.log('Theme store config');

    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    store.injectReducer = (key, asyncReducer) => {
        store.asyncReducers[key] = asyncReducer;
        store.replaceReducer(combineReducers(store.asyncReducers));
    };

    // Return the modified store
    return store;
}

/** @namespace Scandipwa/Util/Store/Index/noopReducer */
export const noopReducer = (state) => state;

export const getStore = (() => {
    // Initialize Sentry
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();

    // eslint-disable-next-line no-console
    console.log('Sentry Redux Enhancer');

    const composeEnhancers = (
        ( // enable Redux dev-tools only in development
            process.env.NODE_ENV === 'development'
            && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true
        })
    ) || compose;

    // Initialize the store
    const store = configureStore(createStore(
        noopReducer,
        composeEnhancers(
            sentryReduxEnhancer
        )
    ));

    return function storeGetter() {
        return store;
    };
})();

export default getStore;
