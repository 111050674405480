/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CUSTOMER } from 'Route/MyAccount/MyAccount.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

export const GUEST_EMAIL_KEY = 'guest_email';

/** @namespace Emarsys/Util/Helper/getCustomerEmail */
export const getCustomerEmail = () => {
    const email = isSignedIn()
        ? BrowserDatabase.getItem(CUSTOMER)?.email
        : BrowserDatabase.getItem(GUEST_EMAIL_KEY);

    return typeof email === 'string' ? email : null;
};
