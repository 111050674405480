/* eslint-disable react/boolean-prop-naming */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { MixType } from 'Type/Common.type';
import { LabelType } from 'Type/Field.type';
import { ProductPriceType, TierPricesType } from 'Type/Price.type';
import {
    calculateFinalPrice,
    formatCurrency,
    roundPrice
} from 'Util/Price';

import ProductPrice from './ProductPrice.component';
import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
} from './ProductPrice.config';

/** @namespace Scandipwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type
});

/** @namespace Scandipwa/Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/**
 * Product price
 * @class ProductPrice
 * @namespace Scandipwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends PureComponent {
    static propTypes = {
        price: ProductPriceType,
        isPreview: PropTypes.bool,
        priceType: PropTypes.oneOf(Object.values(PRODUCT_TYPE)),
        isSchemaRequired: PropTypes.bool,
        mix: MixType,
        displayTaxInPrice: PropTypes.string,
        tierPrices: TierPricesType,
        label: LabelType,
        variantsCount: PropTypes.number,
        disablePercentageLabel: PropTypes.bool
    };

    static defaultProps = {
        isPreview: false,
        isSchemaRequired: false,
        displayTaxInPrice: DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX,
        mix: {},
        price: {},
        priceType: PRODUCT_TYPE.simple,
        tierPrices: [],
        label: '',
        variantsCount: 0,
        disablePercentageLabel: false
    };

    containerProps() {
        const { price } = this.props;
        if (!price) {
            return {};
        }

        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency
                    } = {},
                    regular_price: {
                        value: regularPriceValue
                    } = {}
                } = {}
            } = {}
        } = this.props;

        if (!minimalPriceValue || !regularPriceValue) {
            return {};
        }

        const roundedRegularPrice = roundPrice(regularPriceValue);
        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);
        const roundedFinalPrice = roundPrice(finalPrice);
        const currency = formatCurrency(priceCurrency);

        return {
            roundedRegularPrice,
            priceCurrency,
            discountPercentage,
            roundedFinalPrice,
            currency
        };
    }

    render() {
        return (
            <ProductPrice
              { ...this.containerProps() }
            />
        );
    }
}

export default ProductPriceContainer;
