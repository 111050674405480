/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_ADDRESS_LENGTH = 45;

/** @namespace Scandipwa/Component/Form/Config/validateEmail */
export const validateEmail = ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

/** @namespace Scandipwa/Component/Form/Config/validateEmails */
export const validateEmails = ({ value }) => value.split(',').every((email) => validateEmail({ value: email.trim() }));

/** @namespace Scandipwa/Component/Form/Config/minLength */
export const minLength = ({ value }) => value.length >= MIN_PASSWORD_LENGTH;

/** @namespace Scandipwa/Component/Form/Config/validatePasswordStrength */
export const validatePasswordStrength = ({ value }) => value.match(/^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z0-9])).{8,}$/gm);

/** @namespace Scandipwa/Component/Form/Config/validateTelephone */
export const validateTelephone = (props) => {
    const {
        value,
        prefix,
        minLength,
        dataset
    } = props;
    const phonePrefix = prefix || dataset.prefix;

    return minLength <= value.replace(/[()_-\s]/g, '').substr(phonePrefix.length).length;
};

/** @namespace Scandipwa/Component/Form/Config/validatePostalCode */
export const validatePostalCode = (props) => {
    const {
        value,
        minLength
    } = props;

    return minLength <= value.replace(/[()_-\s]/g, '').length;
};

/** @namespace Scandipwa/Component/Form/Config/isNotEmpty */
export const isNotEmpty = ({ value }) => value.trim().length > 0;

/** @namespace Scandipwa/Component/Form/Config/validatePasswordMatch */
export const validatePasswordMatch = ({ value }, { password }) => {
    if (typeof password === 'string') {
        return value === password;
    }

    const { current: { value: passwordValue } } = password || { current: {} };

    return value === passwordValue;
};

/** @namespace Scandipwa/Component/Form/Config/validateEmailMatch */
export const validateEmailMatch = ({ value }, { email }) => {
    const { current: { value: emailValue } } = email || { current: {} };
    return value === emailValue;
};

/** @namespace Scandipwa/Component/Form/Config/validateAddressMaxLength */
export const validateAddressMaxLength = ({ value }) => value.trim().length < MAX_ADDRESS_LENGTH;

/** @namespace Scandipwa/Component/Form/Config/validatePostalCodeAgainstState */
export const validatePostalCodeAgainstState = (props) => {
    const {
        value,
        postcodeStateMapping,
        selectedState: { code = '' } = {}
    } = props;

    if (!code || !postcodeStateMapping) {
        return true;
    }

    const statePostCodes = postcodeStateMapping[code];

    if (!statePostCodes) {
        return true;
    }

    return !!statePostCodes.includes(value.substring(0, 2));
};

/** @namespace Scandipwa/Component/Form/Config/validateAddressSpecialCharacters */
// eslint-disable-next-line no-useless-escape
export const validateAddressSpecialCharacters = ({ value }) => !/[^S|s\/N|n][!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/.test(value);

/** @namespace Scandipwa/Component/Form/Config/validateAddressStreetNumber */
export const validateAddressStreetNumber = ({ value }) => /[0-9]|(\b\sS\/N\s)|(\b\sS\/N$)/i.test(value);
// ^^^ should contain at least one digit OR 'S/N', 's/n' with whitespace before, as separator

/** @namespace Scandipwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    email: {
        validate: validateEmail,
        message: __('Please enter a valid email address')
    },
    emails: {
        validate: validateEmails,
        message: __('Email addresses are not valid')
    },
    password: {
        validate: minLength,
        message: __('Password should be at least 8 characters long')
    },
    passwordStrength: {
        validate: validatePasswordStrength,
        message: __('Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.')
    },
    telephone: {
        validate: validateTelephone,
        message: __('This field is required!')
    },
    postalcode: {
        validate: validatePostalCode,
        message: __('This field is required!')
    },
    notEmpty: {
        validate: isNotEmpty,
        message: __('This is a required field!')
    },
    matchPassword: {
        validate: validatePasswordMatch,
        message: __('Password does not match.')
    },
    emailMatch: {
        validate: validateEmailMatch,
        message: __('Email does not match')
    },
    addressMaxLength: {
        validate: validateAddressMaxLength,
        message: __('Address cannot contain more than 45 characters')
    },
    postalcodeToState: {
        validate: validatePostalCodeAgainstState,
        message: __('Incorrect postal code for the selected province.')
    },
    addressSpecialCharacters: {
        validate: validateAddressSpecialCharacters,
        message: __('Address cannot contain special characters!')
    },
    addressContainsStreetNumber: {
        validate: validateAddressStreetNumber,
        message: __('It is necessary to enter both the street name and number. In case there is no number, please enter "S/N".')
    }
});

export default formConfig();
