/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SIZE_SELECTOR_POPUP_ID } from 'Component/SizeSelectorPopup/SizeSelectorPopup.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { HistoryType, LocationType } from 'Type/Router.type';
import { noopFn } from 'Util/Common';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Scandipwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cloudinaryStatus: state.ConfigReducer.isCloudinaryCDNActive,
    isUseThumbnailAsSwatch: state.ConfigReducer.use_thumbnail_as_swatch,
    sizeSelectorPopupPayload: state.PopupReducer.popupPayload[SIZE_SELECTOR_POPUP_ID] || noopFn,
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Scandipwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showSizePopup: (payload) => dispatch(showPopup(SIZE_SELECTOR_POPUP_ID, payload)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace Scandipwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        location: LocationType,
        history: HistoryType,
        cloudinaryStatus: PropTypes.bool.isRequired,
        isUseThumbnailAsSwatch: PropTypes.bool,
        activeOverlay: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        isUseThumbnailAsSwatch: false,
        showSizePopup: PropTypes.func.isRequired,
        sizeSelectorPopupPayload: PropTypes.object.isRequired,
        showNotification: PropTypes.object.isRequired,
        activeOverlay: ''
    };

    state = {
        ...this.state,
        changedProductImage: '',
        changedProductImageHover: '',
        activeImage: 0,
        simplePriceRange: null,
        simplePriceRangeHover: null,
        selectedColorSwatch: {}
    };

    containerFunctions = {
        ...this.containerFunctions,
        changeProductThumbnailPrice: this.changeProductImage.bind(this),
        onActiveImageChange: this.onActiveImageChange.bind(this),
        getIsConfigurableAttributeAvailable: this.getIsConfigurableAttributeAvailable.bind(this),
        filterValidVariants: this.filterValidVariants.bind(this),
        onAddToCartClick: this.onAddToCartClick.bind(this),
        onColorSwatchChange: this.onColorSwatchChange.bind(this)
    };

    containerProps() {
        const {
            cloudinaryStatus,
            isUseThumbnailAsSwatch,
            showSizePopup,
            sizeSelectorPopupPayload,
            showNotification,
            activeOverlay
        } = this.props;
        const {
            activeImage,
            simplePriceRange,
            changedProductImageHover,
            simplePriceRangeHover,
            selectedColorSwatch
        } = this.state;

        return {
            ...super.containerProps(),
            activeImage,
            simplePriceRange,
            cloudinaryStatus,
            isUseThumbnailAsSwatch,
            changedProductImageHover,
            simplePriceRangeHover,
            selectedColorSwatch,
            showSizePopup,
            sizeSelectorPopupPayload,
            showNotification,
            activeOverlay
        };
    }

    onAddToCartClick() {
        const { showSizePopup, product } = this.props;

        showSizePopup({
            product,
            selectedColor: this.getMatchingVariantColor(),
            title: __('Select size')
        });
    }

    onColorSwatchChange(selectedColorSwatch) {
        const { changedProductImageHover } = this.state;

        this.setState({ selectedColorSwatch, changedProductImage: changedProductImageHover });
    }

    onActiveImageChange(e, activeImage) {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            activeImage
        });
    }

    changeProductImage(image, simplePriceRange = null, isHover = false) {
        if (isHover) {
            this.setState({
                changedProductImageHover: image,
                simplePriceRangeHover: simplePriceRange
            });
        } else {
            this.setState({
                changedProductImage: image,
                simplePriceRange
            });
        }
    }

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const { location: { pathname } } = history;

        if (!url) {
            return undefined;
        }

        const matchingVariantColor = this.getMatchingVariantColor();
        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category },
            search: matchingVariantColor ? `?color=${matchingVariantColor}` : ''
        };
    }

    getMatchingVariantColor() {
        const { product: { thumbnail: { url: productThumbnailUrl }, variants } } = this.props;
        const { selectedColorSwatch: { attribute_value: selectedColor } = {} } = this.state || {};

        if (selectedColor) {
            return selectedColor;
        }

        if (!variants.length) {
            return null;
        }

        const { color: firstColor } = variants[0];

        return variants.reduce((acc, variant) => {
            const { thumbnail: { url }, color } = variant;

            if (url === productThumbnailUrl) {
                return color;
            }

            return acc;
        }, firstColor);
    }

    getIsConfigurableAttributeAvailable({ stock_status }) {
        return stock_status === 'IN_STOCK';
    }

    filterValidVariants(variants) {
        const uniqueColors = [];

        return variants.filter((variant) => {
            const { stock_status, color } = variant;

            if (stock_status === 'IN_STOCK' && !uniqueColors.includes(color)) {
                uniqueColors.push(color);

                return variant;
            }

            return false;
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
