/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { lazy, Suspense } from 'react';

import Link from 'Component/Link';

import { BIG_SCREEN_SESSION_ITEM } from '../../../component/BigScreenNewSessionPopup/BigScreenNewSessionPopup.config';
import { BIG_SCREEN_HOMEPAGE } from '../../../route/QrPage/QrPage.config';

export const OfflineNotice = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "offline" */ 'Component/OfflineNotice'));
// eslint-disable-next-line max-len
export const SubscriptionPopup = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "subscription" */ 'Component/SubscriptionPopup'));

/**
 * Header Component plugin
 */
export class HeaderComponentPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        onSubscribeClick: PropTypes.func.isRequired,
        showSubscribePopup: PropTypes.bool.isRequired
    });

    renderQuickLinksButton = (args, callback, instance) => {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    };

    renderLogo = (args, callback, instance) => {
        const { isVisible } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (!isBigScreen) {
            return callback(...args);
        }

        return (
            <Link
              to={ BIG_SCREEN_HOMEPAGE }
              aria-label={ __('Go to homepage by clicking on Puma logo') }
              aria-hidden={ !isVisible }
              tabIndex={ isVisible ? 0 : -1 }
              block="Header"
              elem="LogoWrapper"
              mods={ { isVisible } }
              key="logo"
            >
                { instance.renderLogoImage() }
            </Link>
        );
    };

    renderAdditionalButton(args, callback, instance) {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    }

    renderMiniWishlist(args, callback, instance) {
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isBigScreen) {
            return null;
        }

        return callback(...args);
    }

    renderSubscriptionPopup() {
        return (
            <Suspense fallback={ null }>
                <SubscriptionPopup />
            </Suspense>
        );
    }

    render = (args, callback, instance) => {
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout,
            isLoading,
            siteBaseHasLoaded,
            onSubscribeClick,
            showSubscribePopup
        } = instance.props;
        const isBigScreen = sessionStorage.getItem(BIG_SCREEN_SESSION_ITEM);

        if (isCheckout) {
            return null;
        }

        return (
            <>
                <header
                  block="Header"
                  mods={ {
                      name,
                      isHiddenOnMobile,
                      isCheckout,
                      isLoading
                  } }
                >
                    <nav block="Header" elem="Nav">
                        <div block="Header" elem="NavLeft">
                            { instance.renderLogo() }
                            { instance.renderMenu() }
                        </div>
                        <div block="Header" elem="NavRight">
                        { /* --- BigScreen experience button --- */ }
                            { isBigScreen
                                && (
                                    <button
                                      block="Header"
                                      elem="SubscribeBtn"
                                      aria-label="Subscribe now"
                                      onClick={ onSubscribeClick }
                                    >
                                        { __('SUBSCRIBE HERE') }
                                    </button>
                                ) }
                            { /* ^^^^^ BigScreen experience button ^^^^^ */ }
                        { instance.renderNavigationState() }
                        </div>
                    </nav>
                </header>
                { siteBaseHasLoaded && <Suspense fallback={ null }><OfflineNotice /></Suspense> }
                { showSubscribePopup && this.renderSubscriptionPopup() }
            </>
        );
    };
}

const {
    propTypes,
    renderLogo,
    renderQuickLinksButton,
    renderAdditionalButton,
    renderMiniWishlist,
    render
} = new HeaderComponentPlugin();

export const config = {
    'Scandipwa/Component/Header/Component': {
        'static-member': {
            propTypes
        },
        'member-function': {
            renderLogo,
            renderQuickLinksButton,
            renderAdditionalButton,
            renderMiniWishlist,
            render
        }
    }
};

export default config;
