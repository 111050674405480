/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @author Janis Verins <janis.verins@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import Event, { EVENT_GTM_PAGE_DATA } from '../../../../util/Event';
import BaseEventEvent from '../BaseEvent.event';
import CategoryRefinementsDataEventHandler, {
    CATEGORY_REFINEMENTS_DATA_OBJECT
} from './Handlers/CategoryRefinementsDataEvent.handler';
import CheckoutProductsPageDataEventHandler from './Handlers/CheckoutProductsPageDataEvent.handler';
import CustomerDataEventHandler from './Handlers/CustomerDataEvent.handler';
import EventPageDataEventHandler from './Handlers/EventPageDataEvent.handler';
import FormDataEventHandler, { FORM_DATA_OBJECT } from './Handlers/FormDataEvent.handler';
import NavigationPageDataEventHandler from './Handlers/NavigationPageDataEvent.handler';
import OrderPageDataEventHandler from './Handlers/OrderPageDataEvent.handler';
import ProductPageDataEventHandler, { PDP_DATA_OBJECT } from './Handlers/ProductPageDataEvent.handler';
import ProductsPageDataEventHandler, { PLP_DATA_OBJECT } from './Handlers/ProductsPageDataEvent.handler';

export const PLP_TYPE = 'plp_type';
export const ORDER_TYPE = 'order_type';
export const CHECKOUT_PRODUCTS_TYPE = 'checkout_product_type';
export const PDP_TYPE = 'pdp_type';
export const FORM_TYPE = 'form_type';
export const NAVIGATION_TYPE = 'navigation_type';
export const EVENT_TYPE = 'event_type';
export const ON_SITE_SEARCH_TYPE = 'on_site_search_type';
export const CATEGORY_REFINEMENTS_TYPE = 'category_refinements_type';
export const CUSTOMER_TYPE = 'customer_type';

/** @namespace Gtm/Component/GoogleTagManager/Events/PageDataEvent/PageData/Event */
export class PageDataEvent extends BaseEventEvent {
    eventTypesHandlers = {
        [PLP_TYPE]: this.handlePlpDataUpdate.bind(this),
        [ORDER_TYPE]: this.handleOrderDataUpdate.bind(this),
        [CHECKOUT_PRODUCTS_TYPE]: this.handleCheckoutProductsDataUpdate.bind(this),
        [PDP_TYPE]: this.handlePdpDataUpdate.bind(this),
        [FORM_TYPE]: this.handleFormDataUpdate.bind(this),
        [NAVIGATION_TYPE]: this.handleNavigationUpdate.bind(this),
        [EVENT_TYPE]: this.handleEventDataUpdate.bind(this),
        [CATEGORY_REFINEMENTS_TYPE]: this.handleCategoryRefinementsUpdate.bind(this),
        [CUSTOMER_TYPE]: this.handleCustomerDataUpdate.bind(this)
    };

    bindEvent() {
        Event.observer(EVENT_GTM_PAGE_DATA, (data) => {
            this.handle(data);
        });
    }

    handler(data) {
        const { type, ...layerData } = data;

        if (window.pageData === undefined) {
            window.pageData = {};
        }

        this.eventTypesHandlers[type](layerData);
    }

    async handlePlpDataUpdate(data) {
        const productsPageDataEventHandler = new ProductsPageDataEventHandler(this.getAppState());
        const products = await productsPageDataEventHandler.getProductsPageData(data, this.getPageType());
        this.pushPageData(PLP_DATA_OBJECT, products, 'plpData');
    }

    handleOrderDataUpdate(data) {
        const orderPageDataEventHandler = new OrderPageDataEventHandler(this.getAppState());
        const orderPageData = orderPageDataEventHandler.getOrderPageData(data);
        this.pushPageData('order', orderPageData, 'orderData');
    }

    async handleCheckoutProductsDataUpdate() {
        const checkoutProductsPageDataEventHandler = new CheckoutProductsPageDataEventHandler(this.getAppState());
        const checkoutProducts = await checkoutProductsPageDataEventHandler.getCheckoutProducts();
        this.pushPageData('products', checkoutProducts, 'checkoutProductsData');
    }

    async handlePdpDataUpdate(data) {
        const productPageDataEventHandler = new ProductPageDataEventHandler(this.getAppState());
        const product = await productPageDataEventHandler.getProductPageData(data);
        this.pushPageData(PDP_DATA_OBJECT, product, 'pdpData');
        this.handleProductVariantsDataUpdate(data);
    }

    handleFormDataUpdate(data) {
        const formDataEventHandler = new FormDataEventHandler(this.getAppState());
        const form = formDataEventHandler.getFormData(data);
        this.pushPageData(FORM_DATA_OBJECT, form, 'formData');
    }

    handleCustomerDataUpdate() {
        const customerDataEventHandler = new CustomerDataEventHandler(this.getAppState());
        const customer = customerDataEventHandler.getCustomerData();
        this.pushPageData('customer', customer, 'customerData');
    }

    handleNavigationUpdate(data) {
        const navigationPageDataEventHandler = new NavigationPageDataEventHandler(
            this.getAppState(),
            this.getPageType(),
            data
        );
        const navigation = navigationPageDataEventHandler.getNavigationData(data);
        this.pushPageData('navigation', navigation, 'navigationData');
    }

    handleEventDataUpdate(data) {
        const eventPageDataEventHandler = new EventPageDataEventHandler(this.getAppState());
        const event = eventPageDataEventHandler.getEventsData(data);
        this.pushPageData('event', event);
    }

    handleCategoryRefinementsUpdate(data) {
        const categoryRefinementsDataEventHandler = new CategoryRefinementsDataEventHandler(this.getAppState());
        const categoryRefinements = categoryRefinementsDataEventHandler.getRefinements(data);
        this.pushPageData(CATEGORY_REFINEMENTS_DATA_OBJECT, categoryRefinements, 'categoryRefinements');
    }

    // eslint-disable-next-line no-unused-vars
    handleProductVariantsDataUpdate(data) {
        // const productPageDataEventHandler = new ProductPageDataEventHandler(this.getAppState());
        // window.productVariants = productPageDataEventHandler.getProductVariantsData(data);
    }

    /**
     * Event to be fired every time when pageData object is updated.
     */
    firePageDataEvent(event = '') {
        const PAGE_DATA_EVENT = 'pageData';

        if (window.dataLayer === 'undefined') {
            window.dataLayer = [];
        }

        window.dataLayer.push({ event: PAGE_DATA_EVENT.concat(' | ').concat(event) });
    }

    pushPageData(dataType, data, eventKey = false) {
        window.pageData[dataType] = data;

        if (!eventKey) {
            return;
        }

        this.firePageDataEvent(eventKey);
    }
}

export default PageDataEvent;
