/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-curly-newline */
import { PropTypes } from 'prop-types';
import { PureComponent } from 'react';
import InputMask from 'react-input-mask';

/** @namespace Scandipwa/Component/FieldZip/Component */
export class FieldZipComponent extends PureComponent {
    static propTypes = {
        isRequired: PropTypes.bool,
        placeholder: PropTypes.string,
        validateZip: PropTypes.func.isRequired,
        isZipValid: PropTypes.bool.isRequired,
        invalidMessage: PropTypes.string.isRequired,
        minLength: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string,
        validateForm: PropTypes.bool,
        validation: PropTypes.array,
        setRef: PropTypes.func.isRequired,
        message: PropTypes.string,
        type: PropTypes.string,
        regionId: PropTypes.string.isRequired,
        countryId: PropTypes.string.isRequired
    };

    static defaultProps = {
        isRequired: false,
        placeholder: '',
        onChange: () => {},
        value: '',
        validateForm: false,
        validation: [],
        message: undefined,
        type: 'text'
    };

    state = {
        ...this.state,
        zip: '',
        showMessage: false,
        isTwoDigit: false,
        isFocused: false
    };

    componentDidMount() {
        const { value, minLength } = this.props;

        if (value.length >= minLength) {
            this.setState({ zip: value });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            validateForm,
            validation,
            message,
            regionId,
            countryId
        } = this.props;
        const { regionId: prevRegionId, countryId: prevCountryId } = prevProps;

        const { zip } = this.state;

        if (
            validateForm
            || (validation.length > 0 && message)
            || regionId !== prevRegionId
            || countryId !== prevCountryId
        ) {
            this.handleMessageDisplaying(zip);
        }
    }

    handleMessageDisplaying(zip) {
        const { validateZip } = this.props;

        if (!validateZip(zip)) {
            this.setState({ showMessage: true });
        } else {
            this.setState({ showMessage: false });
        }
    }

    handleInputChange = (e) => {
        const { target: { value } } = e;
        const { validateZip, onChange } = this.props;

        this.setState({ zip: value });
        validateZip(value);

        onChange(value);
    };

    handleInputBlur = () => {
        const { showMessage } = this.state;
        if (!showMessage) {
            this.setState(
                (prevState) => ({
                    ...prevState,
                    showMessage: true
                })
            );
        }

        this.setState({ isFocused: false });
    };

    handleFocus = () => {
        this.setState({ isFocused: true });
    };

    handleClick = (e) => {
        const { target } = e;
        const { zip } = this.state;
        target.selectionStart = zip.indexOf('_');
        target.selectionEnd = zip.indexOf('_');
    };

    maskBuilder = () => {
        const { minLength } = this.props;

        return '9'.repeat(minLength);
    };

    render() {
        const {
            label,
            isRequired,
            isZipValid,
            invalidMessage,
            minLength,
            type,
            setRef,
            attr
        } = this.props;

        const {
            zip,
            showMessage,
            isFocused
        } = this.state;

        return (
            <>
                <div block="FieldInput" elem="Wrapper">
                    <div
                      block="FieldInput"
                      elem="InputWrapper"
                      mods={ {
                          isInvalid: !isZipValid && showMessage && invalidMessage.length > 0,
                          isFilled: zip !== '',
                          isFocused
                      } }
                    >
                        <InputMask
                          mask={ this.maskBuilder() }
                          maskChar="_"
                          onChange={ this.handleInputChange }
                          value={ zip }
                          onBlur={ this.handleInputBlur }
                          onFocus={ this.handleFocus }
                          onClick={ this.handleClick }
                          name="postcode"
                          id="postcode"
                          { ...attr }
                        >
                            { (validProps) => (
                                <input
                                  block="FieldInput"
                                  elem="Input"
                                  ref={ (elem) => setRef(elem) }
                                  { ...validProps }
                                  name="postcode"
                                  id="postcode"
                                  minLength={ minLength }
                                  type={ type }
                                  value={ zip }
                                  { ...attr }
                                />
                            ) }
                        </InputMask>
                        { // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <label
                              block="FieldInput"
                              elem="Label"
                              mods={ { isRequired, isFilled: zip !== '', isFocused } }
                            >
                                { label }
                            </label> }
                    </div>
                </div>
                <span block="FieldInput" elem="Error">{ !isZipValid && showMessage ? invalidMessage : ' ' }</span>
            </>
        );
    }
}

export default FieldZipComponent;
