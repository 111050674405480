/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import CartItemPrice from 'Component/CartItemPrice';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { CartItem as SourceCartItem } from 'SourceComponent/CartItem/CartItem.component';
import { formatImageSetUrl, formatImageUrl } from 'Util/ImageCDN';
import { roundPrice } from 'Util/Price';

/**
 * Cart and CartOverlay item
 * @class CartItem
 * @namespace Scandipwa/Component/CartItem/Component */
export class CartItemComponent extends SourceCartItem {
    static propTypes = {
        ...SourceCartItem.propTypes,
        cloudinaryStatus: PropTypes.bool.isRequired
    };

    state = {
        parent: null
    };

    static defaultProps = {
        ...SourceCartItem.defaultProps,
        shouldRenderQuantity: false
    };

    componentDidMount() {
        this.setState({
            // eslint-disable-next-line react/no-find-dom-node
            parent: findDOMNode(this).parentNode.getAttribute('class')
        });
    }

    renderQuantityInput() {
        const {
            item: { quantity },
            minSaleQuantity,
            maxSaleQuantity,
            handleChangeQuantity
        } = this.props;

        const { parent } = this.state;

        const selectOptions = [];

        // eslint-disable-next-line fp/no-loops,no-magic-numbers,fp/no-let
        for (let i = 1; i <= maxSaleQuantity; i++) {
            selectOptions.push({
                id: i,
                value: i,
                label: i.toString()
            });
        }

        if (parent === 'CartPage-Items') {
            return (
                <div block="CartItem" elem="QtyWrapper">
                    <Field
                      type={ FIELD_TYPE.select }
                      attr={ {
                          id: 'item_qty',
                          name: 'item_qty',
                          value: quantity
                      } }
                      isControlled
                      min={ minSaleQuantity }
                      max={ maxSaleQuantity }
                      mix={ { block: 'CartItem', elem: 'Qty' } }
                      events={ { onChange: handleChangeQuantity } }
                      options={ selectOptions }
                      innerLabel={ __('Quantity') }
                      isLabelInner
                    />
                </div>
            );
        }

        return null;
    }

    renderProductQuantity() {
        const {
            shouldRenderQuantity,
            item: {
                quantity
            }
        } = this.props;

        const { parent } = this.state;

        if (parent === 'CartOverlay-Items' || shouldRenderQuantity) {
            return (
                <div block="CartItem" elem="Quantity">
                <span aria-label={ __('Current product quantity') }>
                    { quantity }
                    { ' ' }
                    { __('pc') }
                    .
                </span>
                </div>
            );
        }

        return null;
    }

    renderProductPrice() {
        const {
            currency_code,
            item,
            item: {
                quantity,
                prices: {
                    row_total: {
                        value: row_total = 0
                    } = {},
                    row_total_including_tax: {
                        value: row_total_incl_tax = 0
                    } = {},
                    total_item_discount: {
                        value: discount_amount = 0
                    } = {}
                } = {}
            },
            isCartOverlay,
            isMobileLayout
        } = this.props;

        const { product: { variants }, sku: itemSku } = item;

        variants.forEach((variant) => {
            const { sku: variantSku } = variant;

            if (variantSku === itemSku) {
                item.variant = variant;
            }
        });

        const {
            variant: {
                price_range: {
                    minimum_price: {
                        regular_price:
                            { value: regularPriceValue },
                        final_price:
                            { value: finalPriceValue }
                    }
                }
            }
        } = item;

        const isDiscounted = regularPriceValue !== finalPriceValue || discount_amount > 0;
        const finalProductPrice = row_total_incl_tax - discount_amount;

        return (
            <CartItemPrice
              row_total={ roundPrice(row_total) }
              discount_amount={ roundPrice(discount_amount) }
              regularPriceValue={ roundPrice(regularPriceValue) }
              finalProductPrice={ roundPrice(finalProductPrice) }
              isDiscounted={ isDiscounted }
              quantity={ quantity }
              currency_code={ currency_code }
              mix={ {
                  block: 'CartItem',
                  elem: 'Price',
                  mods: { isCartOverlay, isMobileLayout, isDiscounted }
              } }
            />
        );
    }

    renderProductConfigurationOption = ([key, attribute]) => {
        const {
            item: {
                product: {
                    configurable_options
                }
            }
        } = this.props;

        const { attribute_label, attribute_code, attribute_value } = attribute;

        if (!Object.keys(configurable_options).includes(key)) {
            return null;
        }

        const {
            [attribute_code]: { // configurable option attribute
                attribute_options
            }
        } = configurable_options;

        const { label } = (attribute_options[attribute_value] || {});

        return (
            <li
              key={ attribute_code }
              aria-label={ attribute_code }
              block="CartItem"
              elem="Option"
            >
                <span block="CartItem" elem="OptionAttributeLabel">
                    { ' ' }
                    { attribute_label }
                    :
                    { ' ' }
                </span>
                <span block="CartItem" elem="OptionAttributeValue">
                    { ' ' }
                    { label }
                    { ' ' }
                </span>
            </li>
        );
    };

    renderProductConfigurations() {
        const {
            item: {
                product: {
                    configurable_options,
                    variants,
                    sku
                }
            },
            isLikeTable,
            getCurrentProduct
        } = this.props;

        if (!variants || !configurable_options) {
            return null;
        }

        const { attributes = [] } = getCurrentProduct() || {};

        if (!Object.entries(attributes).length) {
            return null;
        }

        return (
            <ul
              block="CartItem"
              elem="Options"
              mods={ { isLikeTable } }
            >
                { Object.entries(attributes).map(this.renderProductConfigurationOption) }
                <li
                  key="art_nr"
                  aria-label="art_nr"
                  block="CartItem"
                  elem="Option"
                >
                <span block="CartItem" elem="OptionAttributeLabel">
                    { ' ' }
                    { 'Art.' }
                    :
                    { ' ' }
                </span>
                    <span block="CartItem" elem="OptionAttributeValue">
                    { ' ' }
                        { sku }
                        { ' ' }
                    </span>
                </li>
            </ul>
        );
    }

    renderActions() {
        const {
            isEditing,
            isLikeTable,
            handleRemoveItem
        } = this.props;

        return (
            <div
              block="CartItem"
              elem="Actions"
              mods={ { isEditing, isLikeTable } }
            >
                <button
                  block="CartItem"
                  id="RemoveItem"
                  name="RemoveItem"
                  elem="Delete"
                  aria-label={ __('Remove item from cart') }
                  onClick={ handleRemoveItem }
                >
                    <span>{ __('Remove Product') }</span>
                </button>
            </div>
        );
    }

    renderContent() {
        const {
            isLikeTable,
            item: {
                customizable_options,
                bundle_options
            }
        } = this.props;

        return (
            <figcaption
              block="CartItem"
              elem="Content"
              mods={ { isLikeTable } }
            >
                { this.renderProductName() }
                { this.renderProductOptions(customizable_options) }
                { this.renderProductOptions(bundle_options) }
                { this.renderProductConfigurations() }
                <div block="CartItem" elem="Info">
                    { this.renderProductQuantity() }
                    { this.renderQuantityInput() }
                    { this.renderProductPrice() }
                </div>
                { this.renderActions() }
            </figcaption>
        );
    }

    renderStock() {
        const { item: { product: { stock_status } } } = this.props;

        if (stock_status === 'IN_STOCK') {
            return (
                <span block="CartItem" elem="StockWrapper">
                    <div block="Icon" elem="InStock" />
                    <span block="CartItem" elem="StockStatusLabel">
                        { __('In stock') }
                    </span>
                </span>
            );
        }

        return (
            <span block="CartItem" elem="Stock CarItem-Stock_">
                <span block="CartItem" elem="StockIconWrapper">
                    <span block="CartItem" elem="StockIcon" />
                </span>
                <span>{ __('Out of stock') }</span>
            </span>
        );
    }

    renderImage() {
        const {
            item: { product: { name } }, thumbnail, linkTo, cloudinaryStatus
        } = this.props;

        const { parent } = this.state;

        if (parent === 'CartPage-Items') {
            return (
                <div block="CartItem" elem="ImgWrapper">
                    <Link block="CartItem" elem="PictureWrapper" to={ linkTo }>
                        <Image
                          srcset={ formatImageSetUrl(cloudinaryStatus, thumbnail, ['157', '220', '480', '706']) }
                          sizes={ `(max-width: 375px) 157px,
                                   (max-width: 767px) 480px,
                                   220px` }
                          src={ formatImageUrl(cloudinaryStatus, thumbnail, '480') }
                          mix={ {
                              block: 'CartItem',
                              elem: 'Picture'
                          } }
                          ratio="square"
                          alt={ __('Product %s thumbnail.', name) }
                        />
                    </Link>
                    { this.renderStock() }
                </div>
            );
        }

        return (
            <>
                <Link block="CartItem" elem="PictureWrapper" to={ linkTo }>
                    <Image
                      srcset={ `${ thumbnail }w/157/h/157/ 157w,
                                ${ thumbnail }w/220/h/220/ 220w,
                                ${ thumbnail }w/480/h/480/ 480w,
                                ${ thumbnail }w/706/h/706/ 706w` }
                      sizes={ `(max-width: 375px) 157px,
                               (max-width: 767px) 480px,
                               220px` }
                      src={ `${ thumbnail }w/480/h/480/` }
                      mix={ {
                          block: 'CartItem',
                          elem: 'Picture'
                      } }
                      ratio="square"
                      alt={ `Product ${ name } thumbnail.` }
                    />
                </Link>
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </>
        );
    }

    renderProductName() {
        const {
            item: {
                product: {
                    name
                }
            },
            linkTo
        } = this.props;

        return (
            <Link to={ linkTo } block="CartItem" elem="Heading">
                { name }
            </Link>
        );
    }

    renderWrapper() {
        const { linkTo } = this.props;

        if (!linkTo) {
            return null;
        }

        return (
            <figure block="CartItem" elem="Wrapper">
                { this.renderImage() }
                { this.renderContent() }
            </figure>
        );
    }

    /**
     * Loader has been moved to CartPage level to lock the entire cart
     */
    render() {
        return (
            <li block="CartItem" data-clarity-unmask="true">
                { this.renderWrapper() }
            </li>
        );
    }
}

export default CartItemComponent;
