/* eslint-disable react/no-unknown-property */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Popup from 'Component/Popup';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import browserHistory from 'Util/History';

import { BIG_SCREEN_HOMEPAGE } from '../../route/QrPage/QrPage.config';
import Session from '../../util/Session';
import { SALES_ASSOCIATE_ID_COOKIE } from '../SalesAssociate/SalesAssociate.config';
import Timer from '../Timer';
import { NEW_SESSION_POPUP_ID, START_NEW_SESSION_AFTER_SECONDS } from './BigScreenNewSessionPopup.config';

import './BigScreenNewSessionPopup.style.scss';

/** @namespace Bigscreen/Component/BigScreenNewSessionPopup/Component */
export class BigScreenNewSessionPopupComponent extends PureComponent {
    static propTypes = {
        resetCart: PropTypes.func.isRequired,
        resetGuestCart: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    handleTimeEnd = async () => {
        const { resetCart, resetGuestCart, hideActiveOverlay } = this.props;

        await BrowserDatabase.deleteItem(SALES_ASSOCIATE_ID_COOKIE);

        // Wait till closed, so that the page is not 'freeze' state when redirecting to home
        await hideActiveOverlay();

        await Session.clearBrowserStorage();

        if (isSignedIn()) {
            resetCart();
        } else {
            resetGuestCart();
        }

        browserHistory.push(BIG_SCREEN_HOMEPAGE);
    };

    render() {
        return (
            <Popup
              id={ NEW_SESSION_POPUP_ID }
              mix={ { block: 'BigScreenNewSessionPopup' } }
              isUnCloseable
            >
              <div
                block="BigScreenNewSessionPopup"
                elem="Content"
              >
                  <Timer
                    time={ START_NEW_SESSION_AFTER_SECONDS }
                    onTimeEnd={ this.handleTimeEnd }
                  />
                  <button
                    block="BigScreenNewSessionPopup"
                    elem="Button"
                    mix={ { block: 'Button' } }
                    onClick={ this.handleTimeEnd }
                  >
                      { __('Start new session') }
                  </button>
              </div>
            </Popup>
        );
    }
}

export default BigScreenNewSessionPopupComponent;
