import PropTypes from 'prop-types';

import CmsBlockQuery from 'Query/CmsBlock.query';
import { CmsBlockContainer as SourceCmsBlockContainer } from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace Scandipwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        ...this.propTypes,
        setIsLoading: PropTypes.func
    };

    static defaultProps = {
        ...this.defaultProps,
        setIsLoading: () => {}
    };

    containerProps = () => {
        const { cmsBlock } = this.state;
        const { customComponents = {}, blockType } = this.props;

        return { blockType, cmsBlock, customComponents };
    };

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    _getCmsBlock() {
        const { identifier, setIsLoading } = this.props;

        setIsLoading(true);

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
                setIsLoading(false);
            }
        );
    }
}

export default CmsBlockContainer;
