/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SearchItemContainer as SourceSearchItemContainer
} from 'SourceComponent/SearchItem/SearchItem.container';
import browserHistory from 'Util/History';
import { formatImageUrl } from 'Util/ImageCDN';
import getStore from 'Util/Store';

/** @namespace Scandipwa/Component/SearchItem/Container */
export class SearchItemContainer extends SourceSearchItemContainer {
    getLinkTo() {
        const {
            product,
            product: { url }
        } = this.props;

        if (!url) {
            return {};
        }

        return {
            pathname: url,
            state: { product }
        };
    }

    getImgSrc() {
        const {
            product: {
                thumbnail: { url } = {}
            }
        } = this.props;

        if (!url) {
            return '';
        }
        const cloudinaryStatus = getStore().getState().ConfigReducer.isCloudinaryCDNActive;

        return formatImageUrl(cloudinaryStatus, url, '100');
    }

    handleItemClick(event, url) {
        const { clearSearchResults, clearSearchField, closeSearch } = this.props;

        event.preventDefault();

        clearSearchResults();
        clearSearchField();

        browserHistory.push(url.pathname);

        closeSearch();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
