/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import {
    ORDER_ID_KEY,
    PAGE_DATA_ORDER_STORAGE
} from 'Route/Checkout/Checkout.config';
import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getCartShippingPrice, getCartSubtotal } from 'Util/Cart';

export class OrderPageDataEventHandler {
    constructor(appState) {
        this.appState = appState;

        if (window.pageData.order === undefined) {
            window.pageData.order = {
                id: '',
                email: '',
                status: '',
                currency: '',
                shipBillSameAdress: '',
                shipToCountry: '',
                paymentType: '',
                promoCode: '',
                promoValue: '',
                tax: '',
                discount: '',
                shipping: '',
                grossTotal: '',
                netTotal: '',
                subTotal: '',
                total: '',
                shippingType: '',
                promos: {}
            };
        }

        const { pageData: { order: existingOrderPageData } } = window;

        this.existingOrderPageData = existingOrderPageData;
    }

    getOrderPageData(data) {
        const { mods = {}, email = '' } = data;
        const newOrderPageData = {};

        if (Object.keys(mods).length > 0) {
            const { step = '' } = mods;

            if (step === 'details') {
                const {
                    currency,
                    grossTotal,
                    netTotal,
                    promoCode,
                    shipping,
                    shippingType,
                    subTotal,
                    tax,
                    total
                } = this.existingOrderPageData;

                Object.assign(
                    newOrderPageData,
                    {
                        id: this.getOrderId(),
                        currency,
                        grossTotal,
                        netTotal,
                        promoCode,
                        shippingType,
                        shipping,
                        subTotal,
                        tax,
                        total,
                        promos: this.getPromos()
                    }
                );
            }
        }

        if (!Object.keys(mods).length > 0 || mods.step !== 'details') {
            const orderData = BrowserDatabase.getItem(PAGE_DATA_ORDER_STORAGE) || {};

            const {
                CartReducer: {
                    cartTotals: {
                        coupon_code = '',
                        prices: {
                            applied_taxes,
                            quote_currency_code,
                            discount,
                            subtotal_including_tax: { value: subtotal },
                            grand_total: { value: grandTotal }
                        }
                    }
                }
            } = this.appState;
            const discountAmount = discount?.amount?.value || 0;

            const taxAmount = applied_taxes.reduce(
                ({ amount: { value } }, { amount: { value: currentValue } }) => value + currentValue,
                { amount: { value: 0 } }
            );

            Object.assign(
                newOrderPageData,
                {
                    id: this.getOrderId(),
                    email: this.getOrderEmail() || email,
                    currency: quote_currency_code || '',
                    promoCode: coupon_code || '',
                    promoValue: discountAmount * -1 || 0,
                    tax: taxAmount || 0,
                    discount: discountAmount * -1 || 0,
                    grossTotal: subtotal || 0,
                    netTotal: subtotal + discountAmount || orderData.netTotal || 0,
                    subTotal: subtotal || 0,
                    total: this.getGrandTotalFromBrowserDatabase(grandTotal) || 0
                }
            );
        }

        if (isSignedIn()) {
            const { addressInfo = {} } = data;

            if (Object.keys(addressInfo).length > 0) {
                Object.assign(newOrderPageData, this.getShipToCountry(addressInfo));
            }
        }

        if (data.addressInfo !== undefined || data.mods !== undefined || data.billing_address !== undefined) {
            const {
                // eslint-disable-next-line no-unused-vars
                addressInfo, billing_address, mods, ...restOfData
            } = data;

            return Object.assign(this.existingOrderPageData, restOfData, newOrderPageData);
        }

        return Object.assign(this.existingOrderPageData, data, newOrderPageData);
    }

    getGrandTotalFromBrowserDatabase(grandTotal) {
        const { pageData: { order: { shipping } } } = window;

        return grandTotal + shipping;
    }

    getPromos() {
        const promos = BrowserDatabase.getItem('promos') || {};
        const { pageData: { order: { promoValue } } } = window;

        if (promoValue === 0) {
            return [];
        }

        if (Object.keys(promos).length === 0) {
            return [];
        }

        const promosArray = [];
        promosArray.push(promos);

        return promosArray;
    }

    getOrderId() {
        return BrowserDatabase.getItem(ORDER_ID_KEY);
    }

    getOrderEmail() {
        const { MyAccountReducer: { isSignedIn, customer } } = this.appState;

        if (isSignedIn) {
            const { email } = customer;

            return email;
        }

        const { CheckoutReducer: { email } } = this.appState;

        return email;
    }

    getShipToCountry(signedInAddress = {}) {
        window.util = { signedInAddress };
        const { shipping_address: { country_id } } = signedInAddress[0];
        const objectToReturn = {};
        objectToReturn.shipToCountry = country_id.toLowerCase();

        return objectToReturn;
    }

    getOrderStatus() {
        const { AnalyticsReducer: { orderStatus } } = this.appState;

        return orderStatus;
    }
}

export default OrderPageDataEventHandler;
