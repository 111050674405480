/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

/**
 * Event list
 */
export const EVENT_GENERAL = 'general';
export const EVENT_IMPRESSION = 'productImpression';
export const EVENT_PRODUCT_CLICK = 'productClick';
export const EVENT_ADD_TO_CART = 'addToCart';
export const EVENT_REMOVE_FROM_CART = 'removeFromCart';
export const EVENT_PRODUCT_DETAIL = 'productDetail';
export const EVENT_PURCHASE = 'purchase';
export const EVENT_CHECKOUT = 'checkout';
export const EVENT_CHECKOUT_OPTION = 'checkoutOption';
export const EVENT_USER_REGISTER = 'userRegister';
export const EVENT_USER_LOGIN = 'userLogin';
export const EVENT_NOT_FOUND = 'notFound';
export const EVENT_CATEGORY_FILTERS = 'categoryFilters';
export const EVENT_ADDITIONAL = 'additional';
export const EVENT_PAGE_DATA = 'pageData';
export const EVENT_ORDER_CONFIRMATION = 'orderConfirmation';
export const EVENT_PWA_PURCHASE = 'PWA-purchase';
export const EVENT_GENERAL_GA4 = 'general_ga4';
export const EVENT_SELECT_ITEM_GA4 = 'select_item';
export const EVENT_VIEW_ITEM_LIST_GA4 = 'view_item_list';
export const EVENT_CATEGORY_SORT_CHANGE_GA4 = 'sort_selected';
export const EVENT_CATEGORY_REFINEMENT_SELECT_GA4 = 'refinement_selected';
export const EVENT_VIEW_ITEM_GA4 = 'view_item';
export const EVENT_ADD_TO_CART_GA4 = 'add_to_cart';
export const EVENT_VIEW_CART_GA4 = 'view_cart';
export const EVENT_BEGIN_CHECKOUT_GA4 = 'begin_checkout';
export const EVENT_ADD_SHIPPING_INFO_GA4 = 'add_shipping_info';
export const EVENT_ADD_PAYMENT_INFO_GA4 = 'add_payment_info';
export const EVENT_REMOVE_FROM_CART_GA4 = 'remove_from_cart';
export const EVENT_ADD_TO_WISHLIST_GA4 = 'add_to_wishlist';
export const EVENT_VIEW_PROMOTION = 'view_promotion';
export const EVENT_SELECT_PROMOTION = 'select_promotion';
export const EVENT_PURCHASE_GA4 = 'ga4_purchase';
export const EVENT_SORETO_PURCHASE = 'thankyouevent';

/**
 * Const
 */
export const DATA_LAYER_NAME = 'dataLayer';
export const GROUPED_PRODUCTS_PREFIX = 'GROUPED_PRODUCTS_';
export const GROUPED_PRODUCTS_GUEST = `${ GROUPED_PRODUCTS_PREFIX }GUEST`;

export const GTM_INJECTION_TIMEOUT = 500;
