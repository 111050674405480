/* eslint-disable no-undef */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { getCartItems } from '../../../Util/Cart';

/**
 * MyAccount Dispatcher plugin
 */
export class MyAccountDispatcherPlugin {
    signIn = async (args, callback) => {
        const result = await callback(...args);
        const [{ email }] = args;

        if (!window.webExtendEvents.includes('setEmail')) {
            // Identifying the visitor with email address.
            // Should run before the other commands
            ScarabQueue.push(['setEmail', email]);
            window.webExtendEvents.push('setEmail');
        }

        if (!window.webExtendEvents.includes('cart')) {
            ScarabQueue.push(['cart', getCartItems()]);
            window.webExtendEvents.push('cart');
        }

        if (window.webExtendEvents.length) {
            // Firing the ScarabQueue. Should be the last call on the page.
            ScarabQueue.push(['go']);
            window.webExtendEvents.push('go');
        }

        window.webExtendEvents = []; // Resetting the list

        return result;
    };
}

const {
    signIn
} = new MyAccountDispatcherPlugin();

export const config = {
    'Scandipwa/Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn
        }
    }
};

export default config;
