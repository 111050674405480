/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { TotalsType } from 'Type/MiniCart.type';
import { formatCurrency } from 'Util/Price';

import PromptFreeShippingMessage from './PromptFreeShippingMessage.component';

/** @namespace Scandipwa/Component/PromptFreeShippingMessage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    totals: state.CartReducer.cartTotals,
    freeShippingSubtotal: state.ConfigReducer.free_shipping_subtotal,
    currencyCode: state.ConfigReducer.default_display_currency_code,
    isEnabled: state.ConfigReducer.freeShippingMessageEnabled,
    message: state.ConfigReducer.freeShippingMessage,
    messageStyle: state.ConfigReducer.freeShippingMessageStyle
});

/** @namespace Scandipwa/Component/PromptFreeShippingMessage/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/**
 * Prompt free shipping message container
 * @class PromptFreeShippingMessageComponent
 * @namespace Scandipwa/Component/PromptFreeShippingMessage/Container */
export class PromptFreeShippingMessageContainer extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
        freeShippingSubtotal: PropTypes.number.isRequired,
        currencyCode: PropTypes.string.isRequired,
        isEnabled: PropTypes.bool.isRequired
    };

    containerProps = () => ({
        promptedSum: this.getPromptedSum()
    });

    getPromptedSum() {
        const {
            totals: {
                prices: {
                    grand_total: { value: grand_total = 0 }
                }
            },
            freeShippingSubtotal,
            currencyCode
        } = this.props;

        const promptedSum = freeShippingSubtotal - grand_total;

        if (promptedSum <= 0) {
            return 0;
        }

        return `${formatCurrency(currencyCode)}${parseFloat(promptedSum).toFixed(2)}`;
    }

    render() {
        const { isEnabled } = this.props;

        if (!isEnabled) {
            return null;
        }

        return (
            <PromptFreeShippingMessage
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromptFreeShippingMessageContainer);
