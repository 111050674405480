/**
 * Forter compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'Util/Store';

export const ADYEN_CC = 'adyen_cc';

/** @namespace Fortergraphql/Scandipwa/App/Plugin/Component/CheckoutBillingContainer/CheckoutBilling/Container */
export class CheckoutBillingContainer {
    _aroundGetPaymentData = (args, callback = () => {}, instance) => {
        const { paymentMethod: code } = instance.state;

        switch (code) {
        case ADYEN_CC:
            // This value doesn't update properly when injected with mapStateToProps
            const { firstSix, lastFour } = getStore().getState().CheckoutReducer;

            const { additional_data: otherData } = callback(...args);

            const additional_data = {
                ...otherData,
                // TODO: Take cc_type out of redux
                // cc_type,
                first_six: firstSix,
                last_four: lastFour,
                // eslint-disable-next-line no-magic-numbers
                checkout_time: Math.floor(new Date().getTime() / 1000)
            };

            return {
                code,
                additional_data
            };
        default:
            return callback(...args);
        }
    };
}

export const { _aroundGetPaymentData } = new CheckoutBillingContainer();

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            _getPaymentData: {
                position: 110, // We have to be before the AdyenGraphQl extension
                implementation: _aroundGetPaymentData
            }
        }
    }
};
