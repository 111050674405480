/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable import/no-cycle,@scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { LocationType } from 'Type/Router.type';
import BrowserDatabase from 'Util/BrowserDatabase';
import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import { EVENT_GTM_GENERAL_GA4, EVENT_GTM_GENERAL_INIT, EVENT_GTM_PAGE_DATA } from '../../util/Event';
import Event from '../../util/Event/Event';
import AddToCartEvent from './events/AddToCart.event';
import CheckoutEvent from './events/Checkout.event';
import CheckoutOptionEvent from './events/CheckoutOption.event';
import GeneralEvent from './events/General.event';
import ImpressionEvent from './events/Impression.event';
import PageDataEvent, {
    CUSTOMER_TYPE,
    EVENT_TYPE,
    NAVIGATION_TYPE
} from './events/PageDataEvent/PageData.event';
import ProductClickEvent from './events/ProductClick.event';
import ProductDetailEvent from './events/ProductDetail.event';
import PurchaseEvent from './events/Purchase.event';
import PWAPurchaseEvent from './events/PWAPurchase.event';
import RemoveFromCartEvent from './events/RemoveFromCart.event';
import SoretoPurchaseEvent from './events/SoretoPurchase.event';
import UserLoginEvent from './events/UserLogin.event';
import UserRegisterEvent from './events/UserRegister.event';
import AddPaymentInfoGA4Event from './GA4_events/AddPaymentInfo.event';
import AddShippingInfoGA4Event from './GA4_events/AddShippingInfo.event';
import AddToCartGA4Event from './GA4_events/AddToCart.event';
import AddToWishlistGA4Event from './GA4_events/AddToWishlist.event';
import BeginCheckoutGA4Event from './GA4_events/BeginCheckout.event';
import CategoryRefinementSelectGA4Event from './GA4_events/CategoryRefinementSelect.event';
import CategorySortChangeGA4Event from './GA4_events/CategorySortChange.event';
import GeneralGA4Event from './GA4_events/General.event';
import PurchaseGA4Event from './GA4_events/Purchase.event';
import RemoveFromCartGA4Event from './GA4_events/RemoveFromCart.event';
import SelectItemGA4Event from './GA4_events/SelectItem.event';
import SelectPromotionGA4Event from './GA4_events/SelectPromotion.event';
import ViewCartGA4Event from './GA4_events/ViewCart.event';
import ViewItemGA4Event from './GA4_events/ViewItem.event';
import ViewItemListGA4Event from './GA4_events/ViewItemList.event';
import ViewPromotionGA4Event from './GA4_events/ViewPromotion.event';
import {
    DATA_LAYER_NAME,
    EVENT_ADD_PAYMENT_INFO_GA4,
    EVENT_ADD_SHIPPING_INFO_GA4,
    EVENT_ADD_TO_CART,
    EVENT_ADD_TO_CART_GA4,
    EVENT_ADD_TO_WISHLIST_GA4,
    EVENT_BEGIN_CHECKOUT_GA4,
    EVENT_CATEGORY_REFINEMENT_SELECT_GA4,
    EVENT_CATEGORY_SORT_CHANGE_GA4,
    EVENT_CHECKOUT,
    EVENT_CHECKOUT_OPTION,
    EVENT_GENERAL,
    EVENT_GENERAL_GA4,
    EVENT_IMPRESSION,
    EVENT_ORDER_CONFIRMATION,
    EVENT_PAGE_DATA,
    EVENT_PRODUCT_CLICK,
    EVENT_PRODUCT_DETAIL,
    EVENT_PURCHASE,
    EVENT_PURCHASE_GA4,
    EVENT_PWA_PURCHASE,
    EVENT_REMOVE_FROM_CART,
    EVENT_REMOVE_FROM_CART_GA4,
    EVENT_SELECT_ITEM_GA4,
    EVENT_SELECT_PROMOTION,
    EVENT_SORETO_PURCHASE,
    EVENT_USER_LOGIN,
    EVENT_USER_REGISTER,
    EVENT_VIEW_CART_GA4,
    EVENT_VIEW_ITEM_GA4,
    EVENT_VIEW_ITEM_LIST_GA4,
    EVENT_VIEW_PROMOTION,
    GROUPED_PRODUCTS_GUEST,
    GROUPED_PRODUCTS_PREFIX,
    GTM_INJECTION_TIMEOUT
} from './GoogleTagManager.config';
import Scripts from './Scripts';

/**
 * Google tag manager wrapper
 * This should have 1 instance to avoid multiple initializations
 */
export class GoogleTagManagerComponent extends PureComponent {
    static propTypes = {
        gtm: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        state: PropTypes.shape(),
        // eslint-disable-next-line react/no-unused-prop-types
        dispatch: PropTypes.func,
        updateEventData: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        eventDataArray: PropTypes.array.isRequired,
        location: LocationType.isRequired
    };

    static defaultProps = {
        gtm: {
            enabled: false,
            gtm_id: ''
        },
        state: {},
        dispatch: () => {}
    };

    /**
     * Event list used in GTM
     * All used events should be registered in this data mapping
     * TODO: 404 page, categoryFilter, additional events
     *
     * @type {{[p: string]: General|Purchase|CheckoutEvent|OrderData|Impression|AddToCartEvent|ProductClickEvent|ProductDetail|CheckoutOptionEvent|RemoveFromCartEvent}}
     */
    static eventList = {
        [EVENT_GENERAL]: GeneralEvent,
        [EVENT_PURCHASE]: PurchaseEvent,
        [EVENT_CHECKOUT]: CheckoutEvent,
        [EVENT_CHECKOUT_OPTION]: CheckoutOptionEvent,
        [EVENT_IMPRESSION]: ImpressionEvent,
        [EVENT_ADD_TO_CART]: AddToCartEvent,
        [EVENT_PRODUCT_CLICK]: ProductClickEvent,
        [EVENT_PRODUCT_DETAIL]: ProductDetailEvent,
        [EVENT_REMOVE_FROM_CART]: RemoveFromCartEvent,
        [EVENT_USER_REGISTER]: UserRegisterEvent,
        [EVENT_USER_LOGIN]: UserLoginEvent,
        [EVENT_PAGE_DATA]: PageDataEvent,
        [EVENT_ORDER_CONFIRMATION]: PurchaseEvent,
        [EVENT_PWA_PURCHASE]: PWAPurchaseEvent,
        [EVENT_GENERAL_GA4]: GeneralGA4Event,
        [EVENT_SELECT_ITEM_GA4]: SelectItemGA4Event,
        [EVENT_VIEW_ITEM_LIST_GA4]: ViewItemListGA4Event,
        [EVENT_CATEGORY_SORT_CHANGE_GA4]: CategorySortChangeGA4Event,
        [EVENT_CATEGORY_REFINEMENT_SELECT_GA4]: CategoryRefinementSelectGA4Event,
        [EVENT_VIEW_ITEM_GA4]: ViewItemGA4Event,
        [EVENT_ADD_TO_CART_GA4]: AddToCartGA4Event,
        [EVENT_VIEW_CART_GA4]: ViewCartGA4Event,
        [EVENT_BEGIN_CHECKOUT_GA4]: BeginCheckoutGA4Event,
        [EVENT_ADD_SHIPPING_INFO_GA4]: AddShippingInfoGA4Event,
        [EVENT_ADD_PAYMENT_INFO_GA4]: AddPaymentInfoGA4Event,
        [EVENT_PURCHASE_GA4]: PurchaseGA4Event,
        [EVENT_REMOVE_FROM_CART_GA4]: RemoveFromCartGA4Event,
        [EVENT_ADD_TO_WISHLIST_GA4]: AddToWishlistGA4Event,
        [EVENT_VIEW_PROMOTION]: ViewPromotionGA4Event,
        [EVENT_SELECT_PROMOTION]: SelectPromotionGA4Event,
        [EVENT_SORETO_PURCHASE]: SoretoPurchaseEvent
    };

    /**
     * GoogleTagManager instance
     *
     * @type {GoogleTagManager}
     */
    static instance = null;

    /**
     * Push data to GTM
     *
     * @param event
     * @param data
     */
    static pushData(event, data) {
        if (this.getInstance()) {
            this.getInstance().processDataPush(event, data);
        }
    }

    /**
     * Append Data Layer with new data
     *
     * @param data
     */
    static appendData(data) {
        if (this.getInstance()) {
            this.getInstance().addDataLayer(data);
        }
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|BaseEvent}
     */
    static getEvent(name) {
        if (this.getInstance()) {
            return this.getInstance().getEvent(name);
        }

        return null;
    }

    /**
     * Get GoogleTagManager Instance
     *
     * @return {GoogleTagManager}
     */
    static getInstance() {
        return this.instance;
    }

    /**
     * Is GoogleTagManager enabled
     *
     * @type {boolean}
     */
    enabled = false;

    /**
     * Prepared Data Layer
     *
     * @type {{}}
     */
    currentDataLayer = {};

    /**
     * Data layer name
     *
     * @type {string}
     */
    currentDataLayerName = DATA_LAYER_NAME;

    /**
     * groupedProducts
     */
    groupedProductsStorageName = GROUPED_PRODUCTS_GUEST;

    /**
     * Event data object
     *
     * @type {{}}
     */
    events = {};

    /**
     * Data storage for event data
     *
     * @type {{}}
     */
    eventDataStorage = {};

    /**
     * Grouped product storage
     */
    groupedProducts = {};

    /**
     * Did mount
     */
    componentDidMount() {
        this.initialize();
    }

    /**
     * If props is updated
     */
    componentDidUpdate(prevProps) {
        const { location: { pathname, search }, eventDataArray } = this.props;
        const { location: { pathname: prevPathname, search: prevSearch }, eventDataArray: pEventDataArray } = prevProps;

        const pathChanged = pathname.split('?')[0] !== prevPathname.split('?')[0];
        const searchChanged = search.replace(/[?&]*/g, '').replace(/(color|size)=[0-9]*/g, '')
            !== prevSearch.replace(/[?&]*/g, '').replace(/(color|size)=[0-9]*/g, '');

        if (
            (pathChanged || searchChanged)
            // Checking this as the cart overlay button is pushing '/checkout' to history which afterwards redirects
            // to '/checkout/shipping' and does redundant navigation event call.
            && (pathname !== '/checkout/shipping' && prevPathname !== '/checkout')
        ) {
            this.updatePageDataNavigation(pathChanged);
        }

        if (Object.keys(eventDataArray).length !== Object.keys(pEventDataArray).length) {
            this.updatePageDataEvent();
        }

        this.initialize();
    }

    /**
     * Unregister component
     */
    componentWillUnmount() {
        this.destruct();
    }

    /**
     * Get event by name
     *
     * @param name
     * @return {null|*}
     */
    getEvent(name) {
        if (Object.keys(this.events).indexOf(name) >= 0) {
            return this.events[name];
        }

        return null;
    }

    /**
     * Set event storage
     *
     * @param event
     * @param data
     */
    setEventStorage(event, data) {
        this.eventDataStorage[event] = data;
    }

    /**
     * Set grouped products to storage
     *
     * @param groupedProducts
     */
    setGroupedProducts(groupedProducts) {
        BrowserDatabase.setItem(groupedProducts, this.groupedProductsStorageName, ONE_MONTH_IN_SECONDS);
        this.groupedProducts = groupedProducts;
    }

    /**
     * Get reference to grouped products
     */
    getGroupedProducts() {
        return this.groupedProducts;
    }

    /**
     * Get reference to the storage
     *
     * @param event
     * @return {*}
     */
    getEventDataStorage(event) {
        if (typeof this.eventDataStorage[event] === 'undefined') {
            this.resetEventDataStorage(event);
        }

        return this.eventDataStorage[event];
    }

    /**
     * Returns Event object data
     *
     * @returns {{widget: string, name: string}}
     */
    getPageDataEventData() {
        return {
            name: 'page_view',
            widget: 'page'
        };
    }

    /**
     * Reset storage data
     *
     * @param event
     */
    resetEventDataStorage(event) {
        this.eventDataStorage[event] = {};
    }

    /**
     * Updates navigation object data for pageData object
     */
    updatePageDataNavigation(shouldUpdatePageData) {
        const { updateEventData } = this.props;

        Event.dispatch(EVENT_GTM_PAGE_DATA, {
            type: NAVIGATION_TYPE,
            updateEventData: shouldUpdatePageData && updateEventData,
            eventData: this.getPageDataEventData()
        });
    }

    /**
     * Updates event object data for pageData object
     */
    updatePageDataEvent() {
        const { eventDataArray } = this.props;

        Event.dispatch(EVENT_GTM_PAGE_DATA, {
            type: EVENT_TYPE,
            eventData: eventDataArray
        });
    }

    dispatchPageDataCustomerDataEvent() {
        Event.dispatch(EVENT_GTM_PAGE_DATA, { type: CUSTOMER_TYPE });
    }

    updateGroupedProducts() {
        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    updateGroupedProductsStorageName(name) {
        this.groupedProductsStorageName = name
            ? `${ GROUPED_PRODUCTS_PREFIX }${ name }`
            : GROUPED_PRODUCTS_GUEST;

        this.updateGroupedProducts();
    }

    /**
     * Register GTM event handlers
     */
    registerEvents() {
        this.events = Object.entries(GoogleTagManagerComponent.eventList).reduce((acc, [name, Event]) => {
            acc[name] = new Event(name, this);
            acc[name].bindEvent();

            return acc;
        }, {});
    }

    /**
     * Send event and data to the GoogleTagManager
     *
     * @param event
     * @param data
     */
    processDataPush(event, data) {
        if (this.enabled) {
            this.addDataLayer(data);

            if (this.debug) {
                // eslint-disable-next-line no-console
                console.log(event, data);
            }

            window[this.currentDataLayerName].push({
                event,
                ...this.currentDataLayer
            });

            this.currentDataLayer = {};
        }
    }

    /**
     * Unregister/ destruct all parts related to the gtm
     */
    destruct() {
        Object.values(this.events).forEach((event, name) => {
            event.destruct();
            // eslint-disable-next-line fp/no-delete
            delete this.events[name];
        });

        this.events = {};
    }

    /**
     * Append current DataLayer with new nata
     *
     * @param data
     */
    addDataLayer(data) {
        if (this.enabled) {
            this.currentDataLayer = { ...this.currentDataLayer, ...data };
        }
    }

    /**
     * Initialize GTM
     */
    initialize() {
        const { gtm: { enabled } } = this.props;

        if (this.enabled || !enabled || GoogleTagManagerComponent.getInstance()) {
            return;
        }

        this.enabled = true;
        GoogleTagManagerComponent.instance = this;

        this.initGroupedProducts();
        this.injectGTMScripts();
        this.registerEvents();
        this.updatePageDataNavigation(true);
        this.dispatchPageDataCustomerDataEvent();

        Event.dispatch(EVENT_GTM_GENERAL_GA4);

        Event.dispatch(EVENT_GTM_GENERAL_INIT);
    }

    /**
     * Insert GTM scripts to the document
     */
    injectGTMScripts() {
        const { gtm: { gtm_id: id } } = this.props;

        const script = document.createElement('script');
        script.innerHTML = Scripts.getScript(
            { id, dataLayerName: this.currentDataLayerName }
        );

        setTimeout(() => {
            document.head.insertBefore(script, document.head.childNodes[0]);
        }, GTM_INJECTION_TIMEOUT);
        window[this.currentDataLayerName] = window[this.currentDataLayerName] || [];
    }

    /**
     * Initialize grouped products
     */
    initGroupedProducts() {
        const customer = BrowserDatabase.getItem(CUSTOMER);

        this.updateGroupedProductsStorageName(customer && customer.id);

        this.groupedProducts = BrowserDatabase.getItem(this.groupedProductsStorageName) || {};
    }

    /**
     * Skip render
     *
     * @return {null}
     */
    render() {
        return null;
    }
}

export default GoogleTagManagerComponent;
