/**
 * @author Vladislavs Zimnikovs <vladislavs.zimnikovs@scandiweb.com>
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package puma-mx
 */

import { CartQuery as SourceQuery } from 'SourceQuery/Cart.query';
import Field from 'Util/Query/Field';

/** @namespace Scandipwa/Query/Cart/Query */
export class CartQuery extends SourceQuery {
    getApplyCouponMutation(couponCode, quoteId) {
        const input = {
            cart_id: quoteId,
            coupon_code: couponCode
        };

        return new Field('applyCouponToCart')
            .addArgument('input', 'ApplyCouponToCartInput', input)
            .addField(this._getCouponDataFields(couponCode))
            .addField(this._getCart());
    }

    getCouponDataQuery(couponCode) {
        return this._getCouponDataFields(couponCode);
    }

    _getCouponDataFields(couponCode) {
        return new Field('getCouponData')
            .addArgument('couponCode', 'String!', couponCode)
            .addFieldList(['id', 'code', 'name', 'type', 'amount']);
    }

    _getGAFields() {
        return new Field('categoriesForGA')
            .addFieldList(['id', 'name']);
    }

    _getCartItemsFields() {
        return [
            'id',
            'uid',
            'sku',
            'quantity',
            this._getCartItemProduct(),
            this._getCartItemPricesField(),
            this._getCartDownloadableProductLinkField(),
            this._getCartBundleProductFragment(),
            this._getCartConfigurableProductFragment(),
            this._getCartVirtualProductFragments(),
            this._getCartSimpleProductFragments()
            // this._getGAFields()
        ];
    }

    _getPricesFields() {
        return [
            'applied_rule_ids',
            'coupon_code',
            'quote_currency_code',
            this._getGrandTotalField(),
            this._getSubtotalInclTaxField(),
            this._getSubtotalExclTaxField(),
            this._getSubtotalWithDiscountExclTaxField(),
            this._getDiscountField(),
            this._getAppliedTaxesField(),
            'discount_tax_compensation'
        ];
    }
}

export default new CartQuery();
